export const SET_BUY_NOW_MODAL_AEM_PROPS = 'SET_BUY_NOW_MODAL_AEM_PROPS'
export const SET_BUY_NOW_CART_INFO = 'SET_BUY_NOW_CART_INFO'
export const SET_BUY_NOW_INIT_CHECKOUT_INFO = 'SET_BUY_NOW_INIT_CHECKOUT_INFO'
export const CLEAR_BUY_NOW_CART_INFO = 'CLEAR_BUY_NOW_CART_INFO'
export const SET_BUY_NOW_MODAL_OPENED = 'SET_BUY_NOW_MODAL_OPENED'
export const SET_BUY_NOW_API_ERROR = 'SET_BUY_NOW_API_ERROR'
export const UPDATE_BUY_NOW_CART_CTMONEY = 'UPDATE_BUY_NOW_CART_CTMONEY'
export const UPDATE_BUY_NOW_CART_REUSABLE_BAG = 'UPDATE_BUY_NOW_CART_REUSABLE_BAG'
export const SET_FETCHED_BUY_NOW_ACCOUNT_INFO = 'SET_FETCHED_BUY_NOW_ACCOUNT_INFO'
export const SET_FETCHING_BUY_NOW_ADD_TO_CART = 'SET_FETCHING_BUY_NOW_ADD_TO_CART'
