import { Dispatch } from '@reduxjs/toolkit'

import {
    VEHICLE_SELECTOR_TABS,
    VEHICLE_SELECTOR,
} from '../types/automotiveBuyBox/automotiveBuyBoxSelector.actionTypes.constants'
import { vehicleSelectorTabsAction, vehicleSelectorAction } from '../actionCreators/automotiveBuyBox.actionCreators'
import { VehicleSelector, VehicleSelectorTabs } from '../reducers/automotiveBuyBox.reducer'

export const vehicleSelectorTabs =
    (requestPayload: VehicleSelectorTabs) =>
    (dispatch: Dispatch<{ type: typeof VEHICLE_SELECTOR_TABS; payload: VehicleSelectorTabs }>): void => {
        dispatch(vehicleSelectorTabsAction(requestPayload))
    }

export const vehicleSelector =
    (requestPayload: VehicleSelector) =>
    (dispatch: Dispatch<{ type: typeof VEHICLE_SELECTOR; payload: VehicleSelector }>): void => {
        dispatch(vehicleSelectorAction(requestPayload))
    }
