/* eslint  no-magic-numbers: 0 */
import {
    BrandDTO,
    BulkDeliveryOptionDTO,
    CartItemFulFillmentType,
    CartOrderEntries,
    FeeMessage,
    Price,
    ProductOption,
    ProductStatus,
    Rebate,
    VehicleInformation,
} from '../redux/models/cart.interface'
import { FitmentTypeEnum } from '../globalConstants'
import { FulfillmentOptionAvailable } from './providers/provider.type'
import { ProductBrandDTO, ProductSku } from '../redux/models/product.interface'
import { RebateData } from '@nl/lib'

export interface AnalyticsInfo {
    [key: string]: any
}

export interface PageAnalyticsInfo extends AnalyticsInfo {
    isPageLoadSent: boolean
    pageName?: string
    [key: string]: any
}

export interface SpaPageAnalytics {
    name?: string
    url?: string
}

export interface SpaPageEventAnalytics {
    event: string
    page: SpaPageAnalytics
}

export interface SSOLoginEventAnalytics {
    eventType: string
    successLogin: boolean
}

export interface CLPLoginInitEventAnalytics {
    SSOlogin: boolean
    action: string
}

export interface IRewardsRegistrationAnalytics {
    source: string
    sourceURL: string
    step: string
    linkOption: string
    action: string
    actionResult?: IRegistrationAnalyticsActionResult
}

export interface IRegistrationAnalyticsActionResult {
    error?: boolean
    success?: boolean
}

export interface VideoModalAnalytics {
    action: string
    category: string
    label: string
    value: string
}
export interface VideoEventAnalytics {
    event: string
    eventParameters: VideoModalAnalytics
}

export interface CompleteSearchAnalytics {
    userInput: string | null
    redirect: string
    searchType: string | null
    suggestionType: string | null
    suggestionValue: string | null
    numberSearchResults?: string | null
    isTypeaheadUsed: string | null
    pathwaysWidgetId?: string | null
    searchLocation?: string | null
}

export enum MagicNumber {
    MINUS_TWO = -2,
    MINUS_ONE = -1,
    ZERO = 0,
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FIVE = 5,
    TEN = 10,
    ELEVEN = 11,
    FIFTEEN = 15,
    NINETY_NINE = 99,
    HUNDRED = 100,

    SIXHUNDRED = 600,
    TWOHUNDRED = 200,
    FOURHUNDRED = 400,
    FOUR = 4,
    MINUS_FOUR = -4,
    MINUS_FIVE = -5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
    SIXTEEN = 16,
    ONETHOUSAND = 1000,
    FIVETHOUSAND = 5000,
    SIXTYTHOUSAND = 60000,
    FOURHUNDREDFOUR = 404,
    NINE = 9,
    TWENTYFOUR = 24,
    SIXTY = 60,
    FIFTYNINE = 59,
    EIGHTTHOUSAND = 8000,
    THOUSANDTWOHUNDRED = 1200,
}

export interface VariantData {
    options?: ProductOption[]
}

export interface AvailabilityData {
    fulfillment?: CartItemFulFillmentType
}

export interface InstallationsData {
    hasStoreAutoServiceCenter?: boolean
}

export interface PriceData {
    currentPrice?: Price
}

export interface SflAvailabilityData extends AvailabilityData {
    productStatus?: ProductStatus
    sellable?: boolean
    quantity?: number
}

export interface FulfilmentData extends AvailabilityData {
    isBulk?: boolean
    bulkDeliveryOptions?: BulkDeliveryOptionDTO[]
}

export interface ProductCartDataForAnalytics {
    entry?: CartOrderEntries
    quantityAdded?: number
    entryType?: string
    vehicleInformation?: VehicleInformation | null
    fitmentTypeCode?: FitmentTypeEnum
    options?: ProductOption[]
    merchCategories?: string[]
    feeMessages?: FeeMessage[]
    rebate?: Rebate
    fulfillment?: CartItemFulFillmentType
    productStatus?: ProductStatus
    badges?: string[]
    brand?: BrandDTO
    isPackagedItem?: boolean
    hasStoreAutoServiceCenter?: boolean
    name?: string
    baseProduct?: string
    currentPrice?: Price
    quantity?: number
    Rating?: number
    numberOfReviews?: number | null
    shipFrom?: string
    code?: string
    fulfillmentOptionAvailable?: FulfillmentOptionAvailable
    staggeredGroup?: string
    fulfillmentType?: string
    atcLocation?: string
}

export type AddToCartData = Array<ProductCartDataForAnalytics> | ProductCartDataForAnalytics

export interface RecommendationDataForAnalytics {
    code: string
    skus?: ProductSku[]
    badges?: string[]
    currentPrice?: Price
    brand?: ProductBrandDTO
    title?: string
    rating?: number
    ratingsCount?: number
    rebate?: RebateData
    feeMessages?: FeeMessage[]
}

export interface AnalyticsEntryData {
    isService: boolean
    isFitted: boolean
    merchCategories: string[]
    fee: string | undefined
    rebate: string | undefined
}

/**
 * Interface for linkRewardsAnalytics return types
 */
export interface ILinkRewardsAnalyticsEvent {
    event: string
    eventParameters: {
        source: string
        sourceURL: string
        step: string
        linkOption?: string
        action?: string
        error?: boolean
        success?: boolean
    }
}
