import React from 'react'
import { TabButtonProps, ButtonType } from './Button.types'
import { Role } from '../../types'
import { TabDefaultProps, TabButtonClass } from './Button.constants'

/**
 * Core Tab Button component
 * @param {TabButtonProps} props Tab Button props
 * @param {React.ForwardedRef<HTMLButtonElement>} TabButton with ref
 * @returns {JSX.Element} returns TabButton component
 */
const TabButton: React.FC<TabButtonProps> = React.forwardRef<
    HTMLButtonElement,
    React.PropsWithChildren<TabButtonProps>
>(({ ...props }, ref): JSX.Element => {
    const { children, disabled, customClass, onClick, onKeyDown, id, a11y, qm, tabIndex, state, gap } = props
    const customClassName = customClass ? ` ${customClass} ` : ''
    const additionalInnerClass = gap ? { className: gap } : {} // can be extended
    const qmData = qm ? { [`data-qm-${qm?.type}`]: qm?.value } : {}
    const tabStateClass = state ? ` ${TabButtonClass}--${state}` : ''

    return (
        <button
            tabIndex={tabIndex}
            role={Role.TAB}
            id={id}
            className={`${TabButtonClass}${customClassName}${tabStateClass}`}
            ref={ref}
            disabled={disabled}
            type={ButtonType.DEFAULT}
            onClick={onClick}
            onKeyDown={onKeyDown}
            aria-disabled={a11y?.disabled}
            aria-label={a11y?.label ? a11y?.label : ''}
            aria-controls={a11y?.controls}
            aria-selected={a11y?.selected ? a11y?.selected : false}
            {...qmData}>
            {children && <span {...additionalInnerClass}>{children}</span>}
        </button>
    )
})

TabButton.displayName = 'TabButton'

TabButton.defaultProps = TabDefaultProps

export default TabButton
