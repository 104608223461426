import { Dispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
    redeemCTMoneyAction,
    redeemCTMoneyErrorAction,
    resetRedeemCtMoneyAction,
} from '../actionCreators/ctMoneyRedeemUpsell.actionCreators'
import { CtMoneyRedeemType } from '../models/ctMoneyRedeem.interface'
import { ctMoneyRedeemService } from '../../services/ctMoneyRedeemService/ctMoneyRedeemService'
import { ErrorResponse } from '../models/upsellingRewards.interface'
import { setShowSpinner } from '../slices/spinner.slice'

/**
 * calling redeemCTMoney api
 * @param {CtMoneyRedeemType} payload
 * @param {string} code
 * @param {string} amount
 * @return {Promise<void>}
 */
export const redeemedCTMoney =
    (payload: CtMoneyRedeemType, code?: string, amount?: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return ctMoneyRedeemService
            .ctMoneyRedeemedService(code, amount, payload)
            .then(resp => {
                dispatch(redeemCTMoneyAction(resp?.data))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                const errorResponse = error?.response ? error.response.data : error
                dispatch(redeemCTMoneyErrorAction(errorResponse as ErrorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner({ show: false }))
            })
    }

/**
 * reset ctMoney redeem values
 * @return {Dispatch}
 */
export const resetRedeemCtMoney =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetRedeemCtMoneyAction())
    }
