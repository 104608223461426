import store from '../store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/reducers'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppThunkDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
