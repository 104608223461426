import { BREAKPOINTS, PREFIX } from '../../config'
import { onElementAvailable } from '@nl/lib/src/utils/elementAvailable'

/**
 * slimpromocarousel - customize the component based on the screen size
 */

const SlimPromoCarousel = (): void => {
    const tiles = '.slimpromocarousel:has(.swiper-slide)'
    const slimPromoCarousel = '.slimpromocarousel'
    const left = `.${PREFIX}-slimpromocarousel-left`
    const right = `.${PREFIX}-slimpromocarousel-right`
    const slide = '.swiper-slide'
    const element = document.querySelector(slimPromoCarousel)
    const sidebyside = document.querySelector(`.${PREFIX}-slimpromocarousel-sidebyside`)

    if (sidebyside) {
        const leftTile = `${left} ${slide}`
        const rightTile = `${right} ${slide}`
        const sidebysideRecommendations = sidebyside?.querySelectorAll(`${slimPromoCarousel} .recommendation`)
        const screensize = document.documentElement.clientWidth
        if (screensize > BREAKPOINTS.mobileMaxWidth) {
            const slimpromotiles = sidebyside?.querySelectorAll(`.${PREFIX}-slim-tile-promo`)
            const promoTileHeights = Array.from(slimpromotiles).map(tile => (tile as HTMLElement).offsetHeight)
            const tallestPromoTileHeight = Math.max(...promoTileHeights)
            slimpromotiles.forEach(tile => {
                ;(tile as HTMLElement).style.minHeight = tallestPromoTileHeight.toString()
            })
        }
        const displayRecommendation = () => {
            displayCertona()
            adjustTileHeight()
            const sidebysideDots = sidebyside?.querySelectorAll(`${slimPromoCarousel} .swiper-pagination`)
            const leftDotsStyle =
                sidebysideDots[0] && window.getComputedStyle(sidebysideDots[0]).getPropertyValue('display')
            const rightDotsStyle =
                sidebysideDots[1] && window.getComputedStyle(sidebysideDots[1]).getPropertyValue('display')
            const swiperWrappers = document.querySelectorAll(`${slimPromoCarousel} .swiper-wrapper`)
            if (rightDotsStyle === 'none' && leftDotsStyle === 'none' && screensize >= BREAKPOINTS.desktopMinWidth) {
                swiperWrappers.forEach(swiperWrapper => {
                    ;(swiperWrapper as HTMLElement).style.marginBottom = '16px'
                })
            } else {
                swiperWrappers.forEach(swiperWrapper => {
                    ;(swiperWrapper as HTMLElement).style.marginBottom = 'revert-layer'
                })
            }
        }

        const displayCertona = () => {
            const leftTiles = document.querySelectorAll(leftTile)
            const rightTiles = document.querySelectorAll(rightTile)
            if (rightTiles && rightTiles.length >= 3 && leftTiles && leftTiles.length >= 3) {
                sidebysideRecommendations.forEach(recommendation => {
                    ;(recommendation as HTMLElement).style.display = 'block'
                })
            }
        }

        const adjustTileHeight = () => {
            const card = sidebyside.querySelector(slide)
            card &&
                new ResizeObserver(() => {
                    const sidebysideTiles = sidebyside?.querySelectorAll(slide)
                    const tileHeights = Array.from(sidebysideTiles).map(tile => (tile as HTMLElement).offsetHeight)
                    const tallestTileHeight = Math.max(...tileHeights)
                    sidebysideTiles.forEach(tile => {
                        ;(tile as HTMLElement).style.minHeight = tallestTileHeight.toString()
                    })
                }).observe(card)
        }

        onElementAvailable(leftTile, () => {
            const leftRecommendations = document.querySelector(`${left} .recommendation`)
            ;(leftRecommendations as HTMLElement).style.display = 'none'
        })

        onElementAvailable(rightTile, () => {
            const rightRecommendations = document.querySelector(`${right} .recommendation`)
            ;(rightRecommendations as HTMLElement).style.display = 'none'
        })

        onElementAvailable(leftTile, () => {
            onElementAvailable(rightTile, () => {
                displayRecommendation()
                window.addEventListener('resize', displayRecommendation)
            })
        })
    } else {
        const displayRecommendation = () => {
            const screensize = document.documentElement.clientWidth
            const numberOfTiles = element?.querySelectorAll(slide).length
            const recommendation = document.querySelector(`${slimPromoCarousel} > .recommendation`)
            const swiperWrapper = document.querySelector(`${slimPromoCarousel} .swiper-wrapper`)
            const dots = document.querySelector(`${slimPromoCarousel} .swiper-pagination`)
            const dotsStyle = dots && window.getComputedStyle(dots).getPropertyValue('display')

            // eslint-disable-next-line no-magic-numbers
            if (screensize < BREAKPOINTS.wideDesktopWidth && numberOfTiles && numberOfTiles < 5) {
                ;(recommendation as HTMLElement).style.display = 'none'
            } else {
                ;(recommendation as HTMLElement).style.display = 'block'
            }

            if (dotsStyle === 'none' && screensize >= BREAKPOINTS.desktopMinWidth) {
                ;(swiperWrapper as HTMLElement).style.marginBottom = '16px'
            } else {
                ;(swiperWrapper as HTMLElement).style.marginBottom = 'revert-layer'
            }
        }

        onElementAvailable(tiles, () => {
            displayRecommendation()
            window.addEventListener('resize', displayRecommendation)
        })
    }
}

SlimPromoCarousel.displayName = 'SlimPromoCarousel'

export { SlimPromoCarousel }
