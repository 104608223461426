import { replaceCardValue, errorCodes, maskingPart } from '../../globalConstants/global.constant'
import { magicNumber } from '../magicNumber'
import { ErrorMessageType } from './formatCardNumber.type'

/**
 *
 * @param cardNumber
 * @param lastFourDigitsRegex
 * @param separator
 */
export const cardNumberFormatted = (
    cardNumber: string,
    lastFourDigitsRegex: string | RegExp,
    separator = ' - ',
): string => {
    return cardNumber.replace(lastFourDigitsRegex, '$& ').trim().split(' ').join(separator)
}

/**
 * @param {string} cardNumber - credit card to mask
 * @param {string} splitOn - regex on every char to split
 * @param {string} separator - default separator is space
 * @param {number} newCardLength - create new card length
 * @returns {string}
 */
export const formatCreditCard = (
    cardNumber: string,
    splitOn?: string | RegExp,
    separator?: string,
    newCardLength?: number,
): string => {
    // default regex needs to be stored in globalConstants
    const regularCardLength = cardNumber.length === magicNumber.SIXTEEN
    const customCardLength = !regularCardLength && newCardLength
    const regex = splitOn ?? /(.{1,4})/g

    const buildCardNumber = customCardLength ? cardNumber.padStart(newCardLength, '*').match(/(.{1,4})/g) : cardNumber

    return buildCardNumber
        .toString()
        .split(regex)
        .join(separator ?? ' ')
}

/**
 * Replaces hashing in the string with hashedValue value provided
 * @param {string} message - message to display
 * @param {string} hashedValue - value that will be replaced with
 * @param {number} count - iteration count of how many times hashing is repeated
 * @param {boolean} appendHash - should there any be values appended for hashing
 * @returns {string} - return string with replaced value
 */
export const hashCreditCard = (message: string, hashedValue: string, count?: number, appendHash?: boolean): string => {
    const hashedSting = message?.split(replaceCardValue).join(hashedValue)
    const strWithIteration = count ? hashedSting?.slice(count) : hashedSting
    // eslint-disable-next-line no-unsafe-optional-chaining
    return appendHash && count ? `${hashedValue?.repeat(count) + hashedSting?.slice(count)}` : strWithIteration
}

/**
 *
 * @param message
 */
export const getCutHashCreditCard = (message: string): string => {
    const lastDigits = message.split(replaceCardValue)
    return `${maskingPart} ${lastDigits.pop()}`
}

/**
 * checking error validations based on maximum length and regex condtion
 * @param {string} number
 * @param {number} cardLength
 * @param {RegExp} regex
 * @returns {boolean}
 */
export const getCardErrorCheck = (number: string, cardLength: number, regex: RegExp): boolean => {
    return number.length !== cardLength || !regex?.test(number)
}

/**
 * validate the card input if its empty or invalid
 * @param {string} value
 * @param {string} lengthErrorMsg
 * @param {string} cardNumberErrorMessage
 * @param {number} cardLength
 * @param {RegExp} cardRegex
 * @param {boolean} checkZeroValueEntry
 * @returns {void}
 */
export const validateCardInput = (
    value: string,
    lengthErrorMsg?: string,
    cardNumberErrorMessage?: string,
    cardLength?: number,
    cardRegex?: RegExp,
    checkZeroValueEntry?: boolean,
): string => {
    if (!value || (checkZeroValueEntry && Number(value) === magicNumber.ZERO)) {
        return cardNumberErrorMessage
    } else if (getCardErrorCheck(value, cardLength, cardRegex) && lengthErrorMsg) {
        return lengthErrorMsg
    } else {
        return ''
    }
}

/**
 * sends the error to card input
 * @param {string} errorMsg
 * @param {boolean} isValid
 * @returns {string | undefined} returns inline message
 */
export const sendError = (errorMsg?: string, isValid?: boolean): string | undefined => (isValid ? errorMsg : '')

/**
 * matching errorCodes comes from api
 * @param {string} errorCode
 * @returns {string}
 */
export const checkValidationError = (errorCode: string): string => {
    const errors = [
        errorCodes.error00121,
        errorCodes.error00000,
        errorCodes.error00142,
        errorCodes.error00143,
        errorCodes.error00144,
        errorCodes.error00145,
        errorCodes.error00146,
    ]

    return errors.includes(errorCode as errorCodes) ? errorCode : ''
}

/**
 * matching system errorCodes comes from api
 * @param {string} errorCode
 * @returns {string}
 */
export const checkSystemError = (errorCode: string): string => {
    const errors = [
        errorCodes.error00106,
        errorCodes.error00130,
        errorCodes.error00030,
        errorCodes.error00138,
        errorCodes.error00129,
    ]

    return errors.includes(errorCode as errorCodes) ? errorCode : ''
}

/**
 * Return cds error messages from the aem Props
 * @param { string } errorCode
 * @param { number } code
 * @param { ErrorMessageType } messages
 * @param { Function } setErrorRedirect
 * @returns { void }
 */
// eslint-disable-next-line sonar/no-unused-function-argument
export const getErrorMessage = (errorCode: string | undefined, code: number, messages: ErrorMessageType): string => {
    const { errorMsg, redirectError } = messages
    const validationError = checkValidationError(errorCode)
    const systemError = checkSystemError(errorCode)

    switch (errorCode) {
        case errorCodes.error00211:
        case errorCodes.error00140:
        case errorCodes.error00122:
        case errorCodes.error00043: // return inline error message
            return errorMsg
        case systemError: // After 3 Attempts show the rate limiting error
            return errorCodes.error00106
        case validationError: // Card validation success but earnCtMoney gets failed
            return errorCodes.error00121
        case errorCodes.error00141:
            return errorCodes.error00141
        case errorCodes.error00055:
            return redirectError
        default:
            return errorMsg
    }
}
