import React from 'react'
import { useSelector } from 'react-redux'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { Badges, badgesAndPrioritiesType, isArrayNotEmpty } from '@nl/lib'
import { PREFIX } from '../../../config'
import { analyticsAttributes } from '../../../globalConstants'
import { BadgesWrapperProps } from './BadgesWrapper.type'
import { loyaltyBadge } from '../BuyBox.type'
import { useCalculatedLoyaltyValue } from '../../../hooks/useCalculatedLoyaltyValue.hook'

const badgesWrapperClass = `${PREFIX}-buy-box__badges`

/**
 * BadgesWrapper component
 * @param {BadgesWrapperProps} props - props for BadgesWrapper
 * @return {JSX.Element} returns BadgesWrapper component
 */
const BadgesWrapper = ({
    badges,
    isStaggered,
    hideDisclaimer,
    badgesDisplayOptions,
    saleEndDaySoonMessage,
    productSaleEndDate,
    saleEndDisableShift,
    loyaltyValue,
}: BadgesWrapperProps): JSX.Element => {
    const { quantitySelected } = useSelector(productSelector)
    const calculatedLoyaltyValue = useCalculatedLoyaltyValue(
        loyaltyValue as loyaltyBadge,
        isStaggered,
        quantitySelected,
    )

    return (
        <>
            {isArrayNotEmpty(badges) && !isStaggered ? (
                <div className={badgesWrapperClass}>
                    <Badges
                        badges={badges}
                        badgesAndPriorities={badgesDisplayOptions as badgesAndPrioritiesType}
                        hideDisclaimer={hideDisclaimer}
                        clickable={true}
                        saleEndDaySoonMessage={saleEndDaySoonMessage}
                        salePriceEndDate={productSaleEndDate}
                        analyticsLabelValue={analyticsAttributes.eventParameters.labels.clickableBadge}
                        saleEndDisableShift={saleEndDisableShift}
                        loyaltyValue={calculatedLoyaltyValue}
                    />
                </div>
            ) : null}
        </>
    )
}

BadgesWrapper.displayName = 'BadgesWrapper'

export default BadgesWrapper
