import React, { useEffect, useCallback } from 'react'

import { RootState } from '../../redux/reducers'
import interceptorConfig from '../../services/ResponseInterceptors/interceptorConfig.service'
import { fetchInterceptorPromiseState } from '../../redux/actionCreators/tokenUpdate.actionCreators'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import {
    globalPartialAuthCode,
    gigyaTokenMissedErrorCode,
    invalidTokenErrorCode,
    tokenMissedErrorCode,
    hybAuthErrorCode,
} from '../../globalConstants/cdsErrorCodes'
import appCacheService from '../../utils/appCacheService'
import getHttpClient from '../../httpClient'
import { isArrayNotEmpty } from '@nl/lib'
import { authenticationSelector } from '../../redux/selectors/commonContent.selectors'
import { tokenStateSelector } from '../../redux/selectors/tokenState.selectors'
import {
    actionPerformedOnScreenSelector,
    errorInterceptorDataSelector,
} from '../../redux/selectors/errorInterceptorData.selectors'
import { redirectToLoginPage, deleteCookie } from './PageInit.helper'
import { ErrorInterceptorDataType } from './PageInit.type'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import { enableSingleSignOn, profileName, ssoLoginHandler } from '../../helpers/ciam.helper'
import { InterceptorConfig } from '../../httpClient/client.type'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'
import { logNewRelicRedirectToCLP } from '../NewRelic/newRelic.helper'
import { getUserUID } from '../../helpers/liteProfile.helper'

const PartialAuthModal = (): JSX.Element => {
    const tokenState = useAppSelector(tokenStateSelector)
    const pageType = getPageType()
    const { interceptorState } = useAppSelector((state: RootState) => state.interceptorState)
    const errorInterceptorData = useAppSelector(errorInterceptorDataSelector)
    const actionPerformedOnScreen = useAppSelector(actionPerformedOnScreenSelector)
    const partialAuthList = interceptorConfig.getInterceptorPartialAuthList()
    const dispatch = useAppDispatch()
    const { partiallyAuthScreenId = '' } = useAppSelector(authenticationSelector)

    useEffect(() => {
        const cdsCodeErrors = [
            globalPartialAuthCode,
            gigyaTokenMissedErrorCode,
            invalidTokenErrorCode,
            tokenMissedErrorCode,
            hybAuthErrorCode,
        ]
        const screensNotToRedirect = [
            window?.ODP?.globalLinks.loginPageLink,
            window?.ODP?.globalLinks.checkoutPageLink,
            window?.ODP?.globalLinks.passwordResetPageLink,
            window?.ODP?.globalLinks.registrationPageLink,
        ]

        const errorResponse = (errorInterceptorData as unknown as ErrorInterceptorDataType)?.response
        const errorResponseData = errorResponse?.data
        const errorInterceptorCode = errorResponseData?.errCode ?? ''

        if (cdsCodeErrors.includes(errorInterceptorCode) && pageType !== pageTypes.emailVerificationPage) {
            const errorResponseRequest = errorResponse?.request
            const errorRequestAPI = errorResponseRequest?.qurl
            const logInfo = {
                resource: 'autoRedirectToCLP',
                gigyaUID: getUserUID(),
                errorInfo: errorResponseData,
                cdsAPI: errorRequestAPI,
            }

            if (errorInterceptorCode === invalidTokenErrorCode) {
                deleteCookie('Access-Token')
            }
            if (window.location.pathname === window?.ODP?.globalLinks.checkoutPageLink) {
                logNewRelicRedirectToCLP(logInfo)

                if (enableSingleSignOn()) {
                    const url = new URL(window.location.href)
                    const redirectUrl = url.origin + url.pathname
                    ssoLoginHandler(redirectUrl, true, profileName())
                } else {
                    gigyaLoadFunctions(partiallyAuthScreenId, undefined, '', false, undefined, actionPerformedOnScreen)
                    window.gigya.showSignInModal = true
                    dispatch(fetchInterceptorPromiseState(true))
                }
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (!screensNotToRedirect.includes(window.location.pathname)) {
                logNewRelicRedirectToCLP(logInfo)

                // eslint-disable-next-line no-warning-comments
                // TODO (if applicable): use 'remember' value from CLP -
                // Determines which screen to show i.e., Welcome Back or Sign In
                if (enableSingleSignOn()) {
                    const url = new URL(window.location.href)
                    const redirectUrl = url.origin + url.pathname
                    ssoLoginHandler(redirectUrl, true, profileName())
                } else {
                    redirectToLoginPage(window?.ODP?.globalLinks.loginPageLink)
                }
            }
        }
    }, [partiallyAuthScreenId, errorInterceptorData, dispatch, actionPerformedOnScreen, pageType])

    useEffect(() => {
        // if the user JWT token is available get the user profile
        if (tokenState === 'SUCCESS') {
            // gigya screenset is not closing on own , closing using sdk
            window.gigya.accounts?.hideScreenSet?.({ screenSet: 'ODP-LoginSignIn' })
        }
    }, [tokenState, dispatch])

    const updateInterceptorList = useCallback(
        (tokenValue: string) => {
            if (isArrayNotEmpty(partialAuthList)) {
                const updatedToken = appCacheService.gigyaJWTToken.get()
                partialAuthList.forEach((partialAuthReq: Record<string, unknown>) => {
                    const { rejectFunc } = partialAuthReq
                    if (tokenValue.toUpperCase() === 'SUCCESS' && !!updatedToken) {
                        getHttpClient().customInterceptorCall(partialAuthReq as unknown as InterceptorConfig)
                    } else (rejectFunc as (reason: any) => void)(Error)
                })
                interceptorConfig.clearAll()
            }
        },
        [partialAuthList],
    )

    useEffect(() => {
        if (tokenState && interceptorState) {
            updateInterceptorList(tokenState)
        }
    }, [tokenState, updateInterceptorList, interceptorState])

    return <>{null}</>
}

export default PartialAuthModal
