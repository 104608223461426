import { setGigyaScreenDataReducer } from './gigyaScreenSet.reducer'
import { ScreenSetResponse } from './../../components/GigyaScreen/gigyaScreen.type'
import { combineReducers, Reducer } from '@reduxjs/toolkit'

import { productReducer } from './product.reducer'
import { stickyReducer, StickyState } from './stickyTOC.reducer'
import { stickyBBReducer, StickyBuyBarState } from './stickyBuyBar.reducer'
import { productDataReducer, ProductDataState, PRODUCT_DATA_STATE_KEY } from './productData.reducer'
import { recommendationsDataReducer, RecommendationsDataState } from './recommendationsData.reducer'
import { registerFormReducer, registerFormState } from './loyaltyRegisterForm.reducer'
import { ToggleState, toggleReducer } from './toggleButton.reducer'
import { HideVehicleSelectorBannerState, hideVehicleSelectorBannerReducer } from './hideVehicleSelectorBanner.reducer'
import { TokenState, tokenUpdateReducer } from './tokenUpdate.reducer'
import { wishlistState, wishlistReducer, successErrorState, successErrorReducer } from './wishlist.reducer'
import { CommonContentState, commonContentReducer } from './commonContent.reducer'
import { SearchState, searchReducer } from './search.reducer'
import { CartState, cartReducer } from './cart.reducer'
import { SharedCartState, sharedCartReducer } from './sharedCart.reducer'
import { LinkMasterCardState, linkMasterCardReducer } from './linkMasterCard.reducer'
import { tncContentState, getTncContentReducer } from './linkRewards.reducer'
import { userProfileReducer, UserProfileDataState } from './user.profile.reducer'
import { OtpFlowDataState } from '../models/otp.interface'
import { otpReducer } from '../reducers/otp.reducer'
import { SecurityDataState } from '../models/security.interface'
import { securityReducer } from '../reducers/security.reducer'
import { linkLoyaltyCardReducer, LinkLoyaltyCardState } from './linkLoyaltyCard.reducer'
import { linkLoyaltyValidateReducer, LinkLoyaltyValidateState } from './linkLoyaltyValidate.reducer'
import { tryAgainReducer, tryAgainState } from './tryAgain.reducer'
import { storeDetailsReducer } from './storeDetails.reducer'
import { StoreResponse as storeState } from '../models/storeDetails.interface'
import { profilePaymentReducer, profilePaymentState } from './profile.payment.reducer'
import { contactUsReducer, ContactUsState } from './contactUs.reducer'
import { checkoutButtonClickReducer, CheckButtonClickState } from './checkoutButtonClick.reducer'
import { NotificationPreferenceState, notificationPreferenceReducer } from './notification.preferences.reducer'
import { ShoppingPreferenceState, shoppingPreferenceReducer } from './shopping.preferences.reducer'
import { mergeLoyaltyCardReducer, MergeLoyaltyCardState } from './mergeLoyaltyCard.reducer'
import { toastMessageDataReducer, ToastMessageDataState } from './toastMessage.reducer'
import { mergeLoyaltyValidateReducer, MergeLoyaltyValidateState } from './mergeLoyaltyValidate.reducer'
import { globalDataReducer, globalDataState } from './globalData.reducer'
import {
    triangleTransactionHistoryReducer,
    TriangleTransactionHistoryState,
} from './triangleTransactionHistory.reducer'
import { CheckoutState, checkoutReducer } from './checkout.reducer'
import { getCertonaData } from './certona.reducer'
import { OffersState, offersReducer } from './offers.reducer'
import { CertonaResponseType } from '../../certona/certona.type'
import { secondaryNavigationReducer } from './secondaryNavigation.reducer'
import { Category, InitialCategoryPayloadType } from '../models/secondaryNavigation.interface'
import { Headers, headerPropsReducer } from './headerProps.reducer'
import { orderHistoryState, orderHistoryReducer } from './orderHistory.reducer'
import { dynamicComponentData } from './dynamicComponent.reducer'
import { DynamicComponentState } from '../models/dynamicComponent.interface'
import { orderConfirmationState, orderItemsDetailsReducer } from './orderConfirmation.reducer'

import { PageAnalyticsState, pageAnalyticsReducer } from './pageAnalytics.reducer'
import { checkoutDrawerReducer } from './checkoutDrawer.reducer'
import { CategoryResponseById } from '../models/category.interface'
import { getCategoryIdData } from './category.reducer'
import { InterceptorPromiseState, InterceptorStateReducer } from './interceptorStateReducer'
import { orderDetailsReducer, OrderDetailsState } from './orderDetails.reducer'
import { timeoutErrorState, fetchInterceptorErrorReducer } from './fetchInterceptorError.reducer'
import spinnerSliceReducer, { SpinnerState } from '../slices/spinner.slice'
import { BuyboxState, buyboxReducer } from './buybox.reducer'
import { InitialVehicleTireState, updatedVehicleTiresDataReducer } from './automotiveVehicle.reducer'
import { CheckoutDrawerState } from '../models/checkout.interface'
import { fetchCriticalFitmentDataReducer, CriticalFitmentState } from './getCriticalFitment.reducer'
import { orderCancellationReasonsReducer, CancelOrderReasonsState } from './cancelOrderReasons.reducer'
import { cancelOrderReducer, CancelOrderState } from './cancelOrder.reducer'
import { suggestReducer, SuggestState } from './suggest.reducer'
import { roadRatingReducer, RoadRatingState } from './roadRating.reducer'
import { AutomotivePackage } from './PackageLanding.reducer'
import { PackageLandingInitialState } from '../../components/AutomotivePackage/PackageLanding/PackageLanding.type'
import { extraHardwareReducer, extraHardwareState } from './extraHardware.reducer'
import { estimateFeesReducer, estimateFeesState } from './estimateFees.reducer'
import { siteMapReducer } from './siteMap.reducer'
import { deliveryOptionsReducer, deliveryOptionsState } from './deliveryOptions.reducer'
import { breadcrumbDataAuthouredState, breadcrumbAuthouredReducer } from './breadcrumbAuthoured.reducer'
import { saveForLaterListReducer, SaveForLaterState } from './saveForLater.reducer'
import { docLoadState, docLoadStatusReducer } from './docLoadStatusReducer'
import { BVSectionReducer, BVSectionState } from './BVSection.reducer'
import { AOABookingFormState } from '../models/aoaBookingForm.interface'
import { aoaBookingFormReducer } from './aoaBookingForm.reducer'
import { aoaDateTimeStepReducer } from './aoaDateTimeStep.reducer'
import { AOADateTimeState } from '../models/aoaDateTimeStep.interface'
import { VehicleBannerState } from '../models/vehicleBanner.interface'
import { vehicleBannerReducer } from './vehicleBanner.reducer'
import { popupInfoReducer, popupInfoState } from './popupInfoReducer'
import { siteWidePromoCodeReducer, SiteWidePromoCodeState } from './siteWidePromoCode.reducer'
import { aoaHeaderReducer } from './aoaHeader.reducer'
import { AOAHeaderState } from '../models/aoaHeader.interface'
import { OgImageTagState, ogImageTagReducer } from './ogImageTag.reducer'
import { balloonAddonsReducer, balloonAddonsState } from './balloonAddons.reducer'
import { ProductState } from '../models/product.interface'
import { popupsToggleStateReducer, popupsToggleState, POPUPS_TOGGLE_STATE_KEY } from './popupsToggleState.reducer'
import { vehiclesDataInterface } from '../models/vehiclesData.interface'
import { vehiclesDataReducer } from './vehiclesData.reducer'
import { prerenderReducer, IPrerenderState } from './prerenderState'
import { BrowseOnlyState, browseOnlyReducer } from './browseOnly.reducer'
import { ProfilePaymentCardReducer, profilePaymentCardState } from './profilePaymentCard.reducer'
import { triangleRewardsUpsellReducer, TriangleRewardsUpsellState } from './triangleRewardsUpsell.reducer'
import { estimateDeliveryDateReducer, estimateDeliveryDateState } from './estimateDeliveryDate.reducer'
import { ctMoneyRedeemReducer, CTMoneyRedeemUpsellState } from './ctMoneyRedeem.reducer'
import { preselectedVehicle } from '../models/preselectedVehicle.interface'
import { preselectedVehicleReducer } from './preselectedVehicle.reducer'
import { AutomotiveBuyBoxState, automotiveBuyBoxReducer } from './automotiveBuyBox.reducer'
import { ctMoneyBalanceReducer, CTMoneyBalanceState } from './ctMoneyBalance.reducer'
import { weatherTechReducer } from './weatherTech.reducer'
import { WeatherTech } from '../models/weatherTech.interface'
import { OptionalRequestHeaderParameter } from '../models/optionalRequestHeaderParameter.interface'
import { optionalRequestHeaderParameterReducer } from './optionalRequestHeaderParameter.reducer'
import { automotiveAccordionReducer, AutomotiveAccordionState } from './automotiveAccordion.reducer'
import { stickyBannerReducer, stickyBannerState } from './stickyBanner.reducer'
import { addToSalesAlertsReducer, addToSalesAlertsState } from './addToSalesAlerts.reducer'
import { sponsoredAdsReducer } from './sponsoredAds.reducer'
import { SponsoredAdsState } from '../../services/criteoService/criteo.interface'
import { miniPDPFlyoutDataReducer, miniPDPFlyoutDataState } from './miniPDPFlyout.reducer'
import { buyNowModalDataReducer, BuyNowModalDataState } from './buyNowModal.reducer'

/** Maintain alphabetical order. If in order will help while debugging using redux tools */
export type RootState = {
    analytics: PageAnalyticsState
    aoaBookingFormData: AOABookingFormState
    aoaDateTimeStepData: AOADateTimeState
    addToSalesAlerts: addToSalesAlertsState
    breadcrumbAuthouredData: breadcrumbDataAuthouredState
    browseOnly: BrowseOnlyState
    cancelOrder: CancelOrderState
    cart: CartState
    categoryIdData: CategoryResponseById
    certona: CertonaResponseType
    checkout: CheckoutState
    checkoutButtonClick: CheckButtonClickState
    commonContent: CommonContentState
    contactUs: ContactUsState
    criticalFitmentData: CriticalFitmentState
    ctMoneyBalance: CTMoneyBalanceState
    deliveryOptions: deliveryOptionsState
    dynamicComponent: DynamicComponentState
    errorInterceptorData: timeoutErrorState
    estimateDeliveryDate: estimateDeliveryDateState
    checkoutDrawer: CheckoutDrawerState
    gigyaScreenSetData: ScreenSetResponse
    headerProps: Headers
    hideVehicleSelectorBannerState: HideVehicleSelectorBannerState
    interceptorState: InterceptorPromiseState
    linkLoyaltyCard: LinkLoyaltyCardState
    linkLoyaltyValidate: LinkLoyaltyValidateState
    linkMasterCard: LinkMasterCardState
    mergeLoyaltyCardData: MergeLoyaltyCardState
    mergeLoyaltyValidateData: MergeLoyaltyValidateState
    miniPDPFlyoutData: miniPDPFlyoutDataState
    notificationPreference: NotificationPreferenceState
    offers: OffersState
    ogImageTag: OgImageTagState
    orderCancellationReasons: CancelOrderReasonsState
    orderHistory: orderHistoryState
    orderItemsDetails: orderConfirmationState
    orderDetails: OrderDetailsState
    AutomotivePackage: PackageLandingInitialState
    preselectedVehicleFromURL: preselectedVehicle
    product: ProductState
    [PRODUCT_DATA_STATE_KEY]: ProductDataState
    sponsoredAds: SponsoredAdsState
    profilePayment: profilePaymentState
    recommendationsData: RecommendationsDataState
    registerFormData: registerFormState
    saveForLaterListData: SaveForLaterState
    searchData: SearchState
    secondaryNavigation: InitialCategoryPayloadType[]
    siteMap: Category[]
    siteWidePromoCode: SiteWidePromoCodeState
    shoppingPreference: ShoppingPreferenceState
    sticky: StickyState
    stickyBuyBar: StickyBuyBarState
    stickyBannerData: stickyBannerState
    storeDetails: storeState
    successErrorWishlist: successErrorState
    sharedCart: SharedCartState
    spinner: SpinnerState
    buybox: BuyboxState
    tncRichContent: tncContentState
    toastMessageData: ToastMessageDataState
    toggle: ToggleState
    tokenState: TokenState
    triangleTransactionData: TriangleTransactionHistoryState
    tryAgainReducer: tryAgainState
    userProfile: UserProfileDataState
    wishlist: wishlistState
    vehicleBanner: VehicleBannerState
    vehicleTires: InitialVehicleTireState
    vehiclesData: vehiclesDataInterface
    suggest: SuggestState
    roadRating: RoadRatingState
    extraHardware: extraHardwareState
    estimateFees: estimateFeesState
    docLoadStatus: docLoadState
    BVSection: BVSectionState
    popupInfo: popupInfoState
    aoaHeader: AOAHeaderState
    balloonAddons: balloonAddonsState
    [POPUPS_TOGGLE_STATE_KEY]: popupsToggleState
    prerender: IPrerenderState
    paymentCard: profilePaymentCardState
    rewardsUpsellingCard: TriangleRewardsUpsellState
    globalData: globalDataState
    ctMoneyRedeemed: CTMoneyRedeemUpsellState
    automotiveBuyBoxState: AutomotiveBuyBoxState
    weatherTech: WeatherTech
    optionalRequestHeaderParameter: OptionalRequestHeaderParameter
    automotiveAccordion: AutomotiveAccordionState
    otp: OtpFlowDataState
    security: SecurityDataState
    buyNowModalData: BuyNowModalDataState
}

/** Maintain alphabetical order. If in order will help while debugging using redux tools */
const reducers = combineReducers({
    analytics: pageAnalyticsReducer as Reducer<PageAnalyticsState>,
    aoaBookingFormData: aoaBookingFormReducer as Reducer<AOABookingFormState>,
    aoaDateTimeStepData: aoaDateTimeStepReducer as Reducer<AOADateTimeState>,
    addToSalesAlerts: addToSalesAlertsReducer as Reducer<addToSalesAlertsState>,
    breadcrumbAuthouredData: breadcrumbAuthouredReducer as Reducer<breadcrumbDataAuthouredState>,
    browseOnly: browseOnlyReducer as Reducer<BrowseOnlyState>,
    buyNowModalData: buyNowModalDataReducer as Reducer<BuyNowModalDataState>,
    cancelOrder: cancelOrderReducer as Reducer<CancelOrderState>,
    cart: cartReducer as Reducer<CartState>,
    categoryIdData: getCategoryIdData as Reducer<CategoryResponseById>,
    certona: getCertonaData as Reducer<CertonaResponseType>,
    checkout: checkoutReducer as Reducer<CheckoutState>,
    checkoutButtonClick: checkoutButtonClickReducer as Reducer<CheckButtonClickState>,
    commonContent: commonContentReducer as Reducer<CommonContentState>,
    contactUs: contactUsReducer as Reducer<ContactUsState>,
    criticalFitmentData: fetchCriticalFitmentDataReducer as Reducer<CriticalFitmentState>,
    ctMoneyBalance: ctMoneyBalanceReducer as Reducer<CTMoneyBalanceState>,
    deliveryOptions: deliveryOptionsReducer as Reducer<deliveryOptionsState>,
    dynamicComponent: dynamicComponentData as Reducer<DynamicComponentState>,
    errorInterceptorData: fetchInterceptorErrorReducer as Reducer<timeoutErrorState>,
    estimateDeliveryDate: estimateDeliveryDateReducer as Reducer<estimateDeliveryDateState>,
    checkoutDrawer: checkoutDrawerReducer as Reducer<CheckoutDrawerState>,
    gigyaScreenSetData: setGigyaScreenDataReducer as Reducer<ScreenSetResponse>,
    headerProps: headerPropsReducer as Reducer<Headers>,
    interceptorState: InterceptorStateReducer as Reducer<InterceptorPromiseState>,
    linkLoyaltyCard: linkLoyaltyCardReducer as Reducer<LinkLoyaltyCardState>,
    linkLoyaltyValidate: linkLoyaltyValidateReducer as Reducer<LinkLoyaltyValidateState>,
    linkMasterCard: linkMasterCardReducer as Reducer<LinkMasterCardState>,
    mergeLoyaltyCardData: mergeLoyaltyCardReducer as Reducer<MergeLoyaltyCardState>,
    mergeLoyaltyValidateData: mergeLoyaltyValidateReducer as Reducer<MergeLoyaltyValidateState>,
    miniPDPFlyoutData: miniPDPFlyoutDataReducer as Reducer<miniPDPFlyoutDataState>,
    notificationPreference: notificationPreferenceReducer as Reducer<NotificationPreferenceState>,
    offers: offersReducer as Reducer<OffersState>,
    ogImageTag: ogImageTagReducer as Reducer<OgImageTagState>,
    orderCancellationReasons: orderCancellationReasonsReducer as Reducer<CancelOrderReasonsState>,
    orderHistory: orderHistoryReducer as Reducer<orderHistoryState>,
    orderItemsDetails: orderItemsDetailsReducer as Reducer<orderConfirmationState>,
    orderDetails: orderDetailsReducer as Reducer<OrderDetailsState>,
    AutomotivePackage: AutomotivePackage as Reducer<PackageLandingInitialState>,
    preselectedVehicleFromURL: preselectedVehicleReducer as Reducer<preselectedVehicle>,
    product: productReducer as Reducer<ProductState>,
    [PRODUCT_DATA_STATE_KEY]: productDataReducer as Reducer<ProductDataState>,
    sponsoredAds: sponsoredAdsReducer as Reducer<SponsoredAdsState>,
    profilePayment: profilePaymentReducer as Reducer<profilePaymentState>,
    recommendationsData: recommendationsDataReducer as Reducer<RecommendationsDataState>,
    registerFormData: registerFormReducer as Reducer<registerFormState>,
    saveForLaterListData: saveForLaterListReducer as Reducer<SaveForLaterState>,
    searchData: searchReducer as Reducer<SearchState>,
    secondaryNavigation: secondaryNavigationReducer as Reducer<InitialCategoryPayloadType[]>,
    siteMap: siteMapReducer as Reducer<Category[]>,
    siteWidePromoCode: siteWidePromoCodeReducer as Reducer<SiteWidePromoCodeState>,
    shoppingPreference: shoppingPreferenceReducer as Reducer<ShoppingPreferenceState>,
    sticky: stickyReducer as Reducer<StickyState>,
    stickyBuyBar: stickyBBReducer as Reducer<StickyBuyBarState>,
    stickyBannerData: stickyBannerReducer as Reducer<stickyBannerState>,
    storeDetails: storeDetailsReducer as Reducer<storeState>,
    successErrorWishlist: successErrorReducer as Reducer<successErrorState>,
    sharedCart: sharedCartReducer as Reducer<SharedCartState>,
    tncRichContent: getTncContentReducer as Reducer<tncContentState>,
    toastMessageData: toastMessageDataReducer as Reducer<ToastMessageDataState>,
    toggle: toggleReducer as Reducer<ToggleState>,
    tokenState: tokenUpdateReducer as Reducer<TokenState>,
    triangleTransactionData: triangleTransactionHistoryReducer as Reducer<TriangleTransactionHistoryState>,
    tryAgainReducer: tryAgainReducer as Reducer<tryAgainState>,
    userProfile: userProfileReducer as Reducer<UserProfileDataState>,
    wishlist: wishlistReducer as Reducer<wishlistState>,
    spinner: spinnerSliceReducer,
    buybox: buyboxReducer as Reducer<BuyboxState>,
    vehicleBanner: vehicleBannerReducer as Reducer<VehicleBannerState>,
    vehicleTires: updatedVehicleTiresDataReducer as Reducer<InitialVehicleTireState>,
    vehiclesData: vehiclesDataReducer as Reducer<vehiclesDataInterface>,
    hideVehicleSelectorBannerState: hideVehicleSelectorBannerReducer as Reducer<HideVehicleSelectorBannerState>,
    suggest: suggestReducer as Reducer<SuggestState>,
    roadRating: roadRatingReducer as Reducer<RoadRatingState>,
    extraHardware: extraHardwareReducer as Reducer<extraHardwareState>,
    estimateFees: estimateFeesReducer as Reducer<estimateFeesState>,
    docLoadStatus: docLoadStatusReducer as Reducer<docLoadState>,
    BVSection: BVSectionReducer as Reducer<BVSectionState>,
    popupInfo: popupInfoReducer as Reducer<popupInfoState>,
    aoaHeader: aoaHeaderReducer as Reducer<AOAHeaderState>,
    balloonAddons: balloonAddonsReducer as Reducer<balloonAddonsState>,
    [POPUPS_TOGGLE_STATE_KEY]: popupsToggleStateReducer as Reducer<popupsToggleState>,
    prerender: prerenderReducer as Reducer<IPrerenderState>,
    paymentCard: ProfilePaymentCardReducer as Reducer<profilePaymentCardState>,
    rewardsUpsellingCard: triangleRewardsUpsellReducer as Reducer<TriangleRewardsUpsellState>,
    globalData: globalDataReducer as Reducer<globalDataState>,
    ctMoneyRedeemed: ctMoneyRedeemReducer as Reducer<CTMoneyRedeemUpsellState>,
    automotiveBuyBoxState: automotiveBuyBoxReducer as Reducer<AutomotiveBuyBoxState>,
    weatherTech: weatherTechReducer as Reducer<WeatherTech>,
    otp: otpReducer as Reducer<OtpFlowDataState>,
    security: securityReducer as Reducer<SecurityDataState>,
    optionalRequestHeaderParameter: optionalRequestHeaderParameterReducer as Reducer<OptionalRequestHeaderParameter>,
    automotiveAccordion: automotiveAccordionReducer as Reducer<AutomotiveAccordionState>,
})

export default reducers

// export type RootState = ReturnType<typeof reducers>
