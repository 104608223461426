import getAOAWorkflowType, { getAOAWorkFlowQueryParams } from './aoaWorkflow.helper'

/**
 * This helper will provide a string joining by aoaWorkflowType with its respective query param
 * @return {string}
 */
export const createSuffix = (): string => {
    const workflowType = getAOAWorkflowType()
    const { orderReferenceNumber, documentTokenId } = getAOAWorkFlowQueryParams() || {}
    return [workflowType, orderReferenceNumber, documentTokenId].filter(Boolean).join('_')
}

type AOABrowserStorage = Partial<Storage>

/**
 * This is wrapper for  localStorage and it will append suffix to the keys based on the different work flows
 * @param {function} suffixCreator
 * @param {AOABrowserStorage} storage
 * @return {Partial<Storage>}
 */
export const aoaLocalStorageServiceConstructor = (
    suffixCreator: () => string = createSuffix,
    storage: AOABrowserStorage = localStorage,
) => {
    const factorySuffix = suffixCreator()
    const createKey = (key: string) => `${key}_${factorySuffix}`
    return {
        getItem(key: string) {
            return storage.getItem?.(createKey(key)) || ''
        },
        setItem(key: string, value: string) {
            return storage.setItem?.(createKey(key), value)
        },
        removeItem(key: string) {
            return storage.removeItem?.(createKey(key))
        },
        clear() {
            return storage.clear?.()
        },
    }
}

export const aoaSelectedServiceStorageService = aoaLocalStorageServiceConstructor(createSuffix, sessionStorage)
export default aoaLocalStorageServiceConstructor()
