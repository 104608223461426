import { Dispatch } from '@reduxjs/toolkit'

import { HIDE_VEHICLE_SELECTOR_BANNER } from '../types/products/hideVehicleSelectorBanner.actionTypes.constant'
import { hideVehicleSelectorBannerAction } from '../actionCreators/hideVehicleSelectorBanner.actionCreators'

export const hideVehicleSelectorBanner =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof HIDE_VEHICLE_SELECTOR_BANNER; payload: boolean }>): void => {
        dispatch(hideVehicleSelectorBannerAction(requestPayload))
    }
