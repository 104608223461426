import { Dispatch } from '@reduxjs/toolkit'

import { RecommendationResponseDataDTO } from '../models/recommendations.interface'
import {
    fetchRecommendationsDataSuccessAction,
    fetchRecommendationsDataErrorAction,
    removeSpecificSchemaFromRecommendationData,
} from '../actionCreators/recommendationsData.actionCreators'
import { productDetailsService } from '../../services/productDetailsService'
import { AxiosError } from 'axios'
import { getEnvironment } from '../../environments'
import { RootState } from '../reducers'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'

export const fetchRecommendationsData =
    (
        pCodes: string[],
        storeRecommendationData?: RecommendationResponseDataDTO[],
        scheme?: string,
        setIsFetched?: React.Dispatch<React.SetStateAction<boolean>>,
        includeSpecifications?: boolean,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const config = getEnvironment()
        const { selectedPreferredStoreId } = getState().storeDetails
        const { commonContentAvailable } = enableDestructOnUndefinedData(getState().commonContent)
        const { enableSingleSkuOnlyModeForProductFamilyListApi } = enableDestructOnUndefinedData(
            commonContentAvailable?.featureFlag,
        )
        return productDetailsService
            .getProductDetailsUsingPCodes(
                storeRecommendationData as RecommendationResponseDataDTO[],
                pCodes,
                scheme as string,
                selectedPreferredStoreId,
                !!includeSpecifications,
                !!enableSingleSkuOnlyModeForProductFamilyListApi,
            )
            .then(data => {
                if (config.defaultProductImage) {
                    Array.isArray(data) &&
                        data.forEach(recommendationScheme => {
                            replaceEmptyImagesWithDefault(recommendationScheme?.productData, 'images')
                        })
                }
                dispatch(fetchRecommendationsDataSuccessAction(data))
            })
            .catch((error: AxiosError<string>) => {
                dispatch(
                    fetchRecommendationsDataErrorAction({
                        data: error?.response?.data ?? null,
                        status: error?.response?.status ?? null,
                    }),
                )
            })
            .finally(() => {
                setIsFetched && setIsFetched(true)
            })
    }

/**
 * function to dispatch action for removal of specific schema id from recommendation redux state.
 * This is needed to refresh Recommendations data. (ex:-CartAccessoriesFlyout scenario where we are calling same schema id for different products and getting different pcodes.)
 * @param {string} data
 * @returns {void}
 */
export const removeSpecificSchemaFromRecommendation =
    (data: string) =>
    (dispatch: Dispatch): void => {
        dispatch(removeSpecificSchemaFromRecommendationData(data))
    }
