import React, { useCallback, useEffect, useRef } from 'react'
import { MODAL_OPEN_CLASS, PREFIX, previousElementName } from '../../config'
import { RewardsHubFlyoutProps } from './RewardsHub.type'
import {
    BrandingColors,
    ButtonColors,
    disableFocusLock,
    enableFocusLock,
    IconButton,
    iconSizes,
    NotificationBadge,
    Separator,
    SeparatorStyle,
    Size,
    State,
    TabsV2,
    TabV2,
    useClickOutsideClose,
    Variation,
} from '@nl/lib'
import Alerts from './Alerts'
import Offers from './Offers'
import MyDeals from './MyDeals'
import { useAppSelector } from '../../hooks/react-redux.hook'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'

/**
 * RewardsHubFlyout component
 * @param {RewardsHubFlyoutProps} props - The properties for the component
 * @returns {JSX.Element} The rendered component
 */
const RewardsHubFlyout: React.FC<RewardsHubFlyoutProps> = ({ ...props }: RewardsHubFlyoutProps): JSX.Element => {
    const {
        setShouldOpen,
        authenticatedUserGreetingText,
        userName,
        balance,
        ctMoneyBalanceLabel,
        alertLabel,
        offersLabel,
        myDealsLabel,
        selectedTabLabel,
        setSelectedTabLabel,
        previousElement,
        shouldOpen,
        promoSectionList,
    } = props
    const colors = [BrandingColors.RED, BrandingColors.BURGUNDY, BrandingColors.TEAL]
    const componentClass = `${PREFIX}-rewards-hub-flyout`
    const focusLockEventRef = useRef<EventListener>()
    const flyOutContent = useRef<HTMLDivElement>(null)
    const { accessibility } = useAppSelector(commonContentAvailableSelector)
    const { a11yCloseIconLabel } = accessibility

    /**
     * Handles the close click event for the RewardsHubFlyout component.
     * @returns {void}
     */
    const onCloseClick = useCallback((): void => {
        setShouldOpen && setShouldOpen(false)
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }, [setShouldOpen, previousElement])

    useEffect(() => {
        if (shouldOpen) {
            focusLockEventRef.current = enableFocusLock(flyOutContent)
        } else {
            disableFocusLock(focusLockEventRef.current ?? null)
        }
    }, [flyOutContent, shouldOpen])

    useClickOutsideClose(flyOutContent, () => onCloseClick(), shouldOpen ?? false, true)

    useEffect(() => {
        if (shouldOpen) {
            document.body.classList.add(MODAL_OPEN_CLASS)
        } else {
            document.body.classList.remove(MODAL_OPEN_CLASS)
        }

        /**
         * Remove body `modalOpen Class` on Flyout unmount, is necessary for the case
         * when Flyout unmount happens by not changing `isOpen` prop to `false`.
         */
        return () => {
            document.body.classList.remove(MODAL_OPEN_CLASS)
        }
    }, [shouldOpen])

    /**
     * Function to set tab based on click
     * @param {string} tabName
     * @returns {void}
     */
    const tabCallback = (tabName: string): void => {
        setSelectedTabLabel && setSelectedTabLabel(tabName)
    }

    /**
     * Function to render the flyout header
     * @returns {JSX.Element} The rendered flyout header
     */
    const renderFlyoutHeader = (): JSX.Element => {
        return (
            <div className={`${componentClass}__header`}>
                <div className={`${componentClass}__header-content`}>
                    <span className={`${componentClass}__header-greeting`}>
                        {authenticatedUserGreetingText} <b>{userName},</b>
                    </span>
                    <div className={`${componentClass}__header-balance`}>
                        <span
                            className={`${componentClass}__header-balance-label`}
                            dangerouslySetInnerHTML={{ __html: ctMoneyBalanceLabel ?? '' }}
                        />
                        <span className={`${componentClass}__header-balance-value`}>{balance}</span>
                    </div>
                </div>

                <IconButton
                    icon={{ size: iconSizes.Large, type: 'ct-close' }}
                    variant={Variation.TERTIARY}
                    a11y={{ label: a11yCloseIconLabel }}
                    color={ButtonColors.DARK}
                    customClass={`${componentClass}__header-close`}
                    onClick={onCloseClick}
                />
            </div>
        )
    }

    /**
     * Renders the header for the tabs in the Rewards Hub.
     * @param {string} label - The label to display for the tab.
     * @param {string} notificationCount - The count of notifications to display in the badge.
     * @returns {JSX.Element} The JSX element representing the tab header with a label and notification badge.
     */
    const renderTabsHeader = (label: string, notificationCount: string): JSX.Element => {
        return (
            <>
                <p>{label}</p>
                <NotificationBadge
                    value={notificationCount}
                    notificationClass={`${PREFIX}-rewards-hub__navigation-badge`}
                    color={State.ERROR}
                    size={Size.XS}
                />
            </>
        )
    }

    /**
     * Function to render navigation tabs
     * @returns {JSX.Element} The rendered navigation tabs
     */
    const renderNavigationTabs = (): JSX.Element => {
        return (
            <TabsV2
                tabCallback={(value: string) => {
                    tabCallback(value)
                }}
                selected={selectedTabLabel}>
                {/* TO DO: badge count will replace with dynamic value from API */}
                <TabV2 label={renderTabsHeader(alertLabel ?? '', '3')}>
                    <Alerts promoSectionList={promoSectionList} />
                </TabV2>
                <TabV2 label={renderTabsHeader(offersLabel ?? '', '5')}>
                    <Offers></Offers>
                </TabV2>
                <TabV2 label={renderTabsHeader(myDealsLabel ?? '', '6')}>
                    <MyDeals></MyDeals>
                </TabV2>
            </TabsV2>
        )
    }

    return (
        <div className={componentClass}>
            <Separator colors={colors} style={SeparatorStyle.SKEW} />
            <div ref={flyOutContent}>
                {renderFlyoutHeader()}
                {renderNavigationTabs()}
            </div>
        </div>
    )
}
export default RewardsHubFlyout
