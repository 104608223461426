import PrerenderReadySetter from './utils/PrerenderReadySetter'
import CustomMiddleware from '../types/customMiddleware/customMiddleware'

const prerenderReadySetter = new PrerenderReadySetter()

export const prerenderMiddleware: CustomMiddleware = store => next => action => {
    // globalThis used to avoid rare async test failures
    if (!globalThis?.window?.prerenderReady) {
        const { staticPrerenderReady } = store.getState().prerender
        prerenderReadySetter.setConditionsToVerify = staticPrerenderReady
    }
    return next(action)
}
