export interface BoxedRecommendationsProps {
    showComponent: boolean
    enableTile1Badges: boolean
    enableTile3Badges: boolean
    tile1SchemaId: string
    tile1BGColour: string
    tile1FontColour: string
    tile1ButtonTitle: string
    tile1ButtonUrl: string
    tile1ButtonAriaLabel: string
    tile1AnalyticsPromoCode?: string
    tile2ButtonTitle: string
    tile2ButtonUrl: string
    tile2ButtonAriaLabel: string
    tile2SchemaId: string
    tile2BGColour: string
    tile2FontColour: string
    tile2AnalyticsPromoCode?: string
    tile3ButtonTitle: string
    tile3ButtonUrl: string
    tile3ButtonAriaLabel: string
    tile3SchemaId: string
    tile3BGColour: string
    tile3FontColour: string
    tile3AnalyticsPromoCode?: string
}

export interface TilesPCodes {
    tile1PCodes: string[]
    tile3PCodes: string[]
}

export interface ProductCardsContainerProps {
    headerTitleAndCTA: JSX.Element | null
    setIdentifierAnalyticATC?: (val: string) => void
    enableBadges: boolean
    schemaId: string
    backgroundColor: string
}

export interface ProductCardsSkeletonProps {
    productCardCount: number
    tilesCount: string[]
}

// enum for tile identifier
export enum TileIdEnum {
    tile1 = 'tile1',
    tile2 = 'tile2',
    tile3 = 'tile3',
}
