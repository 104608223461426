export const firstNameRegex = /^[a-zA-ZÀ-ÿ\-'’\s]+$/
export const lastNameRegex = /^[a-zA-ZÀ-ÿ\-'’\s]+$/
export const addressRegex = /^[a-zA-ZÀ-ÿ0-9,.'’/\\\-\s]+$/
export const cityRegex = /^[a-zA-ZÀ-ÿ,.'’/\\\-\s]+$/
export const postalCodeRegex = /^ *(?=[^dfioquwz])[a-z]\d(?=[^dfioqu])[a-z] ?\d(?=[^dfioqu])[a-z]\d *$/i
export const postalCodeNoFormatRegex = /[\s\S]*/
export const telephoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
export const phoneNumberFormat = /^(\d{3})(\d{3})(\d{4})$/
export const emailRegEx =
    // eslint-disable-next-line max-len
    /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // Email regex value
export const numberWithHyphenRegEx = /^(([0-9-])*)$/
export const allHyphen = /-/g
export const allWhitespace = /\s/g
export const vehicleHeaderRegex = /^\s+|\s+$/g
export const alphanumericRegex = /^[a-z0-9]+$/i

export const requestParameterRegExp = /[?;&]rq=([^(;|&|#)]+)/
export const lastCharacterRegExp = /.$/
// eslint-disable-next-line no-warning-comments
// ToDo: needs to be visited later - OCCP-24324 adding new regex for contact info email section
export const checkoutContactEmailRegex =
    // eslint-disable-next-line max-len
    /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.(com|org|net|int|edu|gov|ca|in)){1,}))$/ // Email regex value
export const htmlTagSliceRegex = /(<([^>]+)>)/gi
export const numberWithDotRegEx = /[^0-9.,]/g
export const commaRegEx = /,/g
export const commaSpaceRegEx = /[\s,]+/g
export const asteriskRegEx = /\*/g
export const tireRegexPattern = /^(\d+(\.\d+)?X?)\/?(\d+(\.\d+)?)R(\d+(\.\d+)?)$/i

export const telephoneWithMaskingRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/
export const phoneMaskingRegexPatter = /^(([0-9\-\s+()])*)$/

export const passwordComplexityPattern =
    // eslint-disable-next-line max-len
    /^(?=.*[0-9])(?=.*[ !@#$%^&*~()_+\-=[\]{};'`:"\\|,.<>/?])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z! @#$%^&*~()_+\-=[\]{};'`:"\\|,.<>/?]{8,}/g
