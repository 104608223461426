import React, { useEffect } from 'react'

import { setIsGigyaLoaded, setIsGigyaLoadingFailed } from '../../redux/actionCreators/gigyaScreenSet.actionCreators'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { getQueryString } from '@nl/lib'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import PropTypes from 'prop-types'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'
import { verifyGigyaReadiness } from '../../helpers/ciam.helper'
import { gigyaActions } from '../GigyaScreen/gigya.constants'
import { isGigyaLoadedSelector } from '../../redux/selectors/gigyaScreenSetData.selectors'

interface GigyaInitType {
    isFlyerPage?: boolean
}

const GigyaInit: React.FC<GigyaInitType> = ({ ...props }) => {
    const isCommonContentAvailable = useAppSelector(commonContentAvailableSelector)

    const dispatch = useAppDispatch()
    const isGigyaLoaded = useAppSelector(isGigyaLoadedSelector)

    useEffect(() => {
        verifyGigyaReadiness(gigyaActions.saveGigyaReadinessStatus)
            .then(() => {
                dispatch(setIsGigyaLoaded())
            })
            .catch(() => {
                dispatch(setIsGigyaLoadingFailed())
            })
    }, [dispatch])

    /**
     * set flag true when gigya is loaded
     */
    useEffect(() => {
        if (isGigyaLoaded) {
            // show email signup modal on pageload
            const queryParams = window.location.search
            const pageType = getPageType()
            const showEmailSignupModal = getQueryString(queryParams, 'emailSignup')
            const signupParamFromQueryParams = getQueryString(queryParams, 'signup')
            if (
                (showEmailSignupModal && pageType === pageTypes.homePage) ||
                (signupParamFromQueryParams === 'true' && props.isFlyerPage)
            ) {
                gigyaLoadFunctions('ODP-LiteRegistration', undefined, '', false, false)
            }
        }
    }, [dispatch, isGigyaLoaded, props.isFlyerPage, isCommonContentAvailable])

    return null
}

GigyaInit.propTypes = {
    isFlyerPage: PropTypes.bool,
}
export default GigyaInit
export { GigyaInit }
