import { GlobalAnalyticsVehicle } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.type'
import { Price } from '../../redux/models/cart.interface'
import { PaymentDetails, TaxLines } from '../../redux/models/orderConfirmation.interface'
import { CorporateStatus } from '../../redux/models/product.interface'
import { iPrice } from '../../redux/models/productData.interface'
import {
    IRewardsRegistrationAnalytics,
    SSOLoginEventAnalytics,
    CLPLoginInitEventAnalytics,
    SpaPageAnalytics,
} from '../analytics.type'

/**
 * Base provider interface
 */
export interface BaseProvider {
    push(data: AnalyticsRecordInterface | OtpAnalyticsParams, update?: boolean): void
}

export enum Providers {
    DEFAULT = 'gtm',
}

export interface PromotionRecord {
    creative: string
    name: string
    position: string
    componentType: string
    cta?: string | null
    offerStatus?: string
    ActivateAll?: boolean
}

export interface OtpAnalyticsParams {
    event: string
    otpInitialized: boolean
    wrongCode: boolean
    correctCode: boolean
    frequencyLimit: boolean
    resendCode: boolean
    closeScreen: boolean
    tooManyCodeRequestAttempts: boolean
    tooManyCodeVerificationAttempts: boolean
}

export enum YesNoNotApplicable {
    yes = 'yes',
    no = 'no',
    notApplicable = 'not applicable',
    notSelected = 'not selected',
}

export interface CartRecord {
    id?: string
    fulfillmentCost?: string
    financingEligible?: boolean
    financingShown?: boolean
    financingSelected?: boolean
    shippingCost?: number
}
export interface CheckoutRecord {
    step: number
    option?: string
}

export enum Availability {
    inStock = 'instock',
    notInStock = 'not in stock',
    fca = 'fca',
}
export enum FulfillmentOptionAvailable {
    bopisOnly = 'bopis only',
    sthOnly = 'sth only',
    sthAndBopis = 'sth & bopis',
    unavailable = 'unavailable',
}
/**
 * TODO: need to re-use cart.interface.ts
 */
export enum DeliveryMethod {
    inStorePickup = 'bopis only',
    curbsidePickup = 'curbside pickup,',
    shipToHome = 'sth only',
    inHomeDelivery = 'sth - in home delivery',
    inHomeDeliveryAndUnpack = 'sth - in home delivery and unpack',
    shipToHomeBulkStandard = 'sth - standard',
    shipToHomeAndBopis = 'sth & bopis',
    unavailable = 'unavailable',
}
export interface ProductRecordInterface {
    entryNumber?: number
    availability?: Availability
    isBulk?: boolean
    bulk?: boolean
    pdpType?: string
    productType?: string
    installationAvailable?: string
    inventory?: number
    financingEligible?: boolean
    financingShown?: boolean
    fulfillmentOptionAvailable?: FulfillmentOptionAvailable
    productStatus?: string
    badges?: string[]
    brand?: string
    fulfillmentStatus?: string
    guaranteedFitment?: string
    category?: string
    level1?: string
    level2?: string
    level3?: string
    level4?: string
    level5?: string
    list?: string
    fulfillment?: string
    fitted?: string
    package?: string
    fulfillmentDaysAway?: string
    fee?: string
    someoneElsePickingUp?: string
    pickupEmailProvided?: string
    name?: string
    pcode?: string
    price?: number
    rebate?: string
    quantity?: number
    rating?: number
    numberOfReviews?: number
    ror?: boolean
    shipFrom?: string
    sku?: string
    variant1?: string
    variant2?: string
    variant3?: string
    corporateStatus?: CorporateStatus
    shippingMethod?: string
    fulfillmentType?: string
    vehicle?: VehicleInfo
    isPackagedItem?: Record<string, unknown>
    toPlainObject: () => ProductRecordInterface
}

export interface VehicleInfo {
    staggerdFitment?: string
    type?: string
    make?: string
    model?: string
    year?: string
    body?: string
    option?: string
}

export interface UserEmailSubscription {
    emailPreferences: string
}

/**
 * Interface for store details
 */
export interface StoreDetailsInterface {
    id: string
    name: string
    province: string
    city: string
    openNow: string
    selection: string
}

export interface ProductMedias {
    video: string
    images: string
    mediaType: string
}

export interface TransactionDetails {
    code: string
    subTotal: iPrice
    totalTax: iPrice
    taxLines: TaxLines[]
    deliveryCost: iPrice
    totalDiscounts: iPrice
    paymentInfo: PaymentDetails
    totalPriceWithTax: iPrice
}

export interface TransactionDetailsAnalytics {
    id: string
    affiliation: string
    revenue: string
    tax: string
    taxExemption: undefined
    shipping: string
    discount: string
    coupon: string | undefined
    paymentMethod: string[]
}

export interface range {
    ge?: number
    le?: number
}

export interface FilterSelected {
    filterName: string
    actionOperator: string
    range?: range[]
    optionsSelected: string
    optionsAdded?: string[]
    type?: string
    productResults: number
    facetId: string
    categoryId?: string
}

export interface AnalyticsRecordInterface {
    eflyer?: {
        id: string
        publicationId: string
        starDate: string
        endDate: string
        previewDate: string
    }
    event: string
    promotion?: PromotionRecord[] | PromotionRecord
    eventParameters?: {
        action?: string
        location?: string
        category?: string
        label?: string
        value?: string
        systemFailure?: string
        estimatedShippingStatus?: string
        list?: string | null
        filterButtonClicked?: string
    }
    search?: Record<string, string | null>
    installationAvailable?: YesNoNotApplicable
    installationHelp?: YesNoNotApplicable
    vinEntered?: YesNoNotApplicable
    cart?: CartRecord
    checkout?: CheckoutRecord
    products?: ProductRecordInterface[] | ProductRecordInterface | CompareProductCheckboxAnalyticsInterface
    user?: UserEmailSubscription
    store?: StoreDetailsInterface
    page?: ProductMedias | SpaPageAnalytics
    transaction?: TransactionDetails | TransactionDetailsAnalytics
    vehicle?: GlobalAnalyticsVehicle
    productResultsReturned?: number
    filtersSelected?: FilterSelected[]
    centralLogin?: SSOLoginEventAnalytics[] | CLPLoginInitEventAnalytics[]
    centralRegistration?: IRewardsRegistrationAnalytics[]
    shoppingExperience?: ShoppingExperience
    ecommerce?: {
        Currency: string
        value: string
        items: [
            {
                [key: string]: string | number
            },
        ]
    }
    loginStatus?: string
    productPrice?: string
}

export interface FilterAnalyticsData {
    [key: string]: FilterSelected
}

/**
 * interfact to capture the shopping experience related attribute for fullfillment change
 */
export interface ShoppingExperience {
    fulfillmentType: '' | 'pickup' | 'sth'
    shippingMethod: '' | 'standard' | 'express'
}

export interface WishlistAnalyticsItemInterface {
    item_id?: string
    item_name?: string
    item_category?: string
    item_brand?: string
    item_location?: string
    quantity?: number
    price?: string
}

export interface CompareProductCheckboxAnalyticsInterface {
    badges: string[]
    bulk?: boolean
    brand: string
    category: string
    level1?: string
    level2?: string
    level3?: string
    level4?: string
    level5?: string
    level6?: string
    pcode: string
    price: string
    inventory: string | null
    name?: string
    rebate?: string
    rating: number
    numberOfReviews: number
    sku?: string
    guranteedFitment: string | null
    fitted: string
    ror: boolean
}

export interface ComparisonWishlistResponse {
    code: string
    currentPrice: Price
}
