import { Dispatch } from '@reduxjs/toolkit'

import { setToastMessageDataAction, setToastDetailsAction } from '../actionCreators/toastMessage.actionCreators'
import { ToastDetails, ToastMessage } from '../models/toastMessage.interface'

export const setToastMessageData =
    (requestPayload: ToastMessage) =>
    (dispatch: Dispatch): void => {
        dispatch(setToastMessageDataAction(requestPayload))
    }

export const setToastDetails =
    (payload: ToastDetails) =>
    (dispatch: Dispatch): void => {
        dispatch(setToastDetailsAction(payload))
    }
