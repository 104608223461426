/* eslint-disable max-lines */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import ProductImageComponent from '../ProductCartItem/ProductImageComponent'
import Price from '../Price'
import QuantitySelector from '../QuantitySelector'
import { PREFIX } from '../config'
import Icon from '../Icon'
import {
    AutoProductCartItemProps,
    eEntryType,
    // FulfillmentMethods,
    OptionsOnCartItem,
    ProductDataDTO,
    ProductSourceTypes,
} from '../ProductCartItem/ProductItemInfo.type'
import { replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { maxInputVal } from '../QuantitySelector/quantitySelectorConstant'
import Badges from '../Badges'
import {
    addDynamicToken,
    CartItemFulfillmentSource,
    customDebounce,
    getFormattedDateTime,
    getSourceTypeData,
    isArrayNotEmpty,
    replaceMultipleStrWithDynamicVal,
} from '../../utils'
import RenderQuantity from '../../utils/renderQuantity'
import {
    createNewPackageCTA,
    createNewPackageLabel,
    productCartItems,
    saveForLaterParam,
} from '../ProductCartItem/ProductCartItem.constant'
import localization from '../../helpers'
import Button from '../Button'
import { magicNumber } from '../../utils/magicNumber'
import Rebate from '../Rebate/Rebate'
import {
    getBuyItAgainCta,
    renderItemPrice,
    productWheelTypes,
    isProductTire,
    isProductWheel,
} from './AutomotiveProductCards.helper'
import {
    buildEstematedDeliveryDateSplitQty,
    cancellationAnalytics,
    filterSpecification,
    renderWarningToast,
    showWishListIcon,
    isOrderSTH,
    getEstimatedDeliveryDateLabelAvailability,
    addOrRemoveWishlist,
    renderWishListIconComponent,
    checkIsInWishList,
} from '../ProductCartItem/ProductCartItem.helper'
import CommonWarningToast from '../ProductCartItem/CommonWarningToast'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { getETATimeOptions } from '../../utils/getETATimeOptions'
import AutomotiveAddonsListWrapper from './AutomotiveAddonsListWrapper'
import {
    checkIsEstimatedDeliveryDateAvailableNotSplitQty,
    checkIsEstimatedDeliveryDateAvailableSplitQty,
    checkIsOrderFromInStoreAndDC,
} from '../ProductCartItem/ProductCartItem'
import usePrevious from '../../hooks/usePrevious.hook'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import { urlRedirection } from '../../utils/isUrl'
import { checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import PromoMessages from '../PromoMessages'
import { isAtleastOneParamValid } from '../../utils/validParams'
import { useIsMobile } from '../../hooks/useIsMobile.hook'
import { renderSaleEndDaySoonMessageComp } from '../Badges/Badges.helper'
import { useCalculatedLoyaltyValue } from '../../hooks/useCalculatedLoyaltyValue.hook'
// eslint-disable-next-line complexity, max-lines-per-function, sonar/cyclomatic-complexity
const AutomotiveProductCard = (props: AutoProductCartItemProps): JSX.Element => {
    const {
        productData,
        checkoutButtonClick,
        saveForLaterTitle,
        a11yRemoveLabel,
        a11yWishlistIcon,
        saveForLaterFlagEnabled,
        addItemToSaveForLater,
        addItemToWishlist,
        onRemoveCartItem,
        getEntriesInfo,
        isShoppingCartPage,
        betweenLabel,
        betweenLabelOrderConf,
        betweenLabelSTH,
        receiveBetweenLabel,
        removeitemFromWishlist,
        isBulk,
        isShowedEstimatedDeliveryDateBetween,
        estimatedDeliveryDateBetween,
        // pickAtStoreCTALabel,
        // changeShipToHomeCTALabel,
        // onChangeFulfillmentOption,
        // onlineOrdering,
        errorAnalyticsForInlineToast,
        setErrorInfoHandler,
        updateCartData,
        cartItemAnalytics,
        wishlistProducts,
        isAuthUser,
        cartPageLink,
        loginPageLink,
        productsRequirePackageCreationMessage,
        productWheelType,
        packagedItem,
        isCartPackageToggle,
        createNewPackageLabelTire,
        createNewPackageLabelWheel,
        createNewPackageCTATire,
        createNewPackageCTAWheel,
        enableSingleSignOn,
        ssoHandler,
        createPackageAndNavigate,
        a11yCloseIconLabel,
        appliedPromoPrefix,
        a11yTooltipIcon,
        isWishList,
        enableShowPromotionsAndRomanceCopy,
        saleEndDaySoonMessage,
        hideSaleEndDaySoonMessage,
        saleEndDisableShift,
        isPriceRangeViewEnabled = false,
    } = props

    const { salePriceEndDate, isOnSale, isUrgentLowStock } = productData
    const [quantity, setQuantity] = useState(productData.quantity)
    const { fulfillment, estimatedDeliveryDate } = productData
    const [inlineToastError, setInlineToastError] = useState(false)
    /**
     * This function is used to filter the specification based on size, style and offest
     * @param {number} index
     * @return {Specification}
     */
    const specificationsArr = filterSpecification(
        productData?.specifications,
        props.specificationsFromProps?.[magicNumber.ZERO],
    )
    const [prevQuantity, setPrevQuantity] = useState(productData.quantity)
    const [globalBannerMaxVal, setGlobalBannerMax] = useState(false)
    const [fromInput, setFromInput] = useState<boolean>(undefined)
    const [inputElementRef, setInputElementRef] = useState<React.MutableRefObject<any>>(null)
    const [hideAccordionAfterRemove, setHideAccordionAfterRemove] = useState(props.hasSelectedAddOn)
    const maxQuantity = productData.maxPurchaseQty
    const infoClass = `${PREFIX}-shopping-cart__product`
    const cartClass = `${PREFIX}-shopping-cart__item`
    const automotiveClass = `${PREFIX}-automotive-card`
    const customerSelfServiceCancelEnabled = props.customerSelfServiceCancelEnabled

    const feeMessage = isArrayNotEmpty(productData.feeMessages) ? productData.feeMessages?.[0] : {}

    const { oneProductQuantity } = productCartItems
    const itemAvailabilityInfo = productData?.itemAvailabilityInfo
    const { cartDebounceTimeOut } = productCartItems
    const [debounceTimeOut, setDebounceTimeOut] = useState<NodeJS.Timeout>(null)
    const counterRef = useRef<number>(0)
    const isShipHome = isOrderSTH(productData)
    const isInWishList = checkIsInWishList(wishlistProducts, productData.code)
    // eslint-disable-next-line no-template-curly-in-string
    const promoMessagesClass = '${PREFIX}-promo-messages-wrapper'
    const isMobile = useIsMobile()

    /**
     * use effect to update the qunatity in qunatityselector
     */
    useEffect(() => {
        if (counterRef.current === 0) setQuantity(productData.quantity)
    }, [productData])

    /**
     * Method used to check if fulfillment via DC stocks + in store stocks
     * @param {number} dcQuantity - distribution center quantity.
     * @param {number} inStoreQuantity - current store quantity.
     * @param {CartItemFulfillmentSource} sourceDC - if fulfilled through DC
     * @param {CartItemFulfillmentSource} sourceStore - if fulfilled through store
     * @returns {boolean}
     */
    const limitedStockAndAvailableInDc = (
        dcQuantity: number,
        inStoreQuantity: number,
        sourceDC: CartItemFulfillmentSource,
        sourceStore: CartItemFulfillmentSource,
    ): boolean => {
        return props.isShoppingCartPage
            ? Boolean(dcQuantity && inStoreQuantity < productData.quantity)
            : Boolean(Object.keys(sourceDC).length && Object.keys(sourceStore).length)
    }

    /**
     * Calculates the loyalty value for a given product.
     */
    const loyaltyValue = useCalculatedLoyaltyValue(productData?.loyalty, false, {
        qtySelectorInput: 1,
    })

    /**
     * Conditionally sets the `loyaltyProp` object based on whether the current page is the shopping cart page.
     * If it is the shopping cart page, the `loyaltyProp` object will contain the `loyaltyValue` property.
     * Otherwise, it will be an empty object
     */
    const loyaltyProp = isShoppingCartPage ? { loyaltyValue } : {}

    /**
     * function to check if fulfillment is only through store
     * @param {number} storeQty - store quantity
     * @param {CartItemFulfillmentSource} sourceStore - if fulfilled through store
     * @returns {boolean}
     */
    const checkOnlyStore = (storeQty: number, sourceStore: CartItemFulfillmentSource): boolean => {
        return props.isShoppingCartPage ? Boolean(storeQty) : Boolean(Object.keys(sourceStore).length)
    }

    const getETATime = (date?: string): string => {
        if (!date) return ''
        const options = getETATimeOptions(productCartItems.etaTimeOptions)
        const intlDate = localization.formattedDate(options, date, props.language)
        return intlDate
            .split(',')
            .reverse()
            .map(item => item.trim())
            .join(', ')
            .replace(/\./g, '')
            .replace(' ', '')
    }

    const getETAForRange = (date?: string): string => {
        if (!date) return ''
        const options = productCartItems.etaDateOptions
        const intlDate = localization.formattedDate(options, date, props.language)
        return intlDate.replace(/\./g, '')
    }

    /**
     * Method used to check if fulfillment via DC stocks only
     * @param {number} dcQuantity - distribution center quantity.
     * @param {number} inStoreQuantity - current store quantity.
     * @param {CartItemFulfillmentSource} sourceDC - if fulfilled only through DC
     * @param {CartItemFulfillmentSource} sourceStore - if fulfilled through store
     * @return {boolean}
     */
    const checkOnlyDCStock = (
        dcQuantity: number,
        inStoreQuantity: number,
        sourceDC: CartItemFulfillmentSource,
        sourceStore: CartItemFulfillmentSource,
    ): boolean => {
        return props.isShoppingCartPage
            ? Boolean(dcQuantity && !inStoreQuantity)
            : Boolean(!Object.keys(sourceStore).length && Object.keys(sourceDC).length)
    }

    /**
     * Function to get available between date range
     * @param {string} etaEarliestData
     * @param {string} etaLatestData
     * @return {JSX.Element}
     */
    const getAvailableBetween = (etaEarliestData: string, etaLatestData: string | null): JSX.Element => {
        return (
            <li>
                {replaceMultipleStrWithDynamicVal(props.availableBetweenLabel, [
                    getETAForRange(etaEarliestData),
                    getETAForRange(etaLatestData),
                ])}
            </li>
        )
    }

    const getAvailableData = (etaEarliestDate: string): JSX.Element => {
        return <li> {`${props.availableLabel} ${getETATime(etaEarliestDate)}`}</li>
    }

    /**
     * Function to get ETA label for grouped ETA under same YMM
     * @returns {JSX.Element} Element
     */
    const getEtaLabel = (): JSX.Element => {
        return props.sameEtaForSameYMM.etaEarliest && props.sameEtaForSameYMM.etaLatest
            ? getAvailableBetween(props.sameEtaForSameYMM.etaEarliest, props.sameEtaForSameYMM.etaLatest)
            : getAvailableData(props.sameEtaForSameYMM.etaEarliest)
    }

    /**
     * Method used for conditionally rendering the labels based on stock fulfillment
     * @returns {JSX.Element} Element
     */
    const inStorePickup = (): JSX.Element => {
        const { dcQuantity, storeQuantity: inStore } = productData.fulfillment?.stockItemAvailability || {}
        const { sourceTypes = [] } = productData.fulfillment || {}
        const storePickupDC =
            sourceTypes.find(source => source.productSourceType === ProductSourceTypes.DC) ||
            ({} as CartItemFulfillmentSource)
        const storePickupInStore =
            sourceTypes.find(source => source.productSourceType === ProductSourceTypes.INSTORE) ||
            ({} as CartItemFulfillmentSource)

        if (props.sameEtaForSameYMM) {
            return getEtaLabel()
        } else if (checkOnlyDCStock(dcQuantity, inStore, storePickupDC, storePickupInStore)) {
            return getAvailableBetween(storePickupDC.etaEarliest, storePickupDC.etaLatest)
        } else if (limitedStockAndAvailableInDc(dcQuantity, inStore, storePickupDC, storePickupInStore)) {
            return (
                <>
                    <li>{`${storePickupInStore.quantity} ${props.availableLabel} ${getETATime(
                        storePickupInStore.etaEarliest,
                    )}`}</li>
                    <li>
                        {replaceMultipleStrWithDynamicVal(props.availableByLabel, [
                            storePickupDC.quantity?.toString(),
                            getETAForRange(storePickupDC.etaEarliest),
                            getETAForRange(storePickupDC.etaLatest),
                        ])}
                    </li>
                </>
            )
        } else if (checkOnlyStore(inStore, storePickupInStore)) {
            return getAvailableData(storePickupInStore.etaEarliest)
        } else {
            return null
        }
    }

    /**
     * function to set between label for STH DC for order confirmation and cart page
     * @returns {string} Label
     */
    const getBetweenSTHLabel = (): string => {
        return isShoppingCartPage ? betweenLabel : betweenLabelOrderConf
    }

    /**
     * function to set between lable for etas for order confirmation and order detail page
     * @param {CartItemFulfillmentSource} storePickup
     * @returns  {string} Between label
     */
    const getBetweenLabel = (storePickup: CartItemFulfillmentSource): string => {
        const modifiedBetweenLabel = betweenLabelSTH?.split('-')[magicNumber.ZERO]
        return storePickup.etaEarliest && storePickup.etaLatest ? betweenLabelSTH : modifiedBetweenLabel
    }

    /**
     * function build Estemated Delivary Date Label
     * @param {ProductDataDTO} product
     * @param {string} inStoreQuantity
     * @return {string}
     */
    const buildEstimatedDeliveryDateBetweenLabel = (product: ProductDataDTO, inStoreQuantity?: string) => {
        const startDate = isShoppingCartPage ? product.estimatedDeliveryDate?.startDate : product.eddMin
        const endDate = isShoppingCartPage ? product.estimatedDeliveryDate?.endDate : product.eddMax

        const label = replaceMultipleStrWithDynamicVal(estimatedDeliveryDateBetween, [
            getFormattedDateTime(startDate)[magicNumber.ZERO],
            getFormattedDateTime(endDate)[magicNumber.ZERO],
        ])

        return inStoreQuantity ? buildEstematedDeliveryDateSplitQty(inStoreQuantity, label) : label
    }

    /**
     * Method used for creating multiple labels for STH when product is delivered in intervals
     * @return {JSX.Element}
     */
    const shipToHomeLabel = (): JSX.Element => {
        const { dcQuantity, storeQuantity: currentSelectedStoreQuantity } =
            productData.fulfillment?.stockItemAvailability || {}
        const { sourceTypes = [] } = productData.fulfillment || {}
        const isEstimatedDeliveryDateAvailable = getEstimatedDeliveryDateLabelAvailability(
            props.productData.badges,
            ProductSourceTypes.INSTORE,
            isBulk,
            fulfillment,
            isShowedEstimatedDeliveryDateBetween,
            !checkNotNullAndUndefined(estimatedDeliveryDate),
        )
        const storePickupDC = getSourceTypeData(sourceTypes, ProductSourceTypes.DC)
        const storePickupInStore = getSourceTypeData(sourceTypes, ProductSourceTypes.INSTORE)
        const estimatedDeliveryDateRange = `${getETAForRange(storePickupDC?.etaEarliest)} - ${getETAForRange(
            storePickupDC?.etaLatest,
        )}`
        const isOrderFromInStoreAndDC = checkIsOrderFromInStoreAndDC(storePickupDC, storePickupInStore)

        if (
            checkIsEstimatedDeliveryDateAvailableNotSplitQty(isEstimatedDeliveryDateAvailable, isOrderFromInStoreAndDC)
        ) {
            return (
                <SanitizeStringContentWrapper stringContent={buildEstimatedDeliveryDateBetweenLabel(props.productData)}>
                    {memoizedStringContent => <li dangerouslySetInnerHTML={{ __html: memoizedStringContent }} />}
                </SanitizeStringContentWrapper>
            )
        }

        if (checkOnlyDCStock(dcQuantity, currentSelectedStoreQuantity, storePickupDC, storePickupInStore)) {
            return (
                <li>{`${getBetweenSTHLabel()} ${getETAForRange(storePickupDC.etaEarliest)} - ${getETAForRange(
                    storePickupDC.etaLatest,
                )}`}</li>
            )
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (
            limitedStockAndAvailableInDc(dcQuantity, currentSelectedStoreQuantity, storePickupDC, storePickupInStore)
        ) {
            if (
                checkIsEstimatedDeliveryDateAvailableSplitQty(isEstimatedDeliveryDateAvailable, isOrderFromInStoreAndDC)
            ) {
                return (
                    <>
                        <SanitizeStringContentWrapper
                            stringContent={buildEstimatedDeliveryDateBetweenLabel(
                                props.productData,
                                storePickupInStore.quantity.toString(),
                            )}>
                            {memoizedStringContent => (
                                <li dangerouslySetInnerHTML={{ __html: memoizedStringContent }} />
                            )}
                        </SanitizeStringContentWrapper>
                        <li>{`${storePickupDC.quantity} ${betweenLabel} ${estimatedDeliveryDateRange}`}</li>
                    </>
                )
            }

            return (
                <>
                    <li>
                        {replaceMultipleStrWithDynamicVal(
                            isShoppingCartPage ? receiveBetweenLabel : getBetweenLabel(storePickupInStore),
                            [
                                storePickupInStore.quantity?.toString(),
                                getETAForRange(storePickupInStore.etaEarliest),
                                getETAForRange(storePickupInStore.etaLatest),
                            ],
                        )}
                    </li>
                    <li>
                        {replaceMultipleStrWithDynamicVal(
                            isShoppingCartPage ? receiveBetweenLabel : getBetweenLabel(storePickupDC),
                            [
                                storePickupDC.quantity?.toString(),
                                getETAForRange(storePickupDC.etaEarliest),
                                getETAForRange(storePickupDC.etaLatest),
                            ],
                        )}
                    </li>
                </>
            )
        }
        return <li>{`${getBetweenSTHLabel()} ${getETAForRange(storePickupInStore.etaEarliest)}`}</li>
    }

    // const getChangeFulfillmentLabel = (): string | undefined => {
    //     if (isShipHome) return productData.fulfillment?.isEligibleToPickupFromStore && pickAtStoreCTALabel
    //     return productData.fulfillment?.isEligibleToShipHome && changeShipToHomeCTALabel
    // }

    /**
     * We may need this function in future in order to implement Change TO STH/BOPIS Functionality
     * function to render Change Fulfillment option button
     * @return {JSX.Element}
     */
    // const renderChangeFulfillmentButton = (): JSX.Element => {
    //     const showChangeFulfillmentOption = getChangeFulfillmentLabel()
    //     return (
    //         <Button
    //             id="show-fulfillment"
    //             type="tertiary"
    //             size="mini"
    //             onClick={() =>
    //                 onChangeFulfillmentOption(
    //                     !isShipHome ? FulfillmentMethods.STH : FulfillmentMethods.BOPIS,
    //                     productData?.entryNumber,
    //                 )
    //             }>
    //             {showChangeFulfillmentOption}
    //         </Button>
    //     )
    // }

    const quantityChangeHandler = (
        incrementValue: number,
        fromInputUpdate?: boolean,
        inputRef?: React.MutableRefObject<any>,
    ) => {
        setQuantity(incrementValue)
        setPrevQuantity(quantity)
        setFromInput(fromInputUpdate)
        setInputElementRef(inputRef)
        counterRef.current = magicNumber.ONE
    }

    const globalBannerMaxErrorCheck = (value: boolean) => {
        setGlobalBannerMax(value)
    }

    const limitLabelCheck = (): { limitLabel: string; limitLabelShow: boolean } => {
        return {
            limitLabel: maxQuantity ? replaceStrWithDynamicVal(props.limitOfLabel, maxQuantity) : '',
            limitLabelShow: !!maxQuantity,
        }
    }

    const checkMaxQuantityError = (): boolean => {
        return maxQuantity && quantity > maxQuantity && maxQuantity <= checkAvailableQuantity()
    }

    const informationErrorMessage = (
        errorMessage: string,
        errorMessageShow: boolean,
        exceededValue: string,
    ): { errorMsg: string; quantityExceeded: boolean; exceededValue: string } => {
        return {
            errorMsg: errorMessage,
            quantityExceeded: errorMessageShow,
            exceededValue: exceededValue,
        }
    }

    const checkAvailableQuantity = (): number => {
        const { orderableFromDC } = productData.fulfillment || {}
        const { dcQuantity, storeQuantity } = productData.fulfillment?.stockItemAvailability || {}

        return orderableFromDC && dcQuantity ? dcQuantity + storeQuantity : storeQuantity
    }

    const validateQuantityRequest = (): boolean => {
        return !maxQuantity && quantity > checkAvailableQuantity() && checkAvailableQuantity() < maxInputVal
    }

    const checkGlobalBannerMax = (): boolean => {
        return !maxQuantity && quantity > maxInputVal && checkAvailableQuantity() > maxInputVal
    }

    const checkErrorMessage = (): { errorMsg: string; quantityExceeded: boolean; exceededValue: string } => {
        // checking if maxQuantity exist
        if (checkMaxQuantityError()) {
            props.setErrorInfoHandler(true, false)
            return informationErrorMessage(
                replaceStrWithDynamicVal(props.exceededMsg, maxQuantity),
                true,
                `${maxQuantity}`,
            )
            // checking if quantity selected is greater than availaible stocks  when availaible stocks is less than Global Banner Max value
        } else if (validateQuantityRequest()) {
            props.setErrorInfoHandler(true, false)
            return informationErrorMessage(
                replaceStrWithDynamicVal(props.exceededTotalAvailableMsg, checkAvailableQuantity()),
                true,
                `${checkAvailableQuantity()}`,
            )
            // checking if quantity selected is greater than Global Banner Max value  when availaible stocks is greater than Global Banner Max value
        } else if (checkGlobalBannerMax() || globalBannerMaxVal) {
            props.setErrorInfoHandler(true, false)
            return informationErrorMessage(
                replaceStrWithDynamicVal(props.exceededMsg, maxInputVal),
                true,
                maxInputVal.toString(),
            )
        } else {
            return informationErrorMessage('', false, '')
        }
    }

    /**
     * renders front or rear label
     * @return { JSX.Element }
     */
    const renderFrontRear = (): JSX.Element | null => {
        return productData.staggeredGroup ? (
            <div className={`${automotiveClass}__front-rear`}>
                {productData.isFront ? props.attributeFrontLabel : props.attributeRearLabel}
            </div>
        ) : null
    }

    /**
     * Function checks if the product is only availabe in store pickup
     * @return {boolean}
     */
    const isCollapsed = (): boolean => {
        return !props.hasSelectedAddOn && !hideAccordionAfterRemove
    }

    const addOns = props.getSelectedAddOn
    // Remove selected services
    if (isArrayNotEmpty(addOns) && isArrayNotEmpty(props.addons)) {
        addOns.forEach(addon => {
            const index = props.addons.findIndex(hardware => hardware.sku === addon.code)

            if (index !== magicNumber.MINUS_ONE) {
                props.addons.splice(index, magicNumber.ONE)
            }
        })
    }

    const warningToastProps = {
        changeShipToHomeCTALabel: props.changeShipToHomeCTALabel,
        pickAtStoreCTALabel: props.pickAtStoreCTALabel,
        exceededMsgBar: props.exceededMsgBar,
        nearbyAvailabilityCTALabel: props.nearbyAvailabilityCTALabel,
        onChangeFulfillmentOption: props.onChangeFulfillmentOption,
        checkNearByCTAHandler: props.checkNearByCTAHandler,
        checkErrorMessage,
    }
    const addOrRemoveWishlistProps = {
        addItemToWishlist,
        removeitemFromWishlist,
    }

    const isQuantityExceeded = checkErrorMessage().quantityExceeded
    /**
     * use effect to trigger update cart when the quantity is changed but not exceeded
     */
    useEffect(() => {
        if (quantity && prevQuantity !== quantity && !isQuantityExceeded && counterRef.current !== 0) {
            const isAdd = Boolean(prevQuantity < quantity)
            updateCartData(quantity, isAdd, productData.entryNumber, fromInput, inputElementRef)
            counterRef.current = 0
        }
    }, [
        updateCartData,
        prevQuantity,
        quantity,
        isQuantityExceeded,
        fromInput,
        inputElementRef,
        productData.entryNumber,
    ])

    /**
     * Render updated text when there is some stock in dc.
     * @return {JSX.Element}
     */
    const renderUpdatedText = (): JSX.Element => {
        return (
            itemAvailabilityInfo?.optionsToBeDisplayed?.includes(OptionsOnCartItem.UPDATED_TEXT) && (
                <p className={`${infoClass}__updated-text`}>{props.updatedLabel}</p>
            )
        )
    }

    /**
     * Render an urgency limited stock if it is necessary
     * @return {JSX.Element}
     */
    const renderUrgencyLimitedStock = (): JSX.Element | null => {
        const productQty = productData?.fulfillment?.stockItemAvailability?.storeQuantity
        return props.limitedStockMessage && productData?.isUrgentLowStock && !!productQty ? (
            <div className={`${infoClass}__limitedStock`}>
                {replaceStrWithDynamicVal(props.limitedStockMessage, productQty)}
            </div>
        ) : null
    }

    const isRenderPromoMessages = useMemo(() => {
        const isMessagePresent = isAtleastOneParamValid(
            isArrayNotEmpty(productData?.promoMessages),
            isArrayNotEmpty(productData?.restrictedPromoMessages),
            isArrayNotEmpty(productData?.appliedPromoMessages),
        )
        return isMessagePresent && (!isWishList || enableShowPromotionsAndRomanceCopy)
    }, [
        productData?.appliedPromoMessages,
        productData?.promoMessages,
        productData?.restrictedPromoMessages,
        enableShowPromotionsAndRomanceCopy,
        isWishList,
    ])

    /**
     * renders product information (availability)
     * @returns { JSX.Element }
     */
    const renderAvailability = (): JSX.Element => {
        return (
            <>
                {productData?.isOnSale &&
                    renderSaleEndDaySoonMessageComp(
                        salePriceEndDate,
                        isOnSale,
                        isUrgentLowStock,
                        isShoppingCartPage,
                        hideSaleEndDaySoonMessage,
                        saleEndDaySoonMessage,
                        saleEndDisableShift,
                    )}
                {!props.isDetailPage && (
                    <ul className={`${infoClass}__availability`}>
                        {!props.hideQuantitySelector && renderUpdatedText()}
                        {isShipHome ? shipToHomeLabel() : inStorePickup()}
                    </ul>
                )}
                {renderRebateSection()}
            </>
        )
    }

    /**
     * renders product information (name, brand, availability)
     * @return { JSX.Element }
     */
    const renderProductInfo = (): JSX.Element => {
        return (
            <div className={`${infoClass} ${PREFIX}-col-md-8 ${PREFIX}-col-sm-8`}>
                <div className={`${infoClass}__brand`}>{productData?.brand?.label}</div>
                <div className={`${infoClass}__title`}>
                    {productData.url ? (
                        <a
                            href={productData.url}
                            className={`${infoClass}__title-link`}
                            data-link-value={productData.name}>
                            {productData.name}
                        </a>
                    ) : (
                        productData.name
                    )}
                </div>
                {props.hideQuantitySelector && <RenderQuantity qtyAbbrLabel={props.qtyAbbrLabel} quantity={quantity} />}
                {isArrayNotEmpty(specificationsArr) && (
                    <div className={`${infoClass}__colour`}>
                        {specificationsArr.map(spec => (
                            <div key={spec.code}>{`${spec?.label}: ${spec?.value}`}</div>
                        ))}
                    </div>
                )}
                {isRenderPromoMessages && renderPromoMessages(!isMobile)}
                <div className={`${infoClass}--desktop`}>{renderUrgencyLimitedStock()}</div>
                {!(
                    itemAvailabilityInfo?.showInlineError &&
                    !itemAvailabilityInfo?.optionsToBeDisplayed.includes(OptionsOnCartItem.AVAILABILITY_INFO)
                ) && (
                    <div
                        className={`${infoClass} ${infoClass}__availability_section  ${PREFIX}-md-block ${PREFIX}-xs-none ${PREFIX}-sm-none`}>
                        {renderAvailability()}

                        {/* {!!getChangeFulfillmentLabel() && onlineOrdering && renderChangeFulfillmentButton()} */}
                    </div>
                )}
            </div>
        )
    }
    // if the product is a parent product then display wishlist confirmation modal if hardware addons are associated
    const isParentProduct: boolean =
        isArrayNotEmpty(addOns) && productData.entryType === eEntryType.PRODUCT && !productData.cartEntryReference

    /**
     * Function for RemoveButton from Automotive Cart Item.
     * @return {JSX.Element} RemoveButton.
     */
    const renderRemoveButton = (): JSX.Element => {
        return (
            <button
                className={`${cartClass}__icon--close`}
                data-testid="cartCloseIcon"
                onClick={() => {
                    onRemoveCartItem(isParentProduct, productData)
                    !isParentProduct && setHideAccordionAfterRemove(true)
                }}
                aria-label={a11yRemoveLabel}>
                <Icon type="ct-close" size="lg" />

                {saveForLaterFlagEnabled ? (
                    <span
                        className={`${infoClass}__title-link ${infoClass}__title-link--space ${infoClass}__title-link--align`}>
                        {a11yRemoveLabel}
                    </span>
                ) : null}
            </button>
        )
    }

    const onClickForSfl = (redirectToClp?: boolean) => {
        if (redirectToClp) {
            ssoHandler(true)
        }
        addItemToSaveForLater(productData, addOns, true)
    }

    /**
     * Function to render the Save For Later button title.
     * @return {JSX.Element} Save For Later button title.
     */
    const renderSaveForLaterTitle = (): JSX.Element => {
        return (
            <span
                className={`${infoClass}__title-link ${infoClass}__title-link--space ${infoClass}__title-link--align`}>
                {saveForLaterTitle}
            </span>
        )
    }

    const renderSflButton = (useSingleSignOn: boolean): JSX.Element => {
        return (
            <button
                className={`${cartClass}__icon--sfl`}
                data-testid="saveForLaterIcon"
                aria-label={saveForLaterTitle}
                onClick={() => onClickForSfl(useSingleSignOn)}>
                <Icon type="ct-save-for-later" size="lg" />
                {renderSaveForLaterTitle()}
            </button>
        )
    }

    const renderSaveForLaterButtonForURL = (): JSX.Element => {
        return (
            <SanitizeStringContentWrapper
                stringContent={urlRedirection(
                    urlRedirection(loginPageLink, 'returnURL', cartPageLink),
                    saveForLaterParam,
                    'true',
                )}>
                {memoizedStringContent => (
                    <a
                        className={`${cartClass}__icon--sfl`}
                        data-testid="saveForLaterIcon"
                        href={memoizedStringContent}
                        onClick={() => onClickForSfl(false)}
                        data-link-value={saveForLaterTitle}
                        aria-label={saveForLaterTitle}>
                        <Icon type="ct-save-for-later" size="lg" />
                        {renderSaveForLaterTitle()}
                    </a>
                )}
            </SanitizeStringContentWrapper>
        )
    }

    /**
     * Function for SaveLater Item.
     * @return {JSX.Element} Save For Later Button.
     */

    const renderSaveForLaterButton = (): JSX.Element => {
        return (
            <>
                {isAuthUser && renderSflButton(false)}
                {!isAuthUser && enableSingleSignOn && renderSflButton(true)}
                {!isAuthUser && !enableSingleSignOn && renderSaveForLaterButtonForURL()}
            </>
        )
    }
    /**
     * renders buttons to add to wishlist and to delete product
     * @param {boolean} isWishlistSection - Boolean flag to indicate if is in wishlist section.
     * @return { JSX.Element }
     */
    const renderButtons = (isWishlistSection = true) => {
        return isWishlistSection ? (
            <div className={`${cartClass}__icon`}>
                {showWishListIcon(
                    itemAvailabilityInfo?.showInlineError,
                    itemAvailabilityInfo?.optionsToBeDisplayed,
                ) && (
                    <button
                        className={`${cartClass}__icon--wishlist`}
                        aria-label={a11yWishlistIcon}
                        data-testid="cartWishlistIcon"
                        onClick={() => addOrRemoveWishlist(addOrRemoveWishlistProps, isInWishList, productData)}>
                        {renderWishListIconComponent(isInWishList)}
                    </button>
                )}
                {!saveForLaterFlagEnabled && renderRemoveButton()}
            </div>
        ) : (
            <div
                className={`${cartClass}__icon ${cartClass}__icon--bottom ${cartClass}__icon--bottom-container ${cartClass}__icon--sfl-${
                    saveForLaterFlagEnabled ? 'enabled' : 'disabled'
                }`}>
                {saveForLaterFlagEnabled ? (
                    <>
                        {renderSaveForLaterButton()}
                        {renderRemoveButton()}
                    </>
                ) : null}
            </div>
        )
    }

    /**
     * Function for rebate on Cart Item.
     * @return {JSX.Element} Rebate.
     */
    const renderRebateSection = (): JSX.Element | null => {
        if (productData.rebate && Object.keys(productData.rebate).length) {
            const { message, tooltipMessage, greyCopyMsg, pdfLink } = productData.rebate || {}

            return (
                <div className={`${infoClass}__rebate-info`}>
                    <Rebate
                        title={message || ''}
                        tooltipMessage={tooltipMessage}
                        greyCopyMsg={greyCopyMsg}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                        pdfLink={pdfLink}
                    />
                </div>
            )
        }
        return null
    }

    /**
     * cancel product handler
     * @return {void}
     */
    const handleCancelProductClick = (): void => {
        getEntriesInfo(true, 'TIRES', productData.entryNumber, productData.quantity, productData.name)
        cancellationAnalytics('cancel_order', 'partial', 'Order Details')
    }

    /**
     * Function to check the product type is tire or wheel
     * @param {string} productType
     * @return { boolean }
     */
    const isTireOrWheel = (productType: string): boolean => {
        return isProductTire({ productWheelType: productType }) || isProductWheel({ productWheelType: productType })
    }

    /**
     * Function to get compatible Package Name for tire or wheel
     * @param {string} productWheelTypeCurrent
     * @param {string} labelOrLink
     * @return { string }
     */
    const compatiblePackageName = (productWheelTypeCurrent: string, labelOrLink: string): string => {
        if (productWheelType) {
            let oppositeWheelType: string
            switch (productWheelTypeCurrent) {
                case productWheelTypes.Tire:
                case productWheelTypes.WinterTire:
                    oppositeWheelType =
                        labelOrLink === createNewPackageLabel ? createNewPackageLabelWheel : createNewPackageCTAWheel
                    break
                case productWheelTypes.Wheel:
                    oppositeWheelType =
                        labelOrLink === createNewPackageLabel ? createNewPackageLabelTire : createNewPackageCTATire
                    break
                default:
                    oppositeWheelType = ''
                    break
            }
            return oppositeWheelType
        }
        return ''
    }

    /**
     * Function to render packageCreation
     * @returns { JSX.Element }
     */
    const renderPackageCreationToast = (): JSX.Element | null => {
        const isToastVisible =
            productsRequirePackageCreationMessage &&
            !productData.staggeredGroup &&
            isTireOrWheel(productWheelType) &&
            !packagedItem
        return (
            isToastVisible && (
                <CommonWarningToast
                    icon="ct-information-blue"
                    isLink={true}
                    bgType="blue"
                    label={compatiblePackageName(productWheelType, createNewPackageLabel)}
                    checkNearByText={compatiblePackageName(productWheelType, createNewPackageCTA)}
                    createPackageRedirectHandler={() =>
                        props.createPackageRedirect && props.createPackageRedirect(productWheelType)
                    }
                    createPackageAndNavigate={event => createPackageAndNavigate(event, productData)}
                />
            )
        )
    }

    /**
     * function to render toast when no vehicle data
     * @return {JSX.Element}
     */

    const renderNoVehicleToast = (): JSX.Element | null => {
        const isToastVisible =
            !(productData?.vehicleInformation && Object.keys(productData?.vehicleInformation).length) &&
            props.isTire &&
            !props.hideQuantitySelector
        return (
            isToastVisible && (
                <CommonWarningToast
                    icon="ct-notification-caution"
                    bgType="orange"
                    label={props.autoNoGuaranteeFitMsg}
                />
            )
        )
    }

    /**
     * @method checkQunatityReduced  : Method used to check when the quantity decreased wrt to base quantity
     * @param {boolean} removedAnItem - set to true when the entire item to be removed.
     * @returns {number}
     *
     */

    const checkQunatityReduced = useCallback(
        (removedAnItem?: boolean): number => {
            if (removedAnItem) return quantity
            if (quantity < productData.quantity) {
                return productData.quantity - quantity
            } else {
                return quantity - productData.quantity
            }
        },
        [productData.quantity, quantity],
    )

    /**
     * Method is fired when qtySelectorInput is updated and cart analytics needs to be pushed
     */
    const callAnalytics = useCallback((): void => {
        const maxQtyReached = maxQuantity && quantity > maxQuantity
        if (quantity > productData.quantity) {
            cartItemAnalytics(productData, checkQunatityReduced(), 'addToCart', maxQtyReached)
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (quantity < productData.quantity) {
            cartItemAnalytics(productData, checkQunatityReduced())
        }
    }, [checkQunatityReduced, maxQuantity, cartItemAnalytics, productData, quantity])

    const previousQuantity = usePrevious(quantity)

    useEffect(() => {
        if (quantity !== previousQuantity) {
            customDebounce(callAnalytics, cartDebounceTimeOut, debounceTimeOut, setDebounceTimeOut)()
        }
    }, [callAnalytics, cartDebounceTimeOut, debounceTimeOut, setDebounceTimeOut, quantity, previousQuantity])

    /**
     * function to render quantity selector
     * @return {JSX.Element}
     */
    const renderQuantitySelector = (): JSX.Element | null => {
        return (
            <>
                {!props.hideQuantitySelector && (
                    <div className={`${cartClass}__quantity ${PREFIX}-col-md-4 ${PREFIX}-col-sm-3`}>
                        <QuantitySelector
                            setQtySelectorInput={quantityChangeHandler}
                            qtySelectorInput={quantity}
                            defaultQty={productData.quantity}
                            maxQty={maxQuantity}
                            maximumQuantityError={checkErrorMessage().errorMsg}
                            quantityExceeded={checkErrorMessage().quantityExceeded}
                            limitLabel={limitLabelCheck().limitLabel}
                            limitLabelShow={limitLabelCheck().limitLabelShow}
                            checkBannerMaxError={globalBannerMaxErrorCheck}
                            disabled={
                                !(productData.entryType === eEntryType.PRODUCT && !productData.cartEntryReference)
                            }
                            a11yQuantityLabel={props.accessibilityGlobalProps.a11yQuantityLabel}
                            a11yQuantityDecrementLabel={props.accessibilityGlobalProps.a11yQuantityDecrementLabel}
                            a11yQuantityIncrementLabel={props.accessibilityGlobalProps.a11yQuantityIncrementLabel}
                            a11yMinQuantityReached={props.accessibilityGlobalProps.a11yMinQuantityReached}
                            a11yQuantityInputAriaLabel={props.accessibilityGlobalProps.a11yQuantityInputAriaLabel}
                            id={props.quantitySelectorId}
                        />
                    </div>
                )}
            </>
        )
    }

    const renderPromoMessages = (isVisible: boolean): JSX.Element | null => {
        return isVisible ? (
            <div className={promoMessagesClass}>
                <PromoMessages
                    isProductLevel={true}
                    productPromoMessages={productData?.promoMessages}
                    productRestrictedPromoMessages={productData?.restrictedPromoMessages}
                    appliedPromoPrefix={appliedPromoPrefix}
                    appliedPromoMessages={productData?.appliedPromoMessages}
                    a11yTooltipIcon={a11yTooltipIcon}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                />
            </div>
        ) : null
    }

    const {
        errorMessage: itemAvailabilityErrMsg,
        showInlineError: itemAvailabilityShowInlineError,
        hardStopError: itemAvailabilityShowHardStopError,
        optionsToBeDisplayed: itemAvailabilityOptionsToBeDisplayed,
        isShowETAToast: itemAvailabilityInfoShowETAToast,
    } = itemAvailabilityInfo || {}

    const previousItemAvailabilityInfoErrMsg = usePrevious(itemAvailabilityErrMsg)

    /**
     * use effect to trigger error analytics on load when there is an inline errormessage.
     */
    useEffect(() => {
        if (itemAvailabilityErrMsg && itemAvailabilityErrMsg !== previousItemAvailabilityInfoErrMsg) {
            itemAvailabilityShowInlineError &&
                !!errorAnalyticsForInlineToast &&
                errorAnalyticsForInlineToast(itemAvailabilityErrMsg)
            !!itemAvailabilityShowHardStopError && setErrorInfoHandler(itemAvailabilityShowInlineError, false) // run this only when the error is hard stop error. so that user cant be allowed to proceed to checkout.
            itemAvailabilityInfoShowETAToast &&
                !!itemAvailabilityOptionsToBeDisplayed.includes(OptionsOnCartItem.UPDATED_TEXT) &&
                setErrorInfoHandler(false, true, true) // run this when eta changed
        }
    }, [
        errorAnalyticsForInlineToast,
        setErrorInfoHandler,
        itemAvailabilityErrMsg,
        itemAvailabilityShowInlineError,
        itemAvailabilityShowHardStopError,
        itemAvailabilityOptionsToBeDisplayed,
        previousItemAvailabilityInfoErrMsg,
        itemAvailabilityInfoShowETAToast,
    ])

    const cancellationClass = customerSelfServiceCancelEnabled ? `${automotiveClass}__cancellation` : ''

    useEffect(() => {
        if (isQuantityExceeded && checkoutButtonClick) {
            setInlineToastError(true)
        } else if (!!itemAvailabilityErrMsg && checkoutButtonClick) {
            setInlineToastError(true)
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (!isQuantityExceeded && !itemAvailabilityErrMsg) {
            setInlineToastError(false)
        }
    }, [isQuantityExceeded, checkoutButtonClick, itemAvailabilityErrMsg])

    return (
        <div
            className={`${PREFIX}-auto-wrapper ${
                inlineToastError && itemAvailabilityInfo?.hardStopError ? `${PREFIX}-hard-stop-wrapper` : ''
            }`}>
            {(!!itemAvailabilityInfo?.showInlineError || inlineToastError) &&
                renderWarningToast(warningToastProps, itemAvailabilityInfo, productData)}
            {renderNoVehicleToast()}
            <div className={`${PREFIX}-automotive-inner-wrapper ${PREFIX}-row`}>
                <div
                    className={`${cartClass} ${PREFIX}-row ${automotiveClass}__item ${cancellationClass}  ${
                        !!itemAvailabilityInfo?.showInlineError ? 'error-card' : ''
                    }`}>
                    {isCartPackageToggle && renderPackageCreationToast()}
                    {!productData.packageId && renderFrontRear()}
                    <div className={`${automotiveClass}__item-wrapper ${PREFIX}-row`}>
                        {customerSelfServiceCancelEnabled ? (
                            <div className={`${cartClass}__cta-cancel ${cartClass}__icon`}>
                                <Button
                                    id="cancel-product-btn"
                                    type="tertiary"
                                    size="mini"
                                    disabled={!productData.cancellable}
                                    onClick={() => {
                                        handleCancelProductClick()
                                    }}>
                                    {props.cancelProductLabel}
                                </Button>
                            </div>
                        ) : null}
                        <ProductImageComponent
                            itemAvailabilityInfo={itemAvailabilityInfo}
                            isService={false}
                            a11yServiceInstallation={props.a11yServiceInstallation}
                            servicesIcon="ct-tools"
                            images={productData.images}
                            name={productData.name}
                            url={productData.url}
                        />
                        <div className={`${cartClass}__text-container ${PREFIX}-col-md-8 ${PREFIX}-col-sm-8`}>
                            <div
                                className={`${cartClass}__product-item-wrapper ${PREFIX}-col-md-12 ${PREFIX}-col-sm-12`}>
                                {renderProductInfo()}
                                {(!itemAvailabilityInfo?.showInlineError ||
                                    itemAvailabilityInfo?.optionsToBeDisplayed.includes(
                                        OptionsOnCartItem.PRICE_AND_QUANTITY,
                                    )) && (
                                    <div
                                        className={`${
                                            props?.isOrderPage ? `${automotiveClass}__price-quantity-alignment` : ''
                                        } ${automotiveClass}__price-quantity ${PREFIX}-col-md-4 ${PREFIX}-col-sm-12`}>
                                        {renderQuantitySelector()}
                                        <div className={`${cartClass}__price ${PREFIX}-col-md-7 ${PREFIX}-col-sm-8`}>
                                            <Price
                                                isRangeView={isPriceRangeViewEnabled}
                                                priceValue={renderItemPrice(
                                                    quantity,
                                                    productData.currentPrice,
                                                    productData.totalPrice,
                                                )}
                                                currentPrice={productData.currentPrice}
                                                originalPrice={productData.originalPrice}
                                                displayWasLabel={productData.displayWasLabel}
                                                hideSaveLabel={false}
                                                language={props.language}
                                                eachLabel={props.eachLabel}
                                                isMoreQuantityCartPage={quantity > oneProductQuantity}
                                                promotionalPriceLabel={props.saveLabel}
                                                clearancePriceLabel={props.clearancePriceLabel}
                                                unitPriceLabel={props.unitPriceLabel}
                                                thresholdValue={props.thresholdValue}
                                                a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                                                a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                                                feeTitle={
                                                    feeMessage?.value
                                                        ? replaceStrWithDynamicVal(
                                                              addDynamicToken(feeMessage?.feeTitle, '$x'),
                                                              getFormattedPriceValue(props.language, feeMessage?.value),
                                                          )
                                                        : ''
                                                }
                                                feeDisclaimerTitle={feeMessage?.feeDisclaimerTitle}
                                                feeDisclaimerMessage={feeMessage?.feeDisclaimerMessage}
                                                feeDisclaimerType={feeMessage?.type}
                                                nowFromLabel={props.nowFromLabel}
                                                saveFromLabel={props.saveFromLabel}
                                                wasFromLabel={props.wasFromLabel}
                                                fromLabel={props.fromLabel}
                                                scrollToFooter={props.scrollToFooter}
                                                priceMessage={productData?.priceMessage}
                                                isShoppingCartPage={props.isShoppingCartPage}
                                                a11yClickToReadFootnote={props.a11yClickToReadFootnote}
                                                a11yTooltipIcon={props.a11yTooltipIcon}
                                                isOnSale={productData?.isOnSale}
                                            />
                                            {isRenderPromoMessages && renderPromoMessages(isMobile)}
                                            {isArrayNotEmpty(productData.badges) && (
                                                <div className={`${cartClass}__cart-badges`}>
                                                    <Badges
                                                        badges={productData.badges}
                                                        badgesAndPriorities={props.badgePriorities}
                                                        saleEndDaySoonMessage={props.saleEndDaySoonMessage}
                                                        salePriceEndDate={productData?.salePriceEndDate}
                                                        isShoppingCart={props.isShoppingCartPage}
                                                        isOnSale={productData?.isOnSale}
                                                        {...loyaltyProp}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={`${infoClass}--mobile`}>{renderUrgencyLimitedStock()}</div>
                            <div className={`${cartClass}__availability-container ${PREFIX}-row`}>
                                {!(
                                    itemAvailabilityInfo?.showInlineError &&
                                    !itemAvailabilityInfo?.optionsToBeDisplayed.includes(
                                        OptionsOnCartItem.AVAILABILITY_INFO,
                                    )
                                ) && (
                                    <div
                                        className={`${infoClass} ${PREFIX}-md-none ${PREFIX}-xs-none ${PREFIX}-sm-block`}>
                                        {isOnSale &&
                                            renderSaleEndDaySoonMessageComp(
                                                salePriceEndDate,
                                                isOnSale,
                                                isUrgentLowStock,
                                                isShoppingCartPage,
                                                hideSaleEndDaySoonMessage,
                                                saleEndDaySoonMessage,
                                                saleEndDisableShift,
                                            )}
                                        {!props.isDetailPage && (
                                            <ul className={`${infoClass}__availability`}>
                                                {!props.hideQuantitySelector && renderUpdatedText()}
                                                {isShipHome ? shipToHomeLabel() : inStorePickup()}
                                            </ul>
                                        )}
                                        {renderRebateSection()}
                                        {/* {!!getChangeFulfillmentLabel() &&
                                            onlineOrdering &&
                                            renderChangeFulfillmentButton()} */}
                                    </div>
                                )}
                                {!(
                                    itemAvailabilityInfo?.showInlineError &&
                                    !itemAvailabilityInfo?.optionsToBeDisplayed.includes(
                                        OptionsOnCartItem.AVAILABILITY_INFO,
                                    )
                                ) && (
                                    <div
                                        className={`${infoClass}  ${PREFIX}-md-none ${PREFIX}-xs-block ${PREFIX}-sm-none`}>
                                        {isOnSale &&
                                            renderSaleEndDaySoonMessageComp(
                                                salePriceEndDate,
                                                isOnSale,
                                                isUrgentLowStock,
                                                isShoppingCartPage,
                                                hideSaleEndDaySoonMessage,
                                                saleEndDaySoonMessage,
                                                saleEndDisableShift,
                                            )}
                                        {!props.isDetailPage && (
                                            <ul className={`${infoClass}__availability`}>
                                                {isShipHome ? shipToHomeLabel() : inStorePickup()}
                                            </ul>
                                        )}
                                        {renderRebateSection()}
                                        {/* {!!getChangeFulfillmentLabel() &&
                                            onlineOrdering &&
                                            renderChangeFulfillmentButton()} */}
                                    </div>
                                )}
                            </div>
                        </div>
                        {props.isDetailPage &&
                            getBuyItAgainCta(props.dpBuyAgainLabel, productData?.url, productData.name)}
                        {!props.hideQuantitySelector && renderButtons()}
                        {renderButtons(false)}
                    </div>
                </div>
            </div>
            <AutomotiveAddonsListWrapper
                {...props}
                isCollapsed={isCollapsed()}
                setHideAccordionAfterRemove={setHideAccordionAfterRemove}
            />
        </div>
    )
}

export default AutomotiveProductCard
