import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/checkout.actionCreators'
import {
    AddressSuggestions,
    AddressSuggestionsError,
    AddressAutoSuggestions,
    InitPayment,
    PaymentDetails,
    PaymentDetailsError,
    GiftCardDTO,
    AddressSuggestionsResponseErrorDTO,
} from '../models/checkout.interface'
import { CartResponseErrorDTO, RedeemCTMoneyResponse, PlaceOrderDTO } from '../models/cart.interface'
import {
    getSuggestionsFailure,
    getSuggestionsSuccess,
    getAddressSuggestionsSuccess,
    getAddressSuggestionsFailure,
    getErrorSuggestionsSuccess,
    getAddressSuggestionsCleanup,
    placeOrderSuccess,
    placeOrderFailure,
    clearPlaceOrderFailure,
    semaFoneEnabled,
    redeemCTMoneyResponseAction,
    getPaymentDetailsAction,
    getPaymentDetailsFailure,
    editPaymentClickedAction,
    isGiftCardJustDeletedAction,
    setInitPaymentStatusAction,
    setSignInButtonClicked,
    setShouldInvokeInitPayment,
    setPlaceOrderRedirectParam,
    setOrderSummaryRootAvailable,
    setThirdPartyContainerAvailable,
    setPaymentFlowType,
    setIsPlaceOrderFlowFailed,
} from '../actionCreators/checkout.actionCreators'
import { PaymentFlowType } from '@nl/lib'

type Action = ActionType<typeof actions>

export interface GiftCard {
    accountNumber: string
    amount: string
    id: string
    remainingBalance: string
}

export interface CheckoutState extends Partial<InitPayment> {
    addressSuggestions: AddressSuggestions
    addressSuggestionsError: AddressSuggestionsResponseErrorDTO
    autoCompleteSuggestions: AddressAutoSuggestions
    addressSuggestionsFailure: AddressSuggestionsError
    errorSuggestions: AddressSuggestions
    placeOrderSuccess: PlaceOrderDTO
    placeOrderError: CartResponseErrorDTO
    paymentDetails: PaymentDetails
    redeemCTMoneyResponse: RedeemCTMoneyResponse | null
    semaFoneEnabled?: boolean
    paymentDetailsError: PaymentDetailsError
    editPaymentClicked: boolean
    isGiftCardDeleted: boolean
    isInitPaymentSuccess: boolean
    isSignInButtonClicked: boolean
    shouldInvokeInitPayment: boolean
    placeOrderRedirectParam: string
    orderSummaryRootAvailable: boolean
    isThirdPartyContainerAvailable: boolean
    paymentFlowType: PaymentFlowType
    isPlaceOrderFlowFailed: boolean
}

export const initialState: CheckoutState = {
    addressSuggestions: {} as unknown as AddressSuggestions,
    addressSuggestionsError: {} as unknown as AddressSuggestionsResponseErrorDTO,
    autoCompleteSuggestions: {} as unknown as AddressAutoSuggestions,
    addressSuggestionsFailure: {} as unknown as AddressSuggestionsError,
    errorSuggestions: {} as unknown as AddressSuggestions,
    placeOrderSuccess: {} as unknown as PlaceOrderDTO,
    placeOrderError: {} as unknown as CartResponseErrorDTO,
    paymentDetails: {} as unknown as PaymentDetails,
    redeemCTMoneyResponse: null,
    paymentDetailsError: null as unknown as PaymentDetailsError,
    giftCards: [] as GiftCardDTO[],
    editPaymentClicked: false,
    isGiftCardDeleted: false,
    isInitPaymentSuccess: false,
    isSignInButtonClicked: false,
    shouldInvokeInitPayment: true,
    placeOrderRedirectParam: '',
    orderSummaryRootAvailable: false,
    isThirdPartyContainerAvailable: false,
    paymentFlowType: PaymentFlowType.NORMAL,
    isPlaceOrderFlowFailed: false,
}

/* eslint-disable complexity */
// eslint-disable-next-line sonar/cyclomatic-complexity
export const checkoutReducer = (state: CheckoutState = initialState, action: Action): CheckoutState => {
    switch (action.type) {
        case getType(getSuggestionsSuccess): {
            return { ...state, autoCompleteSuggestions: action.payload }
        }
        case getType(getSuggestionsFailure): {
            return { ...state, addressSuggestionsError: action.payload }
        }
        case getType(getAddressSuggestionsSuccess): {
            return { ...state, addressSuggestions: action.payload }
        }
        case getType(getAddressSuggestionsFailure): {
            return { ...state, addressSuggestionsError: action.payload }
        }
        case getType(getErrorSuggestionsSuccess): {
            return { ...state, errorSuggestions: action.payload }
        }
        case getType(getAddressSuggestionsCleanup): {
            return {
                ...state,
                errorSuggestions: {} as unknown as AddressSuggestions,
                addressSuggestions: {} as unknown as AddressSuggestions,
            }
        }
        case getType(actions.setHppToken): {
            return { ...state, ...action.payload }
        }
        case getType(placeOrderSuccess): {
            return {
                ...state,
                placeOrderSuccess: action.payload,
                placeOrderError: {} as unknown as CartResponseErrorDTO,
            }
        }
        case getType(placeOrderFailure): {
            return { ...state, placeOrderError: action.payload }
        }
        case getType(clearPlaceOrderFailure): {
            return { ...state, placeOrderError: {} as unknown as CartResponseErrorDTO }
        }
        case getType(semaFoneEnabled): {
            return { ...state, ...action.payload } as CheckoutState
        }
        case getType(redeemCTMoneyResponseAction): {
            return { ...state, redeemCTMoneyResponse: action.payload }
        }
        case getType(getPaymentDetailsAction): {
            return { ...state, paymentDetails: action.payload }
        }
        case getType(getPaymentDetailsFailure): {
            return { ...state, paymentDetailsError: action.payload }
        }
        case getType(editPaymentClickedAction): {
            return { ...state, editPaymentClicked: action.payload }
        }
        case getType(isGiftCardJustDeletedAction): {
            return { ...state, isGiftCardDeleted: action.payload }
        }
        case getType(setInitPaymentStatusAction): {
            return { ...state, isInitPaymentSuccess: action.payload }
        }
        case getType(setSignInButtonClicked): {
            return { ...state, isSignInButtonClicked: action.payload }
        }
        case getType(setShouldInvokeInitPayment): {
            return { ...state, shouldInvokeInitPayment: action.payload }
        }
        case getType(setPlaceOrderRedirectParam): {
            return { ...state, placeOrderRedirectParam: action.payload }
        }
        case getType(setOrderSummaryRootAvailable): {
            return { ...state, orderSummaryRootAvailable: action.payload }
        }
        case getType(setThirdPartyContainerAvailable): {
            return { ...state, isThirdPartyContainerAvailable: action.payload }
        }
        case getType(setPaymentFlowType): {
            return { ...state, paymentFlowType: action.payload }
        }
        case getType(setIsPlaceOrderFlowFailed): {
            return { ...state, isPlaceOrderFlowFailed: action.payload }
        }
        default:
            return state
    }
}
