import React, { useCallback, useEffect, useRef, useState } from 'react'

import { CustomDropdownProps } from './CustomDropdown.type'
import Icon from '../Icon'
import { getInteractiveElements } from '../../helpers/getInteractiveElements.helper'
import { isEnterPressed, isEscPressed, isSpacePressed, isTabPressed } from '../../helpers/checkKeyboardKey.helper'
import { useOnClickOutside, magicNumber } from '../../utils'
import PropTypes from 'prop-types'
import { useOnKeyDownOutside } from '../../utils/useOnKeyDownOutside'
import { useOnKeyUpOutside } from '../../utils/useOnKeyUpOutside'

/**
 * This component should be used as an all-purpose dropdown component.
 * You can provide any component as a child to this component as well as the label prop.
 * If you need a dropdown list (dropdown menu) consider using "Dropdown" component.
 * Note:
 * You will have to stylise children (and label) you provided by yourself in your styles files.
 * @param {CustomDropdownProps} props
 * @returns {Element} dropdown component
 */
const CustomDropdown: React.FC<CustomDropdownProps> = props => {
    const {
        id,
        children,
        label,
        showIcon = true,
        filterRow,
        filterPanelRef,
        forSEOOnLoad,
        isMaxHeightRemove,
        disabled,
        shouldClose,
        setShouldClose,
        placeholder,
        isSearchable,
        searchChangeHandler,
        searchInputClassname,
    } = props

    const [isOpen, setIsOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    // Close dropdown when we press esc in dropdown area

    // useEffect to handle dropdown open/close on scroll event of mobile
    useEffect(() => {
        if (setShouldClose && shouldClose) {
            if (isOpen) {
                setIsOpen(false)
                setSearchValue('')
            }
            setShouldClose(false)
        }
    }, [shouldClose, isOpen, setShouldClose])

    useEffect(() => {
        const dropdownNode = dropdownPanelRef?.current
        if (dropdownNode) {
            dropdownNode.addEventListener('keydown', (event: KeyboardEvent) => {
                if (isEscPressed(event.key)) {
                    setIsOpen(false)
                    setSearchValue('')
                    mainRef?.current?.focus()
                }
            })
        }
    }, [])

    const filterRef = useRef<HTMLDivElement>()
    const mainRef = useRef<HTMLButtonElement>()
    const dropdownPanelRef: React.MutableRefObject<HTMLDivElement> | undefined = useRef()
    const customDropdownPanelOpen = isMaxHeightRemove
        ? 'custom-dropdown-panel--open custom-dropdown-panel--remove-max-height'
        : 'custom-dropdown-panel--open'
    const customDropdownPanel = 'custom-dropdown-panel'
    const dropdownTestId = id ? `custom-dropdown_${id}` : 'custom-dropdown'

    /**
     * Handles the click event to toggle the dropdown menu.
     * @function handleClick
     * @param {React.MouseEvent} e - The click event object.
     * @returns {void}
     */
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()
        if (dropdownPanelRef?.current?.contains(e.target as Node)) return
        setIsOpen(!isOpen)
        setSearchValue('')
        !isOpen && searchChangeHandler && searchChangeHandler('')
    }

    // Handler should be wrapped with useCallback in order to prevent unnecessary fire of the effect
    const clickOutsideHandler = useCallback(() => {
        if (isOpen) {
            setIsOpen(false)
            setSearchValue('')
        }
    }, [isOpen])

    useOnClickOutside(filterRef, clickOutsideHandler)

    /**
     * callback function handles dropdown close on esc, space, enter
     * @param { React.KeyboardEvent<HTMLButtonElement> } event
     * @return { void }
     */
    const closeDropDown = useCallback((event: KeyboardEvent) => {
        if (isEscPressed(event.key) || isSpacePressed(event.key) || isEnterPressed(event.key)) {
            setIsOpen(false)
            setSearchValue('')
        }
    }, [])

    useOnKeyDownOutside(filterRef, closeDropDown, filterRow)

    // In order to close the last dropdown filter on row change.
    const closeDropDownOnTab = useCallback((event: KeyboardEvent) => {
        if (isTabPressed(event.key)) {
            setIsOpen(false)
            setSearchValue('')
        }
    }, [])

    // this hook is used to handle key up event
    useOnKeyUpOutside(filterRow, closeDropDownOnTab, filterPanelRef)

    // In order to close the first dropdown filter on shift-tab
    useEffect(() => {
        const filterRowNode = filterRow?.current
        if (filterRowNode) {
            filterRowNode.addEventListener('keydown', (event: KeyboardEvent) => {
                const firstInteractiveElement = getInteractiveElements(filterRowNode)[magicNumber.ZERO]
                if (firstInteractiveElement === event.target && event.shiftKey && isTabPressed(event.key)) {
                    // Do your action here
                    setIsOpen(false)
                    setSearchValue('')
                }
            })
        }
    }, [filterRow])

    /**
     * This function handles key down event on custom dropdown button
     * @param { React.KeyboardEvent<HTMLButtonElement> } event
     * @return { void }
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (isEscPressed(event.key)) {
            setIsOpen(false)
            setSearchValue('')
        }
    }

    /**
     * This function render input for searchable dropdown
     * @returns { JSX.Element }
     */
    const renderSearchButton = (): JSX.Element => {
        return (
            <div className={searchInputClassname}>
                <input
                    value={isOpen ? searchValue : String(label)}
                    id={id}
                    placeholder={placeholder}
                    data-testid={dropdownTestId}
                    disabled={disabled}
                    onChange={event => {
                        searchChangeHandler && searchChangeHandler(event.currentTarget.value)
                        setSearchValue(event.currentTarget.value)
                    }}
                    onClick={!isOpen && handleClick}
                />
                {showIcon ? (
                    <button disabled={disabled} onClick={handleClick} aria-label={String(label)}>
                        <Icon type={isOpen ? 'ct-chevron-up' : 'ct-chevron-down'} />
                    </button>
                ) : null}
            </div>
        )
    }

    /**
     * Determines whether the search component should be rendered.
     * @function shouldRenderSearch
     * @returns {boolean} - Returns `true` if the search component should be rendered,
     *                      otherwise `false`.
     */
    const shouldRenderSearch = useCallback(() => {
        return isSearchable && searchChangeHandler
    }, [searchChangeHandler, isSearchable])

    // Below we have introduced a div in order to have dropdown button and dropdown list bind to each other as per HTML an UI in a perticular div.
    return (
        <div ref={filterRef}>
            {shouldRenderSearch() ? (
                renderSearchButton()
            ) : (
                <button
                    className={'custom-dropdown'}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    ref={mainRef}
                    disabled={disabled}
                    data-testid={dropdownTestId}
                    id={id}
                    aria-expanded={isOpen}>
                    <div className={'custom-dropdown__label'}>
                        {label}
                        {showIcon ? <Icon type={isOpen ? 'ct-chevron-up' : 'ct-chevron-down'} /> : null}
                    </div>
                </button>
            )}
            <div ref={dropdownPanelRef} className={isOpen ? customDropdownPanelOpen : customDropdownPanel}>
                {(isOpen || forSEOOnLoad) && children}
            </div>
        </div>
    )
}

CustomDropdown.propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.element.isRequired,
    showIcon: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default CustomDropdown
