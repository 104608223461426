import React, { useEffect } from 'react'
import { PREFIX } from '../config'
import ComparisonTable from '../ComparisonTable/ComparisonTable'
import { RenderFullModalProps } from './CompareProductsFlyout.type'

/**
 * render full modal for comaprison table
 * @param {RenderFullModalProps} props - props for render full modal
 * @returns {JSX.Element} returns JSX.Element
 */
const RenderFullModal: React.FC<RenderFullModalProps> = (props): JSX.Element => {
    const {
        comparisonTableData,
        rowsOnLoad,
        seeMoreLabel,
        seeLessLabel,
        isSticky,
        setIsSticky,
        visibleRows,
        setVisibleRows,
        isTableExpanded,
        setIsTableExpanded,
    } = props

    /**
     * Lifecycle hook for listening to the scroll event
     */
    useEffect(() => {
        /**
         * function to set sticky header from scroll event
         * @param {number} scrollTop - listening to the scroll event
         * @return {void}
         */
        const handleScroll = (scrollTop: number): void => {
            const buyBoxCompEL = document.querySelector('.first-row') || null
            const buyBoxCompELHeight = buyBoxCompEL?.clientHeight ?? 0
            scrollTop + 100 > buyBoxCompELHeight && setIsSticky(true)
            if (scrollTop === 0) {
                setIsSticky(false)
            }
        }
        const scrollableElement = document.querySelector(`.${PREFIX}-csp__table `)
        scrollableElement.addEventListener('scroll', () => handleScroll(scrollableElement.scrollTop))
        return () => {
            scrollableElement.removeEventListener('scroll', () => handleScroll(scrollableElement.scrollTop))
        }
    }, [])

    const overFlowHiddenClass = 'modal-open-not-scroll'
    document.body.classList.add(overFlowHiddenClass)
    return (
        <ComparisonTable
            comparisonTableData={comparisonTableData as unknown as Record<string, string>[]}
            rowsOnLoad={rowsOnLoad}
            seeMoreLabel={seeMoreLabel}
            seeLessLabel={seeLessLabel}
            isSticky={isSticky}
            setIsSticky={setIsSticky}
            visibleRows={visibleRows}
            setVisibleRows={setVisibleRows}
            isTableExpanded={isTableExpanded}
            setIsTableExpanded={setIsTableExpanded}
        />
    )
}

export default RenderFullModal
