import { Dispatch } from '@reduxjs/toolkit'
import { AxiosPromise, AxiosError } from 'axios'
import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import {
    notificationPreferenceSuccess,
    notificationUpdateSuccessAction,
    notificationUpdateErrorAction,
    notificationPreferenceError,
    resetNotificationPreferenceAction,
    shoppingPreferenceSuccess,
    shoppingPreferenceError,
    shoppingUpdateSuccessAction,
    shoppingUpdateErrorAction,
    resetShoppingPreferenceAction,
    swapOffersTourCompleted,
} from '../actionCreators/user.preferences.actionCreators'
import {
    PreferenceError,
    NotificationPreferenceBody,
    NotificationPreference,
    PreferencesJnjtraitsSuccessResponse,
    PreferencesJnjtraitsBody,
} from '../models/user.preferences.interface'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import BaseService from '../../services/base.service'
import { isOtpErrorCode, OtpErrorType } from '@nl/lib'
import { componentList } from '../../globalConstants/global.constant'
import { initOtpFlow, setOtpErrCode } from '../actionCreators/otp.actionCreators'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * fetch notification preferences
 * @return { void }
 */
export const fetchNotificationPreferences =
    () =>
    (dispatch: Dispatch): void => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.notificationPreference}`

        // get the user shopping preference
        httpClient
            // eslint-disable-next-line no-warning-comments
            // TODO :uncomment below code for final integration
            .apiGet<NotificationPreference>(url, { lang: BaseService.language }, headers, true)
            .then(preferenceData => {
                // store the CDS access token and swapOffersTourCompleted in local storage
                dispatch(notificationPreferenceSuccess(preferenceData.data))
                localStorageService.setItem(
                    'swapOffersTourCompleted',
                    String(preferenceData.data.swapOffersTourComplete),
                )
                dispatch(swapOffersTourCompleted(preferenceData.data.swapOffersTourComplete))
            })
            .catch(() => {
                dispatch(notificationPreferenceError())
            })
    }

const getUpadatedNotificationPreference = (
    headers: { authorization?: string },
    requestPayload?: NotificationPreferenceBody,
): AxiosPromise<any> => {
    const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.notificationPreference}`
    return httpClient.apiPost(url, { ...requestPayload }, headers, true)
}

export const updateEmailSingUP = (headers: { authorization?: string }, requestPayload?: NotificationPreferenceBody) => {
    return getUpadatedNotificationPreference(headers, requestPayload)
}

/**
 * update notification preferences
 * @param { NotificationPreferenceBody } requestPayload payload for updating preferences
 * @return { Promise }
 */
export const updateNotificationPreferences =
    (requestPayload?: NotificationPreferenceBody) =>
    (dispatch: Dispatch): Promise<void> => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }

        return getUpadatedNotificationPreference(headers, requestPayload)
            .then(data => {
                const successStatus = 200
                const preferenceUpdated = data.status === successStatus
                dispatch(notificationUpdateSuccessAction(preferenceUpdated))
            })
            .catch(() => {
                dispatch(notificationUpdateErrorAction())
            })
    }

/**
 * reset notification preferences
 *
 * @return { void }
 */
export const resetNotificationPreference =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetNotificationPreferenceAction())
    }

/**
 * fetch shopping preferences
 *
 * @return { void }
 */
export const fetchShoppingPreferences =
    () =>
    (dispatch: Dispatch): void => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const shoppingPreferenceheaders: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.shoppingPreference}`
        // get the user shopping preference
        httpClient
            .apiGet<PreferencesJnjtraitsSuccessResponse>(
                url,
                { lang: BaseService.language, includeContent: true },
                shoppingPreferenceheaders,
                true,
            )
            .then(preferenceData => {
                // store the CDS access token in local storage
                dispatch(shoppingPreferenceSuccess(preferenceData.data))
            })
            .catch(() => {
                dispatch(shoppingPreferenceError())
            })
    }

/**
 * Update Shopping preferences e.g: subscribe to eFlyer
 * @param { PreferencesJnjtraitsBody } requestPayload payload for updating preferences
 * @return { Promise }
 */
export const updateShoppingPreferences =
    (requestPayload: PreferencesJnjtraitsBody[]) =>
    (dispatch: Dispatch): Promise<void> => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const shoppingPreferenceheaders: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.shoppingPreference}`

        return httpClient
            .apiPost(url, requestPayload, shoppingPreferenceheaders, true)
            .then(data => {
                const successStatus = 200
                const preferenceUpdated = data.status === successStatus
                dispatch(shoppingUpdateSuccessAction(preferenceUpdated))
            })
            .catch((err: AxiosError<PreferenceError>) => {
                if (isOtpErrorCode(err.response?.data?.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: err.response?.data.vToken as string,
                            component: componentList.accounts.shoppingPreferences,
                            userID: err.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(err.response?.data?.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(err.response?.data.errCode as string))
                } else {
                    dispatch(shoppingUpdateErrorAction())
                }
            })
    }

/**
 * fetch shopping preferences
 *
 * @return { void }
 */
export const resetShoppingPreference =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetShoppingPreferenceAction())
    }
