export const NOTIFICATION_PREFERENCE_SUCCESS = 'NOTIFICATION_PREFERENCE_SUCCESS'
export const NOTIFICATION_PREFERENCE_UPDATE = 'NOTIFICATION_PREFERENCE_UPDATE'
export const NOTIFICATION_PREFERENCE_ERROR = 'NOTIFICATION_PREFERENCE_ERROR'
export const NOTIFICATION_PREFERENCE_CLEAR = 'NOTIFICATION_PREFERENCE_CLEAR'
export const SHOPPING_PREFERENCE_SUCCESS = 'SHOPPING_PREFERENCE_SUCCESS'
export const SHOPPING_PREFERENCE_UPDATE = 'SHOPPING_PREFERENCE_UPDATE'
export const SHOPPING_PREFERENCE_UPDATE_ERROR = 'SHOPPING_PREFERENCE_UPDATE_ERROR'
export const SHOPPING_PREFERENCE_ERROR = 'SHOPPING_PREFERENCE_ERROR'
export const SHOPPING_PREFERENCE_CLEAR = 'SHOPPING_PREFERENCE_CLEAR'
export const NOTIFICATION_PREFERENCE_UPDATE_ERROR = 'NOTIFICATION_PREFERENCE_UPDATE_ERROR'
export const NOTIFICATION_PREFERENCE_UPDATE_CLEAR = 'NOTIFICATION_PREFERENCE_UPDATE_CLEAR'
export const SWAP_OFFERS_TOUR_COMPLETED = 'SWAP_OFFERS_TOUR_COMPLETED'
