import React from 'react'
import clx from 'classnames'

import {
    Badges,
    Price,
    Rebate,
    RoadRatingBadge,
    VehicleAvailabilitySection,
    getAccessibilityId,
    isAtleastOneParamValid,
    stringToBooleanMap,
} from '@nl/lib'
import { sanitizeStringContent } from '@nl/lib/src/utils/sanitizeStringContent'

import { hallOFameFitmentStaggered, hallOFameProductTile } from './HallOfFame.classnames'
import { BadgePriorityType, CommonProductDetailsProps, HallOfFameProductTileProps } from './HallOfFame.types'
import { HOFAccessabilityId, availabilityTestID } from './HallOfFame.constants'
import { TireWheelFitmentNote } from '../../../globalConstants'
import useIsPriceRangeIsAllowed from '../../../hooks/useIsPriceRangeIsAllowed.hook'

/**
 * @description representation of single featured product
 * @param {HallOfFameProductTileProps} product - product
 * @return {JSX.Element} component
 */
function HallOfFameProductTile({
    title,
    aggregateValue,
    roadRatedLabel,
    eachLabel,
    fromLabel,
    pdpUrl,
    badgesConfig,
    currentPrice,
    originalPrice,
    priceMessage,
    displayWasLabel,
    unitPriceLabel,
    promotionalPriceLabel,
    clearancePriceLabel,
    discount,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    a11yCloseIconLabel,
    a11yTooltipIcon,
    plusMinusSymbol,
    wasFromLabel,
    nowFromLabel,
    saveFromLabel,
    language,
    tireSize,
    sliSkuNumber,
    thumbImgUrl,
    rebate,
    prodName,
    recordType,
    wheelOffset,
    wheelRimWidth,
    wheelRimDiameter,
    fulfillment,
    isUrgentLowStock,
    badges,
    prodId,
    skuId,
    tireSizeLabel,
    wheelOffsetLabel,
    wheelRimWidthLabel,
    wheelRimDiameterLabel,
    checkAvailabilityLabel,
    checkOtherStoresLabel,
    isUseLegacyLogicOfVehicleCheckAvailability,
    inStockAisleLabel,
    inStockOnlineLabel,
    outOfStockLabel,
    isUrgentLowStockLabel,
    inStorePurchaseLabel,
    IsOnline,
    originallyInstalled,
    // rear
    rearSliSkuNumber,
    rearDisplayWasLabel,
    rearRebate,
    rearBadges,
    rearIsUrgentLowStock,
    rearDiscount,
    rearPriceMessage,
    rearSkuId,
    rearWheelOffset,
    rearWheelRimWidth,
    rearWheelRimDiameter,
    rearTireSize,
    rearCurrentPrice,
    rearOriginalPrice,
    rearFulfillment,
    rearIsOnline,
    rearOriginallyInstalled,
    attributeFrontLabel,
    attributeRearLabel,
    renderFitmentMessage,
    isOnSale,
}: HallOfFameProductTileProps): JSX.Element {
    const isStaggered = Boolean(rearSliSkuNumber)
    const rebateToDisplay = isStaggered ? rearRebate : rebate
    const isWheelOrTirePDP = true
    const accessibilityId = getAccessibilityId(HOFAccessabilityId, recordType === 'product' ? prodId : skuId)

    return (
        <li>
            <a className={hallOFameProductTile} href={sanitizeStringContent(pdpUrl)} tabIndex={0}>
                <div className="product-tile__header-wrapper">
                    <span className="product-tile__ranking-rule">{title}</span>
                    <img className="product-tile__image" src={sanitizeStringContent(thumbImgUrl)} alt="" />
                </div>
                <div className="product-tile__product-details">
                    <div className="product-details__header">
                        <span
                            className="product-details__product-name"
                            data-testid={`${prodId}-product-name`}
                            dangerouslySetInnerHTML={{ __html: sanitizeStringContent(prodName) }}
                        />
                        {aggregateValue && (
                            <RoadRatingBadge
                                roadRatedLabel={roadRatedLabel}
                                aggregateValue={aggregateValue}
                                ariaHidden={true}
                            />
                        )}
                        <div
                            className="product-details__rating"
                            data-bv-show="inline_rating"
                            data-bv-product-id={prodId}
                            data-bv-seo="false"
                            aria-hidden={true}
                        />
                    </div>
                    {isStaggered && renderFitmentMessage?.(accessibilityId, isStaggered, hallOFameFitmentStaggered)}
                    <CommonProductDetails
                        isStaggered={isStaggered}
                        recordType={recordType}
                        language={language}
                        eachLabel={eachLabel}
                        nowFromLabel={nowFromLabel}
                        saveFromLabel={saveFromLabel}
                        wasFromLabel={wasFromLabel}
                        fromLabel={fromLabel}
                        promotionalPriceLabel={promotionalPriceLabel}
                        unitPriceLabel={unitPriceLabel}
                        clearancePriceLabel={clearancePriceLabel}
                        a11yStrikeOutPrice={a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                        a11yTooltipIcon={a11yTooltipIcon}
                        a11yCloseIconLabel={a11yCloseIconLabel}
                        plusMinusSymbol={plusMinusSymbol}
                        badgesConfig={badgesConfig}
                        isUrgentLowStockLabel={isUrgentLowStockLabel}
                        outOfStockLabel={outOfStockLabel}
                        checkAvailabilityLabel={checkAvailabilityLabel}
                        checkOtherStoresLabel={checkOtherStoresLabel}
                        isUseLegacyLogicOfVehicleCheckAvailability={isUseLegacyLogicOfVehicleCheckAvailability}
                        inStockAisleLabel={inStockAisleLabel}
                        inStockOnlineLabel={inStockOnlineLabel}
                        wheelOffsetLabel={wheelOffsetLabel}
                        wheelRimDiameterLabel={wheelRimDiameterLabel}
                        wheelRimWidthLabel={wheelRimWidthLabel}
                        //
                        sliSkuNumber={sliSkuNumber}
                        isUrgentLowStock={isUrgentLowStock}
                        fulfillment={fulfillment}
                        priceMessage={priceMessage}
                        displayWasLabel={displayWasLabel}
                        currentPrice={currentPrice}
                        originalPrice={originalPrice}
                        badges={badges}
                        wheelOffset={wheelOffset}
                        wheelRimWidth={wheelRimWidth}
                        wheelRimDiameter={wheelRimDiameter}
                        tireSize={tireSize}
                        tireSizeLabel={tireSizeLabel}
                        isOrderable={stringToBooleanMap[IsOnline?.Orderable]}
                        isSellable={stringToBooleanMap[IsOnline?.Sellable]}
                        discount={discount}
                        accessibilityId={accessibilityId}
                        staggeredPriceLabel={attributeFrontLabel}
                        originallyInstalled={stringToBooleanMap[originallyInstalled]}
                        isWheelOrTirePDP={isWheelOrTirePDP}
                        inStorePurchaseLabel={inStorePurchaseLabel}
                        renderFitmentMessage={renderFitmentMessage}
                        isOnSale={isOnSale}
                    />
                    {isStaggered && (
                        <CommonProductDetails
                            isOnSale={isOnSale}
                            isStaggered={isStaggered}
                            recordType={recordType}
                            language={language}
                            eachLabel={eachLabel}
                            nowFromLabel={nowFromLabel}
                            saveFromLabel={saveFromLabel}
                            wasFromLabel={wasFromLabel}
                            fromLabel={fromLabel}
                            promotionalPriceLabel={promotionalPriceLabel}
                            unitPriceLabel={unitPriceLabel}
                            clearancePriceLabel={clearancePriceLabel}
                            a11yStrikeOutPrice={a11yStrikeOutPrice}
                            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                            a11yTooltipIcon={a11yTooltipIcon}
                            a11yCloseIconLabel={a11yCloseIconLabel}
                            plusMinusSymbol={plusMinusSymbol}
                            badgesConfig={badgesConfig}
                            isUrgentLowStockLabel={isUrgentLowStockLabel}
                            outOfStockLabel={outOfStockLabel}
                            checkAvailabilityLabel={checkAvailabilityLabel}
                            checkOtherStoresLabel={checkOtherStoresLabel}
                            isUseLegacyLogicOfVehicleCheckAvailability={isUseLegacyLogicOfVehicleCheckAvailability}
                            inStockAisleLabel={inStockAisleLabel}
                            inStockOnlineLabel={inStockOnlineLabel}
                            wheelOffsetLabel={wheelOffsetLabel}
                            wheelRimDiameterLabel={wheelRimDiameterLabel}
                            wheelRimWidthLabel={wheelRimWidthLabel}
                            tireSizeLabel={tireSizeLabel}
                            //
                            sliSkuNumber={rearSliSkuNumber}
                            isUrgentLowStock={rearIsUrgentLowStock}
                            fulfillment={rearFulfillment}
                            priceMessage={rearPriceMessage}
                            displayWasLabel={rearDisplayWasLabel}
                            currentPrice={rearCurrentPrice}
                            originalPrice={rearOriginalPrice}
                            badges={rearBadges}
                            wheelOffset={rearWheelOffset}
                            wheelRimWidth={rearWheelRimWidth}
                            wheelRimDiameter={rearWheelRimDiameter}
                            tireSize={rearTireSize}
                            isOrderable={stringToBooleanMap[rearIsOnline?.Orderable]}
                            isSellable={stringToBooleanMap[rearIsOnline?.Sellable]}
                            discount={rearDiscount}
                            accessibilityId={getAccessibilityId(
                                HOFAccessabilityId,
                                recordType === 'product' ? prodId : rearSkuId,
                            )}
                            staggeredPriceLabel={attributeRearLabel}
                            originallyInstalled={stringToBooleanMap[rearOriginallyInstalled]}
                            isWheelOrTirePDP={isWheelOrTirePDP}
                            inStorePurchaseLabel={inStorePurchaseLabel}
                        />
                    )}

                    {rebateToDisplay && (
                        <div className="product-details__rebate">
                            <Rebate
                                title={rebateToDisplay.message || ''}
                                greyCopyMsg={rebateToDisplay.greyCopyMsg}
                                pdfLink={rebateToDisplay.pdfLink}
                                tooltipMessage={rebateToDisplay.tooltipMessage}
                                ariaHidden={true}
                                a11yTooltipIcon={a11yTooltipIcon}
                                rebateIcon="ct-offer"
                            />
                        </div>
                    )}
                </div>
            </a>
        </li>
    )
}

/**
 * @description common product details for regular and staggered vehicle
 * @param {CommonProductDetailsProps} props - props to pass to CommonProductDetails component
 * @return {JSX.Element} component
 */
export const CommonProductDetails = ({
    isOrderable,
    isSellable,
    recordType,
    accessibilityId,
    fulfillment,
    currentPrice,
    originalPrice,

    sliSkuNumber,

    eachLabel,
    nowFromLabel,
    saveFromLabel,
    wasFromLabel,
    fromLabel,
    priceMessage,
    displayWasLabel,
    promotionalPriceLabel,
    unitPriceLabel,
    clearancePriceLabel,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    a11yTooltipIcon,
    a11yCloseIconLabel,
    plusMinusSymbol,
    discount,
    staggeredPriceLabel,
    badges,
    badgesConfig,
    isStaggered,
    wheelOffset,
    wheelRimWidth,
    wheelRimDiameter,
    tireSize,
    language,
    tireSizeLabel,
    wheelOffsetLabel,
    wheelRimWidthLabel,
    wheelRimDiameterLabel,
    checkAvailabilityLabel,
    checkOtherStoresLabel,
    isUseLegacyLogicOfVehicleCheckAvailability,
    inStockAisleLabel,
    inStockOnlineLabel,
    outOfStockLabel,
    isUrgentLowStockLabel,
    originallyInstalled,
    inStorePurchaseLabel,
    isWheelOrTirePDP,
    renderFitmentMessage,
}: CommonProductDetailsProps): JSX.Element => {
    const hasOriginalPrice = isAtleastOneParamValid(Boolean(originalPrice?.value), Boolean(originalPrice?.minPrice))
    const isSkuLevelProduct = recordType === 'sku'
    const badgesAndPriorities = badgesConfig as BadgePriorityType
    const badgesToDisplay = originallyInstalled ? [TireWheelFitmentNote.OI, ...badges] : badges
    const skuData = {
        active: true,
        badges: [],
        code: '',
        displayWasLabel: false,
        optionIds: [],
        url: '',
        fulfillment,
        orderable: isOrderable,
        sellable: isSellable,
    }

    const isRangeView = useIsPriceRangeIsAllowed()

    return (
        <>
            <div
                className={clx('product-details__price-section', {
                    'product-details__price-section--staggered': isStaggered,
                    'current-price--highlight': hasOriginalPrice,
                })}>
                {isStaggered && <span className="price-section__staggered-label">{staggeredPriceLabel}:</span>}
                <Price
                    isRangeView={isRangeView}
                    currentPrice={currentPrice}
                    originalPrice={originalPrice}
                    language={language}
                    isAutomotiveEachLabel={true}
                    eachLabel={eachLabel}
                    nowFromLabel={nowFromLabel}
                    saveFromLabel={saveFromLabel}
                    wasFromLabel={wasFromLabel}
                    fromLabel={fromLabel}
                    priceMessage={priceMessage}
                    displayWasLabel={displayWasLabel}
                    promotionalPriceLabel={promotionalPriceLabel}
                    unitPriceLabel={unitPriceLabel}
                    clearancePriceLabel={clearancePriceLabel}
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    ariaHidden={true}
                    a11yTooltipIcon={a11yTooltipIcon}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                    plusMinusSymbol={plusMinusSymbol}
                    discountValue={discount}
                />
            </div>
            <div className="product-details__badges">
                <Badges badges={badgesToDisplay} badgesAndPriorities={badgesAndPriorities} hideDisclaimer={true} />
            </div>
            {!isStaggered && renderFitmentMessage?.(accessibilityId, isStaggered)}
            <div className="product-details__specifications">
                {
                    // eslint-disable-next-line sonar/expression-complexity
                    isSkuLevelProduct && (
                        <>
                            {tireSize && (
                                <span className="product-details__spec">
                                    <span className="spec__label">{tireSizeLabel}: </span>
                                    {tireSize}
                                </span>
                            )}
                            {wheelOffset && (
                                <span className="product-details__spec">
                                    <span className="spec__label">{wheelOffsetLabel}: </span>
                                    {wheelOffset}
                                </span>
                            )}
                            {wheelRimWidth && (
                                <span className="product-details__spec">
                                    <span className="spec__label">{wheelRimWidthLabel}: </span>
                                    {wheelRimWidth}
                                </span>
                            )}
                            {wheelRimDiameter && (
                                <span className="product-details__spec">
                                    <span className="spec__label">{wheelRimDiameterLabel}: </span>
                                    {wheelRimDiameter}
                                </span>
                            )}
                            <span className="product-details__sku-id">#{sliSkuNumber}</span>
                        </>
                    )
                }
            </div>
            {isSkuLevelProduct && (
                <div data-testid={availabilityTestID} className="product-details__availability-section">
                    <VehicleAvailabilitySection
                        skuData={skuData}
                        accessibilityId={accessibilityId}
                        inStockAisleLabel={inStockAisleLabel}
                        inStockOnlineLabel={inStockOnlineLabel}
                        isUrgentLowStock={false}
                        isWheelOrTirePDP={isWheelOrTirePDP}
                        isAutoPartPlp={false}
                        isUrgentLowStockLabel={isUrgentLowStockLabel}
                        outOfStockLabel={outOfStockLabel}
                        checkAvailabilityLabel={checkAvailabilityLabel}
                        checkOtherStoresLabel={checkOtherStoresLabel}
                        isUseLegacyLogicOfVehicleCheckAvailability={isUseLegacyLogicOfVehicleCheckAvailability}
                        inStorePurchaseLabel={inStorePurchaseLabel}
                    />
                </div>
            )}
        </>
    )
}

HallOfFameProductTile.displayName = 'HallOfFameProductTile'
CommonProductDetails.displayName = 'CommonProductDetails'

export default HallOfFameProductTile
