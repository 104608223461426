/* eslint-disable default-param-last */
import { Dispatch } from '@reduxjs/toolkit'
import { pageTypes } from '../../config'
import { HttpRequestState } from '../../httpClient/client.type'

import { aemFragmentService } from '../../services/aemFragment'
import { setApiXhrInfo, setDynamicContentDataOnSuccess } from '../actionCreators'
import { DynamicComponentReduxType } from '../models/dynamicComponent.interface'

export const getDynamicComponentData =
    (pageName: string, categories: string[] = [], pageId?: string) =>
    (dispatch: Dispatch): Promise<void> | void => {
        let promise: Promise<unknown>
        switch (pageName) {
            case pageTypes.pdpPage:
                promise = aemFragmentService.pdpFragment(categories)
                break
            case pageTypes.store:
                promise = aemFragmentService.storeFragment(pageId as string)
                break
            case pageTypes.brandPage:
                promise = aemFragmentService.brandFragment(categories)
                break
            default:
                promise = aemFragmentService.plpFragment(pageId as string)
                break
        }
        promise
            .then(data => {
                dispatch(setDynamicContentDataOnSuccess(data as DynamicComponentReduxType))
                dispatch(setApiXhrInfo(HttpRequestState.done))
            })
            .catch(err => console.error(err))
        dispatch(setApiXhrInfo(HttpRequestState.inProgress))
    }
