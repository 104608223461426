import React, { useMemo } from 'react'
import { Role, State } from '../../types'
import { ITab } from './Tabs.type'
import { TabButton } from '../ButtonV2'

/**
 * TabV2 component
 * @param {ITab} Props ITab
 * @returns {JSX.Element} returns TabV2 component
 */
const TabV2: React.FC<ITab> = ({ ...props }): JSX.Element => {
    const { index, children, isActive, label, gap } = props
    const tabIndex = index ? index : 0

    /**
     * To check add class based on button tab state
     */
    const isActiveState = useMemo(() => (isActive ? State.ACTIVE : State.DEFAULT), [isActive])

    const a11yTabs = {
        controls: `${Role.TABPANEL}-${tabIndex}`,
        selected: isActive,
    }

    return (
        <TabButton
            tabIndex={isActive ? 0 : -1}
            id={`tab-${tabIndex}`}
            a11y={a11yTabs}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                props.onSwitchTab(e, index, label as string)
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                props.onSwitchTab(e, index, label as string)
            }}
            state={isActiveState}
            {...(gap ? { gap } : {})}>
            {children}
        </TabButton>
    )
}

export default TabV2
