import { Dispatch } from '@reduxjs/toolkit'
import httpClient from '../utils/httpClient'
import { setSiteWidePromoCodeSuccess } from '../actionCreators'

const getSiteWidePromoCodeEndpoint = (): string =>
    document.getElementById('siteWidePromoCodeEndpoint')?.getAttribute('data-siteWidePromoCodeEndpoint') || ''

export const getSiteWidePromoCode =
    () =>
    (dispatch: Dispatch): Promise<void> | void => {
        const payloadEndpoint = getSiteWidePromoCodeEndpoint()
        if (!payloadEndpoint) {
            return
        }

        // eslint-disable-next-line consistent-return
        return httpClient.apiGet(payloadEndpoint, {}, 'AEM_EXP_FRAG').then(data => {
            if (data.data) {
                dispatch(setSiteWidePromoCodeSuccess(data.data))
            }
        })
    }
