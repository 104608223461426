import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { areAllParamsValid, CategoryCard, Heading, isArrayEmpty, isArrayNotEmpty } from '@nl/lib'
import { CertonaInitialization, getImagesFromCertona } from '../../certona/certona.service'
import { CertonaProductType } from '../../certona/certona.type'
import { RootState } from '../../redux/reducers'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'
import { PREFIX } from '../../config'
import { getEnvironment } from '../../environments'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { analyticsInteraction, fetchRecommendationsData } from '../../redux/actions'
import { analyticsAttributes } from '../../globalConstants'
import { RecommendationResponseDataDTO } from '../../redux/models/recommendations.interface'
import { useSelector } from 'react-redux'
import ProductCardsContainer from './ProductCardsContainer/ProductCardsContainer'
import { BoxedRecommendationsProps, TileIdEnum, TilesPCodes } from './BoxedRecommendations.type'
import ProductCardsSkeleton from './ProductCardsContainer/ProductCardsSkeleton'
import { MAX_RECOMMENDATIONS } from './BoxedRecommendations.constant'

const componentClassName = `${PREFIX}-boxed-recommendations`

/**
 * BoxedRecommendations
 * @param {BoxedRecommendationsProps} props - props for BoxedRecommendations component
 * @returns JSX.Element | null
 */
const BoxedRecommendations: React.FC<BoxedRecommendationsProps> = ({ ...props }): JSX.Element | null => {
    const {
        showComponent,
        enableTile1Badges,
        enableTile3Badges,
        tile1ButtonTitle,
        tile1ButtonUrl,
        tile1ButtonAriaLabel,
        tile1BGColour,
        tile1FontColour,
        tile1SchemaId,
        tile2ButtonTitle,
        tile2ButtonUrl,
        tile2ButtonAriaLabel,
        tile2BGColour,
        tile2FontColour,
        tile2SchemaId,
        tile3ButtonTitle,
        tile3ButtonUrl,
        tile3ButtonAriaLabel,
        tile3BGColour,
        tile3FontColour,
        tile3SchemaId,
    } = props
    const config = getEnvironment()
    const [tiles, setTiles] = useState({
        tile1: { title: '', items: [] as CertonaProductType[], pCodes: [] as string[] },
        tile2: { title: '', items: [] as CertonaProductType[], pCodes: null },
        tile3: { title: '', items: [] as CertonaProductType[], pCodes: [] as string[] },
    })
    const [isloading, setIsLoading] = useState<boolean>(true)
    const [pCodes, setPCodes] = useState<TilesPCodes>({ tile1PCodes: [], tile3PCodes: [] })

    const recommendationData = useAppSelector((state: RootState) => state.certona)

    const { recommendationsData: recommendationRootState } = useAppSelector(
        (state: RootState) => state.recommendationsData,
    )
    const { recommendationsData } = useSelector((state: RootState) => state.recommendationsData)

    // flag to make sure the api is called only once
    const runApiOnce = useRef(false)

    const {
        event: { filter },
        eventParameters: {
            action: { shopByCategory },
        },
    } = analyticsAttributes

    const dispatch = useAppDispatch()

    /**
     * Slice pCodes for each tile based on maximum number of recommendations
     */
    const slicePCodes = useCallback((productCodesObj: { [key: string]: string[] }): TilesPCodes => {
        const slicedObj: { [key: string]: string[] } = {}

        Object.keys(productCodesObj).forEach(key => {
            const codes = productCodesObj[key]
            slicedObj[key] = codes.slice(0, MAX_RECOMMENDATIONS)
        })

        return slicedObj as unknown as TilesPCodes
    }, [])

    // get schemas from certona api call
    useEffect(() => {
        const schemes = recommendationData?.resonance?.schemes

        if (isArrayEmpty(schemes)) {
            return
        }

        // extract all data --
        // product title and items - tile 1
        const {
            getTitle: getTile1Title,
            getItems: getTile1Items,
            getPCodes: getTile1PCodes,
        } = CertonaInitialization.extractSchemeDetails(schemes, tile1SchemaId)

        // category title and items - tile 2
        const { getTitle: getTile2Title, getItems: getTile2Items } = CertonaInitialization.extractSchemeDetails(
            schemes,
            tile2SchemaId,
        )

        // product title and items - tile 3
        const {
            getTitle: getTile3Title,
            getItems: getTile3Items,
            getPCodes: getTile3PCodes,
        } = CertonaInitialization.extractSchemeDetails(schemes, tile3SchemaId)

        setTiles({
            tile1: {
                title: getTile1Title,
                items: getTile1Items,
                pCodes: getTile1PCodes,
            },
            tile2: {
                title: getTile2Title,
                items: getTile2Items,
                pCodes: null,
            },
            tile3: {
                title: getTile3Title,
                items: getTile3Items,
                pCodes: getTile3PCodes,
            },
        })

        // handle PCodes after tiles update
        const slicedPCodes = slicePCodes({
            tile1PCodes: getTile1PCodes,
            tile3PCodes: getTile3PCodes,
        })

        setPCodes({
            tile1PCodes: slicedPCodes.tile1PCodes,
            tile3PCodes: slicedPCodes.tile3PCodes,
        })

        // set isloading to false after extracting all data
        setIsLoading(false)
    }, [recommendationData, tile2SchemaId, tile1SchemaId, tile3SchemaId, slicePCodes])

    // get pCodes for each tile
    const { tile1PCodes: tile1SlicedPCodes } = pCodes
    const { tile3PCodes: tile3SlicedPCodes } = pCodes

    // define schema IDs for each tile - schema(s) will be stored in redux
    const reduxSchemaName = 'BoxedRecommendations'
    const schemaIds = useMemo(
        () => [
            { schema: `${reduxSchemaName} - ${tile1SchemaId}`, pCodes: tile1SlicedPCodes },
            { schema: `${reduxSchemaName} - ${tile3SchemaId}`, pCodes: tile3SlicedPCodes },
        ],
        [tile1SchemaId, tile1SlicedPCodes, tile3SchemaId, tile3SlicedPCodes],
    )

    // useEffect to call fetchRecommendationsData when pCodes are available
    useEffect(() => {
        const pCodesArray = Object.values(pCodes)
        // ensures one of tile1 or tile3 product pCodes has at least one item
        const isAtLeastOneNonEmpty = pCodesArray.some((arr: []) => arr.length > 0)
        // ensures the api is called when one of the tiles has items and not more than once
        if (areAllParamsValid(isAtLeastOneNonEmpty, !runApiOnce.current)) {
            schemaIds.forEach(schemaId => {
                dispatch(fetchRecommendationsData(schemaId.pCodes, recommendationsData, schemaId.schema))
            })
            runApiOnce.current = true
        }
    }, [pCodes, dispatch, recommendationsData, schemaIds])

    // useEffect to update tiles state with images if missing
    useEffect(() => {
        const productListTile1 =
            recommendationRootState.find(
                (singleProductData: RecommendationResponseDataDTO) => singleProductData.scheme === schemaIds[0].schema,
            )?.productData || []

        const productListTile3 =
            recommendationRootState.find(
                (singleProductData: RecommendationResponseDataDTO) => singleProductData.scheme === schemaIds[1].schema,
            )?.productData || []

        // add images if missing for tile 1
        productListTile1?.forEach(product => {
            if (!product?.images) {
                product.images = getImagesFromCertona(tiles.tile1.items, product)
            }
        })

        // add images if missing for tile 3
        productListTile3?.forEach(product => {
            if (!product?.images) {
                product.images = getImagesFromCertona(tiles.tile3.items, product)
            }
        })

        // update both tiles keeping previous items intact
        if (isArrayNotEmpty(productListTile1) && isArrayNotEmpty(productListTile3)) {
            setTiles(prev => ({
                ...prev,
                tile1: { ...prev.tile1, items: productListTile1 as CertonaProductType[] },
                tile3: { ...prev.tile3, items: productListTile3 as CertonaProductType[] },
            }))
        }
    }, [recommendationRootState, schemaIds])

    // defines attributes for each tile's header and CTA
    const tileHeader = {
        tile1: {
            headerTitle: tile1ButtonTitle,
            headerCTAUrl: tile1ButtonUrl,
            headerCTAAriaLabel: tile1ButtonAriaLabel,
            headerTextAndCTAColor: tile1FontColour,
        },
        tile2: {
            headerTitle: tile2ButtonTitle,
            headerCTAUrl: tile2ButtonUrl,
            headerCTAAriaLabel: tile2ButtonAriaLabel,
            headerTextAndCTAColor: tile2FontColour,
        },
        tile3: {
            headerTitle: tile3ButtonTitle,
            headerCTAUrl: tile3ButtonUrl,
            headerCTAAriaLabel: tile3ButtonAriaLabel,
            headerTextAndCTAColor: tile3FontColour,
        },
    }

    /**
     * Renders title component
     * @param {TileIdEnum} tileId - tile ID
     * @param {string} className - class name for styling
     * @returns {JSX.Element | null} title and title CTA component
     */
    const renderHeaderTitleAndCTA = (tileId: TileIdEnum, className: string): JSX.Element | null => {
        const showTitle = !!tiles[tileId].title && isArrayNotEmpty(tiles[tileId].items)
        const showCTA = !!tileHeader[tileId].headerCTAUrl && isArrayNotEmpty(tiles[tileId].items)

        return (
            <div
                style={{ color: `${tileHeader[tileId].headerTextAndCTAColor}` }}
                className={`${PREFIX}-xs-flex ${PREFIX}-xs-justify-space-between`}>
                {showTitle && (
                    <Heading variant={'h3'} componentClass={`${className}__title`}>
                        {tiles[tileId].title}
                    </Heading>
                )}
                {showCTA && (
                    <span className={`${className}__titleCTA`}>
                        <a href={tileHeader[tileId].headerCTAUrl} aria-label={tileHeader[tileId].headerCTAAriaLabel}>
                            {tileHeader[tileId].headerTitle}
                        </a>
                    </span>
                )}
            </div>
        )
    }

    // dispatches analytics event when a category card is clicked
    const cardClicked = (label: string | undefined): void => {
        dispatch(analyticsInteraction(label as string, '', filter, shopByCategory))
    }

    /**
     * Renders category cards using certona's response
     * @returns {JSX.Element[] | false} category items
     */
    const renderCategoryItems = (): JSX.Element[] | false => {
        const categoryItems = tiles?.tile2?.items || []

        return (
            categoryItems.length > 0 &&
            categoryItems
                .slice(0, MAX_RECOMMENDATIONS)
                .map(({ url, label, image, categoryid }: CertonaProductType, index: number) => (
                    <CategoryCard
                        href={url as string}
                        key={`${String(label)}_${index}`}
                        imagePolicies={AkamaiImagePolicies.initIndPolicy}
                        cardClicked={cardClicked}
                        linkClass={`${PREFIX}-category-product__link`}
                        imageClass={`${PREFIX}-category-product__image`}
                        labelClass={`${PREFIX}-category-product__label`}
                        image={image ? image : config.defaultProductImage}
                        label={label as string}
                        categoryId={categoryid as string}
                        imageDataComponentName="boxed-recommendations-category-list"
                    />
                ))
        )
    }

    /**
     * Renders product cards container
     * @param {string} schemaId - The schema ID
     * @param {string} bgColor - The background color
     * @param {TileIdEnum} title - The title
     * @param {boolean} enableBadges - Flag to enable badges
     * @returns {JSX.Element} - The product cards container component
     */
    const renderProductCardsContainer = (
        schemaId: string,
        bgColor: string,
        title: TileIdEnum,
        enableBadges: boolean,
    ): JSX.Element => {
        return (
            <ProductCardsContainer
                schemaId={schemaId}
                backgroundColor={bgColor}
                headerTitleAndCTA={renderHeaderTitleAndCTA(title, `${PREFIX}-product-cards`)}
                enableBadges={enableBadges}
            />
        )
    }

    // return null if showComponent is false
    if (!showComponent) {
        return null
    }

    return (
        showComponent && (
            <div className={`${componentClassName}`}>
                {isloading ? (
                    <ProductCardsSkeleton productCardCount={MAX_RECOMMENDATIONS} tilesCount={Object.keys(tiles)} />
                ) : (
                    <>
                        {renderProductCardsContainer(
                            schemaIds[0].schema,
                            tile1BGColour,
                            TileIdEnum.tile1,
                            enableTile1Badges,
                        )}
                        <div
                            style={{
                                backgroundColor: `${tiles?.tile2?.items?.length > 0 ? tile2BGColour : 'transparent'}`,
                                display: `${tiles?.tile2?.items?.length > 0 ? 'block' : 'none'}`,
                            }}
                            className={`${PREFIX}-category-cards__wrapper`}>
                            <div className={`${PREFIX}-category-cards__header`}>
                                {renderHeaderTitleAndCTA(TileIdEnum.tile2, `${PREFIX}-category-cards`)}
                            </div>
                            <div className={`${PREFIX}-category-cards__items`}>{renderCategoryItems()}</div>
                        </div>
                        {renderProductCardsContainer(
                            schemaIds[1].schema,
                            tile3BGColour,
                            TileIdEnum.tile3,
                            enableTile3Badges,
                        )}
                    </>
                )}
            </div>
        )
    )
}

export default BoxedRecommendations
