import { PREFIX } from '../../config'

export enum toastTypeConst {
    postalCode = 'postalCode',
    pickupInformation = 'pickupInformation',
    general = 'general',
    successFees = 'successFees',
    changeShippingFee = 'changeShippingFee',
    CTMoneyError = 'CTMoneyError',
    CTMoneyNumericalError = 'CTMoneyNumericalError',
    CTMoneySuccess = 'CTMoneySuccess',
    deliveryModeChanged = 'deliveryModeChanged',
    contact = 'contactError',
    ToggleOffByUser = 'toggleOffByUser',
    shippingFailure = 'shippingFailure',
    deliveryToPOBoxesFailure = 'deliveryToPOBoxesFailure',
    shippingFeeApplied = 'shippingFeeApplied',
    giftCardErrors = 'giftCardErrors',
    paymentDetailsError = 'paymentDetailsError',
}

export enum drawerFillStatus {
    contactInfoDrawer = 'contact',
    shippingInfoDrawer = 'shipping',
    pickupInfoDrawer = 'pickup',
    paymentInfoDrawer = 'payment',
}
export const trueString = 'true'
export const inactiveClassName = 'inactive-bar'
export const gcBarClassName = 'giftcard-bar'
export const rewardsBarCardClassName = 'rewards-card'
export const rewardsCardClassName = `${PREFIX}-reward-card`
export const gcBarClassNameInactive = `${gcBarClassName} ${PREFIX}-${gcBarClassName}--inactive`
export const PAYMENT_INFO_CARD_CLASS = 'payment-info-card'
export const PAYMENT_INFO_ID = 'payment-information'
export const PAYMENT_INFO_CLASS = `${PREFIX}-payment-information`
export const GIFTCARD_CLASS = 'gift-card'
export const THIRD_PARTY_PAYMENT_CLASS = `${PREFIX}-third-party-payment`
export const THIRD_PARTY_PAYMENT_CONTENT_CLASS = `${THIRD_PARTY_PAYMENT_CLASS}__content`
export const THIRD_PARTY_PAYMENT_CONTAINER_ID = 'third-party-payment-container'

export const successStatus = 200
export const checkoutContactUrl = 'updateContactCheckout'
export const checkoutDeliveryUrl = 'updateDeliveryCheckout'
export const checkoutPickupUrl = 'updatePickupCheckout'
export const checkoutPaymentUrl = 'updatePaymentCheckout'
export const PlaceOrderErrorCode = {
    hystrixError: 'error00271',
    inSufficientInventoryError: 'error03054',
    cartEmptyError: 'error03037',
    cardMessagePathError: 'error03000',
}
export const inflationAppointmentErrorCode = '03100'
export const inflationAppointmentPickupTogetherErrorCode = '03200'
export enum pickupAllItemsTogetherEligibleType {
    ELIGIBLE = 'ELIGIBLE',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export const RedeemCTMoneyURL = 'redeemCTMoney'

export const NO_ERROR = 'No Error'
export const ProfileURL = 'profile'
export const PaymentDetailsURL = 'paymentDetails'

// attribute name to access the old car banner id from AEM for Gigya
export const gigyaBannerId = 'gigyaBannerId'
export const isCheckoutSignInRedirect = 'isCheckoutSignInRedirect'
export const returnURL = 'returnURL'
export const PaymentDetailsErrorCodes = {
    paymentDetailsGenericError: 'paymentDetailsGenericError',
    paymentDetailsHybrisError: '00106',
    paymentDetailsActHolderNameError: '00260',
    paymentDetailsBillingAdrsError: '00263',
}
export const defaultInitPaymentVersion = 2
