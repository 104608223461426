import React from 'react'
import { isArrayNotEmpty } from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import { checkDataLength } from '../components/Accounts/Addresses/checkDataLength'
import {
    AutoPackages,
    PackageLandingInitialState,
    TireWheelDetails,
} from '../components/AutomotivePackage/PackageLanding/PackageLanding.type'
import { getEnvironment } from '../environments'
import {
    updateAutoPackages,
    updateCurrentPackageId,
    updateUserInitializedPackageFlow,
    analyticsInteraction,
    updateSessionStorageData,
    createAutoPackage,
} from '../redux/actions'
import { AutoPackageKeys, analyticsAttributes, productWheelTypes, specifictionsCode } from '../globalConstants'
import { CartItemsData, CartModificationsDTO, CartOrderEntries } from '../redux/models/cart.interface'
import store from '../store'
import sessionStorageService from '../utils/sessionStorageService'
import { isTirePDP, isWheelPDP } from '../components/Vehicles/Vehicle.helper'
import { PackageFlowData } from './packageData.type'
import { prepareAutoPackageDataList } from '../components/AutomotivePackage/PackageLanding/PackageLanding.helper'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { setShowSpinner } from '../redux/slices/spinner.slice'

const config = getEnvironment()

/**
 * We are passing user selected value and preparing array for autoPackage array.
 * @param {string} updatedKey TIRE/WHEEL
 * @param {string} type TIRE/WHEEL
 * @param {AutoPackages} autoPackages auto packages
 * @param {boolean} isPackageId is package id
 * @returns {AutoPackages[]} auto packages
 */
export const prepareInitialAutoPackageData = (
    updatedKey: string,
    type: TireWheelDetails | string | boolean | undefined,
    autoPackages: AutoPackages[],
    isPackageId?: boolean,
): AutoPackages[] => {
    return autoPackages.reduce((initialValue: AutoPackages[], currentValue: AutoPackages, index) => {
        if (autoPackages.length - MagicNumber.ONE === index) {
            if (isPackageId) return [...initialValue, { ...currentValue, [updatedKey]: type }]
            return [
                ...initialValue,
                { ...currentValue, vehicleInfo: { ...currentValue.vehicleInfo, [updatedKey]: type } },
            ]
        }
        return [...initialValue, currentValue]
    }, [])
}

/**
 * Function to create the Package flow
 * @param {boolean} isPackageFlow is package flow
 * @param {CartOrderEntries} selectedCardData selected card data
 * @param {string} selectedPreferredStoreId selected preferred store id
 * @param {string} productWheelType product wheel type
 * @param {AutoPackages} autoPackages auto packages
 * @param {string} cartGUID cart GUID
 * @param {Dispatch} dispatch dispatch
 * @param {string} redirectURL redirect URL
 * @returns {void} void
 */
export const createPackage = (
    isPackageFlow: boolean,
    selectedCardData: CartOrderEntries,
    selectedPreferredStoreId: string,
    productWheelType: string | undefined,
    autoPackages: AutoPackages[],
    cartGUID: string,
    dispatch: React.Dispatch<(dispatch: Dispatch) => Promise<void>>,
    redirectURL: string,
    // eslint-disable-next-line max-params
): void => {
    const packages = (sessionStorageService.getItem('packageFlow') &&
        JSON.parse(sessionStorageService.getItem('packageFlow') as string)) as PackageLandingInitialState
    const currentPackage = packages?.autoPackages?.find(item => item.packageId === packages.currentPackageId)

    if (isPackageFlow && currentPackage) {
        window.location.href = encodeURI(redirectURL)
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (selectedCardData && selectedPreferredStoreId) {
        dispatch(setShowSpinner({ show: true }) as unknown as (dispatch: Dispatch) => Promise<void>)
        const isTirePLP = isTirePDP(productWheelType)
        const isWheelPLP = isWheelPDP(productWheelType)
        let tireORWheel = ''
        if (isTirePLP) {
            tireORWheel = productWheelTypes.Tire
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (isWheelPLP) {
            tireORWheel = productWheelTypes.Wheel
        }
        const initialAutoPackageData = prepareAutoPackageDataList(tireORWheel, autoPackages)
        const packageDataObj = {
            autoPackages: initialAutoPackageData,
            isPackageFlow: true,
        }
        sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.packageFlow, JSON.stringify(packageDataObj))
        dispatch(updateUserInitializedPackageFlow(true) as unknown as (dispatch: Dispatch) => Promise<void>)
        dispatch(
            createAutoPackage(
                cartGUID,
                redirectURL,
                initialAutoPackageData,
                selectedPreferredStoreId,
                selectedCardData.entryNumber || selectedCardData.entryNumber === MagicNumber.ZERO
                    ? [selectedCardData.entryNumber]
                    : [],
                selectedCardData,
            ),
        )
    }
}

/**
 * function to get tire specifications
 * @param { CartOrderEntries[] } productEntry product entry
 * @returns {string} tire specification
 */
const getTireSpecification = (productEntry?: CartOrderEntries): string => {
    let width = ''
    let aspectRatio = ''
    let diameter = ''
    productEntry?.specifications?.forEach(item => {
        if (item.code.toLowerCase() === specifictionsCode.tireWidth.toLowerCase()) {
            width = item.value
        } else if (item.code.toLowerCase() === specifictionsCode.tireAspectRatio.toLowerCase()) {
            aspectRatio = item.value
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (item.code.toLowerCase() === specifictionsCode.tireDiameter.toLowerCase()) {
            diameter = item.value
        }
    })
    return width && aspectRatio && diameter ? `${width.split(' ')[0]}/${aspectRatio}R${diameter.split(' ')[0]}` : ''
}

/**
 * function to get wheel specifications
 * @param { CartOrderEntries[] } productEntry product entry
 * @returns {string} wheel specification
 */
const getwheelSpecification = (productEntry?: CartOrderEntries): string => {
    let width = ''
    let diameter = ''
    productEntry?.specifications?.forEach(item => {
        if (item.code.toLowerCase() === specifictionsCode.wheelWidth.toLowerCase()) {
            width = item.value
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (item.code.toLowerCase() === specifictionsCode.wheeDiameter.toLowerCase()) {
            diameter = item.value
        }
    })
    return width && diameter ? `${width.split(' ')[0]}x${diameter.split(' ')[0]}` : ''
}

/**
 * This function is used to generate tire or wheel data with existing autoPackage array and also add the latest array into session storage
 * @param {string} key key
 * @param {CartModificationsDTO} cartModification cart modification
 * @param {AutoPackages} autoPackages auto packages
 * @param {boolean} isCreatePackage is create package
 * @returns {AutoPackages} auto packages
 */
export const updateSessionDataForPackageFlow = (
    key: string,
    cartModification: CartModificationsDTO,
    autoPackages: AutoPackages[],
    isCreatePackage?: boolean,
): AutoPackages[] => {
    const packageData = JSON.parse(
        sessionStorageService.getItem(config.SESSION_STORAGE_KEYS.packageFlow) as string,
    ) as PackageFlowData
    const presentPackage = packageData?.autoPackages?.find(
        (packageItem: AutoPackages) => packageItem.packageId === packageData?.currentPackageId,
    )
    const modifiedCartEntry = cartModification?.entry
    const productData: TireWheelDetails = {
        brand: modifiedCartEntry?.brand?.label,
        desc: modifiedCartEntry?.name,
        price: modifiedCartEntry?.totalPrice?.value as number,
        qty: modifiedCartEntry?.quantity,
        imageUrl: modifiedCartEntry?.images?.[MagicNumber.ZERO]?.url,
        imageAltText: modifiedCartEntry?.images?.[MagicNumber.ZERO]?.altText,
        tireSpecifications: getTireSpecification(modifiedCartEntry),
        wheelSpecifications: getwheelSpecification(modifiedCartEntry),
    }
    let initialPackage = prepareInitialAutoPackageData(key, productData, autoPackages)
    initialPackage = prepareInitialAutoPackageData('packageId', cartModification.entry.packageId, initialPackage, true)
    AutoPackageKeys.changeSelectedLink &&
        (initialPackage = prepareInitialAutoPackageData(
            AutoPackageKeys.changeSelectedLink,
            false,
            initialPackage,
            true,
        ))
    AutoPackageKeys.cartFlyoutChangeSelectedLink &&
        (initialPackage = prepareInitialAutoPackageData(
            AutoPackageKeys.cartFlyoutChangeSelectedLink,
            presentPackage ? !!presentPackage.cartFlyoutChangeSelectedLink : false,
            initialPackage,
            true,
        ))
    if (isCreatePackage) {
        const currentPackage = initialPackage.find(
            (packageItem: AutoPackages) => packageItem.packageId === cartModification.entry.packageId,
        )
        const wheelType =
            currentPackage?.currentSelectedContainer === productWheelTypes.Wheel
                ? productWheelTypes.Tire
                : productWheelTypes.Wheel
        initialPackage = prepareInitialAutoPackageData('nextSelectedContainer', wheelType, initialPackage, true)
        initialPackage = prepareInitialAutoPackageData('cartFlyoutChangeSelectedLink', wheelType, initialPackage, true)
    }
    packageData.currentPackageId = cartModification.entry.packageId as string
    packageData.isPackageFlow = true
    sessionStorageService.setItem(
        config.SESSION_STORAGE_KEYS.packageFlow,
        JSON.stringify({
            ...packageData,
            autoPackages: initialPackage,
        }),
    )
    return initialPackage
}

/**
 * This function is used to create packageFlow for Tire related products
 * @param {CartOrderEntries[]} entry entry
 * @returns {AutoPackages} auto packages
 */
export const prepareTirePackageFlow = (entry: CartOrderEntries[]): AutoPackages => {
    const firstCartItemData = entry[MagicNumber.ZERO]
    const imageUrl = isArrayNotEmpty(firstCartItemData?.images) && firstCartItemData.images[MagicNumber.ZERO].url
    return {
        currentSelectedContainer: firstCartItemData.productWheelType?.toUpperCase(),
        packageId: firstCartItemData.packageId,
        vehicleInfo: {
            tireData: {
                brand: firstCartItemData.brand.label,
                desc: firstCartItemData.name,
                price: firstCartItemData.currentPrice.value || undefined,
                imageUrl: imageUrl as string,
                qty: firstCartItemData.quantity,
                tireSpecifications: getTireSpecification(firstCartItemData),
            },
        },
    }
}

/**
 * This function is used to create packageFlow for Wheel related products
 * @param {CartOrderEntries[]} entry entry
 * @returns {AutoPackages} auto packages
 */
export const prepareWheelPackageFlow = (entry: CartOrderEntries[]): AutoPackages => {
    const firstCartItemData = entry[MagicNumber.ZERO]
    const imageUrl = isArrayNotEmpty(firstCartItemData?.images) && firstCartItemData.images[MagicNumber.ZERO].url
    return {
        currentSelectedContainer: firstCartItemData.productWheelType?.toUpperCase(),
        packageId: firstCartItemData.packageId,
        vehicleInfo: {
            wheelData: {
                brand: firstCartItemData.brand.label,
                desc: firstCartItemData.name,
                price: firstCartItemData.currentPrice.value || undefined,
                imageUrl: imageUrl as string,
                qty: firstCartItemData.quantity,
                wheelSpecifications: getwheelSpecification(firstCartItemData),
            },
        },
    }
}

/**
 * This function is used to create package flow for Tire and Wheel product for same packageId
 * @param {number} tireIndex tire index
 * @param {number} wheelIndex wheel index
 * @param {CartOrderEntries[]} entry entry
 * @returns {AutoPackages} auto packages
 */
export const preparePackageFlow = (tireIndex: number, wheelIndex: number, entry: CartOrderEntries[]): AutoPackages => {
    const tireIndexCartItem = entry[tireIndex]
    const wheelIndexCartItem = entry[wheelIndex]
    const tireImageUrl = isArrayNotEmpty(tireIndexCartItem?.images) && tireIndexCartItem.images[MagicNumber.ZERO].url
    const wheelImageUrl = isArrayNotEmpty(wheelIndexCartItem?.images) && wheelIndexCartItem.images[MagicNumber.ZERO].url
    return {
        currentSelectedContainer:
            tireIndexCartItem.productWheelType?.toUpperCase() === productWheelTypes.WinterTire
                ? productWheelTypes.Tire
                : tireIndexCartItem.productWheelType?.toUpperCase(),
        nextSelectedContainer:
            entry[wheelIndex].productWheelType?.toUpperCase() === productWheelTypes.WinterTire
                ? productWheelTypes.Tire
                : entry[wheelIndex].productWheelType?.toUpperCase(),
        packageId: tireIndexCartItem.packageId,
        vehicleInfo: {
            tireData: {
                brand: tireIndexCartItem.brand.label,
                desc: tireIndexCartItem.name,
                price: tireIndexCartItem.currentPrice.value || undefined,
                imageUrl: tireImageUrl as string,
                qty: tireIndexCartItem.quantity,
                tireSpecifications: getTireSpecification(tireIndexCartItem),
            },
            wheelData: {
                brand: wheelIndexCartItem.brand.label,
                desc: wheelIndexCartItem.name,
                price: wheelIndexCartItem.currentPrice.value || undefined,
                imageUrl: wheelImageUrl as string,
                qty: wheelIndexCartItem.quantity,
                wheelSpecifications: getwheelSpecification(wheelIndexCartItem),
            },
        },
    }
}

/**
 * This function is used to group the product based on the packageId
 * @param {Map<string, CartOrderEntries[]>} groupAutoPackages group auto packages
 * @param {CartOrderEntries} productData product data
 * @returns {Map<string, CartOrderEntries[]>} group auto packages
 */
export const groupPackagesBasedOnPackageId = (
    groupAutoPackages: Map<string, CartOrderEntries[]>,
    productData: CartOrderEntries,
): Map<string, CartOrderEntries[]> => {
    const packageId = productData.packageId as string
    let entries = groupAutoPackages.get(packageId)

    if (!entries) {
        entries = []
        groupAutoPackages.set(packageId, entries)
    }

    entries.push(productData)
    return groupAutoPackages
}
/**
 * function to check If PackageId Not Exists In CartResponse
 * @param {PackageLandingInitialState} packageData package data
 * @param {CartOrderEntries[]} autoPackages auto packages
 * @returns {boolean} boolean value to check if package id exists in cart response
 */
const checkIfPackageIdNotExistsInCartResponse = (
    packageData: PackageLandingInitialState,
    autoPackages?: CartOrderEntries[],
): boolean =>
    !!(
        autoPackages?.findIndex((product: CartOrderEntries) => product.packageId === packageData.currentPackageId) ===
            MagicNumber.MINUS_ONE && packageData.currentPackageId
    )
/**
 * Function to update the session storage if package ID is not present in cart response.
 * @param {number} autoPackageIndex - Auto package index.
 * @param {PackageLandingInitialState} packageData - Package data.
 * @param {number} itemsToRemove - Items to remove.
 * @param {boolean} isPackageFlow - Indicates if it's a package flow.
 * @returns {void} - Returns void.
 */
export const updatePackageData = (
    autoPackageIndex: number,
    packageData: PackageLandingInitialState,
    itemsToRemove = MagicNumber.ONE,
    isPackageFlow = false,
): void => {
    if (autoPackageIndex !== MagicNumber.MINUS_ONE) {
        packageData.autoPackages.splice(autoPackageIndex, itemsToRemove)
        packageData.currentPackageId = ''
        packageData.isPackageFlow = !!isPackageFlow
        sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.packageFlow, JSON.stringify(packageData))
        store.dispatch(updateUserInitializedPackageFlow(packageData.isPackageFlow) as unknown as AnyAction)
        store.dispatch(updateAutoPackages(packageData.autoPackages) as unknown as AnyAction)
        store.dispatch(updateCurrentPackageId('') as unknown as AnyAction)
    }
}

/**
 * Function to check if all items removed and not in package flow
 * @param { CartOrderEntries[] } autoPackages auto packages
 * @param { PackageLandingInitialState } packageData package data
 * @returns {boolean} boolean value to check if all items removed from cart
 */
const checkIfAllItemsRemovedFromCart = (
    autoPackages?: CartOrderEntries[],
    packageData?: PackageLandingInitialState,
): boolean => {
    return !!(!isArrayNotEmpty(autoPackages) && isArrayNotEmpty(packageData?.autoPackages))
}

/**
 * function to update session storage on page load
 * @param {any} packageData package data
 * @param {CartItemsData} cartData cart data
 * @param {CartOrderEntries[]} autoPackages auto packages
 * @returns {void} void
 */
// eslint-disable-next-line complexity
const updatePackageDataOnPageLoad = (
    packageData: PackageLandingInitialState,
    cartData: CartItemsData,
    autoPackages?: CartOrderEntries[],
    // eslint-disable-next-line sonar/cyclomatic-complexity
): void => {
    if (checkIfPackageIdNotExistsInCartResponse(packageData, autoPackages)) {
        const autoPakageIndex = packageData.autoPackages.findIndex(
            (autoPackage: AutoPackages) => autoPackage.packageId === packageData.currentPackageId,
        )
        updatePackageData(autoPakageIndex, packageData)
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (!checkIfPackageIdNotExistsInCartResponse(packageData, autoPackages) && packageData.isPackageFlow) {
        const currentPackage = packageData?.autoPackages?.find(
            (autoPackage: AutoPackages) => autoPackage.packageId === packageData?.currentPackageId,
        )
        const currentOrderEntry = autoPackages?.find(
            (cartItem: CartOrderEntries) => cartItem.packageId === packageData.currentPackageId,
        )
        const modifiedData = {
            entry: currentOrderEntry,
            errorCode: currentOrderEntry,
            errorMessage: cartData?.errorMessage,
            quantityAdded: currentOrderEntry?.quantity,
        }
        const currentCartQuantity =
            modifiedData?.entry?.productWheelType === productWheelTypes.Wheel
                ? currentPackage?.vehicleInfo?.wheelData?.qty
                : currentPackage?.vehicleInfo?.tireData?.qty
        const groupAutoPackages: Record<string, CartOrderEntries[]> = {}
        autoPackages?.forEach(product => {
            const packageId = product.packageId
            if (packageId) {
                groupAutoPackages[packageId] = groupAutoPackages[packageId] || []
                groupAutoPackages[packageId].push(product)
            }
        })
        if (
            currentPackage &&
            Object.keys(groupAutoPackages).find(item => item === packageData.currentPackageId) &&
            groupAutoPackages[packageData.currentPackageId as string].length === MagicNumber.ONE
        ) {
            if (
                groupAutoPackages[packageData.currentPackageId as string][0].productWheelType ===
                    productWheelTypes.Wheel &&
                currentPackage?.vehicleInfo?.tireData
            ) {
                delete currentPackage?.vehicleInfo?.tireData
                currentPackage.currentSelectedContainer = productWheelTypes.Wheel
                currentPackage.nextSelectedContainer = productWheelTypes.Tire
                sessionStorageService.setItem(
                    config.SESSION_STORAGE_KEYS.packageFlow,
                    JSON.stringify({
                        ...packageData,
                        autoPackages: packageData.autoPackages,
                    }),
                )
                store.dispatch(updateAutoPackages(packageData.autoPackages) as unknown as AnyAction)
                store.dispatch(updateUserInitializedPackageFlow(true) as unknown as AnyAction)
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (
                (groupAutoPackages[packageData.currentPackageId as string][MagicNumber.ZERO].productWheelType ===
                    productWheelTypes.Tire ||
                    groupAutoPackages[packageData.currentPackageId as string][MagicNumber.ZERO].productWheelType ===
                        productWheelTypes.WinterTire) &&
                currentPackage?.vehicleInfo?.wheelData
            ) {
                delete currentPackage?.vehicleInfo?.wheelData
                sessionStorageService.setItem(
                    config.SESSION_STORAGE_KEYS.packageFlow,
                    JSON.stringify({
                        ...packageData,
                        autoPackages: packageData.autoPackages,
                    }),
                )
                store.dispatch(updateAutoPackages(packageData.autoPackages) as unknown as AnyAction)
                store.dispatch(updateUserInitializedPackageFlow(true) as unknown as AnyAction)
            }
        }
        if (modifiedData?.entry?.quantity !== currentCartQuantity) {
            store.dispatch(
                updateSessionStorageData(
                    modifiedData?.entry?.productWheelType,
                    modifiedData as unknown as CartModificationsDTO,
                    packageData?.autoPackages,
                    false,
                ) as unknown as AnyAction,
            )
        }
    }
}
/**
 * This function is used to generate the packageFlow data and store it in redux and session storage
 * @param {CartItemsData} cartData cart data
 * @returns {void} void
 */
export const filterAutoPackageProductOnload = (cartData: CartItemsData): void => {
    const packages = sessionStorageService.getItem(config.SESSION_STORAGE_KEYS.packageFlow)
    const packageData = (
        packages !== null ? (JSON.parse(packages) as PackageLandingInitialState) : packages
    ) as PackageLandingInitialState
    const packageFlow = {
        autoPackages: [],
    } as unknown as PackageFlowData
    // eslint-disable-next-line sonar/function-return-type
    const autoPackages = cartData.orderEntries?.filter(entry => {
        return entry.packageId && entry.isPackagedItem
    })
    if (checkDataLength(cartData.orderEntries) && !isArrayNotEmpty(packageData?.autoPackages)) {
        if (checkDataLength(autoPackages)) {
            let groupAutoPackages = new Map<string, CartOrderEntries[]>()
            autoPackages.forEach(product => {
                groupAutoPackages = groupPackagesBasedOnPackageId(groupAutoPackages, product)
            })
            Array.from(groupAutoPackages.values()).forEach(entry => {
                if (entry.length > MagicNumber.ONE) {
                    if (
                        entry[MagicNumber.ZERO].productWheelType?.toUpperCase() === productWheelTypes.Tire ||
                        entry[MagicNumber.ZERO].productWheelType?.toUpperCase() === productWheelTypes.WinterTire
                    ) {
                        const autoPackage = preparePackageFlow(MagicNumber.ZERO, MagicNumber.ONE, entry)
                        packageFlow.autoPackages.push(autoPackage)
                        // eslint-disable-next-line sonarjs/elseif-without-else
                    } else if (entry[MagicNumber.ZERO].productWheelType?.toUpperCase() === productWheelTypes.Wheel) {
                        const autoPackage = preparePackageFlow(MagicNumber.ONE, MagicNumber.ZERO, entry)
                        packageFlow.autoPackages.push(autoPackage)
                    }
                } else {
                    if (
                        entry[MagicNumber.ZERO].productWheelType?.toUpperCase() === productWheelTypes.Tire ||
                        entry[MagicNumber.ZERO].productWheelType?.toUpperCase() === productWheelTypes.WinterTire
                    ) {
                        const autoPackage = prepareTirePackageFlow(entry)
                        packageFlow.autoPackages.push(autoPackage)
                        // eslint-disable-next-line sonarjs/elseif-without-else
                    } else if (entry[MagicNumber.ZERO].productWheelType?.toUpperCase() === productWheelTypes.Wheel) {
                        const autoPackage = prepareWheelPackageFlow(entry)
                        packageFlow.autoPackages.push(autoPackage)
                    }
                }
                packageFlow.currentPackageId = ''
            })
            store.dispatch(updateUserInitializedPackageFlow(packageFlow.isPackageFlow) as unknown as AnyAction)
            store.dispatch(updateAutoPackages(packageFlow.autoPackages) as unknown as AnyAction)
            store.dispatch(updateCurrentPackageId(packageFlow.currentPackageId || '') as unknown as AnyAction)
            sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.packageFlow, JSON.stringify(packageFlow))
        } else {
            sessionStorageService.removeItem(config.SESSION_STORAGE_KEYS.packageFlow)
        }
    } else if (isArrayNotEmpty(autoPackages)) {
        updatePackageDataOnPageLoad(packageData, cartData, autoPackages)
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (checkIfAllItemsRemovedFromCart(autoPackages, packageData)) {
        const itemsToRemoveFromSessions = packageData?.autoPackages?.filter((autoPackageItem: AutoPackages) =>
            checkDataLength(autoPackageItem.vehicleInfo),
        )
        updatePackageData(
            MagicNumber.ZERO,
            packageData,
            itemsToRemoveFromSessions.length,
            itemsToRemoveFromSessions.length !== packageData?.autoPackages.length,
        )
    }
}

/**
 * function to update package flow session storage value
 * @param { AutoPackages[] } autoPackageValue auto package value
 * @param { boolean } packageFlow package flow
 * @param { string } packageIdValue package id value
 * @returns {void} void
 */
const updatePackageFlowState = (
    autoPackageValue: AutoPackages[],
    packageFlow: boolean,
    packageIdValue: string,
): void => {
    const packageData = {
        autoPackages: autoPackageValue,
        isPackageFlow: packageFlow,
        currentPackageId: packageIdValue,
    }
    sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.packageFlow, JSON.stringify(packageData))
}

const {
    event: { vehicleInteraction },
    eventParameters: {
        action: { packageRestarted },
        category: { vehicleType },
    },
} = analyticsAttributes

/**
 * function to add nextSelectedContainer
 * @param {AutoPackages[]} initialAutoPackageData initial auto package data
 * @param {string} currentPackageId current package id
 * @returns {AutoPackages[]} auto packages
 */
const updatedAutoPackageValue = (initialAutoPackageData: AutoPackages[], currentPackageId: string): AutoPackages[] => {
    const updatedAutoPackageList: AutoPackages[] = []
    initialAutoPackageData.forEach((currentValue: AutoPackages) => {
        if (currentValue.packageId === currentPackageId) {
            const nextContainer = {
                ...currentValue,
                nextSelectedContainer:
                    currentValue.currentSelectedContainer === productWheelTypes.Wheel
                        ? productWheelTypes.Tire
                        : productWheelTypes.Wheel,
            }
            updatedAutoPackageList.push(nextContainer)
        } else updatedAutoPackageList.push(currentValue)
    })
    return updatedAutoPackageList
}

/**
 * This function will update package flow state and redirect to the PLP for the package completion
 * @param {string} currentPackageId package id that user wants to finish
 * @param {AutoPackages[]} autoPackages auto packages
 * @param {string} continuePackagePath tires or wheels PLP path
 * @returns {void} void
 */
export const completePackageAndRedirect = (
    currentPackageId: string,
    autoPackages: AutoPackages[],
    continuePackagePath: string,
): void => {
    updatePackageFlowState(updatedAutoPackageValue(autoPackages, currentPackageId), true, currentPackageId)

    const packageData = {
        autoPackages: updatedAutoPackageValue(autoPackages, currentPackageId),
        isPackageFlow: true,
        currentPackageId: currentPackageId,
    }
    sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.packageFlow, JSON.stringify(packageData))

    window.location.href = encodeURI(continuePackagePath)
}

/**
 * Sends complete package analytics
 * @param {string} productWheelType product wheel/tire type that is already in package, expecting input value as Tire / Wheel
 * @param {string} unfinishedPackageLocation page and component that produces event
 * @returns {void} void
 */
export const sendCompletePackageAnalytics = (productWheelType: string, unfinishedPackageLocation: string): void => {
    store.dispatch(
        analyticsInteraction(
            productWheelType?.toLowerCase(),
            unfinishedPackageLocation,
            vehicleInteraction,
            packageRestarted,
            vehicleType,
            unfinishedPackageLocation,
            'undefined',
        ) as unknown as AnyAction,
    )
}
/**
 * function to check if wheel or tire already in cart
 * @param {AutoPackages} currentAutoPackage current auto package
 * @returns {boolean} boolean value to check if wheel or tire already in cart
 */
const checkIfWheelorTireAddedtoPkg = (currentAutoPackage?: AutoPackages): boolean => {
    const temp =
        // eslint-disable-next-line sonar/expression-complexity
        (((!checkDataLength(currentAutoPackage?.vehicleInfo?.tireData) &&
            checkDataLength(currentAutoPackage?.vehicleInfo?.wheelData)) ||
            (!checkDataLength(currentAutoPackage?.vehicleInfo?.wheelData) &&
                checkDataLength(currentAutoPackage?.vehicleInfo?.tireData))) &&
            !currentAutoPackage?.changeSelectedLink) ||
        (checkDataLength(currentAutoPackage?.vehicleInfo?.tireData) &&
            checkDataLength(currentAutoPackage?.vehicleInfo?.wheelData) &&
            currentAutoPackage?.cartFlyoutChangeSelectedLink)
    return !!temp
}

/**
 * Returns tire plp path if selected unfinished package contains wheel and vice-versa
 * @param {string} productWheelType product wheel/tire type that is already in package, expecting input value as Tire / Wheel
 * @param {string} packagesWheelsPath wheels PLP path
 * @param {string} packagesTiresPath tires PLP path
 * @returns {string} PLP path
 */
export const selectCompletePackageLink = (
    productWheelType: string,
    packagesWheelsPath: string,
    packagesTiresPath: string,
): string => {
    return isTirePDP(productWheelType) ? packagesWheelsPath : packagesTiresPath
}

/**
 * function to get wheel or tire size
 * @param {string} currentPackageId current package id
 * @param {string} autoPackages auto packages
 * @param {string} isPackageFlow is package flow
 * @returns {string} tire or wheel size
 */
export const getTireOrWheelSize = (
    currentPackageId?: string,
    autoPackages?: AutoPackages[],
    isPackageFlow?: boolean,
): string => {
    let tireOrWheelSize = ''
    if (isPackageFlow && checkDataLength(autoPackages) && currentPackageId) {
        const currentAutoPackage = autoPackages?.find(
            (packageObj: AutoPackages) => packageObj.packageId === currentPackageId,
        )
        if (checkIfWheelorTireAddedtoPkg(currentAutoPackage)) {
            tireOrWheelSize =
                currentAutoPackage?.vehicleInfo?.tireData?.tireSpecifications ||
                currentAutoPackage?.vehicleInfo?.wheelData?.wheelSpecifications ||
                ''
        }
    }
    return tireOrWheelSize
}
