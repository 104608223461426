import { Dispatch } from '@reduxjs/toolkit'

import {
    addHppToken,
    addCreditCardAction,
    addCreditCardErrorAction,
    resetAddCreditCardAction,
} from '../actionCreators/profilePaymentCard.actionCreators'
import { ErrorResponse } from '../models/loyaltyRegisterForm.interface'
import { profilePaymentService } from '../../services/profilePaymentService'
import { AxiosError, AxiosResponse } from 'axios'
import { InitAddUpdateCardRequestType } from '../../redux/models/profile.payment.interface'
import { isOtpErrorCode, OtpErrorType } from '@nl/lib'
import { initOtpFlow, setOtpErrCode } from '../actionCreators/otp.actionCreators'

/**
 * Get hpp token
 * @param {InitAddUpdateCardRequestType} payload
 * @return {Promise<void>} returns api response promise
 */

export const fetchAddPaymentHppToken =
    (payload: InitAddUpdateCardRequestType, component: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return profilePaymentService
            .hppToken(payload)
            .then((resp: AxiosResponse<Record<string, unknown>>) => {
                dispatch(
                    addHppToken({
                        hppToken: String(resp.data?.transactionId || resp.data?.hppToken),
                        validUntil: Number(resp.data.validUntil),
                    }),
                )
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                if (isOtpErrorCode(err.response?.data.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: err.response?.data.vToken as string,
                            component,
                            userID: err.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(err.response?.data.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(err.response?.data.errCode as string))
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(err)
                }
            })
    }

/**
 * add credit card
 * @param {string} tratransactionId
 * @return {Promise<void>} returns api response promise
 */

export const addCreditCard =
    (tratransactionId: string, component: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return profilePaymentService
            .addCreditCard(tratransactionId)
            .then(resp => {
                dispatch(addCreditCardAction(resp.data))
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                if (!err.response) return

                if (isOtpErrorCode(err.response.data.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: err.response.data.vToken,
                            component: component,
                            userID: err.response.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(err.response.data.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(err.response.data.errCode))
                } else {
                    dispatch(addCreditCardErrorAction(err.response))
                }
            })
    }

/**
 * reset credit card
 * @return {Dispatch}
 */
export const resetAddCreditCard =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetAddCreditCardAction())
    }
