import { Dispatch } from '@reduxjs/toolkit'

import { HeaderNavigationService } from '../../services/headerNavigationService'
import sessionStorageService from '../../utils/sessionStorageService'
import { fetchCategoryIdAction, fetchCategoryIdErrorAction, fetchCategoryIdRequestAction } from '../actionCreators'
import {
    CategoryResponseById,
    CategoryResponseErrorDTO,
    CategorySessionStorage,
    FetchCategoryResponseErrorDTO,
} from '../models/category.interface'
import HeaderNavigationHelper from '../../helpers/headerNavigation.helper'
import { getEnvironment } from '../../environments'
import { MagicNumber } from '../../analytics/analytics.type'
import { AxiosError } from 'axios'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'

export const fetchDataUsingCategoryId =
    (categoryId: string) =>
    (dispatch: Dispatch): void => {
        const config = getEnvironment()
        const currentTime = new Date().getTime()
        let categoryDataList: CategorySessionStorage[] = []
        const testData = sessionStorageService.getItem(config.SESSION_STORAGE_KEYS.categoryIdData)
        categoryDataList = (JSON.parse(testData as string) || []) as CategorySessionStorage[]
        const categoryDetailData: CategorySessionStorage | undefined = categoryDataList?.find(
            (item: CategorySessionStorage) => item?.categoryData?.id === categoryId,
        )
        dispatch(fetchCategoryIdRequestAction())

        const resetCategoryList = () => {
            if (
                // eslint-disable-next-line sonar/expression-complexity
                categoryDetailData &&
                ((categoryDetailData && categoryDetailData?.maxAge && currentTime > categoryDetailData?.maxAge) ||
                    (!categoryDetailData?.maxAge && currentTime > categoryDetailData?.expiryTimestamp))
            ) {
                const index = categoryDataList?.indexOf(categoryDetailData)
                if (index > MagicNumber.MINUS_ONE) {
                    categoryDataList.splice(index, MagicNumber.ONE)
                }
            }
        }

        const setSessionStorage = (data: CategoryResponseById, headers: Record<string, string>) => {
            if (headers['expires']) {
                const categoryIdData = HeaderNavigationHelper.prepareCategorySessionStorage(
                    data,
                    headers['expires'],
                    headers['date'],
                    headers['cache-control'],
                )
                categoryDataList.push(categoryIdData)
                sessionStorageService.setItem(
                    config.SESSION_STORAGE_KEYS.categoryIdData,
                    JSON.stringify(categoryDataList),
                )
            }
            dispatch(fetchCategoryIdAction(data))
        }

        if (
            // eslint-disable-next-line sonar/expression-complexity
            !categoryDetailData ||
            (categoryDetailData?.maxAge && currentTime > categoryDetailData?.maxAge) ||
            (!categoryDetailData?.maxAge && currentTime > categoryDetailData?.expiryTimestamp)
        ) {
            resetCategoryList()
            if (window.prefetchedCategoryPromise) {
                const headers: Record<string, string> = {}
                // eslint-disable-next-line no-void
                void window.prefetchedCategoryPromise
                    .then(response => {
                        if (response?.headers && Array.isArray(response?.headers)) {
                            response?.headers.forEach((val, key) => {
                                headers[key] = val as string
                            })
                        }

                        return response.json().then(json => {
                            if (response.ok) return json
                            else throw json
                        })
                    })
                    .then(data => {
                        replaceEmptyImagesWithDefault(data?.subcategories, 'image', config.defaultProductImage)
                        setSessionStorage(data, headers)
                    })
                    .catch((error: FetchCategoryResponseErrorDTO) => {
                        dispatch(fetchCategoryIdErrorAction({ data: error, status: error.status }))
                    })
            } else {
                HeaderNavigationService.getCategories(categoryId)
                    .then(data => {
                        replaceEmptyImagesWithDefault(data?.data?.subcategories, 'image', config.defaultProductImage)
                        setSessionStorage(data.data, data.headers as Record<string, string>)
                    })
                    .catch((error: AxiosError<CategoryResponseErrorDTO>) => {
                        const { data, status } = error.response as unknown as CategoryResponseErrorDTO
                        dispatch(fetchCategoryIdErrorAction({ data, status }))
                    })
            }
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (categoryDetailData) {
            dispatch(fetchCategoryIdAction(categoryDetailData?.categoryData))
        }
    }
