import store from '../store'
import { getEnvironment } from '../environments'
import getProvider from '../providers/storage'
import {
    addVehicleListAction,
    setDefaultVehicleAction,
    setIsVehicleSyncedWithLSOnPageLoadAction,
} from '../redux/actionCreators/user.profile.actionCreators'
import { Vehicle } from '@nl/lib'
import StorageService from '../services/storage.service'
import sessionStorageService from '../utils/sessionStorageService'
import { TireType } from '../redux/models/tireVehicle.interface'
import {
    updateAutoPackages,
    updateUserInitializedPackageFlow,
    updateTiresData,
    updateCurrentPackageId,
    setIsTiresDataSyncedWithLSOnPageLoad,
} from '../redux/actions'
import { PackageDataType } from '../components/AutomotivePackage/ProgressBanner/ProgressBanner.type'
import { REF_URL_KEY } from '../config'
import { setSavedVehicleChecked } from '../redux/actionCreators/vehiclesData.actionCreators'
import { AnyAction } from '@reduxjs/toolkit'

const localStorageProvider = getProvider()
const config = getEnvironment()

/**
 */
export class StorageHelper {
    static storageService = new StorageService(localStorageProvider)

    /**
     * This function used to prepare vehicle state
     * @returns {Promise<void>} - returns Promise<void>
     */
    static async prepareVehicleState(): Promise<void> {
        const vehicleList = await StorageHelper.storageService.getVehicleList<Vehicle[]>(
            config.LOCAL_STORAGE_KEYS.userVehicles,
        )
        // update redux when list exist
        if (vehicleList) {
            store.dispatch(setDefaultVehicleAction(vehicleList[0]))
            store.dispatch(addVehicleListAction(vehicleList))
            store.dispatch(setSavedVehicleChecked())
        }
        store.dispatch(setIsVehicleSyncedWithLSOnPageLoadAction(true))
    }

    /**
     * Function Used To Persist Local Storage Value For Tire Data
     * @returns {Promise<void>} - returns Promise<void>
     */
    static async prepareTireState(): Promise<void> {
        const tireList = await StorageHelper.storageService.getVehicleList<TireType>(
            config.LOCAL_STORAGE_KEYS.tiresData,
        )
        // update redux when list exist
        tireList && store.dispatch(updateTiresData(tireList) as unknown as AnyAction)
        store.dispatch(setIsTiresDataSyncedWithLSOnPageLoad(true) as unknown as AnyAction)
    }

    /**
     * Function used to update redux for package flow.
     * @returns {void} - returns void
     */
    static prepareIsPackageFlowState(): void {
        const packageFlow = sessionStorageService.getItem(config.SESSION_STORAGE_KEYS.packageFlow)
        // update redux when user going through package flow
        const parsedPackageFlowData = (packageFlow ? JSON.parse(packageFlow) : '') as PackageDataType
        parsedPackageFlowData &&
            store.dispatch(
                updateUserInitializedPackageFlow(parsedPackageFlowData.isPackageFlow) as unknown as AnyAction,
            )
        parsedPackageFlowData &&
            store.dispatch(updateAutoPackages(parsedPackageFlowData.autoPackages) as unknown as AnyAction)
        parsedPackageFlowData &&
            store.dispatch(
                updateCurrentPackageId(parsedPackageFlowData.currentPackageId as string) as unknown as AnyAction,
            )
    }

    /**
     * Function used to track referrer url for search calls.
     * @returns {void} - returns void
     */
    static handleRefUrl(): void {
        const refUrl = sessionStorage.getItem(REF_URL_KEY)
        if (!refUrl) {
            sessionStorageService.setItem(REF_URL_KEY, document.referrer || window.location.href)
        }
        window.addEventListener('beforeunload', () => {
            sessionStorageService.setItem(REF_URL_KEY, window.location.href)
        })
    }

    /**
     * This function used to prepare redux storage
     * @returns {void} - returns void
     */
    static prepareRedux(): void {
        StorageHelper.prepareVehicleState().catch(err => console.error(err))
        StorageHelper.prepareTireState().catch(err => console.error(err))
        StorageHelper.prepareIsPackageFlowState()
        StorageHelper.handleRefUrl()
    }
}

export default StorageHelper
