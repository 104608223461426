import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import { Button, areAllParamsValid } from '@nl/lib'
import { PREFIX } from '../../../config'
import { checkIfProductDiscontinuedEOL } from '../BuyBox.helper'
import { getWishListIconComponent, getAddToWishListLabel } from '../Buybox.component'
import { isWheelOrTirePDP } from '../../Vehicles/Vehicle.helper'
import { useAuth, useIsMobile } from '../../../hooks'
import { fetchProfilePayment, fetchUserProfile, toggleAddToCartCTA } from '../../../redux/actions'
import { userProfileDataSelector, userProfileVehicleSelector } from '../../../redux/selectors/userProfile.selectors'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { wishlistRemoveSuccessSelector } from '../../../redux/selectors/wishlist.selectors'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { automotivePackageSelector } from '../../../redux/selectors/automotivePackage.selector'
import { IFeatureFlag, IGlobalLinks, IProduct } from '../../../redux/models/commonContent.interface'
import { ATCWishlistBtnsComponentProps } from './ATCWishlistBtnsComponent.type'
import { addRemoveWishList } from '../AddRemoveWishlist'
import useProductCodeForWishlist from '../useProductCodeForWishlist.hook'
import { StickyBuyBoxCTARequestPayload } from '../../../redux/models/product.interface'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'
import { enableSingleSignOn, profileName, ssoLoginHandler } from '../../../helpers/ciam.helper'
import { redirectToLoginPage } from '../../PageInit/PageInit.helper'
import { profilePaymentDataSelector } from '../../../redux/selectors/profilePayment.selector'
import appCacheService from '../../../utils/appCacheService'
import { BuyNowModalDataSelector } from '../../../redux/selectors/buyNowModalData.selectors'
import PriceComponent from '../PriceComponent/PriceComponent'
import {
    setBuyNowAPIError,
    setFetchedBuyNowAccountInfo,
    setFetchingBuyNowAddToCart,
} from '../../../redux/actionCreators'
import { getRewardsHubBottomStyle } from '../../RewardsHub/RewardsHub.helper'
import { useIsLoggedInLoyaltyUser } from '../../../hooks/useIsLoggedInLoyaltyUser.hook'
import { cartCodeBuyNow, buyNowParam } from '../../BuyNowModal/BuyNowModal.constant'
import { enableDestructOnUndefinedData } from '../../../utils/PDP/enableDestructOnUndefinedData.utils'
import { initBuyNowModal } from '../../../redux/actions/buyNowModal.action'
import { UserProfileData } from '../../../redux/models/user.profile.interface'

/**
 * Component to return ATC and wishlist button
 * @param {ATCWishlistBtnsComponentProps} props ATCWishlistBtnsComponentProps props
 * @returns {JSX.Element | null} if not limited PDP then return ATC and wishlist button else return null
 */
const ATCWishlistBtnsComponent: React.FC<ATCWishlistBtnsComponentProps> = (
    props: ATCWishlistBtnsComponentProps,
): JSX.Element | null => {
    const {
        addCartClicked,
        needRenderATCWishlistButton,
        isAddedToWishlist,
        criticalFitmentGrouped,
        setIsAddToWishlistClicked,
        checkIfAllVariantsSelected,
        setShowErrorMsgForVariantSelection,
        isAutomotive,
        isTireSizePresent,
        setIsVariantSelected,
        setIsNoSkuFindStoreClicked,
        scrollOnToastDisplayed,
        productObject,
        homeService,
        inStockInSelectedStore,
        isProductSellable,
        isRestrictedPDP,
        isDisabledATC,
        isSelectAStoreButtonAvailable,
        showSpinner,
        isOutOfStockInAllStore,
        isBopisOnlyUnavailableForPickupInStock,
        getAtcPrimaryButtonType,
        isSelectAllOptionToShow,
        isBopisOnlySkuWithStoreBopisUnavailable,
        isProductFullyDiscontinued,
        flyoutVariantsInfoData,
        isShowBuyNowBtn,
        buyNowBtnClicked,
        setBuyNowBtnClicked,
        handlerBuyNowClicked,
        showSpinnerBuyNow,
        setShowSpinnerBuyNow,
        showStickyATC,
        buyNowCTALabel,
        setShowModalBuyNow,
        addProductToCartAPI,
        setIsShipToHome,
        setIsFreePickUp,
        setIsExpressDelivery,
        isStaggered,
        isProductLevel,
        staggeredFulfillmentSkus,
        staggeredFulfillmentSkusFiltered,
        productDataBySku,
        setOfTwoLabel,
        setOfTwoNowLabel,
        isShowPriceInSticky,
        isFreePickUp,
    } = props

    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()

    const generalProductData = useAppSelector(productSelector)
    const { productData, isStickyBuyboxCTAClicked: { isToastDisplayed } = {} as StickyBuyBoxCTARequestPayload } =
        useMemo(() => flyoutVariantsInfoData || generalProductData, [flyoutVariantsInfoData, generalProductData])
    const { defaultVehicle } = useAppSelector(userProfileVehicleSelector)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const {
        product: commonContentProduct = {} as IProduct,
        featureFlag = {} as IFeatureFlag,
        globalLinks = {} as IGlobalLinks,
    } = commonContentAvailable
    const { enableVehicleInformationOnWishlist, enableWishlistOnPDP, enableRewardsHub } = featureFlag
    const { selectAStoreLabel } = commonContentProduct
    const { checkoutPageLink } = globalLinks
    const profilePaymentData = useAppSelector(profilePaymentDataSelector)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const wishlistRemoveSuccess = useAppSelector(wishlistRemoveSuccessSelector)
    const { preferredStoreDetails } = useAppSelector(storeDetailsDataSelector)
    const { email = '' } = enableDestructOnUndefinedData(userProfileData) as UserProfileData
    const { onlineOrdering, id: prefferedStoreId } = preferredStoreDetails
    const { isPackageFlow } = useAppSelector(automotivePackageSelector)
    const {
        guid: buyNowGuid,
        cartCode: buyNowCartCode = '',
        initCheckoutInfo,
        hasAPIError,
        isFetchedBuyNowAccountInfo,
        isFetchingBuyNowAddToCart,
        aemPropsAndFlags: { enableBuyNowOnBopisProd },
    } = useAppSelector(BuyNowModalDataSelector)

    const [wishLoadToggle, setWishLoadToggle] = useState(false)
    const [showWishListSpinner, setShowWishListSpinner] = useState(false)

    const atcBuyNowClass = 'buy-box__add-cart-buy-now'
    const atcWishlist = 'buy-box__add-cart-wishlist'
    const isLoggedInLoyaltyUser = useIsLoggedInLoyaltyUser()

    const wishloadRef = useRef<HTMLDivElement>(null)
    const hasFetchedAccountDataOnce = useRef<boolean>(false)
    // Used to limit the no of toggleAddToCartCTA to one on page load.
    const ctaDispatchCount = useRef({
        showCount: 0,
        hideCount: 0,
    })

    // Check if product is fully discontinued or discontinue when out
    const isProductDiscontinuedEOL = checkIfProductDiscontinuedEOL(productData?.corporateStatus)
    const { isLoggedIn } = useAuth()

    /**
     * Updating the loading message after adding and removing the product in wishlist
     * @returns {void}
     */
    const updatingWishList = (): void => {
        if (wishloadRef.current) {
            wishloadRef.current.innerHTML = wishLoadToggle
                ? `${props.a11yUpdatingWishList ?? ''}.`
                : `${props.a11yUpdatingWishList ?? ''}`
        }
    }

    /**
     * This function returns class for mobile ATC
     * @returns {string} class for mobile ATC
     */
    const getATCMobileClass = (): string => {
        return isMobile
            ? `${PREFIX}-buy-box__add-cart-wishlist__atc--sticky ${getRewardsHubBottomStyle(
                  areAllParamsValid(isLoggedInLoyaltyUser, Boolean(enableRewardsHub), !isShowBuyNowBtn),
              )}`
            : ''
    }

    // Gets product code for wishlist
    const getPCodeForWishlist = useProductCodeForWishlist(isAddedToWishlist)

    /**
     * function returns wishlist btn with or without label
     * @param { boolean | undefined} withLabel defines if label should be rendered
     * @returns {JSX.Element} WishList component
     */
    const getWishListComponent = (withLabel: boolean | undefined = false): JSX.Element => {
        const innerButton = (
            <>
                <span className="sr-only" ref={wishloadRef} aria-live="polite" aria-atomic="true" role="status"></span>
                <button
                    data-qm-allow="true"
                    className={`${
                        isAddedToWishlist && !wishlistRemoveSuccess
                            ? `${PREFIX}-buy-box__add-cart-wishlist--active`
                            : ''
                    } ${PREFIX}-buy-box__wishlist-container ${
                        withLabel ? `${PREFIX}-buy-box__wishlist-container--with-label` : ''
                    } ${PREFIX}-button ${PREFIX}-button--secondary`}
                    onClick={() =>
                        addRemoveWishList(
                            criticalFitmentGrouped,
                            setIsAddToWishlistClicked,
                            setWishLoadToggle,
                            updatingWishList,
                            getPCodeForWishlist.getProductCodeForWishlist,
                            isAddedToWishlist,
                            checkIfAllVariantsSelected,
                            setShowErrorMsgForVariantSelection,
                            isAutomotive,
                            setIsVariantSelected,
                            setIsNoSkuFindStoreClicked,
                            isToastDisplayed,
                            dispatch,
                            scrollOnToastDisplayed,
                            defaultVehicle,
                            isTireSizePresent,
                            enableVehicleInformationOnWishlist,
                            productObject,
                            props.addToWishlistInactiveProductErrorMsg,
                            setShowWishListSpinner,
                        )
                    }
                    aria-label={isAddedToWishlist ? props.a11yRemoveFromWishList : props.a11yAddToWishList}
                    data-testid="wishlist-button">
                    {getWishListIconComponent(isAddedToWishlist && !wishlistRemoveSuccess, showWishListSpinner)}{' '}
                    {withLabel &&
                        getAddToWishListLabel(isAddedToWishlist, props.addToWishListLabel, props.addedToWishListLabel)}
                </button>
            </>
        )

        return withLabel ? <>{innerButton}</> : <span className={`${PREFIX}-ml-sm`}>{innerButton}</span>
    }

    /**
     * function to display wishlist button, ATW should appear ALL the time except Ã¢â‚¬â€œ Home Service, FD/DWO discontinued and store has no inventory
     * @returns {JSX.Element | null} wishlist btn or null
     */
    const renderWishListBtnComp = (): JSX.Element | null => {
        const applicableForWishList =
            enableWishlistOnPDP && !homeService && !(isProductDiscontinuedEOL && !inStockInSelectedStore())
        const withLabel = props.wishListNonSellableEnabled && !isProductSellable
        return applicableForWishList ? (
            <span className={`${PREFIX}-ml-xs`}>{getWishListComponent(withLabel)}</span>
        ) : null
    }

    /**
     * function renders wishlist btn if PDP is restricted
     * @returns {JSX.Element | null} wishlist btn or null
     */
    const isRestrictedPDPComponent = (): JSX.Element | null => {
        const isRestricted = isRestrictedPDP()

        if (isRestricted && ctaDispatchCount.current.hideCount === 0) {
            dispatch(toggleAddToCartCTA(false))
            ctaDispatchCount.current.hideCount = 1
        }

        return isRestricted || isBopisOnlySkuWithStoreBopisUnavailable ? (
            <div className={`${PREFIX}-buy-box__add-cart-wishlist`}>{getWishListComponent(true)}</div>
        ) : null
    }

    /**
     * function returns label for ATC btn
     * @returns {string | undefined} label for ATC btn
     */
    const getATCBtnLabel = (): string | undefined => {
        // eslint-disable-next-line sonar/expression-complexity
        return isPackageFlow && isWheelOrTirePDP(productData?.productWheelType)
            ? props.addToPackageCTA
            : // eslint-disable-next-line sonar/no-nested-conditional
            isSelectAStoreButtonAvailable()
            ? selectAStoreLabel
            : // eslint-disable-next-line sonar/no-nested-conditional
            isSelectAllOptionToShow()
            ? props.selectAllOptionsLabel
            : props.buyboxButtonLabel
    }

    /**
     * function to check if the billing address available or not
     * @returns {boolean}
     */
    const checkBillingAddNotAvailable = useCallback(
        (): boolean =>
            userProfileData?.primaryBillingAddress
                ? !areAllParamsValid(
                      Boolean(userProfileData?.primaryBillingAddress.city),
                      Boolean(userProfileData?.primaryBillingAddress.postalCode),
                      Boolean(userProfileData?.primaryBillingAddress.country),
                      Boolean(userProfileData?.primaryBillingAddress.province),
                      Boolean(userProfileData?.primaryBillingAddress.addressLineOne),
                      Boolean(userProfileData?.addresses?.phone),
                      Boolean(userProfileData?.email),
                  )
                : true,
        [userProfileData],
    )

    const hasAllNeededAccountDataAvailableForBuyNow = useMemo(
        () => Boolean(profilePaymentData?.length && !checkBillingAddNotAvailable()),
        [profilePaymentData, checkBillingAddNotAvailable],
    )

    useEffect(() => {
        if (buyNowBtnClicked && enableBuyNowOnBopisProd && isLoggedIn && !isFetchedBuyNowAccountInfo) {
            if (email && profilePaymentData) {
                dispatch(setFetchedBuyNowAccountInfo(true))
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (!hasFetchedAccountDataOnce.current) {
                hasFetchedAccountDataOnce.current = true
                !email && dispatch(fetchUserProfile(false, true))
                !profilePaymentData && dispatch(fetchProfilePayment())
            }
        }
    }, [
        isLoggedIn,
        dispatch,
        enableBuyNowOnBopisProd,
        buyNowBtnClicked,
        isFetchedBuyNowAccountInfo,
        email,
        profilePaymentData,
    ])

    /**
     * useEffect to redirect the anonymous user to the login page when 'buy now' is clicked
     * And to redirect the logged in user with missing info to the checkout page when 'buy now' is clicked
     * And launch addToCart api for creating buynow cart
     */
    useEffect(() => {
        if (buyNowBtnClicked) {
            if (hasAPIError) {
                setBuyNowBtnClicked(false)
                setShowSpinnerBuyNow(false)
                dispatch(setFetchedBuyNowAccountInfo(false))
                dispatch(setFetchingBuyNowAddToCart(false))
                hasFetchedAccountDataOnce.current = false
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (!isLoggedIn) {
                const url = new URL(window.location.href)
                const redirectUrl = url.origin + url.pathname
                appCacheService.buyNowModalAutoOpening.set('true')
                if (enableSingleSignOn()) {
                    ssoLoginHandler(redirectUrl, true, { ...profileName() })
                } else {
                    redirectToLoginPage(window?.ODP?.globalLinks.loginPageLink)
                }
            }
        } else {
            hasAPIError && dispatch(setBuyNowAPIError(false))
        }
    }, [buyNowBtnClicked, hasAPIError, setBuyNowBtnClicked, setShowSpinnerBuyNow, isLoggedIn, dispatch])

    useEffect(() => {
        if (buyNowBtnClicked && !hasAPIError && isLoggedIn && isFetchedBuyNowAccountInfo) {
            if (!isFetchingBuyNowAddToCart) {
                dispatch(setFetchingBuyNowAddToCart(true))
                addProductToCartAPI(true)
            } else if (!hasAllNeededAccountDataAvailableForBuyNow) {
                appCacheService.buyNowCartDataRequest.delete()
                appCacheService.buyNowProductData.delete()
                if (initCheckoutInfo) {
                    const checkoutBuyNowLink = `${checkoutPageLink}${buyNowParam}${cartCodeBuyNow}${buyNowCartCode}`
                    window.location.href = encodeURI(checkoutBuyNowLink)
                } else {
                    buyNowGuid && dispatch(initBuyNowModal(prefferedStoreId as string))
                }
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (buyNowGuid) {
                setShowModalBuyNow(true)
                setBuyNowBtnClicked(false)
                setShowSpinnerBuyNow(false)
                hasFetchedAccountDataOnce.current = false
            }
        }
    }, [
        buyNowBtnClicked,
        buyNowGuid,
        buyNowCartCode,
        hasAPIError,
        setBuyNowBtnClicked,
        setShowSpinnerBuyNow,
        hasAllNeededAccountDataAvailableForBuyNow,
        checkoutPageLink,
        setShowModalBuyNow,
        isLoggedIn,
        addProductToCartAPI,
        dispatch,
        initCheckoutInfo,
        isFetchedBuyNowAccountInfo,
        isFetchingBuyNowAddToCart,
        prefferedStoreId,
    ])

    useEffect(() => {
        const hasBuyNowModalAutoOpening = appCacheService.buyNowModalAutoOpening.get()

        if (hasAllNeededAccountDataAvailableForBuyNow && hasBuyNowModalAutoOpening) {
            appCacheService.buyNowModalAutoOpening.delete()
            setIsShipToHome(false)
            setIsFreePickUp(true)
            setIsExpressDelivery(false)
            handlerBuyNowClicked()
        }
    }, [
        profilePaymentData,
        checkBillingAddNotAvailable,
        handlerBuyNowClicked,
        hasAllNeededAccountDataAvailableForBuyNow,
        setIsShipToHome,
        setIsFreePickUp,
        setIsExpressDelivery,
    ])

    /**
     * function to return true/false whether to show price in sticky component
     * @returns {boolean} true/false
     */
    const isShowPriceForMobileSticky = useMemo(
        (): boolean => areAllParamsValid(Boolean(showStickyATC), Boolean(isShowPriceInSticky), isMobile, !isFreePickUp),
        [showStickyATC, isShowPriceInSticky, isMobile, isFreePickUp],
    )

    /**
     * This function returns class for sticky price
     * @returns {string} class for sticky price
     */
    const getATCMobileStickyClass = (): string => (isShowPriceForMobileSticky ? `${PREFIX}-buy-box__sticky-price` : '')

    /**
     * function to return price info
     * @returns {JSX.Element} price info
     */
    const getPriceDetails = (): JSX.Element => {
        return (
            <>
                {onlineOrdering && (
                    <PriceComponent
                        isStaggered={Boolean(isStaggered)}
                        isProductLevel={Boolean(isProductLevel)}
                        staggeredFulfillmentSkus={staggeredFulfillmentSkus}
                        staggeredFulfillmentSkusFiltered={staggeredFulfillmentSkusFiltered}
                        productDataBySku={productDataBySku}
                        setOfTwoLabel={setOfTwoLabel}
                        setOfTwoNowLabel={setOfTwoNowLabel}
                    />
                )}
            </>
        )
    }

    /**
     * function to display add to cart button
     * @returns {JSX.Element} add to cart btn
     */
    const renderATCBtn = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-${atcWishlist}__atc ${getATCMobileClass()} ${getATCMobileStickyClass()}`}>
                {onlineOrdering && (
                    <Button
                        id="add-to-cart"
                        disabled={isDisabledATC}
                        type={getAtcPrimaryButtonType()}
                        onClick={() => {
                            addCartClicked()
                        }}
                        size="large"
                        label={getATCBtnLabel()}
                        showSpinner={showSpinner}></Button>
                )}
                {isShowPriceForMobileSticky && getPriceDetails()}
            </div>
        )
    }

    /**
     * function to display buy now button
     * @returns {JSX.Element} buy now btn
     */
    const renderBuyNowBtn = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-buy-box__add-cart-wishlist__atc ${getATCMobileClass()}`}>
                {onlineOrdering && (
                    <Button
                        id="buy-now"
                        type="primary"
                        onClick={() => {
                            handlerBuyNowClicked()
                        }}
                        size="large"
                        label={buyNowCTALabel}
                        showSpinner={showSpinnerBuyNow}></Button>
                )}
            </div>
        )
    }

    /**
     * function to display price information in sticky price
     * @returns {JSX.Element} price component
     */
    const renderPriceComponent = (): JSX.Element => (
        <div className={`${PREFIX}-${atcWishlist}__price ${getATCMobileClass()}`}>{getPriceDetails()}</div>
    )

    const isPriceStickyClassToShow = areAllParamsValid(Boolean(showStickyATC), Boolean(isShowPriceInSticky))

    /**
     * This function returns class for price sticky
     * @returns {string} class for price sticky
     */
    const getPriceStickyClass = (): string => {
        if (isPriceStickyClassToShow) {
            return `${PREFIX}-${atcBuyNowClass}--sticky-price`
        } else {
            return ''
        }
    }

    /**
     *
     */
    const renderATCBuyNowBtns = (): JSX.Element => {
        const getClassNameBuyNowContainer = showStickyATC
            ? `${PREFIX}-${atcBuyNowClass}--sticky ${getPriceStickyClass()} ${getRewardsHubBottomStyle(
                  areAllParamsValid(isLoggedInLoyaltyUser, Boolean(enableRewardsHub)),
              )}`
            : `${PREFIX}-${atcBuyNowClass}`

        return (
            <>
                <div className={`${PREFIX}-${atcWishlist} ${PREFIX}-${atcWishlist}-buy-now`}>
                    {renderWishListBtnComp()}
                </div>
                <div className={`${PREFIX}-${atcWishlist} ${getClassNameBuyNowContainer}`}>
                    {renderATCBtn()}
                    {isPriceStickyClassToShow ? renderPriceComponent() : renderBuyNowBtn()}
                </div>
            </>
        )
    }

    if (needRenderATCWishlistButton) {
        return (
            <>
                {isShowBuyNowBtn ? (
                    renderATCBuyNowBtns()
                ) : (
                    <div className={`${PREFIX}-buy-box__add-cart-wishlist`}>
                        {renderATCBtn()}
                        {renderWishListBtnComp()}
                    </div>
                )}
            </>
        )
    } else if (
        // eslint-disable-next-line sonar/expression-complexity
        (!isRestrictedPDP() && isOutOfStockInAllStore && !isProductFullyDiscontinued) ||
        isBopisOnlyUnavailableForPickupInStock ||
        !onlineOrdering
    ) {
        return (
            <div className={`${PREFIX}-buy-box__add-cart-wishlist`}>
                {!homeService && enableWishlistOnPDP && getWishListComponent(true)}
            </div>
        )
    } else {
        return isRestrictedPDPComponent()
    }
}

export default ATCWishlistBtnsComponent
