import React from 'react'
import clx from 'classnames'

import { checkDataLength, isPrerenderOrNot, ProductFitmentMessage, Vehicle } from '@nl/lib'
import { getLanguage, stringToBooleanMap } from '@nl/lib/src/utils'

import HallOfFameProductTile from './HallOfFameProductTile'
import {
    hallOFameFitment,
    hallOFameProductGrid,
    hallOFameProductTile,
    hallOFameSkeleton,
} from './HallOfFame.classnames'

import {
    AuthorableProductProps,
    HallOfFameProductGridProps,
    HallOfFameProductTileProps,
    Language,
    ProductDetailsProps,
} from './HallOfFame.types'

import { commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import { HALL_OF_FAME_PRODUCTS_AMOUNT } from './HallOfFame.constants'
import PrerenderHallOfFameProductTile from './PrerenderHallOfFameProductTile'
import { IAccessibility, IGeneral, IGlobalProps, IProduct } from '../../../redux/models/commonContent.interface'
import { useAppSelector } from '../../../hooks/react-redux.hook'
import { getFitmentIcon, getFitmentMessage } from '../../ProductGridView/ProductCard.helper'
import { defaultVehicleSelector } from '../../../redux/selectors/userProfile.selectors'
import { isSuggestiveDropdownStateSelector } from '../../../redux/selectors/hideVehicleSelectorBanner.selectors'
import { tiresDataSelector } from '../../../redux/selectors/vehicleTires.selectors'
import { isTirePDP } from '../../Vehicles/Vehicle.helper'

/**
 * HallOfFameProductGrid
 * @param {HallOfFameProductGridProps} props - props
 * @return {JSX.Element} - HallOfFameProductGrid
 */
function HallOfFameProductGrid({
    products,
    isLoading,
    productDetailsProps = {} as ProductDetailsProps,
    productWheelType,
}: HallOfFameProductGridProps): JSX.Element {
    const {
        badges,
        general = {} as IGeneral,
        automotive = {} as IGlobalProps['automotive'],
        product = {} as IProduct,
        accessibility = {} as IAccessibility,
    } = useAppSelector(commonContentAvailableSelector)

    const defaultVehicle = useAppSelector(defaultVehicleSelector)
    const isSuggestiveDropdownState = useAppSelector(isSuggestiveDropdownStateSelector)
    const tiresData = useAppSelector(tiresDataSelector)

    const { roadRatedLabel, attributeFrontLabel, attributeRearLabel } = automotive
    const {
        eachLabel,
        fromLabel,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
    } = product
    const { a11yStrikeOutPrice, a11yStrikeOutPriceRange, a11yCloseIconLabel, a11yTooltipIcon } = accessibility
    const { plusMinusSymbol = '' } = general

    const language = getLanguage() as Language

    const {
        specificationsProps = {} as typeof productDetailsProps.specificationsProps,
        availabilityProps = {} as typeof productDetailsProps.availabilityProps,
        compatibilityProps = {} as typeof productDetailsProps.compatibilityProps,
    } = productDetailsProps

    const showFitmentMessage =
        stringToBooleanMap[compatibilityProps.featureToggle] &&
        !(isTirePDP(productWheelType) && checkDataLength(tiresData))

    const fitmentIcon = getFitmentIcon(isSuggestiveDropdownState, defaultVehicle as Vehicle)

    const fitmentMessage = getFitmentMessage(
        isSuggestiveDropdownState,
        compatibilityProps.fitMessage,
        defaultVehicle as Vehicle,
        compatibilityProps.moreDetailsRequired,
    )

    const authorableProps = {
        a11yCloseIconLabel,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yTooltipIcon,
        attributeFrontLabel,
        attributeRearLabel,
        clearancePriceLabel,
        eachLabel,
        fromLabel,
        nowFromLabel,
        plusMinusSymbol,
        promotionalPriceLabel,
        roadRatedLabel,
        saveFromLabel,
        unitPriceLabel,
        wasFromLabel,
        badgesConfig: badges,
        checkAvailabilityLabel: availabilityProps.checkAvailabilityLabel,
        checkOtherStoresLabel: availabilityProps.checkOtherStoresLabel,
        inStockAisleLabel: availabilityProps.inStockAisleLabel,
        inStockOnlineLabel: availabilityProps.inStockOnlineLabel,
        inStorePurchaseLabel: availabilityProps.inStorePurchaseLabel,
        isUrgentLowStockLabel: availabilityProps.isUrgentLowStockLabel,
        isUseLegacyLogicOfVehicleCheckAvailability: availabilityProps.isUseLegacyLogicOfVehicleCheckAvailability,
        outOfStockLabel: availabilityProps.outOfStockLabel,
        tireSizeLabel: specificationsProps.tireSizeLabel,
        wheelOffsetLabel: specificationsProps.wheelOffsetLabel,
        wheelRimDiameterLabel: specificationsProps.wheelRimDiameterLabel,
        wheelRimWidthLabel: specificationsProps.wheelRimWidthLabel,
        renderFitmentMessage: (accessibilityId, isStaggered, customClass) =>
            showFitmentMessage ? (
                <ProductFitmentMessage
                    customClass={clx(hallOFameFitment, customClass)}
                    fitmentMessage={fitmentMessage}
                    fitmentIcon={fitmentIcon}
                    accessibilityId={accessibilityId}
                    isStaggered={isStaggered}
                />
            ) : null,
    } as HallOfFameProductTileProps | AuthorableProductProps

    return (
        <ol className={hallOFameProductGrid}>
            {isLoading && <HOFSkeleton />}
            {!isLoading ? (
                <>
                    {products.map((productItem, index) => {
                        return isPrerenderOrNot() ? (
                            <PrerenderHallOfFameProductTile
                                key={productItem.prodId}
                                language={language}
                                {...productItem}
                                {...authorableProps}
                            />
                        ) : (
                            <HallOfFameProductTile
                                key={`hall-of-fame-item-${index}`}
                                language={language}
                                {...productItem}
                                {...authorableProps}
                            />
                        )
                    })}
                </>
            ) : null}
        </ol>
    )
}

const HOFSkeleton = () => {
    const skeletonItems = new Array<number>(HALL_OF_FAME_PRODUCTS_AMOUNT).fill(HALL_OF_FAME_PRODUCTS_AMOUNT)
    return (
        <>
            {skeletonItems.map((value, index) => (
                <div key={value + index} className={clx(hallOFameProductTile, hallOFameSkeleton)} />
            ))}
        </>
    )
}

HOFSkeleton.displayName = 'HOFSkeleton'
HallOfFameProductGrid.displayName = 'HallOfFameProductGrid'

export default HallOfFameProductGrid
