export const gigyaNewRelicResources = {
    gigyaWebSdkInitiated: 'gigyaWebSdkInitiated',
    gigyaWebSdkInitializationFailed: 'gigyaWebSdkInitializationFailed',
    gigyaIsReadyFailed: 'gigyaIsReadyFailed',
}

export const gigyaInitStatuses = {
    READY: 'READY',
    FAILED: 'FAILED',
    TIMEOUT: 'TIMEOUT',
}
