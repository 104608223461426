import getPageTitle from '../../utils/getPageTitle'
import { onLoadEvent } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.constant'
import { getProvider } from '../providers'
import { IRegistrationAnalyticsActionResult, SpaPageAnalytics, SpaPageEventAnalytics } from '../analytics.type'
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { analyticsAttributes } from '../../globalConstants'
import { GlobalPropsHelper } from '../helpers/globalProps'

const provider = getProvider()

const globalProps = new GlobalPropsHelper()
const dataConfig = globalProps.readDataConfig()

const spaAnalytics = (() => {
    const {
        eventParameters: { location },
    } = analyticsAttributes

    /**
     * Prepare analytics data for onload page
     * @param {string} pageName
     * @returns {SpaPageAnalytics}
     */
    const prepareOnloadPageData = (pageName?: string): SpaPageAnalytics => {
        return {
            name: pageName ? pageName : getPageTitle(),
            url: window.location.href,
        }
    }

    /**
     * This function is to trigger analytics for single page
     * @param { string } pageName
     * @returns { SpaPageEventAnalytics }
     */
    const _spaAnalyticsData = (pageName?: string): SpaPageEventAnalytics => {
        return {
            event: onLoadEvent,
            page: prepareOnloadPageData(pageName),
        }
    }

    const _spaLoginAnalyticsData = (successLogin: boolean, eventType: string): AnalyticsRecordInterface => {
        return {
            event: onLoadEvent,
            centralLogin: [
                {
                    eventType,
                    successLogin,
                },
            ],
        }
    }

    /**
     * Prepare registration onload data
     * @param {string} sourceURL - The source URL of the registration
     * @param {string} step - The current step of the registration process
     * @param {string} linkOption - The link option used during registration
     * @param {string} action - The action taken during registration
     * @param {IRegistrationAnalyticsActionResult} actionResult - The result of the registration action
     * @returns {AnalyticsRecordInterface} - The analytics data for registration
     */
    const _spaRewardsRegistrationAnalyticsData = (
        sourceURL: string,
        step: string,
        linkOption: string,
        action: string,
        actionResult: IRegistrationAnalyticsActionResult,
    ): AnalyticsRecordInterface => {
        return {
            event: onLoadEvent,
            centralRegistration: [
                {
                    source: dataConfig?.bannerId,
                    sourceURL,
                    step,
                    linkOption,
                    action,
                    ...actionResult,
                },
            ],
        }
    }

    const _spaRedirectToCLPAnalytics = (action: string): AnalyticsRecordInterface => {
        return {
            event: onLoadEvent,
            centralLogin: [
                {
                    SSOlogin: false,
                    action,
                },
            ],
        }
    }

    return {
        setAnalytics: (pageName?: string): void => {
            provider.push(_spaAnalyticsData(pageName))
        },
        setSSOLoginAnalytics: (successLogin: boolean): void => {
            provider.push(_spaLoginAnalyticsData(successLogin, location.ssoLogin))
        },
        setLoginFromCLPAnalytics: (successLogin: boolean): void => {
            provider.push(_spaLoginAnalyticsData(successLogin, location.finishLoginFromCLP))
        },
        setRewardsRegistrationAnalytics: (
            sourceUrl: string,
            step: string,
            linkOption: string,
            action: string,
            actionResult: IRegistrationAnalyticsActionResult,
        ): void => {
            provider.push(_spaRewardsRegistrationAnalyticsData(sourceUrl, step, linkOption, action, actionResult), true)
        },
        setRedirectToCLPAnalytics(action: string): void {
            provider.push(_spaRedirectToCLPAnalytics(action), true)
        },
    }
})()

export default spaAnalytics
