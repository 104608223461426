import React, { useEffect, useState, useCallback } from 'react'
import GigyaService from '../../services/gigyaService/gigya.service'

import appCacheService from '../../utils/appCacheService'
import { GigyaActionError, GigyaJWTResp } from '../../utils/gigya.type'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import {
    oneMinuteToTokenExpiration,
    tokenExpirationCheckInterval,
    retryMaxAttemptsNumber,
    oneSecondForDelayInterval,
    defaultRetryAttemptValue,
    retryAttemptIncrement,
    baseRetryAttemptDelay,
    retryAttemptsOffset,
    visibilityState,
} from './GigyaTokenRefresh.constant'
import { JWTTokenNoRetryErrorCodes } from '../GigyaScreen/gigya.constants'
import { useAppSelector } from '../../hooks/react-redux.hook'
import { logNewRelicError, logNewRelicInfo } from '../NewRelic/newRelic.helper'
import { getUserUID } from '../../helpers/liteProfile.helper'
import { getGigyaJwtTokenExpirationStatus, parseGigyaJwtToken } from '../../utils/jwtToken.utils'

const gigyaService = new GigyaService()
const JwtTokenHelper: React.FC = () => {
    const userProfileData = useAppSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useAppSelector(isAuthFlowExecutedSelector)
    const [intervalVal, setIntervalVal] = useState<NodeJS.Timeout | string>('')
    const isAuth = isAuthFlowExecuted && checkDataLength(userProfileData)

    /**
     * @method updateJwtBeforeExpire uses to check if expire time is 1 minute or less than that and refreshing token in each 5 seconds.
     */
    const updateJwtBeforeExpire = useCallback(
        (retryAttemptNum?: number) => {
            const expTime = getTokenExpTime()

            if (expTime) {
                const currInterval = setInterval(() => {
                    // Gigya token is expires after 5 mins. So here we are refreshing token on 4th minute. e.g exprire time 10:10:00, refresh call will be at 10:09:00
                    const isTokenAboutToExpire = getGigyaJwtTokenExpirationStatus(expTime, oneMinuteToTokenExpiration)

                    if (isTokenAboutToExpire) {
                        logNewRelicInfo({ logInfo: {}, userUID: getUserUID(), resource: 'updateJwtBeforeExpire' })

                        clearTimeInterval(currInterval)
                        getJWTToken(retryAttemptNum)
                    }
                }, tokenExpirationCheckInterval)
                setIntervalVal(currInterval)
            }
        },
        [setIntervalVal],
    )

    const getJWTToken = (retryAttemptNum?: number): void => {
        gigyaService
            .jwtToken()
            .then((resp: GigyaJWTResp) => {
                appCacheService.gigyaJWTToken.set(resp.id_token)
                updateJwtBeforeExpire()
            })
            .catch((err: GigyaActionError) => {
                console.error('JWT API failed: ', err)

                logNewRelicError({
                    error: err,
                    errorInfo: { retryAttemptNumber: retryAttemptNum || defaultRetryAttemptValue },
                    userUID: getUserUID(),
                    resource: 'updateJwtBeforeExpire',
                })

                const errorCode = err.errorCode?.toString()
                !err.gigyaReadyTimewindow &&
                    retryUpdateJwtBeforeExpire(
                        Number(retryAttemptNum || defaultRetryAttemptValue) + retryAttemptIncrement,
                        errorCode ?? '',
                    )
            })
    }

    const retryUpdateJwtBeforeExpire = (retryAttemptNum: number, errorCode: string) => {
        if (!JWTTokenNoRetryErrorCodes.includes(errorCode) && retryAttemptNum <= retryMaxAttemptsNumber) {
            const delayInterval =
                Math.pow(baseRetryAttemptDelay, retryAttemptNum - retryAttemptsOffset) * oneSecondForDelayInterval
            setTimeout(() => getJWTToken(retryAttemptNum), delayInterval)
        }
    }

    const getTokenExpTime = (): number => {
        const gigyaJWTToken = appCacheService.gigyaJWTToken.get()
        const parsedData = parseGigyaJwtToken(gigyaJWTToken)
        return (parsedData?.exp as number) ?? 0
    }

    const visibilityChangeHandler = (): void => {
        if (document.visibilityState === visibilityState.visible) {
            const expTime = getTokenExpTime()
            if (expTime && getGigyaJwtTokenExpirationStatus(expTime)) {
                updateJwtBeforeExpire()
            }
        }
    }

    useEffect(() => {
        if (isAuth) {
            updateJwtBeforeExpire()
        }
    }, [isAuth, updateJwtBeforeExpire])

    useEffect(() => {
        if (isAuth) {
            document.addEventListener('visibilitychange', visibilityChangeHandler)
        }
        return () => {
            document.removeEventListener('visibilitychange', visibilityChangeHandler)
        }
    }, [isAuth])

    useEffect(() => {
        return () => {
            if (intervalVal) {
                clearTimeInterval(intervalVal as NodeJS.Timeout)
            }
        }
    }, [intervalVal])

    /**
     * Function uses to clear the timer.
     * @param {NodeJS.Timeout} intervalValue
     * @return {void}
     */
    const clearTimeInterval = (intervalValue: NodeJS.Timeout): void => {
        if (intervalValue) {
            clearInterval(intervalValue)
        }
    }

    return null
}

export default JwtTokenHelper
export { JwtTokenHelper }
