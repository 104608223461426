import React from 'react'
import cx from 'classnames'

import Icon from '../Icon'
import { sanitizeStringContent } from '../../utils/sanitizeStringContent'
import { productCardVehicleClassPrefix } from './VehicleProduct.constant'
import { ProductFitmentMessageProps } from './VehicleProductCard.type'

/**
 * Function to render Fitment Message for products
 * @return {JSX.Element}
 */
const ProductFitmentMessage = ({
    customClass,
    fitmentIcon,
    fitmentMessage,
    accessibilityId,
    isStaggered = false,
}: ProductFitmentMessageProps): JSX.Element | null => {
    const vehicleFitmentContainerClass = `${productCardVehicleClassPrefix}--fitment-container`
    const staggeredFitmentClass = `${vehicleFitmentContainerClass}__staggered`
    return (
        <div className={cx(vehicleFitmentContainerClass, { [staggeredFitmentClass]: isStaggered }, customClass)}>
            <Icon type={fitmentIcon} size="sm" />
            <div
                id={`fitment__${accessibilityId}`}
                className={`${vehicleFitmentContainerClass}__fitment-message`}
                dangerouslySetInnerHTML={{ __html: sanitizeStringContent(fitmentMessage) }}></div>
        </div>
    )
}

ProductFitmentMessage.displayName = 'ProductFitmentMessage'

export default ProductFitmentMessage
