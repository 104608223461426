import { once } from 'lodash'
import { useRef } from 'react'

/**
 * This hook is necessary to execute some action only once per a component's life.
 * @param {Function} callback - The callback function to be executed once.
 * @returns {Function} Callback that can be used only once.
 */
export default function useDoOnce<T>(callback: () => T): T {
    const callbackRef = useRef<() => T>(once(callback))

    return callbackRef.current()
}
