import { createAction } from 'typesafe-actions'
import {
    SET_BUY_NOW_CART_INFO,
    SET_BUY_NOW_MODAL_AEM_PROPS,
    SET_BUY_NOW_MODAL_OPENED,
    CLEAR_BUY_NOW_CART_INFO,
    SET_BUY_NOW_INIT_CHECKOUT_INFO,
    SET_BUY_NOW_API_ERROR,
    UPDATE_BUY_NOW_CART_CTMONEY,
    UPDATE_BUY_NOW_CART_REUSABLE_BAG,
    SET_FETCHED_BUY_NOW_ACCOUNT_INFO,
    SET_FETCHING_BUY_NOW_ADD_TO_CART,
} from '../types/buyNowModal/buyNowModal.actionTypes.constant'
import { AEMPropsAndFlags, UpdateBuyNowCartReusableBagPayload } from '../models/buyNowModal.interface'
import { AddProductToCartGuestResponseDTO, FilteredCartData, CartItemsData } from '../models/cart.interface'

export const setBuyNowAEMProps = createAction(SET_BUY_NOW_MODAL_AEM_PROPS)<AEMPropsAndFlags>()
export const setBuyNowCartInfo = createAction(SET_BUY_NOW_CART_INFO)<AddProductToCartGuestResponseDTO>()
export const setBuyNowInitCheckoutInfo = createAction(SET_BUY_NOW_INIT_CHECKOUT_INFO)<FilteredCartData>()
export const clearBuyNowCartInfo = createAction(CLEAR_BUY_NOW_CART_INFO)()
export const setBuyNowModalOpened = createAction(SET_BUY_NOW_MODAL_OPENED)<boolean>()
export const setBuyNowAPIError = createAction(SET_BUY_NOW_API_ERROR)<boolean>()
export const setFetchedBuyNowAccountInfo = createAction(SET_FETCHED_BUY_NOW_ACCOUNT_INFO)<boolean>()
export const setFetchingBuyNowAddToCart = createAction(SET_FETCHING_BUY_NOW_ADD_TO_CART)<boolean>()
export const updateBuyNowCartCTMoney = createAction(UPDATE_BUY_NOW_CART_CTMONEY)<CartItemsData>()
export const updateBuyNowCartReusableBag = createAction(
    UPDATE_BUY_NOW_CART_REUSABLE_BAG,
)<UpdateBuyNowCartReusableBagPayload>()
