import { createAction } from 'typesafe-actions'

import {
    GET_ADDRESS_SUGGESTIONS_SUCCESS,
    GET_ADDRESS_SUGGESTIONS_ERROR,
    GET_SHIPPING_ADDRESS_SUCCESS,
    GET_SHIPPING_ADDRESS_FAILURE,
    GET_ERROR_SUGGESTIONS,
    ERROR_SUGGESTIONS_CLEANUP,
    SET_HPP_TOKEN,
    PLACE_ORDER_SUCCESS,
    PLACE_ORDER_FAILURE,
    CLEAR_PLACE_ORDER_FAILURE,
    SEMAFONE_ENABLED,
    REDEEM_CT_MONEY_RESPONSE,
    GET_PAYMENT_DETAILS,
    GET_PAYMENT_DETAILS_FAILURE,
    EDIT_PAYMENT_CLICKED,
    IS_GIFT_CARD_JUST_DELETED_ACTION,
    SET_INIT_PAYMENT_STATUS_ACTION,
    SET_SIGN_IN_BTN_CLICKED,
    SET_SHOULD_INVOKE_INIT_PAYMENT,
    PLACE_ORDER_REDIRECT_PARAM,
    SET_ORDER_SUMMARY_ROOT_AVAILABLE,
    SET_THIRD_PARTY_CONTAINER_AVAILABLE,
    SET_PAYMENT_FLOW_TYPE,
    SET_IS_PLACE_ORDER_FLOW_FAILED,
} from '../types/checkout/checkout.actionTypes.constant'
import {
    AddressAutoSuggestions,
    AddressSuggestions,
    InitPayment,
    PaymentDetails,
    PaymentDetailsError,
    AddressSuggestionsResponseErrorDTO,
    SemaFoneEnabledActionPayload,
    RedeemCTMoneyResponseActionPayload,
} from '../models/checkout.interface'
import { CartResponseErrorDTO, PlaceOrderDTO } from '../models/cart.interface'
import { PaymentFlowType } from '@nl/lib'

export const getSuggestionsSuccess = createAction(
    GET_ADDRESS_SUGGESTIONS_SUCCESS,
    (payload: AddressAutoSuggestions) => payload,
)<AddressAutoSuggestions>()
export const getSuggestionsFailure = createAction(
    GET_ADDRESS_SUGGESTIONS_ERROR,
    (payload: AddressSuggestionsResponseErrorDTO) => payload,
)<AddressSuggestionsResponseErrorDTO>()
export const getAddressSuggestionsSuccess = createAction(
    GET_SHIPPING_ADDRESS_SUCCESS,
    (payload: AddressSuggestions) => payload,
)<AddressSuggestions>()
export const getAddressSuggestionsFailure = createAction(
    GET_SHIPPING_ADDRESS_FAILURE,
    (payload: AddressSuggestionsResponseErrorDTO) => payload,
)<AddressSuggestionsResponseErrorDTO>()
export const getErrorSuggestionsSuccess = createAction(
    GET_ERROR_SUGGESTIONS,
    (payload: AddressSuggestions) => payload,
)<AddressSuggestions>()

export const getPaymentDetailsAction = createAction(GET_PAYMENT_DETAILS)<PaymentDetails>()
export const getPaymentDetailsFailure = createAction(GET_PAYMENT_DETAILS_FAILURE)<PaymentDetailsError>()
export const getAddressSuggestionsCleanup = createAction(ERROR_SUGGESTIONS_CLEANUP)()
export const setHppToken = createAction(SET_HPP_TOKEN)<InitPayment>()
export const placeOrderSuccess = createAction(PLACE_ORDER_SUCCESS)<PlaceOrderDTO>()
export const placeOrderFailure = createAction(PLACE_ORDER_FAILURE)<CartResponseErrorDTO>()
export const clearPlaceOrderFailure = createAction(CLEAR_PLACE_ORDER_FAILURE)()
export const semaFoneEnabled = createAction(SEMAFONE_ENABLED)<SemaFoneEnabledActionPayload>()
export const editPaymentClickedAction = createAction(EDIT_PAYMENT_CLICKED)<boolean>()
export const redeemCTMoneyResponseAction = createAction(
    REDEEM_CT_MONEY_RESPONSE,
)<RedeemCTMoneyResponseActionPayload | null>()

export const isGiftCardJustDeletedAction = createAction(IS_GIFT_CARD_JUST_DELETED_ACTION)<boolean>()
export const setInitPaymentStatusAction = createAction(
    SET_INIT_PAYMENT_STATUS_ACTION,
    (payload: boolean) => payload,
)<boolean>()
export const setSignInButtonClicked = createAction(SET_SIGN_IN_BTN_CLICKED)<boolean>()
export const setShouldInvokeInitPayment = createAction(SET_SHOULD_INVOKE_INIT_PAYMENT)<boolean>()
export const setPlaceOrderRedirectParam = createAction(PLACE_ORDER_REDIRECT_PARAM)<string>()
export const setOrderSummaryRootAvailable = createAction(SET_ORDER_SUMMARY_ROOT_AVAILABLE)<boolean>()
export const setThirdPartyContainerAvailable = createAction(
    SET_THIRD_PARTY_CONTAINER_AVAILABLE,
    (payload: boolean) => payload,
)<boolean>()
export const setPaymentFlowType = createAction(
    SET_PAYMENT_FLOW_TYPE,
    (payload: PaymentFlowType) => payload,
)<PaymentFlowType>()
export const setIsPlaceOrderFlowFailed = createAction(SET_IS_PLACE_ORDER_FLOW_FAILED)<boolean>()
