import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/user.preferences.actionCreators'
import { NotificationPreference } from '../models/user.preferences.interface'
import {
    notificationPreferenceSuccess,
    notificationPreferenceError,
    notificationUpdateSuccessAction,
    notificationUpdateErrorAction,
    resetNotificationPreferenceAction,
    resetNotificationPreferenceUpdateAction,
    swapOffersTourCompleted,
} from '../actionCreators/user.preferences.actionCreators'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface NotificationPreferenceState {
    notificationPreferenceData: NotificationPreference | null
    notificationPreferenceUpdated: boolean | null
    swapOffersTourCompleted: boolean | null
}

export const initialState: NotificationPreferenceState = {
    notificationPreferenceData: {} as unknown as NotificationPreference,
    notificationPreferenceUpdated: null,
    swapOffersTourCompleted: false,
}

/**
 * update user preference state preferences
 * @param { NotificationPreferenceState } state set to initial state by default
 * @param { Action } action action called
 * @return { NotificationPreferenceState }
 */
export const notificationPreferenceReducer = (
    state: NotificationPreferenceState = initialState,
    action: Action,
): NotificationPreferenceState => {
    switch (action.type) {
        case getType(notificationPreferenceSuccess): {
            return { ...state, notificationPreferenceData: action.payload, notificationPreferenceUpdated: null }
        }
        case getType(notificationPreferenceError): {
            return { ...state }
        }
        case getType(notificationUpdateSuccessAction): {
            return { ...state, notificationPreferenceUpdated: true }
        }
        case getType(notificationUpdateErrorAction): {
            return { ...state, notificationPreferenceUpdated: false }
        }
        case getType(resetNotificationPreferenceAction): {
            return { ...state, notificationPreferenceData: null }
        }
        case getType(resetNotificationPreferenceUpdateAction): {
            return { ...state, notificationPreferenceUpdated: null }
        }
        case getType(swapOffersTourCompleted): {
            return { ...state, swapOffersTourCompleted: action.payload }
        }
        default:
            return state
    }
}
