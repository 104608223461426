import { parse } from 'qs'
import { saveCurrentBookingFormStep } from '../redux/actionCreators'
import { StepsQueue } from '../components/AOABookingForm/AOABookingForm.constants'
import { Dispatch } from '@reduxjs/toolkit'

export enum AOAWorkflowType {
    Regular = 'Regular',
    ChangeOrCancel = 'ChangeOrCancel',
    ETire = 'eTire',
    ConfirmationEmail = 'ConfirmationEmail',
}

export const AOALinkTypes = {
    ConfirmationEmail: 'Confirmation',
    OrderPage: 'OrderPage',
} as const

export const provinceCodeRegex = /-(\w+)$/

type AOALinkType = typeof AOALinkTypes[keyof typeof AOALinkTypes]
export const AOAConfirmationEmailFlowLinkTypes = [AOALinkTypes.ConfirmationEmail, AOALinkTypes.OrderPage] as const
export const onlineOrderAppointmentsWorkflow = [AOAWorkflowType.ETire, AOAWorkflowType.ConfirmationEmail] as const

/**
 * Interface for AOAWorkFlowQueryParams
 */
export interface AOAWorkflowQueryParams {
    orderReferenceNumber?: string
    documentTokenId?: string
    storeId?: string
    aoaLinkType?: typeof AOALinkTypes[keyof typeof AOALinkTypes]
}

/**
 * getAOAWorkFlowQueryParams will fetch AOAWorkflow specific query parameters from url
 * @returns
 */
export const getAOAWorkFlowQueryParams = (): Required<AOAWorkflowQueryParams> => {
    const urlParts = window.location.href.split('?')
    const queryParams = urlParts[1] || ''

    const {
        orderId = '',
        t = '',
        branch = '',
        aoaLinkType = '',
        token = '',
    } = parse(queryParams.replace(/%20/g, ''), {
        ignoreQueryPrefix: true,
    })

    return {
        // for Order Details page token(query parameter) = orderId
        orderReferenceNumber: orderId || token,
        documentTokenId: t,
        storeId: branch,
        aoaLinkType,
    } as Required<AOAWorkflowQueryParams>
}

/**
 * Determine if the provided link type matches an AOA Confirmation Email Flow type
 * @param aoaLinkType - Link type to be checked. type link - {@link aoaLinkType}
 * @returns - Returns true if the provided link type matches an
 *                      AOA Confirmation Email Flow type, false otherwise
 */
export const isAOAConfirmationEmailFlow = (aoaLinkType: AOALinkType): boolean =>
    AOAConfirmationEmailFlowLinkTypes.includes(aoaLinkType)

export const moveToBookingLocationStep = (dispatch: Dispatch): void => {
    // redirect to location step w/o any query parameters
    const currentURL = new URL(window.location.href)
    const newUrlWithoutQueryParameters = currentURL.origin + currentURL.pathname
    history.pushState({}, '', newUrlWithoutQueryParameters)

    dispatch(saveCurrentBookingFormStep(StepsQueue.Location))
}

/**
 * A utility function that returns workflow type based on the query string parameters
 * @returns the workflow type
 */
export default function getAOAWorkflowType(): AOAWorkflowType {
    const { orderReferenceNumber, documentTokenId, storeId, aoaLinkType } = getAOAWorkFlowQueryParams()

    switch (true) {
        case Boolean(documentTokenId) && Boolean(storeId):
            return AOAWorkflowType.ChangeOrCancel
        case Boolean(orderReferenceNumber):
            return isAOAConfirmationEmailFlow(aoaLinkType) ? AOAWorkflowType.ConfirmationEmail : AOAWorkflowType.ETire
        default:
            return AOAWorkflowType.Regular
    }
}
