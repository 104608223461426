import React, { useEffect } from 'react'
import { BuyNowModalComponentProps } from './BuyNowModal.type'
import { useAppDispatch } from '../../hooks/react-redux.hook'
import { setBuyNowAEMProps } from '../../redux/actionCreators'

/**
 *
 * @param props
 */
export const BuyNowModalComponent: React.FC<BuyNowModalComponentProps> = props => {
    const dispatch = useAppDispatch()
    const { showComponent, enableBuyNowOnBopisProd } = props

    useEffect(() => {
        if (showComponent && enableBuyNowOnBopisProd) {
            dispatch(setBuyNowAEMProps({ ...props }))
        }
    }, [dispatch, props])

    return null
}
