import { ProductCard, renderClearanceBadge, ProductCardType, getAccessibilityId } from '@nl/lib'
import React from 'react'
import { RootState } from '../../../redux/reducers'
import { useAppSelector } from '../../../hooks/react-redux.hook'
import { PREFIX } from '../../../config'
import { AkamaiImagePolicies } from '../../../akamaiPolicy/akamaiPolicy.service'
import { commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import { BadgePriority, IAccessibility, IProduct } from '../../../redux/models/commonContent.interface'
import {
    ProductCardType as RecommendedProductCardType,
    RecommendationResponseDataDTO,
} from '../../../redux/models/recommendations.interface'
import { ProductImage } from '@nl/lib/src/components/ProductReusableCard/product.types'
import { ProductCardsContainerProps } from '../BoxedRecommendations.type'
import { BrandDTO } from '../../../redux/models/cart.interface'

const componentClassName = `${PREFIX}-product-cards`

/**
 * ProductCardsContainer
 * @param {props} props - ProductCardsContainerProps
 * @returns : JSX.Element | null
 */
const ProductCardsContainer: React.FC<ProductCardsContainerProps> = ({ ...props }): JSX.Element | null => {
    const { headerTitleAndCTA, enableBadges, schemaId, backgroundColor } = props

    const {
        product: commonContentProduct = {} as IProduct,
        accessibility = {} as IAccessibility,
        badges: commonContentBadges = {} as BadgePriority,
    } = useAppSelector(commonContentAvailableSelector)

    const { unitPriceLabel, promotionalPriceLabel, clearancePriceLabel, thresholdValue } = commonContentProduct

    const {
        a11yClickToReadFootnote,
        a11yTooltipIcon,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yCloseIconLabel,
    } = accessibility

    const { recommendationsData: recommendationRootState } = useAppSelector(
        (state: RootState) => state.recommendationsData,
    )
    const productCardData =
        recommendationRootState.find((productData: RecommendationResponseDataDTO) => productData.scheme === schemaId)
            ?.productData || []

    const productProps = {
        ratingsAndReview: true,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: thresholdValue,
    }

    /**
     * @param {ProductCardType} product - product data
     * @param {number} index - index of product
     * @returns JSX.Element
     */
    const productList = (product: RecommendedProductCardType, index: number): JSX.Element => {
        const imageDataComponentName = 'product-card'

        return (
            <ProductCard
                key={`product-${index}`}
                idx={index}
                cardType="grid"
                productProps={productProps}
                product={product as unknown as ProductCardType}
                title={product.title}
                brand={product.brand as BrandDTO}
                images={product.images as ProductImage[]}
                url={product.url}
                code={null}
                badges={renderClearanceBadge(product.badges || [], true)}
                badgePriorities={commonContentBadges}
                isOnSale={product.isOnSale}
                rating={product.rating}
                ratingsCount={product.ratingsCount}
                enableBadges={enableBadges}
                showSaleClearanceBadge={true}
                showBadge={true}
                hideDisclaimer={true}
                currentPrice={product.currentPrice}
                returnPolicy={AkamaiImagePolicies.returnPolicy}
                language="en"
                imageDataComponentName={imageDataComponentName}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                a11yCloseIconLabel={a11yCloseIconLabel}
                a11yTooltipIcon={a11yTooltipIcon}
                accessibilityId={getAccessibilityId(
                    product.title,
                    product?.skus?.[0]?.code ? product?.skus?.[0]?.code : product.code,
                )}
            />
        )
    }

    // return null if no product data
    if (!productCardData || productCardData.length === 0) {
        return null
    }

    return (
        <div style={{ backgroundColor: `${backgroundColor}` }} className={`${componentClassName}__wrapper`}>
            <div className={`${componentClassName}__header`}>{headerTitleAndCTA}</div>
            <div className={`${componentClassName}__items`}>
                {productCardData.map((product, index: number) => (
                    <div className={`${PREFIX}-product-card`} key={index}>
                        {productList(product, index)}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProductCardsContainer
