import { Store } from '@reduxjs/toolkit'

/**
 * @class {InjectStoreInInterceptor}
 */
class InjectStoreInInterceptor {
    store: Store

    /**
     * @constructor
     * @param {Store} store
     */
    constructor(store: Store) {
        this.store = store
    }

    /**
     * This function is used to set store value
     * @param {Store} _store
     */
    setStoreValue(_store: Store) {
        this.store = _store
    }

    /**
     * This function is used to get store value
     * @returns {Store} store
     */
    getStoreValue() {
        return this.store
    }
}

const injectStoreInInterceptor = new InjectStoreInInterceptor({} as Store)

export default injectStoreInInterceptor
