import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { PREFIX } from '../config'
import { RoadRatingBadgeProps } from './RoadRatingBadge.types'
import { magicNumber } from '../../utils/magicNumber'

/**
 * Road rating badge
 * @return {JSX.Element} returns Overlay component
 */
declare const window: Window & typeof globalThis
if (typeof window !== 'undefined') {
    window.analyticsLayer = window.analyticsLayer || []
}

export const roadRatingSkeletonTestId = 'road-rating-badge-skeleton-id'
export const roadRatingTestId = 'road-rating-badge-id'

const RoadRatingBadgeSkeleton = () => (
    <div data-testid={roadRatingSkeletonTestId} className={`${PREFIX}-road-rating-badge-skeleton`} />
)

const RoadRatingBadge: React.FC<RoadRatingBadgeProps> = ({ ...props }): JSX.Element => {
    const roadRatingBadgeClick = (): void => {
        window.analyticsLayer = window.analyticsLayer || []
        window.analyticsLayer.push({
            event: 'interaction',
            eventParameters: {
                action: 'click on internal link',
                category: 'interaction',
                label: 'road rating badge',
                value: '',
                location: 'pdp - road rating badge', // sample output: 'pdp - header'
            },
        })

        props.scrollToRoadRating()
    }

    return props.aggregateValue ? (
        <div
            data-testid={roadRatingTestId}
            ref={node => {
                props?.updateRoadRatingRef?.(node)
            }}
            aria-hidden={props.ariaHidden ? props.ariaHidden : false}
            role="link"
            tabIndex={props.ariaHidden ? magicNumber.MINUS_ONE : magicNumber.ZERO}
            className={classNames(`${PREFIX}-road-rating-badge`, `${PREFIX}-road-rating-badge-btn`, {
                [`${PREFIX}-road-rating-badge--avaialble`]: Boolean(props.isReviewAvailable),
            })}
            onClick={roadRatingBadgeClick}
            onKeyPress={roadRatingBadgeClick}>
            {`${props.roadRatedLabel} - ${props.aggregateValue}`}
        </div>
    ) : (
        <RoadRatingBadgeSkeleton />
    )
}

RoadRatingBadge.propTypes = {
    roadRatedLabel: PropTypes.string,
    aggregateValue: PropTypes.number,
    scrollToRoadRating: PropTypes.func,
    isReviewAvailable: PropTypes.bool,
}

RoadRatingBadge.displayName = 'RoadRatingBadge'
RoadRatingBadgeSkeleton.displayName = 'RoadRatingBadgeSkeleton'

export default RoadRatingBadge
