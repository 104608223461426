import { Dispatch } from '@reduxjs/toolkit'

import {
    deletePaymentInfoAction,
    deletePaymentInfoErrorAction,
    fetchPaymentErrorAction,
    fetchPaymentSuccessAction,
    resetPaymentAction,
} from '../actionCreators/profile.payment.actionCreators'
import {
    DeletePaymentInfo,
    DeletePaymentSuccess,
    PaymentInfoType,
    PaymentUnitType,
} from '../models/profile.payment.interface'
import { ErrorResponse } from '../models/loyaltyRegisterForm.interface'
import { profilePaymentService } from '../../services/profilePaymentService'
import { CardType } from '@nl/lib/'
import { MagicNumber } from '../../analytics/analytics.type'
import appCacheService from '../../utils/appCacheService'
import { AxiosError, AxiosResponse } from 'axios'

/**
 * Fetches user's profile payment information
 * @return {Promise<void>} returns api response promise
 */
export const fetchProfilePayment =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        return profilePaymentService
            .fetchProfilePaymentData()
            .then((data: AxiosResponse<PaymentInfoType>) => {
                appCacheService.ctfsMember.set(
                    JSON.stringify(
                        data.data.paymentInfos?.findIndex(
                            (paymentInfo: PaymentUnitType) =>
                                paymentInfo.type?.toUpperCase() === CardType.triangle.toUpperCase(),
                        ) !== MagicNumber.MINUS_ONE,
                    ),
                )

                dispatch(fetchPaymentSuccessAction(data.data?.paymentInfos))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(fetchPaymentErrorAction(error?.response as unknown as ErrorResponse))
            })
    }

export const resetProfilePayment =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetPaymentAction())
    }

/**
 * Deletes saved payment info of user
 * @param {DeletePaymentInfo} payload
 * @return {Promise<void>} returns api response promise
 */
export const deletePaymentInfoFunc =
    (payload: DeletePaymentInfo) =>
    (dispatch: Dispatch): Promise<void> => {
        return profilePaymentService
            .deleteProfilePaymentData(payload.maskedCardNumber)
            .then(data => {
                dispatch(deletePaymentInfoAction(data.data as DeletePaymentSuccess))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(deletePaymentInfoErrorAction(error?.response as unknown as ErrorResponse))
            })
    }
