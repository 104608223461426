import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { VariantsProps } from './Variants.type'
import { FilteredVariantType, Variant, VariantType } from '../BuyBox/Buybox.type'
import { getSkuArray } from '../../helpers/getSkuArray.helper'
import Button from '../Button'
import Icon from '../Icon'
import { variantsConstant } from './Variants.constant'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { magicNumber, replaceStrWithDynamicVal, stringKeyCodes, updateUrlHistory, useOnClickOutside } from '../../utils'
import usePrevious from '../../hooks/usePrevious.hook'
import { areAllParamsValid } from '../../utils/validParams'
import {
    arrowActionIdentifier,
    handleArrowsAccessibility,
    handleClickOrEnterAccessibility,
    outsideActionHandler,
    slectionTypeChecked,
} from '../../helpers/keyboardAccessibility.helper'
import { useOnKeyDownOutside } from '../../utils/useOnKeyDownOutside'
import { ProductSkusData } from '../../types'

const {
    maxHeight,
    variantLabel,
    firstVariantName,
    secondVariantName,
    thirdVariantName,
    hideVariantClass,
    showVariantClass,
    colorCodeParam,
} = variantsConstant

const listItems = (
    variantsData: FilteredVariantType,
    variantData: VariantType[],
    setSelectedFirstVariantValue: (variant: string, variantDisplayName?: string) => void,
    firstVariantStyleItem: string,
    setSelectedFirstVariantIdValue: (id: string) => void,
    setSelectedSecondVariantValue: (variant: string) => void,
    setSelectedSecondVariantIdValue: (id: string) => void,
    setSelectedThirdVariantValue: (variant: string) => void,
    setSelectedThirdVariantIdValue: (id: string) => void,
    variantKey: string,
    variantValue: string,
    variantId: string,
    isAddToCartClicked: boolean,
    isNoSkuFindStoreClicked: boolean,
    variantRef: number,
    setIsFirstVariantClicked: (isClicked: boolean) => void,
    variantClickHandler: () => void,
    isColourSwatchesActive: boolean,
    variantsProductSkuData: ProductSkusData,
    checkOutOfStock: (item: VariantType, variantKey: string) => boolean,
    enableHidingFirstVariantWhereAllSkusOOS: boolean,
    selectedProductCode: string,
    isAutomotive: boolean,
    inStockLabel: string,
    outOfStockLabel: string,
    a11yVariantsSelectedCount: string,
    checkIfAllVariantsSelected: boolean,
    variantsListRef: React.RefObject<HTMLUListElement>,
    enableOnlyAvailableOnline?: boolean,
    availableOnlinePurchaseLabel?: string,
    onlyAvailableOnlineAtLabel?: string,
    preferredStoreDetails?: string,
    noUrlUpdates?: boolean,
    limitedStockMessage?: string,
    isLimitedStock?: boolean,
    setSelectedVariantByUserClick?: (isSelected: boolean) => void,
    // eslint-disable-next-line max-params, sonar/function-return-type
) => {
    //  Function to set selected variant value
    /**
     * Function to set selected variant value
     * @param {VariantType} selectedItem
     */
    const setVariant = (selectedItem: VariantType): void => {
        variantClickHandler()
        setSelectedVariantByUserClick && setSelectedVariantByUserClick(true)
        if (!firstVariantStyleItem) {
            switch (variantKey) {
                case firstVariantName:
                    setSelectedFirstVariantValue(selectedItem.name, selectedItem.displayNameForVariants)
                    setSelectedFirstVariantIdValue(selectedItem.id)
                    setIsFirstVariantClicked(true)
                    setSelectedColorCodeToUrl(selectedItem.id)
                    break
                case secondVariantName:
                    setSelectedSecondVariantValue(selectedItem.name)
                    setSelectedSecondVariantIdValue(selectedItem.id)
                    setIsFirstVariantClicked(false)
                    break
                default:
                    setSelectedThirdVariantValue(selectedItem.name)
                    setSelectedThirdVariantIdValue(selectedItem.id)
                    setIsFirstVariantClicked(false)
            }
        }
    }

    /**
     * Function to set selected Color to Url
     * @param {string} selectedColor
     */
    const setSelectedColorCodeToUrl = (selectedColor: string): void => {
        const urlSearchParams = new URLSearchParams(window.location.search)

        if (!noUrlUpdates) {
            urlSearchParams.set(colorCodeParam, selectedColor)
            updateUrlHistory(`?${decodeURIComponent(urlSearchParams.toString())}`)
        }
    }

    /**
     * Function to get variant show/hide class
     * @param {boolean} isUnavailableVariant
     * @return {string}
     */
    const getVariantClass = (isUnavailableVariant: boolean): string => {
        if (isUnavailableVariant) {
            if (variantRef === magicNumber.ONE && variantKey === firstVariantName) {
                return showVariantClass
            } else {
                return hideVariantClass
            }
        } else {
            return showVariantClass
        }
    }

    /**
     * This function will return true if product is Automotive and selectedProductCode present in item's skuCodes or product is not a Automotive and variantValue is same as item's name
     * @param {VariantType} item
     * @return {boolean}
     */
    const isPillsSelected = (item: VariantType): boolean => {
        return isAutomotive ? item?.skuCodes?.includes(selectedProductCode) : item?.id === variantId
    }

    const isWarningVariant = Boolean((isAddToCartClicked || isNoSkuFindStoreClicked) && !variantValue)

    /**
     * Function to get variant assigned class selected/unselected
     * @param {VariantType} item
     * @return {string}
     */
    const getVariantAssignedClass = (item: VariantType): string => {
        const isColorSwathesStylesNeeded = isColourSwatchesActive && !!item.colourSwatchImageUrl
        const colourSwathesClass = isColorSwathesStylesNeeded ? `${PREFIX}-variants__variant--colour-swatches` : ''
        const outOfStockClass =
            item.isUnavailableDueToStock || (variantsProductSkuData?.skus && checkOutOfStock(item, variantKey))
                ? `${PREFIX}-variants__disabled`
                : ''
        const defaultClass = `${
            firstVariantStyleItem ? firstVariantStyleItem : `${PREFIX}-variants__variant ${colourSwathesClass}`
        } ${outOfStockClass}`
        const warningClass = isWarningVariant ? `${PREFIX}-variants__variant--warning` : ''

        return isPillsSelected(item)
            ? `${defaultClass} ${PREFIX}-variants__variant--selected`
            : `${defaultClass} ${PREFIX}-variants__variant--unselected ${warningClass}`
    }

    /**
     * Function to get aria label
     * @param {VariantType} variantObj
     * @return {string}
     */

    const getAriaLabel = (variantObj: VariantType): string => {
        const variantsSelectedCount = a11yVariantsSelectedCount
        const variantsLength =
            variantKey === firstVariantName
                ? variantData?.length
                : variantData?.filter(variantInfo => !variantInfo.isUnavailable).length
        const inStockValue = variantsProductSkuData?.skus?.find(variant => variant.code === selectedProductCode)
            ?.fulfillment?.availability?.quantity

        const storeShelfLoc = variantsProductSkuData?.skus?.find(variant => variant.code === selectedProductCode)
            ?.fulfillment?.availability?.storeShelfLocation

        const getStoreShelfLoc = typeof storeShelfLoc === 'string' ? `- ${storeShelfLoc}` : ''

        const corporateQuantityValue = variantsProductSkuData?.skus?.find(
            variant => variant.code === selectedProductCode,
        )?.fulfillment?.availability?.Corporate?.Quantity

        const quantityValue =
            // eslint-disable-next-line sonar/expression-complexity
            inStockValue > magicNumber.ZERO
                ? // eslint-disable-next-line sonar/no-nested-conditional
                  isLimitedStock
                    ? ` ${replaceStrWithDynamicVal(limitedStockMessage, inStockValue)} ${getStoreShelfLoc}`
                    : ` ${inStockValue} ${inStockLabel} ${getStoreShelfLoc}`
                : // eslint-disable-next-line sonar/no-nested-conditional
                corporateQuantityValue > magicNumber.ZERO
                ? // eslint-disable-next-line sonar/no-nested-conditional
                  enableOnlyAvailableOnline
                    ? ` ${onlyAvailableOnlineAtLabel} ${preferredStoreDetails} `
                    : availableOnlinePurchaseLabel
                : ` ${outOfStockLabel}`

        const quantityMsg = checkOutOfStock(variantObj, variantKey) ? `${outOfStockLabel}` : `${inStockLabel}`

        return isVariantSelected(variantObj)
            ? // eslint-disable-next-line sonar/no-nested-conditional
              checkIfAllVariantsSelected
                ? `${variantsData?.display}: ${variantObj?.name} ${replaceStrWithDynamicVal(
                      variantsSelectedCount,
                      variantsLength,
                  )}`.concat(quantityValue)
                : `${variantsData?.display}: ${variantObj?.name} ${replaceStrWithDynamicVal(
                      variantsSelectedCount,
                      variantsLength,
                  )}`
            : `${variantObj?.name} `.concat(quantityMsg)
    }

    /**
     * This function handles click or enter event for tab
     * @param { React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { VariantType } variant
     */
    const handleClickOrEnter = (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        variant: VariantType,
    ) => {
        handleClickOrEnterAccessibility(event, currentIndex, variantsListRef.current, slectionTypeChecked)
        setVariant(variant)
    }

    /**
     * This function handles keydown event for variants (radio group)
     * @param { React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { VariantType } variant
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, currentIndex: number, variant: VariantType) => {
        if (event.key === stringKeyCodes.rightArrow) {
            handleArrowsAccessibility(event, arrowActionIdentifier.NEXT, currentIndex, variantsListRef.current)
        } else if (event.key === stringKeyCodes.leftArrow) {
            handleArrowsAccessibility(event, arrowActionIdentifier.PREVIOUS, currentIndex, variantsListRef.current)
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.space) {
            handleClickOrEnter(event, currentIndex, variant)
        }
    }

    /**
     * This function returns color variant image
     * @param { boolean } isColorSwathesStylesNeeded
     * @param { VariantType } variantObj
     * @return { JSX.Element }
     */
    const returnColorVariantImage = (isColorSwathesStylesNeeded: boolean, variantObj: VariantType): JSX.Element => {
        return isColorSwathesStylesNeeded ? <img src={variantObj.colourSwatchImageUrl} alt={variantObj.name} /> : null
    }

    /**
     * This function returns color variant span
     * @param { boolean } isColorSwathesStylesNeeded
     * @param { VariantType } variantObj
     * @return { JSX.Element }
     */
    const returnColorVariantSpan = (isColorSwathesStylesNeeded: boolean, variantObj: VariantType): JSX.Element => {
        return isColorSwathesStylesNeeded ? null : (
            <span className={`${PREFIX}-variants__variant-text`}>
                {isAutomotive ? variantObj.displayNameForVariants : variantObj.name}
            </span>
        )
    }

    /**
     * @param { VariantType } variantObj
     * @return { boolean }
     */
    const isFirstVariantOOS = (variantObj: VariantType) =>
        areAllParamsValid(
            secondVariantName in variantObj,
            checkOutOfStock(variantObj, variantKey),
            enableHidingFirstVariantWhereAllSkusOOS,
        )

    /**
     * @param { VariantType } variantObj
     * @return { boolean }
     */
    const isVariantSelected = (variantObj: VariantType) => variantObj?.id === variantId

    const filteredVariantData = variantData.filter((variantObj: VariantType) => {
        if (isFirstVariantOOS(variantObj) && !isVariantSelected(variantObj)) {
            return false
        }
        return getVariantClass(variantObj.isUnavailable) === 'show'
    })

    return (
        isArrayNotEmpty(variantData) &&
        variantData.map((variantObj: VariantType, index: number) => {
            const currentIndex = filteredVariantData.indexOf(variantObj)
            const isColorSwathesStylesNeeded = isColourSwatchesActive && !!variantObj.colourSwatchImageUrl
            const disabledColourSwathes = () => isColorSwathesStylesNeeded && checkOutOfStock(variantObj, variantKey)

            if (isFirstVariantOOS(variantObj)) {
                if (!isVariantSelected(variantObj)) {
                    return null
                }
                const firstAvailableVariant = variantData?.find(
                    (variant: VariantType) => !checkOutOfStock(variant, variantKey),
                )
                setSelectedFirstVariantValue(firstAvailableVariant?.name, firstAvailableVariant?.displayNameForVariants)
                setSelectedFirstVariantIdValue(firstAvailableVariant?.id)
            }

            return (
                <li
                    key={index}
                    className={
                        firstVariantStyleItem
                            ? `${PREFIX}-variants__color-list--list`
                            : `${PREFIX}-variants__variant-item ${getVariantClass(variantObj.isUnavailable)}`
                    }
                    role="presentation">
                    {disabledColourSwathes() && <div className="colour-swathes--disable" />}
                    <div
                        className={getVariantAssignedClass(variantObj)}
                        aria-label={getAriaLabel(variantObj)}
                        data-testid={`${variantLabel}${index}`}
                        data-accessibility={currentIndex > magicNumber.MINUS_ONE ? 'true' : 'false'}
                        role={currentIndex > magicNumber.MINUS_ONE ? 'radio' : ''}
                        aria-checked={isPillsSelected(variantObj) ? 'true' : 'false'}
                        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                            handleKeyDown(event, currentIndex, variantObj)
                        }}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            handleClickOrEnter(event, currentIndex, variantObj)
                        }}>
                        {returnColorVariantImage(isColorSwathesStylesNeeded, variantObj)}
                        {firstVariantStyleItem && (
                            <img
                                className={`${PREFIX}-variants__variant-image`}
                                alt="ColourVariant"
                                src={variantObj.name}
                            />
                        )}
                        {returnColorVariantSpan(isColorSwathesStylesNeeded, variantObj)}
                    </div>
                </li>
            )
        })
    )
}

/**
 * Variant component
 * @param {VariantsProps} props -colourVariants,
 *      selectedFirstVariant,
 *      setSelectedFirstVariantValue,
 *      firstVariantStyle,
 *      firstVariantStyleItem,
 * @return {JSX.Element} returns Variant Component
 */
const Variants: React.FC<VariantsProps> = props => {
    const {
        checkIfAllVariantsSelected,
        variantsData,
        selectedFirstVariant,
        selectedFirstVariantDisplayName,
        setSelectedFirstVariantValue,
        firstVariantStyle,
        firstVariantStyleItem,
        setSelectedFirstVariantIdValue,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        variantOptions,
        path,
        viewMoreLabel,
        viewLessLabel,
        setSelectedSecondVariantValue,
        setSelectedSecondVariantIdValue,
        setSelectedThirdVariantValue,
        setSelectedThirdVariantIdValue,
        setIsVariantSelected,
        variantKey,
        variantDetails,
        selectedSecondVariant,
        selectedThirdVariant,
        isAddToCartClicked,
        isNoSkuFindStoreClicked,
        isFirstVariantClicked,
        setIsFirstVariantClicked,
        variantClickHandler,
        isColourSwatchesActive,
        setShowSizeChart,
        showSizeChart,
        isSizeChartAvailable,
        sizeChartButtonTitle,
        isOnlyOneVariant,
        variantsProductSkuData,
        checkOutOfStock,
        enableHidingFirstVariantWhereAllSkusOOS,
        selectedProductCode,
        isAutomotive,
        a11yVariantsSelectedCount,
        inStockLabel,
        outOfStockLabel,
        enableOnlyAvailableOnline,
        availableOnlinePurchaseLabel,
        onlyAvailableOnlineAtLabel,
        preferredStoreDetails,
        limitedStockMessage,
        noUrlUpdates,
        isLimitedStock,
        setSelectedVariantByUserClick,
    } = props

    const [showViewMore, setShowViewMore] = useState(false)
    const [showViewMoreOrLess, setShowViewMoreOrLess] = useState(true)
    const variantData = useRef<VariantType[]>(variantsData?.values)
    const variantValue = useRef<string>('')
    const variantDisplayName = useRef<string>('')
    const variantId = useRef<string>('')
    const variantsListRef = useRef<HTMLUListElement>(null)

    const viewMoreVariantClass = !showViewMore
        ? `${PREFIX}-variants__variant-list--expanded`
        : `${PREFIX}-variants__variant-list--collapsed`
    const variantEle = document.querySelector(`.${PREFIX}-variants__${variantKey}`)
    const selectedElemt = document.querySelector(
        `.${PREFIX}-variants__${variantKey} .${PREFIX}-variants__variant--selected`,
    )
    const variantRef = useRef(0)

    const { firstVariant, secondVariant, thirdVariant } = variantDetails || {}

    const previousSelectedFirstVariantId = usePrevious(selectedFirstVariantId)
    const previousSelectedSecondVariantId = usePrevious(selectedSecondVariantId)
    const previousSelectedThirdVariantId = usePrevious(selectedThirdVariantId)
    const previousShowViewMore = usePrevious(showViewMore)
    const previousIsFirstVariantClicked = usePrevious(isFirstVariantClicked)
    const previousSelectedFirstVariant = usePrevious(selectedFirstVariant)

    // useEffect to set view more/less section state
    useEffect(() => {
        if (variantEle instanceof HTMLElement) {
            setShowViewMoreOrLess(variantEle && variantEle.scrollHeight > maxHeight)
            const variantEleTop = variantEle?.getBoundingClientRect().top || 0
            const selectedElemtTop = selectedElemt?.getBoundingClientRect().top || 0
            selectedElemtTop > maxHeight + variantEleTop ? setShowViewMore(false) : setShowViewMore(true)
        }
    }, [setShowViewMoreOrLess, setShowViewMore, variantEle, variantData.current, selectedElemt, variantsProductSkuData])

    /**
     * Function to get first variant values
     * @return {VariantType} returns first variant values
     */
    const getVariantData = useCallback((): VariantType => {
        return (
            isArrayNotEmpty(variantData.current) &&
            firstVariant?.values?.find(item => item?.id === selectedFirstVariantId)
        )
    }, [firstVariant, selectedFirstVariantId, variantData.current])

    const setFilteredFirstData = useCallback(() => {
        const getSelectedFirstVariant: VariantType = getVariantData()
        switch (variantKey) {
            case secondVariantName:
                getSelectedFirstVariant &&
                    secondVariant &&
                    (variantData.current = [...getSelectedFirstVariant.secondVariant])
                break
            case thirdVariantName:
                getSelectedFirstVariant &&
                    thirdVariant &&
                    (variantData.current = [...getSelectedFirstVariant.thirdVariant])
                break
            default:
        }
    }, [getVariantData, secondVariant, thirdVariant, variantKey])

    /**
     * useEffect used to set the filtered first variant data
     */
    useEffect(() => {
        if (
            (selectedFirstVariant !== previousSelectedFirstVariant ||
                isFirstVariantClicked !== previousIsFirstVariantClicked) &&
            (variantRef.current === magicNumber.ZERO || isFirstVariantClicked)
        ) {
            setFilteredFirstData()
        }
    }, [
        isFirstVariantClicked,
        previousIsFirstVariantClicked,
        previousSelectedFirstVariant,
        selectedFirstVariant,
        setFilteredFirstData,
    ])

    // useEffect to set initial scroll position
    useEffect(() => {
        // eslint-disable-next-line sonar/expression-complexity
        showViewMore &&
            showViewMore !== previousShowViewMore &&
            variantEle &&
            variantEle.scrollTo &&
            variantEle.scrollTo({
                top: 0,
            })
    }, [showViewMore, previousShowViewMore, variantEle, variantData.current])

    /**
     * Function to set the selected variant value
     */
    const getSelectedVariantValue = useCallback((): void => {
        switch (variantKey) {
            case firstVariantName:
                variantValue.current = selectedFirstVariant
                variantDisplayName.current = selectedFirstVariantDisplayName
                variantId.current = selectedFirstVariantId
                break
            case secondVariantName:
                variantValue.current = selectedSecondVariant
                variantId.current = selectedSecondVariantId
                break
            default:
                variantValue.current = selectedThirdVariant
                variantId.current = selectedThirdVariantId
        }
    }, [
        selectedFirstVariant,
        selectedSecondVariant,
        selectedThirdVariant,
        variantKey,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        selectedFirstVariantDisplayName,
    ])

    const getAvailableVariantOption = useCallback(() => {
        const variantArr = [selectedFirstVariantId]
        selectedSecondVariantId && variantArr.push(selectedSecondVariantId)
        selectedThirdVariantId && variantArr.push(selectedThirdVariantId)
        const getSkuArr = getSkuArray(variantArr, variantOptions)
        if (variantKey === firstVariantName) {
            const getFirstVatiants = variantData.current.map((firstVariantValue: VariantType) => {
                return {
                    ...firstVariantValue,
                    isUnavailable: !firstVariantValue.skuCodes.some((sku: string) => getSkuArr.includes(sku)),
                }
            })
            getFirstVatiants && (variantData.current = getFirstVatiants)
        }
        variantRef.current = magicNumber.ONE
    }, [
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        variantData.current,
        variantKey,
        variantOptions,
    ])

    /**
     * useEffect used to get the available variant option on selected color & size or dynamic variant
     */
    useEffect(() => {
        if (
            // eslint-disable-next-line sonar/expression-complexity
            variantData.current &&
            selectedFirstVariantId &&
            (selectedSecondVariantId || selectedThirdVariantId) &&
            (selectedFirstVariantId !== previousSelectedFirstVariantId ||
                selectedSecondVariantId !== previousSelectedSecondVariantId ||
                selectedThirdVariantId !== previousSelectedThirdVariantId)
        ) {
            getAvailableVariantOption()
        }
        getSelectedVariantValue()
    }, [
        getAvailableVariantOption,
        getSelectedVariantValue,
        previousSelectedFirstVariantId,
        previousSelectedSecondVariantId,
        previousSelectedThirdVariantId,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        variantData.current,
    ])

    /**
     * useEffect used to get the available variant option on selected color & dynamic variant
     */
    useEffect(() => {
        if (
            // eslint-disable-next-line sonar/expression-complexity
            variantData.current &&
            selectedFirstVariantId &&
            variantKey === secondVariantName &&
            (selectedFirstVariantId !== previousSelectedFirstVariantId ||
                selectedThirdVariantId !== previousSelectedThirdVariantId)
        ) {
            const variantArr = [selectedFirstVariantId, selectedThirdVariantId]
            const getSkuArr = getSkuArray(variantArr, variantOptions)
            const getSizeVariants = variantData.current.map((size: Variant) => {
                return {
                    ...size,
                    isUnavailable: !size.skuCodes.some((sku: string) => getSkuArr.includes(sku)),
                }
            })

            const isAvailable = Boolean(getSizeVariants.find((item: Variant) => !item.isUnavailable))

            if (isArrayNotEmpty(getSizeVariants) && !isAvailable) {
                setIsVariantSelected(true)
                setSelectedSecondVariantIdValue('')
                setSelectedSecondVariantValue('')
            } else {
                setIsVariantSelected(false)
            }
            getSizeVariants && (variantData.current = getSizeVariants)
        }
    }, [
        selectedFirstVariantId,
        selectedThirdVariantId,
        previousSelectedFirstVariantId,
        previousSelectedThirdVariantId,
        setIsVariantSelected,
        setSelectedSecondVariantIdValue,
        setSelectedSecondVariantValue,
        variantData.current,
        variantKey,
        variantOptions,
    ])

    /**
     * useEffect used to get the available variant option on selected color & size variant
     */
    useEffect(() => {
        if (
            // eslint-disable-next-line sonar/expression-complexity
            variantData.current &&
            selectedFirstVariantId &&
            selectedSecondVariantId &&
            variantKey === thirdVariantName &&
            (selectedFirstVariantId !== previousSelectedFirstVariantId ||
                selectedSecondVariantId !== previousSelectedSecondVariantId)
        ) {
            const variantArr = [selectedFirstVariantId, selectedSecondVariantId]
            const getSkuArr = getSkuArray(variantArr, variantOptions)
            const getDynamicVatiants = variantData.current.map((dynamicItem: Variant) => {
                return {
                    ...dynamicItem,
                    isUnavailable: !dynamicItem.skuCodes.some((sku: string) => getSkuArr.includes(sku)),
                }
            })

            const isAvailable = Boolean(getDynamicVatiants.find((item: Variant) => !item.isUnavailable))

            if (isArrayNotEmpty(getDynamicVatiants) && !isAvailable) {
                setIsVariantSelected(true)
                setSelectedThirdVariantIdValue('')
                setSelectedThirdVariantValue('')
            } else {
                setIsVariantSelected(false)
            }
            getDynamicVatiants && (variantData.current = getDynamicVatiants)
        }
    }, [
        selectedFirstVariantId,
        selectedSecondVariantId,
        previousSelectedFirstVariantId,
        previousSelectedSecondVariantId,
        setIsVariantSelected,
        setSelectedThirdVariantIdValue,
        setSelectedThirdVariantValue,
        variantData.current,
        variantKey,
        variantOptions,
    ])

    /**
     * Function to get variant label text
     * @return {JSX.Element} returns variant label text
     */
    const getVariantLabel = (): JSX.Element => {
        const isSizeChartButtonVisible = isOnlyOneVariant
            ? variantKey === firstVariantName
            : variantKey === secondVariantName
        const sizeChart =
            isSizeChartButtonVisible && isSizeChartAvailable ? (
                <button onClick={() => setShowSizeChart(!showSizeChart)} className={`${PREFIX}-variants__size-chart`}>
                    {sizeChartButtonTitle}
                </button>
            ) : null
        return (
            !firstVariantStyle && (
                <div className={`${PREFIX}-variants__label`}>
                    <div className={`${PREFIX}-variants__label__position`}>
                        <label className={`${PREFIX}-h4--sm`} id={`variant-type-label-${variantKey}`}>
                            {variantsData?.display}:
                        </label>
                        <p className={`${PREFIX}-body-md ${PREFIX}-variants__label__text`}>
                            {isAutomotive ? variantDisplayName.current : variantValue.current}
                        </p>
                    </div>
                    {sizeChart}
                </div>
            )
        )
    }

    /**
     * Function to get variant warning message
     * @param {string} variantName
     * @return {JSX.Element} returns variant warning message
     */
    const showVariantWarningMessage = (variantName: string): JSX.Element => {
        return (
            (isAddToCartClicked || isNoSkuFindStoreClicked) &&
            !variantValue.current && (
                <div className={`${PREFIX}-variants__warning`}>
                    <Icon type="ct-warning" size="lg"></Icon>
                    {variantName && (
                        <p className={`${PREFIX}-body-sm ${PREFIX}-variants__warning__text`}>
                            {replaceStrWithDynamicVal(props.variantWarningLabel, variantName)}
                        </p>
                    )}
                </div>
            )
        )
    }

    /**
     * useEffect to reset selected second and third options if not available
     */
    useEffect(() => {
        if (variantKey === firstVariantName || selectedSecondVariant === '') {
            return
        }
        const selectedVariant = variantData.current?.find(item => item?.id === variantId.current)
        if (selectedVariant?.isUnavailable) {
            if (variantKey === thirdVariantName && selectedThirdVariant !== '') {
                setSelectedThirdVariantIdValue('')
                setSelectedThirdVariantValue('')
            } else {
                setSelectedSecondVariantIdValue('')
                setSelectedSecondVariantValue('')
            }
            setFilteredFirstData()
        }
    }, [
        setSelectedSecondVariantIdValue,
        setSelectedSecondVariantValue,
        setSelectedThirdVariantIdValue,
        setSelectedThirdVariantValue,
        variantData.current,
        variantDetails,
        variantKey,
        variantId.current,
        setFilteredFirstData,
    ])

    /**
     * This function is dedicated for variants outside action handler
     * set tabindex to "0" for selected variant when user clicks or move outside of variant radio group
     */
    const variantsOutsideActionHandler = useCallback(() => {
        outsideActionHandler(variantsListRef.current, slectionTypeChecked)
    }, [])
    useOnClickOutside(variantsListRef, variantsOutsideActionHandler)
    useOnKeyDownOutside(variantsListRef, variantsOutsideActionHandler)

    useEffect(() => {
        if (variantsListRef.current) {
            outsideActionHandler(variantsListRef.current, slectionTypeChecked)
        }
    })

    return (
        <div className={`${PREFIX}-col-md-12 ${PREFIX}-col-sm-9 ${PREFIX}-col-xs-6`}>
            {isArrayNotEmpty(variantData.current) ? (
                <>
                    {getVariantLabel()}
                    {showVariantWarningMessage(variantsData?.display)}
                    <ul
                        aria-labelledby={`variant-type-label-${variantKey}`}
                        className={
                            firstVariantStyle
                                ? firstVariantStyle
                                : `${PREFIX}-variants__variant-list ${viewMoreVariantClass} ${PREFIX}-variants__${variantKey}`
                        }
                        role="radiogroup"
                        ref={variantsListRef}>
                        {listItems(
                            variantsData,
                            variantData.current,
                            setSelectedFirstVariantValue,
                            firstVariantStyleItem,
                            setSelectedFirstVariantIdValue,
                            setSelectedSecondVariantValue,
                            setSelectedSecondVariantIdValue,
                            setSelectedThirdVariantValue,
                            setSelectedThirdVariantIdValue,
                            variantKey,
                            variantValue.current,
                            variantId.current,
                            isAddToCartClicked,
                            isNoSkuFindStoreClicked,
                            variantRef.current,
                            setIsFirstVariantClicked,
                            variantClickHandler,
                            isColourSwatchesActive,
                            variantsProductSkuData,
                            checkOutOfStock,
                            enableHidingFirstVariantWhereAllSkusOOS,
                            selectedProductCode,
                            isAutomotive,
                            inStockLabel,
                            outOfStockLabel,
                            a11yVariantsSelectedCount,
                            checkIfAllVariantsSelected,
                            variantsListRef,
                            enableOnlyAvailableOnline,
                            availableOnlinePurchaseLabel,
                            onlyAvailableOnlineAtLabel,
                            preferredStoreDetails,
                            noUrlUpdates,
                            limitedStockMessage,
                            isLimitedStock,
                            setSelectedVariantByUserClick,
                        )}
                    </ul>
                    {showViewMoreOrLess && (
                        <div className={`${PREFIX}-variants__view-more`}>
                            <Button type="tertiary" size="mini" onClick={() => setShowViewMore(!showViewMore)}>
                                {showViewMore ? viewMoreLabel : viewLessLabel}{' '}
                                <Icon type={showViewMore ? 'ct-chevron-down' : 'ct-chevron-up'} size="md" path={path} />
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                ''
            )}
        </div>
    )
}

Variants.propTypes = {
    enableOnlyAvailableOnline: PropTypes.bool,
    availableOnlinePurchaseLabel: PropTypes.string,
    onlyAvailableOnlineAtLabel: PropTypes.string,
    preferredStoreDetails: PropTypes.string,
    limitedStockMessage: PropTypes.string,
    isLimitedStock: PropTypes.bool,
    checkIfAllVariantsSelected: PropTypes.bool,
    a11yVariantsSelectedCount: PropTypes.string,
    inStockLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
    variantsData: PropTypes.any,
    selectedFirstVariant: PropTypes.string,
    selectedFirstVariantDisplayName: PropTypes.string,
    setSelectedFirstVariantValue: PropTypes.func,
    firstVariantStyle: PropTypes.string,
    firstVariantStyleItem: PropTypes.string,
    selectedSecondVariant: PropTypes.string,
    selectedThirdVariant: PropTypes.string,
    setSelectedFirstVariantIdValue: PropTypes.func,
    selectedFirstVariantId: PropTypes.string,
    selectedSecondVariantId: PropTypes.string,
    selectedThirdVariantId: PropTypes.string,
    variantOptions: PropTypes.array,
    path: PropTypes.string,
    viewMoreLabel: PropTypes.string,
    viewLessLabel: PropTypes.string,
    setSelectedSecondVariantValue: PropTypes.func,
    setSelectedSecondVariantIdValue: PropTypes.func,
    setSelectedThirdVariantValue: PropTypes.func,
    setSelectedThirdVariantIdValue: PropTypes.func,
    variantKey: PropTypes.string,
    variantDetails: PropTypes.any,
    setIsVariantSelected: PropTypes.func,
    variantWarningLabel: PropTypes.string,
    isAddToCartClicked: PropTypes.bool,
    isFirstVariantClicked: PropTypes.bool,
    setIsFirstVariantClicked: PropTypes.func,
    enableHidingFirstVariantWhereAllSkusOOS: PropTypes.bool,
    isAutomotive: PropTypes.bool,
}

Variants.displayName = 'VariantsInLib'

export default Variants
