import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Icon from '../Icon'
import Switch from '../Switch'
import Heading from '../Heading'
import ButtonV2, { SpinnerButton } from '../ButtonV2'
import { Variation, Size, WishlistProduct } from '../../types'
import { ButtonColors } from '../ButtonV2/Button.types'
import { replaceStrWithDynamicVal } from '../../utils'
import { areAllParamsValid } from '../../utils/validParams'
import ProductCard from '../ProductReusableCard'
import { ProductCardType } from '../ProductGridView/ProductGrid.types'
import { AddAllToWishListProductInterface, CompareProductsProps } from './CompareProductsFlyout.type'
import { getAccessibilityId } from '../../utils/getAccessibilityId'

import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import { isArrayEmpty } from '../../utils/isArrayEmpty'
import { PRODUCT_CARD_COUNT } from '../ProductReusableCard/productCard.constant'
import RenderFullModal from './RenderFullModal'

/**
 * CompareProductsFlyout component
 * @param {CompareProductsProps} props
 * @returns {JSX.Element} returns CommonWarningToast component
 */
const CompareProductsFlyout: React.FC<CompareProductsProps> = ({ ...props }): JSX.Element => {
    const {
        compareProductList,
        returnPolicy,
        productCardClick,
        removeCompareProductCard,
        compareProductCardClearAllBtn,
        compareBtn,
        isHalfModalOpen,
        setIsHalfModalOpen,
        isFullModalOpen,
        setIsFullModalOpen,
        compareProductsL,
        clearAllLabel,
        compareL,
        selectItemsToCompareL,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        closeLabel,
        language,
        badgePriority,
        emptyCardClick,
        addProductItemToWishlist,
        addToCartBtnClick,
        wishlistItems,
        compareProductsSpecificationList,
        showAtcBtnSpinner,
        clickedATCCardIdx,
        showWishlistBtnSpinner,
        clickedWishlistIdx,
        showDifferencesData,
        isShowDifferenceToggled,
        viewMoreSpecsL,
        viewFewSpecsL,
        addItemstoCompareL,
        showDiffL,
        addAllToWishListL,
        specificationLabel,
        compareBtnSpinner,
        noOfSpecsOnIniLoad,
        addToCartLabel,
        addButton,
        optionsButton,
        isAllComparisonProductAreWishlist,
        notApplicableL,
    } = props
    const [isSticky, setIsSticky] = useState(false)
    const compareProductsFlyoutClass = `${PREFIX}-compare-products-flyout`
    const showFullModalClass = isFullModalOpen ? `${PREFIX}-compare-products-flyout__full-modal` : ''
    const showOverlayClass = isFullModalOpen ? `${PREFIX}-overlay` : ''
    const showHalfOrFullModalClass = isHalfModalOpen
        ? `${PREFIX}-compare-products-flyout__half-modal`
        : showFullModalClass

    useEffect(() => {
        const productDataCardList = document.body.getElementsByClassName(`${PREFIX}-product__list-view`)
        isHalfModalOpen &&
            productDataCardList.length &&
            productDataCardList[productDataCardList.length - 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
    }, [isHalfModalOpen, compareProductList])

    /**
     * Show updated Add to Cart or wishlist spinner based on the index
     * @param {number} idx - Index of the product card
     * @param {boolean} isATCSpinner - Flag to check if spinner is for Add to Cart or Wishlist
     * @returns {boolean} - Returns true if the spinner should be shown
     */
    const showUpdatedSpinner = (idx: number, isATCSpinner: boolean): boolean => {
        const cardIndex: number = isATCSpinner ? clickedATCCardIdx : clickedWishlistIdx
        const isSpinnerShow: boolean = isATCSpinner ? showAtcBtnSpinner : showWishlistBtnSpinner
        return cardIndex === idx ? isSpinnerShow : false
    }

    /**
     * Get product codes from the compare product list
     * @param {ProductCardType[]} productList - List of products to compare
     * @returns {AddAllToWishListProductInterface[]} - List of product codes
     */
    const getProductCodes = (productList: ProductCardType[]): AddAllToWishListProductInterface[] => {
        return (
            productList
                ?.filter(product => product.isWishlistEligible && !product.isWishlistItemActive)
                .map(({ code, skus, currentPrice }) => ({ code, skus, currentPrice })) || []
        )
    }

    /**
     * Return updated Comparison product list with empty card.
     * Adding empty object in array to represent empty card.
     * @returns {Record<string, unknown>[]} returns array of JSX.Element
     */
    const updateComparisonProductList = (): Record<string, unknown>[] => {
        const updatedProductList = [...compareProductList] as unknown as Record<string, unknown>[]
        // eslint-disable-next-line no-magic-numbers
        const numberOfEmptyCard = 4 - updatedProductList.length
        // eslint-disable-next-line no-magic-numbers
        if (numberOfEmptyCard < 4) {
            ;[...Array<number>(numberOfEmptyCard)].forEach(() => {
                updatedProductList.push({})
            })
        } else return compareProductList as unknown as Record<string, unknown>[]
        return updatedProductList
    }

    /**
     * function returns sku code bansedon banners
     * @param {ProductSku[]} sku array of skus
     * @param {string} code product code
     * @returns {string} returns string product code
     */
    const getProductSkuCode = (sku: ProductSku[], code: string): string =>
        sku?.length === 1 ? sku?.[0]?.code : code.toUpperCase().replace('P', '')

    /**
     * function to check product is whishlist
     * @param {ProductSku[]} sku array of skus
     * @param {string} code product code
     * @returns {boolean} returns boolean true/false
     */
    const isWishlistActive = (sku: ProductSku[], code: string): boolean =>
        wishlistItems?.products?.some((wishlist: WishlistProduct) => wishlist.code === getProductSkuCode(sku, code))

    /**
     * render Compare and clear all button
     * @returns {JSX.Element} returns JSX.Element
     */
    const renderCTA = (): JSX.Element => {
        return (
            <div className={`${compareProductsFlyoutClass}__btn-row`}>
                <SpinnerButton
                    variant={Variation.PRIMARY}
                    color={ButtonColors.DARK}
                    size={Size.MINI}
                    a11y={{
                        label: compareL,
                    }}
                    showSpinner={compareBtnSpinner}
                    onClick={compareBtn}
                    customClass={`${compareProductsFlyoutClass}__btn-row-compare`}>
                    {!compareBtnSpinner && compareL}
                </SpinnerButton>
                <ButtonV2
                    variant={Variation.TERTIARY}
                    a11y={{
                        label: clearAllLabel,
                    }}
                    onClick={compareProductCardClearAllBtn}>
                    {clearAllLabel}
                </ButtonV2>
            </div>
        )
    }

    /**
     * gets the ProductCarousel List
     * @param {ProductCardType} product product
     * @param {number} index index
     * @param {string} showGridViewListView showGridViewListView
     * @returns {JSX.Element} returns array of JSX.Element
     */
    const getProductCardsList = (
        product: ProductCardType,
        index: number,
        showGridViewListView: string,
    ): JSX.Element => {
        const {
            title: productTitle,
            brand,
            images,
            rating,
            ratingsCount,
            currentPrice,
            url,
            code,
            skus,
            isOnSale,
            badges,
            isWishlistToShow,
            isWishlistEligible,
        } = product

        const productProps = {
            ratingsAndReview: true,
            addButton,
            optionsButton,
        }

        const isWishlistItemActive = areAllParamsValid(isWishlistToShow, isWishlistActive(skus, code))
        return (
            <div className={showGridViewListView}>
                <ProductCard
                    product={product}
                    url={url}
                    key={index}
                    productProps={productProps}
                    idx={index}
                    cardType="grid"
                    title={productTitle}
                    code={null}
                    brand={brand}
                    images={images}
                    rating={rating}
                    ratingsCount={ratingsCount || 0}
                    productCardClick={(event, productdata, idx) => productCardClick(event, productdata, idx)}
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    showRatingSection={true}
                    language={language}
                    currentPrice={currentPrice || skus?.[0]?.currentPrice}
                    returnPolicy={returnPolicy}
                    enableSaveStory={false}
                    productDataId={code}
                    accessibilityId={getAccessibilityId(code, skus?.[0]?.code ? skus?.[0]?.code : code)}
                    boldBrand={true}
                    showSaleClearanceBadge={true}
                    swiperLazyLoading={true}
                    showCloseBtn={true}
                    removeCompareProductCard={removeCompareProductCard}
                    isOnSale={isOnSale}
                    closeLabel={closeLabel}
                    isFullModalOpen={isFullModalOpen}
                    isHalfModalOpen={isHalfModalOpen}
                    isSticky={isSticky}
                    badges={badges}
                    badgePriorities={badgePriority}
                    showBadge={isFullModalOpen}
                    addProductItemToWishlist={addProductItemToWishlist}
                    addToCartBtnClick={addToCartBtnClick}
                    isWishlistToShow={isWishlistToShow}
                    isWishlistItemActive={isWishlistItemActive}
                    isWishlistEligible={isWishlistEligible}
                    showAtcBtnSpinner={showUpdatedSpinner(index, true)}
                    showWishlistBtnSpinner={showUpdatedSpinner(index, false)}
                    addToCartLabel={addToCartLabel}
                    enableBadges={isFullModalOpen}
                />
            </div>
        )
    }

    /**
     * render empty card element
     * @param {number} index index to add empty card
     * @returns {JSX.Element} returns array of JSX.Element
     */
    const emptyCard = (index: number): JSX.Element => {
        return isFullModalOpen ? (
            <div
                className={`${compareProductsFlyoutClass}__full-modal-empty-card ${PREFIX}-sm-justify-center ${PREFIX}-sm-align-items-center ${PREFIX}-sm-flex ${PREFIX}-sm-column`}>
                <Icon type="ct-plus" size="md" />
                <ButtonV2
                    variant={Variation.TERTIARY}
                    color={ButtonColors.DARK}
                    a11y={{
                        label: addItemstoCompareL,
                    }}
                    onClick={emptyCardClick}>
                    {addItemstoCompareL}
                </ButtonV2>
            </div>
        ) : (
            <div className={`${compareProductsFlyoutClass}__empty-product-card`}>
                <div className={`${compareProductsFlyoutClass}__empty-product-card-text`}>
                    {replaceStrWithDynamicVal(selectItemsToCompareL, index + 1)}
                </div>
            </div>
        )
    }

    /**
     * render comparison half modal element
     * @returns {JSX.Element[]} returns array of JSX.Element
     */
    const renderHalfModal = (): JSX.Element[] => {
        const overFlowHiddenClass = 'modal-open-not-scroll'
        document.body.classList.remove(overFlowHiddenClass)
        // eslint-disable-next-line no-magic-numbers
        return [...Array<number>(PRODUCT_CARD_COUNT)].map((_el, index) => {
            if (isArrayEmpty(Object.keys(updateComparisonProductList()[index]))) {
                return emptyCard(index)
            } else return getProductCardsList(compareProductList[index], index, `${PREFIX}-product__list-view`)
        })
    }

    /**
     * Function returns list of product cards which will be shown in table as a first row
     * @returns {JSX.Element[]} returns array of JSX.Element
     */
    const getCompareTableProductCard = (): JSX.Element[] => {
        return [...Array<number>(PRODUCT_CARD_COUNT)].map((_el, index) => {
            return (
                <div
                    className={`${PREFIX}-common-card ${PREFIX}-product__content`}
                    key={compareProductList[index]?.code || index}>
                    <div className={`${PREFIX}-csp-product-list`}>
                        {isArrayEmpty(Object.keys(updateComparisonProductList()[index])) ? (
                            emptyCard(index)
                        ) : (
                            <div role="none">
                                {getProductCardsList(compareProductList[index], index, `${PREFIX}-product__grid-view`)}
                            </div>
                        )}
                    </div>
                </div>
            )
        })
    }

    const productCardTableData = [{ '': getCompareTableProductCard() }]
    const halfModelData = [{ '': renderHalfModal() }]
    /**
     * Function returns custom row which will be shown in full modal below product cards
     * @returns {JSX.Element[]} returns JSX.Element[]
     */
    const getCustomRowInFullModal = (): JSX.Element[] => {
        return [...Array<number>(1)].map((index: number) => (
            <div className={`${PREFIX}-custom-row-section ${PREFIX}-sm-flex ${PREFIX}-sm-column`} key={index}>
                <div className={`${PREFIX}-custom-row-left ${PREFIX}-sm-flex  ${PREFIX}-sm-justify-space-between`}>
                    <div className={`${PREFIX}-custom-row-switch-button ${PREFIX}-sm-flex`}>
                        <Switch
                            handleInputChange={showDifferencesData}
                            label={showDiffL}
                            checked={isShowDifferenceToggled}
                        />
                        <span>|</span>
                        <ButtonV2
                            variant={Variation.TERTIARY}
                            color={ButtonColors.DARK}
                            a11y={{
                                label: addAllToWishListL,
                            }}
                            icon={{
                                type: isAllComparisonProductAreWishlist ? 'ct-favorite-active' : 'ct-favorite-inactive',
                                size: 'md',
                            }}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                addProductItemToWishlist(
                                    event,
                                    getProductCodes(compareProductList) as unknown as ProductCardType,
                                    false,
                                    null,
                                    true,
                                )
                            }>
                            {addAllToWishListL}
                        </ButtonV2>
                    </div>
                    <ButtonV2
                        variant={Variation.TERTIARY}
                        color={ButtonColors.DARK}
                        a11y={{
                            label: clearAllLabel,
                        }}
                        onClick={compareProductCardClearAllBtn}>
                        {clearAllLabel}
                    </ButtonV2>
                </div>
                <span className={`${PREFIX}-specification-label`}>{specificationLabel}</span>
            </div>
        ))
    }

    const customRowData = [{ '': getCustomRowInFullModal(), colspan: PRODUCT_CARD_COUNT }]

    /**
     * to create specifications row for comparison table
     * @returns {Record<string, JSX.Element[]>[]} returns : Record<string, JSX.Element[]>[]
     */
    const specificationData: Record<string, JSX.Element[]>[] = Object.entries(compareProductsSpecificationList).map(
        ([label, values]) => ({
            [label]: [...Array<number>(PRODUCT_CARD_COUNT)].map((_, idx) => (
                <div
                    key={`${label}-${idx}`}
                    className={`${PREFIX}-specification-row ${PREFIX}-sm-flex ${PREFIX}-sm-column`}>
                    <span>{idx === 0 ? label : ''}</span>
                    <span>{values[idx] || notApplicableL}</span>
                </div>
            )),
        }),
    )

    const comparisonTableData: Record<string, JSX.Element[]>[] = [
        ...productCardTableData,
        ...halfModelData,
        ...customRowData,
        ...specificationData,
    ]
    const [visibleRows, setVisibleRows] = useState(+noOfSpecsOnIniLoad + 1)
    const [isTableExpanded, setIsTableExpanded] = useState(visibleRows === comparisonTableData.length)

    return (
        <div className={`${showOverlayClass}`}>
            <div
                className={`${compareProductsFlyoutClass} ${PREFIX}-column ${PREFIX}-sticky-comparison-bar 
            ${PREFIX}-sticky-comparison-bar--sticky ${showHalfOrFullModalClass}`}>
                <button
                    className={`${compareProductsFlyoutClass}__comparison-btn`}
                    onClick={() => {
                        setIsHalfModalOpen(!isHalfModalOpen)
                        setIsFullModalOpen(false)
                        setIsSticky(false)
                    }}>
                    <Heading variant={'h3'} componentClass={`${compareProductsFlyoutClass}__comparison-heading`}>
                        {`${compareProductsL ?? ''} (${compareProductList?.length})`}
                    </Heading>
                    <div className={`${compareProductsFlyoutClass}__comparison-heading-icon`}>
                        {isHalfModalOpen || isFullModalOpen ? (
                            <Icon type="ct-chevron-down" size="lg" />
                        ) : (
                            <Icon type="ct-chevron-up" size="lg" />
                        )}
                    </div>
                </button>
                <div className={`${compareProductsFlyoutClass}__product-card-row ${PREFIX}-full-width-container`}>
                    <div className={`${compareProductsFlyoutClass}__product-card-row-data`}>
                        {isFullModalOpen ? (
                            <RenderFullModal
                                comparisonTableData={comparisonTableData}
                                rowsOnLoad={noOfSpecsOnIniLoad}
                                seeMoreLabel={viewMoreSpecsL}
                                seeLessLabel={viewFewSpecsL}
                                isSticky={isSticky}
                                setIsSticky={setIsSticky}
                                visibleRows={visibleRows}
                                setVisibleRows={setVisibleRows}
                                isTableExpanded={isTableExpanded}
                                setIsTableExpanded={setIsTableExpanded}
                            />
                        ) : (
                            renderHalfModal()
                        )}
                    </div>
                    {!isFullModalOpen && renderCTA()}
                </div>
            </div>
        </div>
    )
}

CompareProductsFlyout.propTypes = {
    compareProductList: PropTypes.array,
    returnPolicy: PropTypes.func,
    productCardClick: PropTypes.func,
    removeCompareProductCard: PropTypes.func,
    compareProductCardClearAllBtn: PropTypes.func,
    compareBtn: PropTypes.func,
    isHalfModalOpen: PropTypes.bool,
    setIsHalfModalOpen: PropTypes.func,
    isFullModalOpen: PropTypes.bool,
    setIsFullModalOpen: PropTypes.func,
    compareProductsL: PropTypes.string,
    clearAllLabel: PropTypes.string,
    compareL: PropTypes.string,
    selectItemsToCompareL: PropTypes.string,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    closeLabel: PropTypes.string,
    language: PropTypes.string,
}

export default CompareProductsFlyout
