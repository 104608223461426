import { isArrayNotEmpty } from '../../utils'
import { checkDataLength } from '../../utils/checkDataLength'
import { areAllParamsValid } from '../../utils/validParams'
import { PREFIX } from '../config'
import { LOYALTY_BADGE_WEB, replaceStringCharacter } from './Badges.helper'
import { LoyaltyBadge, priorityAndColourProps, UpdatedBadgeProps } from './Badges.type'

/**
 * Returns first n badges per sorted priority where n is max cap
 * @param {string[]} badgeIds
 * @param {priorityAndColourProps[]} priorityAndColour
 * @param {number} maxBadgesToDisplay
 * @returns {priorityAndColourProps[]}
 */
export const getMaxPrioritizedBadges = (
    badgeIds: string[],
    priorityAndColour: priorityAndColourProps[],
    maxBadgesToDisplay: number,
): priorityAndColourProps[] => {
    const priorityAndColourCopy = [...priorityAndColour]
    priorityAndColourCopy.sort((badge1, badge2) => badge1.priority - badge2.priority)
    const availableBadges = priorityAndColourCopy.filter(badge => badgeIds.includes(badge.badgeId))
    return availableBadges.slice(0, maxBadgesToDisplay)
}
/**
 * This function updates the badges which has the multiplier value and updates the respective badgedetails
 * @param {string} badges
 * @param {priorityAndColourProps} priorityAndColour
 */

export const updateDynamicBadges = (
    badges: string[],
    priorityAndColour: priorityAndColourProps[],
    enableRewardValBadge?: boolean,
    loyaltyValue?: LoyaltyBadge,
): UpdatedBadgeProps => {
    const deLimiter = '|'
    const idxForMultipler = 1
    const updatedBadges = [...badges] // clone badges
    const updatedpriorityAndColour = priorityAndColour.map(item => ({ ...item })) // clone array of objects
    const isLoyaltyValueEmpty = !checkDataLength(loyaltyValue)
    isArrayNotEmpty(updatedBadges) &&
        updatedBadges.forEach(function (badge, index, updatedBadgesArr) {
            const badgewithMultiplier = badge?.split(deLimiter)
            if (isArrayNotEmpty(badgewithMultiplier) && badgewithMultiplier.length > idxForMultipler) {
                const bonusBadge = areAllParamsValid(enableRewardValBadge, !isLoyaltyValueEmpty)
                    ? LOYALTY_BADGE_WEB
                    : badgewithMultiplier?.[0]
                updatedBadgesArr[index] = bonusBadge
                const multiplierLabel = badgewithMultiplier?.[idxForMultipler]
                const idxDynamicBadge = updatedpriorityAndColour?.findIndex(item => item.badgeId === bonusBadge)
                if (idxDynamicBadge >= 0) {
                    const existingBadgeLabel = updatedpriorityAndColour[idxDynamicBadge].badgeLabel as string
                    const bonusBadgeLabel = replaceStringCharacter(
                        existingBadgeLabel,
                        loyaltyValue,
                        `${PREFIX}-loyalty-crv`,
                    )
                    updatedpriorityAndColour[idxDynamicBadge].badgeLabel = areAllParamsValid(
                        enableRewardValBadge,
                        !isLoyaltyValueEmpty,
                    )
                        ? bonusBadgeLabel
                        : `${multiplierLabel} ${existingBadgeLabel}`
                }
            }
        })

    return { updatedBadges, updatedpriorityAndColour }
}
