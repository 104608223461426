import { useMemo } from 'react'
import { useAppSelector } from './react-redux.hook'
import { checkDataLength, getUserProfileStatus } from '@nl/lib'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../redux/selectors/userProfile.selectors'

/**
 * Custom hook to determine if the user is a logged-in loyalty user.
 * This hook uses the `useAppSelector` to retrieve the user profile data and the authentication flow status.
 * It then checks if the user is logged in and has loyalty status.
 * @returns {boolean} - Returns `true` if the user is logged in and has loyalty status, otherwise `false`.
 */
export const useIsLoggedInLoyaltyUser = (): boolean => {
    const userProfileData = useAppSelector(userProfileDataSelector)
    const isUserLoggedIn = (userProfileData && checkDataLength(userProfileData)) || false
    const isAuthFlowExecuted = useAppSelector(isAuthFlowExecutedSelector)

    const { hasLoyalty } = useMemo(
        () => getUserProfileStatus(isAuthFlowExecuted, userProfileData),
        [isAuthFlowExecuted, userProfileData],
    )

    return isUserLoggedIn && hasLoyalty
}
