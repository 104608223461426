import linkRewardsAnalytics from '../analytics/components/linkRewardsAnalytics'
import spaAnalytics from '../analytics/components/spaAnalytics'
import { analyticsAttributes, rewardsAnalyticsActions } from '../globalConstants'

const {
    eventParameters: { action, step },
} = analyticsAttributes

/**
 * SSO login analytics
 * @param  {boolean} isSuccess - true if user becomes authorized
 * @returns {void}
 */
export const ssoLoginAnalyticsEvent = (isSuccess: boolean): void => {
    spaAnalytics.setSSOLoginAnalytics(isSuccess)
}

/**
 * User Login From CLP analytics
 * @param  {boolean} isSuccess - true if user becomes authorized
 * @returns {void}
 */
export const setLoginFromCLPAnalytics = (isSuccess: boolean): void => {
    spaAnalytics.setLoginFromCLPAnalytics(isSuccess)
}

/**
 * Login was initialized automatically analytics
 * @returns {void}
 */
export const setAutoRedirectToCLPAnalytics = (): void => {
    spaAnalytics.setRedirectToCLPAnalytics(action.automaticRedirectToCLP)
}

/**
 * Login was initialized manually by user analytics
 * @returns {void}
 */
export const setManualRedirectToCLPAnalytics = (): void => {
    spaAnalytics.setRedirectToCLPAnalytics(action.manualRedirectToCLP)
}

/**
 * Analytics for link rewards card
 * @param  {string} sourceURL - The source URL of the registration
 * @param {boolean} isFromCRP - true if initiated from CRP, false if from OB
 * @param {string} linkOption - The link option used during registration
 * @param {string} currentAction - current state of registration rewards card process
 * @returns {void}
 */
export const linkRewardsCardAnalytics = (
    sourceURL: string,
    isFromCRP: boolean,
    linkOption: string,
    currentAction: string,
): void => {
    const loyaltyStep = isFromCRP ? step.registerLoyaltyCardFromCRP : step.registerLoyaltyCardFromOb
    switch (currentAction) {
        case rewardsAnalyticsActions.openRewards: {
            !isFromCRP && linkRewardsAnalytics.setChooseLinkOptionAnalytics(linkOption)
            break
        }
        case rewardsAnalyticsActions.successLinkRewards: {
            linkRewardsAnalytics.setLinkRewardsResultAnalytics(sourceURL, linkOption, loyaltyStep, { success: true })
            break
        }
        case rewardsAnalyticsActions.errorLinkRewards: {
            linkRewardsAnalytics.setLinkRewardsResultAnalytics(sourceURL, linkOption, loyaltyStep, { error: true })
            break
        }
        case rewardsAnalyticsActions.successScreen: {
            linkRewardsAnalytics.setSuccessScreenAnalytics(sourceURL, successScreenAnalyticsStep(isFromCRP))
            break
        }
        default:
    }
}

/**
 * Determines the success screen analytics step based on the source.
 * @param {boolean} isFromCRP - true if initiated from CRP, false if from OB
 * @returns {string} - The success screen analytics step
 */
const successScreenAnalyticsStep = (isFromCRP: boolean): string =>
    isFromCRP ? step.successScreenFromCRP : step.successScreenFromOb
