import { CountriesResponse } from '../../../redux/models/loyaltyRegisterForm.interface'
import { MagicNumber } from '../../../analytics/analytics.type'
import {
    checkDataLength,
    isArrayNotEmpty,
    libUtils,
    baseCTFSInputStyles,
    shippingAddressErrorCodes,
    getSubscriptionDateTime,
    replaceStrWithDynamicVal,
    getBalance,
} from '@nl/lib'
import { paymentInformationConst } from './cardTypeConstants'
import { PaymentUnitType, HppCreditCardInfoType } from '../../../redux/models/profile.payment.interface'
import { PartialResponse } from '../../../redux/models/errorInterceptor.interface'
import {
    PaymentDetailsErrorCodes,
    PaymentDetailsURL,
    ProfileURL,
    successStatus,
    toastTypeConst,
} from '../Checkout.constant'
import { isRedirectedAfterClickToPaySTHValidation, isRedirectedAfterPayPal } from '../Checkout.helper'
import { apiMethods } from '../../../services/service.constants'
import { NotificationPreference } from '../../../redux/models/user.preferences.interface'
import {
    CheckoutPaymentInfo,
    EmailConsentType,
    PaymentDetailsError,
    CheckoutPaymentConditions,
} from '../../../redux/models/checkout.interface'
import { CountryType, InitialAddressType } from '../ShippingAddress/ShippingAddress.types'
import { FilteredCartData } from '../../../redux/models/cart.interface'
import { UserProfileData, billingAddressType } from '../../../redux/models/user.profile.interface'
import { OnlineSource } from '../../../globalConstants'
import { PREFIX } from '../../../config'
import { defaultCountry } from '../../Accounts/Addresses/Addresses.constant'
import { GiftCardInterface } from '@nl/lib/src/components/GiftCard/GiftCard.types'
import GigyaService from '../../../services/gigyaService/gigya.service'

/**
 * This function returns country name from country code
 * @param {string} countryCode
 * @param {CountriesResponse} countriesData
 * @return {string} country name or empty string
 */
export const getCountryRegionNameFromCode = (
    countryCode: string | Record<string, string>,
    countriesData: CountriesResponse,
): string => {
    const countryIsoCode = typeof countryCode === 'string' ? countryCode : countryCode?.isocode
    if (isArrayNotEmpty(countriesData)) {
        return countriesData.find(country => country.isocode === countryIsoCode)?.name || ''
    } else return typeof countryCode === 'string' ? countryCode : countryCode?.name
}

export const getHPPCallback = (
    isNewCard: boolean,
    masterPassClicked: boolean,
    semaFoneEnabled: boolean,
    piCardInputErrorMsg?: string,
    piCVVInputErrorMsg?: string,
    piMonthYearInputErrorMsg?: string,
): void => {
    setTimeout(() => {
        if (isNewCard) {
            window.HPP.setErrors({
                en: {
                    card_number: piCardInputErrorMsg,
                    expiry_date_text: piMonthYearInputErrorMsg,
                    cvd: piCVVInputErrorMsg,
                },
                fr: {
                    card_number: piCardInputErrorMsg,
                    expiry_date_text: piMonthYearInputErrorMsg,
                    cvd: piCVVInputErrorMsg,
                },
            })
            if (semaFoneEnabled) {
                window.HPP.createCardElement('hostedFields__cardElement')
            } else {
                window.HPP.setCss({
                    cardNumber: baseCTFSInputStyles,
                    cvd: baseCTFSInputStyles,
                })
                window.HPP.createCardNumberElement('hostedFields__cardNumberElement')
                window.HPP.createCvdElement('hostedFields__cvdElement')
                window.HPP.setNoCardExpiryDate(true)
            }
        } else {
            if (masterPassClicked) {
                window.HPP.submitMasterpass()
            }
        }
    }, MagicNumber.HUNDRED)
}

/**
 * function to check if the error code is paypal manual cancel error
 * @param {string} errorCode error code
 * @returns {boolean} isPayPalManualCancelError
 */
export const isPayPalManualCancelError = (errorCode: string): boolean => {
    return paymentInformationConst.payPalError461 === errorCode
}

/**
 * Function to check if user has modified the default payment
 * @param {PaymentUnitType} defaultSavedCard
 * @param {string} selectedCardNumber
 * @return {boolean}
 */
export const isDefaultPaymentModified = (defaultSavedCard: PaymentUnitType, selectedCardNumber: string): boolean => {
    return defaultSavedCard.maskedCardNumber !== selectedCardNumber
}

/**
 * Function to check cardLogoType mapping and map cardLogoType key to profilePaymentData
 * @param {PaymentUnitType[] | null} profilePaymentData
 */
export const mapCardLogoTypeToPaymentData = (profilePaymentData: PaymentUnitType[] | null) => {
    if (isArrayNotEmpty(profilePaymentData)) {
        const isAlreadyMapped = profilePaymentData?.some(el => !!el.cardLogoType)
        if (!isAlreadyMapped) {
            profilePaymentData?.map(item => {
                const creditCardInfo: HppCreditCardInfoType = window.HPP.getBINInfo(
                    item.maskedCardNumber,
                ) as unknown as HppCreditCardInfoType
                item.cardLogoType = creditCardInfo?.cardType
            })
        }
    }
}

/**
 * Function to reset the payment errors when the valid entry for CC, CVV fields entered and resets when saved cc or CTMoney selected.
 * @param {void} onPlaceOrderClick
 * @param {boolean} isResetErrors
 */
export const resetPaymentErrors = (onPlaceOrderClick: (code: number) => void, isResetErrors = false) => {
    if (isResetErrors) onPlaceOrderClick(MagicNumber.ZERO)
}

/**
 * Function to capture save card consent for the new credit cards added by authenticated users
 * @param {boolean} isAuthUser
 * @param {boolean} isSaveToProfile
 * @param {boolean} isNewCreditCard
 */
export const captureSaveCardConsent = (isAuthUser: boolean, isSaveToProfile: boolean, isNewCreditCard: boolean) => {
    if (isAuthUser && isNewCreditCard) {
        isSaveToProfile ? window.HPP.saveCreditCard() : window.HPP.unsaveCreditCard()
    }
}

/**
 * Function to check if partial response recieved is for paymentDetails api request
 * @param {PartialResponse | null} partialResponseData
 * @return {boolean}
 */
export const isPaymentDetailsPartialResponse = (partialResponseData: PartialResponse | null): boolean => {
    return (
        !!partialResponseData &&
        partialResponseData?.url.includes(PaymentDetailsURL) &&
        apiMethods.POST.toLowerCase() === partialResponseData.response.config.method?.toLowerCase() &&
        successStatus === partialResponseData.response.status
    )
}

/**
 * function to check if partial response from profile api
 * @param {PartialResponse | null} partialResponseData is partial response data
 * @returns {boolean} proceed to set save billing flag success
 */
export const isProfileBillingSavePartialResponse = (partialResponseData: PartialResponse | null): boolean => {
    return (
        !!partialResponseData &&
        partialResponseData?.url.includes(ProfileURL) &&
        apiMethods.PUT.toLowerCase() === partialResponseData.response.config.method?.toLowerCase() &&
        successStatus === partialResponseData.response.status
    )
}

/**
 * Function to validate if payment information has any validation errors
 * @param {boolean} shippingError
 * @param {string | undefined} monthError
 * @param {string | undefined} yearError
 * @param {boolean} semaFoneEnabled
 * @return {boolean}
 */
export const isPaymentInfoValid = (
    shippingError: boolean,
    monthError: string | undefined,
    yearError: string | undefined,
    semaFoneEnabled: boolean | undefined,
): boolean => {
    return semaFoneEnabled ? shippingError : shippingError || !!monthError || !!yearError
}

/**
 * Function to validate if billing preview can be shown or not
 * @param {boolean} isAuthUser
 * @param {string} selectedMonth
 * @param {string} selectedYear
 * @param {boolean | undefined} semaFoneEnabled
 * @param {Object} giftCards
 * @param {boolean} isOrderTotalCovered
 * @param {boolean} isShowPreview
 * @return {boolean}
 */
export const showBillingPreview = (
    isAuthUser: boolean,
    selectedMonth: string,
    selectedYear: string,
    semaFoneEnabled: boolean | undefined,
    giftCards: GiftCardInterface[],
    isOrderTotalCovered: boolean,
    isShowPreview: boolean,
): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        ((!isAuthUser &&
            ((!!selectedMonth && !!selectedYear) || (isOrderTotalCovered && checkDataLength(giftCards)))) ||
            semaFoneEnabled ||
            isAuthUser ||
            isRedirectedAfterPayPal() ||
            isRedirectedAfterClickToPaySTHValidation()) &&
        !isShowPreview
    )
}

/**
 * Function to get disable value for 3rd party payments based on guest and auth user
 * @param {boolean} isAuthUser
 * @param {PaymentUnitType[] | null} profilePaymentData
 * @param {boolean} disablePaymentOptions
 * @param {boolean | undefined} semaFoneEnabled
 * @return {boolean}
 */
export const isDisable3rdPartyPayments = (
    isAuthUser: boolean,
    profilePaymentData: PaymentUnitType[] | null,
    disablePaymentOptions: boolean,
    semaFoneEnabled: boolean | undefined,
): boolean | undefined => {
    return disablePaymentOptions || (isAuthUser && isArrayNotEmpty(profilePaymentData) && semaFoneEnabled)
}

/**
 * Function to validate if email acquistion feature is enabled and the user is eligble to provide consent
 * @param {boolean} emailAcquisitionFlagEnabled
 * @param {boolean} isAuthenticatedUser
 * @param {NotificationPreference} notificationPreferenceData
 * @return {boolean}
 */
export const showEmailAcquisition = (
    emailAcquisitionFlagEnabled: boolean,
    isAuthenticatedUser: boolean,
    notificationPreferenceData: NotificationPreference,
): boolean => {
    const isPreferenceAlreadyCaptured = checkDataLength(notificationPreferenceData) && notificationPreferenceData.eFlyer
    return emailAcquisitionFlagEnabled && (!isAuthenticatedUser || !isPreferenceAlreadyCaptured)
}

/**
 * Function to verify if the user is opting for email consent inside Canada only
 * @param {boolean} emailConsent
 * @param {string} canadaPostCountry
 * @param {Record<string, string>} billingCountry
 * @return {boolean}
 */
export const isEmailConsentValid = (
    emailConsent: boolean,
    canadaPostCountry: string,
    billingCountry: Record<string, string>,
): boolean => {
    return emailConsent && billingCountry?.isocode === canadaPostCountry
}

/**
 * Function to construct payload for setting account info
 * @param {responseType} response
 * @param {EmailConsentType} emailConsentData
 * @return {any}
 */
const constructSetAccountRequest = (emailConsentData: EmailConsentType) => {
    return {
        eFlyer: 'true',
        email: emailConsentData.email,
        languages: libUtils.getLanguage(),
        postalCode: emailConsentData.postalCode,
        source: OnlineSource.checkout,
        subscriptionDate: getSubscriptionDateTime(),
    }
}

/**
 * Function to capture user's email consent in gigya
 * @param {EmailConsentType} emailConsentData
 */
export const captureEmailConsent = (emailConsentData: EmailConsentType): void => {
    const gigyaService = new GigyaService()
    const registrationCallback = () => {
        const reqPayload = constructSetAccountRequest(emailConsentData)
        gigyaService.updateAccountInformation(reqPayload).catch(err => console.error(err))
    }

    if (window?.gigya?.accounts?.initRegistration) {
        gigyaService.initRegistration({
            isLite: true,
            callback: registrationCallback,
        })
    }
}

/**
 * Method to extract iso code for country and region data
 * @param { string | Record<string, string> | CountryType | undefined} countryRegionData
 * @return {string}
 */
export const extractIsoCode = (
    countryRegionData: string | Record<string, string> | CountryType | undefined,
): string => {
    return (typeof countryRegionData === 'string' ? countryRegionData : countryRegionData?.isocode) || ''
}

/**
 * Method to check do we need to render financing plan
 * @param { boolean } isAuthUserNoCC
 * @param { boolean } isNotSameAddress
 * @param { boolean } isSTHProductExistInCart
 * @return { boolean }
 */
export const isRenderFinancingPlanForAuthUserNoCC = (
    isAuthUserNoCC: boolean,
    isNotSameAddress: boolean,
    isSTHProductExistInCart: boolean,
): boolean => {
    return isAuthUserNoCC || (!isNotSameAddress && isSTHProductExistInCart)
}

/**
 * Method to check if billing address available and  "the same as shipping" checkbox selected
 * @param { boolean } isNotSameAddress
 * @param { boolean } isBillingAddIsNotAvailable
 * @param { boolean } isSTHMethodSelected
 * @param { boolean } isOrderFullyPaidByGiftCard
 * @return { boolean }
 */
export const isBillingAddSameAsShipping = (
    isNotSameAddress: boolean,
    isBillingAddIsNotAvailable: boolean,
    isSTHMethodSelected: boolean,
    isOrderFullyPaidByGiftCard: boolean,
): boolean => {
    return !isNotSameAddress && (isBillingAddIsNotAvailable || isOrderFullyPaidByGiftCard) && isSTHMethodSelected
}

/**
 * Method to check if IsNotSameAddress (not same as shipping address) need to be set to true
 *  for cases when "Billing address same as shipping address" checkbox
 *  is switched to "Use the Billing Address saved in My Account" radiobutton
 *  but previous state for checkbox was checked
 * @param { boolean } isGiftCardDeleted
 * @param { boolean } isOrderFullyPaidByGiftCard
 * @param { boolean } isBillingAddressAvailable
 * @param { boolean } isSameAsShippingAddress
 * @return { boolean }
 */
export const isNeedToResetSameAsShippingFlag = (
    isGiftCardDeleted: boolean,
    isOrderFullyPaidByGiftCard: boolean,
    isBillingAddressAvailable: boolean,
    isSameAsShippingAddress: boolean,
): boolean => {
    return isGiftCardDeleted && !isOrderFullyPaidByGiftCard && isBillingAddressAvailable && isSameAsShippingAddress
}

/**
 * Method to check if after redirection after PayPal there is a billingAddress exist in paymentInfo
 * @param { boolean } isRedirectedAfterPayPalPayment
 * @param { boolean } isPaymentInfoWithBillingAddress
 * @return { boolean }
 */
export const isRedirectedAfterPayPalWithBillingAddress = (
    isRedirectedAfterPayPalPayment: boolean,
    isPaymentInfoWithBillingAddress: boolean,
): boolean => isRedirectedAfterPayPalPayment && isPaymentInfoWithBillingAddress

/**
 * Method to check if shipping address visible
 * @param { boolean } isNotSameAddress
 * @param { FilteredCartData } cartItemsData
 * @return { boolean }
 */
export const showShippingAddressFields = (isNotSameAddress: boolean, cartItemsData: FilteredCartData): boolean =>
    isNotSameAddress ||
    !!(!cartItemsData?.sth?.length && (cartItemsData?.bopis?.length || cartItemsData?.services?.length))

/**
 * function to scroll to top
 */
export const scrollToTop = (): void => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

/**
 * Redirects to another page on a specific error
 * @param { PaymentDetailsError } paymentDetailsError get payment details API error
 * @param { string } cartPageLink cartPageLink
 * @returns {void}
 */
export const redirectOnPaymentErrors = (paymentDetailsError: PaymentDetailsError, cartPageLink: string): void => {
    if (paymentDetailsError?.errCode === shippingAddressErrorCodes.addressQuebec) {
        window.location.href = `${cartPageLink}?${paymentInformationConst.thirdPartyPaymentErrorKey}=${paymentInformationConst.payPalShippingErrorRestricted}`
    }
    if (paymentDetailsError?.errCode === shippingAddressErrorCodes.addressPOBox) {
        window.location.href = `${cartPageLink}?${paymentInformationConst.thirdPartyPaymentErrorKey}=${paymentInformationConst.payPalShippingErrorPOBox}`
    }
}

/**
 * Function to check if selected payment method is paypal or not
 * @param {string} selectedCard
 * @return {boolean}
 */
export const isPayPalPaymentSelected = (selectedCard: string): boolean => {
    return selectedCard === paymentInformationConst.payPal
}

/**
 * Function to check if we can render the new place order merge button or not
 * @param {boolean | undefined} mergePlaceOrderAndReviewOrderFeature
 * @param {string} selectedCard
 * @param {string} onlineOrdering
 * @return {boolean}
 */
export const isRenderMergedPlaceOrder = (
    mergePlaceOrderAndReviewOrderFeature: boolean | undefined,
    isThirdPartyPaymentSelected: boolean,
    onlineOrdering: boolean,
): boolean => {
    return !!mergePlaceOrderAndReviewOrderFeature && !isThirdPartyPaymentSelected && onlineOrdering
}

/**
 * Function to set Payment Details to State
 * @param {CheckoutPaymentInfo} checkoutPaymentInfo
 * @param {React.Dispatch<React.SetStateAction<CheckoutPaymentInfo>>} setSelectedPaymentData
 * @param {void} setBillingAddressRef
 * @param {React.Dispatch<React.SetStateAction<string>>} setSelectedMonth
 * @param {React.Dispatch<React.SetStateAction<string>>} setSelectedYear
 * @return {void}
 */
export const setPaymentDetailsToState = (
    checkoutPaymentInfo: CheckoutPaymentInfo,
    setSelectedPaymentData: React.Dispatch<React.SetStateAction<CheckoutPaymentInfo>>,
    setBillingAddressRef: () => void,
    setSelectedMonth: React.Dispatch<React.SetStateAction<string>>,
    setSelectedYear: React.Dispatch<React.SetStateAction<string>>,
): void => {
    if (checkoutPaymentInfo?.billingAddress?.line1) {
        setSelectedPaymentData(prevstate => ({ ...prevstate, ...checkoutPaymentInfo }))
        setBillingAddressRef()
    }
    checkoutPaymentInfo?.expiryMonth && setSelectedMonth(checkoutPaymentInfo?.expiryMonth)
    checkoutPaymentInfo?.expiryYear && setSelectedYear(checkoutPaymentInfo?.expiryYear)
}

/**
 * Function to check if user is in payment drawer and different card is selected
 * @param {number} drawerLevelEditState
 * @param {string} selectedCard
 * @return {boolean}
 */
export const isPaymentDrawerWithDifferentCard = (drawerLevelEditState: number, selectedCard: string): boolean => {
    return drawerLevelEditState === MagicNumber.FOUR && selectedCard === paymentInformationConst.differentCard
}

/**
 * function to get account holder name from profile api for auth user for saving card to their account
 * @param {UserProfileData | null} userProfileData
 * @return {string | undefined}
 */
export const getAccountHolderName = (userProfileData: UserProfileData | null): string | undefined => {
    return !!userProfileData && userProfileData?.firstName && userProfileData?.lastName
        ? `${userProfileData.firstName}${' '}${userProfileData.lastName}`
        : ''
}

/**
 * function to return the css class to show or hide checbox
 * @param {boolean} isSaveCardOrSignInToShow
 * @return {string}
 */
export const hideCheckboxClass = (isSaveCardOrSignInToShow: boolean): string => {
    return isSaveCardOrSignInToShow ? `${PREFIX}-hide` : ''
}

/**
 * function to check if profile api failed to reset the flag in subsequent place order click
 * @param {Record<string, Record<string, string>>} errorInterceptorData is error interceptor data
 * @param {boolean} mergePlaceOrderAndReviewOrderFeature is merge review order place order button flag
 * @returns {boolean} proceed with reset save billing address flag
 */
export const isProfileUpdateSkipped = (
    errorInterceptorData: Record<string, Record<string, string>>,
    mergePlaceOrderAndReviewOrderFeature: boolean,
): boolean => {
    return (
        mergePlaceOrderAndReviewOrderFeature &&
        checkDataLength(errorInterceptorData) &&
        errorInterceptorData?.config?.url?.includes(ProfileURL) &&
        apiMethods.PUT.toLowerCase() === errorInterceptorData?.config?.method?.toLowerCase()
    )
}

/**
 * function to check if payment details api failed in partial auth
 * @param {Record<string, Record<string, string>>} errorInterceptorData is error interceptor data
 * @returns {boolean} is flag for payment details failure
 */
export const isPaymentDetailsFailed = (errorInterceptorData: Record<string, Record<string, string>>): boolean => {
    return (
        checkDataLength(errorInterceptorData) &&
        errorInterceptorData?.config?.url?.includes(PaymentDetailsURL) &&
        apiMethods.POST.toLowerCase() === errorInterceptorData?.config?.method?.toLowerCase()
    )
}

/**
 * function to set IsReviewOrderClicked when review order button is clicked
 * @param {boolean} isRenderReviewOrderCTA is render review order button flag
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsReviewOrderClicked is setter for isRenderReviewOrderCTA state
 * @returns {void}
 */
export const handleReviewOrderClick = (
    isRenderReviewOrderCTA: boolean,
    setIsReviewOrderClicked: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
    isRenderReviewOrderCTA && setIsReviewOrderClicked(true)
}

/**
 * function to get country iso code
 * @param {string | CountryType} countryInfo is country info
 * @returns {string | CountryType} is country iso code
 */
export const getCountryIsoCode = (countryInfo: string | CountryType): string | CountryType => {
    return extractIsoCode(countryInfo) || defaultCountry
}

/**
 * render title with 24 month equal payment value
 * @param {string} title epp section title comes from AEM
 * @param {number} value calculated value of 24 month total value coming from CDS
 * @returns {string}
 */
export const getEPPValue = (title: string, value: number): string =>
    replaceStrWithDynamicVal(title, getBalance(value?.toString() || '0'))

/**
 * function to get desciption for Financing plan component
 * @param {string} isFEligible is to know epp is elibilbe or not
 * @param {string} piTriangleDesc above $150 description text
 * @param {string} piTriangleDescBelow15 below $150 description text
 * @returns {string}
 */
export const getEPPDescriptiontext = (
    isFEligible: boolean,
    piTriangleDesc: string,
    piTriangleDescBelow15: string,
): string => (isFEligible ? piTriangleDesc : piTriangleDescBelow15)

/**
 * function to call dispatchCheckoutPayment if certain conditions are met
 * @param {CheckoutPaymentConditions} checkoutPaymentConditions
 * @param {React.MutableRefObject<InitialAddressType | null>} billingAddress
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setReadyForCheckoutPayment
 * @param {string} isProfileBillingSuccess
 * @param {function} proceedCheckoutPayment
 * @returns {void}
 */
export const checkoutPaymentIfReady = (
    checkoutPaymentConditions: CheckoutPaymentConditions,
    billingAddress: React.MutableRefObject<InitialAddressType | null>,
    setReadyForCheckoutPayment: React.Dispatch<React.SetStateAction<boolean>>,
    isProfileBillingSuccess: string,
    proceedCheckoutPayment: () => void,
): void => {
    if (
        // eslint-disable-next-line sonar/expression-complexity
        checkoutPaymentConditions.isReadyForCheckoutPayment &&
        !checkoutPaymentConditions.isPayPalPayment &&
        !isRedirectedAfterClickToPaySTHValidation() &&
        !isRedirectedAfterPayPal() &&
        checkDataLength(billingAddress) &&
        (checkoutPaymentConditions.isBillingSameAsShipping ||
            !checkoutPaymentConditions.isSaveBillingAddress ||
            isProfileBillingSuccess !== '')
    ) {
        proceedCheckoutPayment()
        setReadyForCheckoutPayment(false)
    }
}

/**
 * Checks if the provided primary billing address object is valid.
 * @param {billingAddressType | undefined} primaryBillingAddress The primary billing address to validate.
 * @returns {boolean} Returns true if the primary billing address is valid (all fields are non-empty), or if it is not provided. Returns false if any field in the primary billing address is empty.
 */
export const isPrimaryBillingAddressValid = (primaryBillingAddress: billingAddressType | undefined): boolean =>
    !!primaryBillingAddress &&
    Object.keys(primaryBillingAddress).every(
        key => key === 'addressLineTwo' || !!primaryBillingAddress[key as keyof billingAddressType],
    )

/**
 * function to dispatch toast if payment details api failed with error
 * and reset button click states
 * @param {string} paymentDetailsError payment details error code
 * @param {Function} showToastMessage show toast message callback
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setAccountHolderNameError set account holder name error
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsSubmittedBillingAddress submit billing address setter
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setBillingError billing error state setter
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsPaymentDataValid payment data valid state setter
 * @param {Function} resetPlaceOrderStates reset button click states callback
 * @returns {void}
 */
export const showToastForPaymentDetailsError = (
    paymentDetailsError: string,
    showToastMessage: (toastType: string) => void,
    setAccountHolderNameError: React.Dispatch<React.SetStateAction<boolean>>,
    setIsSubmittedBillingAddress: React.Dispatch<React.SetStateAction<boolean>>,
    setBillingError: React.Dispatch<React.SetStateAction<boolean>>,
    setIsPaymentDataValid: React.Dispatch<React.SetStateAction<boolean>>,
    resetPlaceOrderStates: () => void,
): void => {
    if (paymentDetailsError) {
        showToastMessage(toastTypeConst.paymentDetailsError)
        setAccountHolderNameError(paymentDetailsError === PaymentDetailsErrorCodes.paymentDetailsActHolderNameError)
        setIsSubmittedBillingAddress(false)
        setBillingError(true)
        setIsPaymentDataValid(false)
        resetPlaceOrderStates()
    }
}
