import { AxiosResponse } from 'axios'
import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import { apiMethods } from '../service.constants'
import { getParamValue, queryParameters } from '@nl/lib'
import { cartguidParam } from '../cartService/cart.service.constants'
import { checkoutDeliveryUrl } from '../../components/Checkout/Checkout.constant'

const environment = getEnvironment()

/**
 * service for actions of drawerInteraction
 */
export class DrawerInteractionService extends BaseService {
    /**
     * Function to get guid from passed or from url
     * @param {string} guid - guid passed from action
     * @return {string}
     */
    getGUID(guid: string): string {
        return guid || getParamValue(window.location.search, cartguidParam, queryParameters.plpCDSDivider) || ''
    }

    /**
     *
     * @param {Record<sting, unknown>}datapost
     * @param {string} guid
     * @param {string} urlType
     * @param {string} selectedPreferredStoreId
     * @param {boolean} isOneTimeCart - is one time cart
     * @param {string} buyNowCartCode - buy now cart code
     * @return {AxiosResponse}
     */
    drawerApiSave(
        datapost: Record<string, unknown>,
        guid: string,
        urlType: string,
        selectedPreferredStoreId?: string,
        isOneTimeCart = false,
        buyNowCartCode = '',
    ): Promise<AxiosResponse> {
        /**
         * TODO:change apiGet to apiPost and use datapost as body
         */
        const url: URL = this.createCheckoutUrl(guid, urlType, selectedPreferredStoreId, isOneTimeCart, buyNowCartCode)
        const apiMethod = urlType === checkoutDeliveryUrl ? apiMethods.PUT : apiMethods.POST
        return DrawerInteractionService.apiMethod(apiMethod, url.toString(), datapost)
    }

    /**
     * Create drawer interaction api url based on mock or cds
     * @param {string} guid - guid for user
     * @param {string} urlType - urltype whether contact,shipping or pickup
     * @param {string} selectedPreferredStoreId - selectedPreferredStoreId
     * @param {boolean} isOneTimeCart - is one time cart
     * @param {string} buyNowCartCode -  buy now cart code
     * @return {URL}
     */
    createCheckoutUrl(
        guid: string,
        urlType: string,
        selectedPreferredStoreId?: string,
        isOneTimeCart = false,
        buyNowCartCode = '',
    ): URL {
        const endPoint = environment.API_ENDPOINTS[urlType]
        const availableGuid = this.getGUID(guid)
        const availableStoreId = selectedPreferredStoreId ? `&storeId=${selectedPreferredStoreId}` : ''
        const isOneTimeCartToPass = isOneTimeCart ? `&isOneTimeCart=${String(isOneTimeCart)}` : ''
        const cartCode = buyNowCartCode ? `&cartCode=${buyNowCartCode}` : ''
        return new URL(
            `${environment.API_BASE_URL}${endPoint}?guid=${availableGuid}&lang=${DrawerInteractionService.language}${availableStoreId}${isOneTimeCartToPass}${cartCode}`,
        )
    }
}

const drawerInteractionService = new DrawerInteractionService()

export { drawerInteractionService }
export default drawerInteractionService
