import { PREFIX } from '../../../config'

export const hallOfFame = `${PREFIX}-hall-of-fame`
export const hallOFameTitle = `${hallOfFame}__title`
export const hallOFameProductGrid = `${hallOfFame}__product-grid`
export const hallOFameProductTile = `${hallOfFame}__product-tile`
export const hallOFameSkeleton = 'HOF-skeleton'

export const hallOFameFitment = 'HOF_fitment'
export const hallOFameFitmentStaggered = 'HOF_fitment--staggered'
