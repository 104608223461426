import React, { ReactNode } from 'react'
import { Direction, Position, Size } from '../../types'

/**
 *Interface for Tabs component
 */
export interface TabsProps {
    children?: React.ReactElement<TabChildren>[]
    tabClickedCallBack?: (tab: string) => void
    selectedTab?: string
    isPrerender?: boolean
}

export interface TabProps {
    activeTab: string
    label: string
    currentIndex: number
    handleKeyDown: (event: React.KeyboardEvent<HTMLDivElement>, currentIndex: number, label: string) => void
    handleClickOrEnter: (event: React.MouseEvent<HTMLDivElement>, currentIndex: number, label: string) => void
    dataTestId?: string
}

export interface TabsItemContainerProps {
    label: string
    children?: React.ReactNode
}

export type TabChildren = { children?: ReactNode } & TabProps

// V2 TAB Interface

export enum TabVariants {
    DEFAULT = 'default',
    SHADOW_BOX_LIGHT = 'shadow-box-light',
}

export interface ITabsStyle {
    direction?: Direction
    position?: Position.LEFT | Position.RIGHT | Position.TOP | Position.BOTTOM
    variant?: TabVariants
    tabGap?: Size | 'none'
    tabsGap?: Size | 'none'
    tabContentGap?: Size | 'none'
}

/**
 * Interface for Tab Group component V2
 * tabCallback - callback function to handle certain action on click of tabs
 */
export interface ITabs {
    selected?: number | string
    isPrerenderMode?: boolean
    id?: string
    children: React.ReactElement<ITab>[]
    tabCallback?: (tab: string) => void
    style?: ITabsStyle
}

/**
 * Interface for Singular Tab component V2
 */
export interface ITab {
    isActive?: boolean
    label: React.ReactNode
    index?: number
    id?: string
    gap?: string
    children: React.ReactNode
    onSwitchTab?: (
        e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        index: number,
        label: string,
    ) => void
}
