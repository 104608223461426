import { analyticsAttributes } from '../../globalConstants'
import { ILinkRewardsAnalyticsEvent, IRegistrationAnalyticsActionResult } from '../analytics.type'
import { GlobalPropsHelper } from '../helpers/globalProps'
import { getProvider } from '../providers'

const provider = getProvider()

const globalProps = new GlobalPropsHelper()
const dataConfig = globalProps.readDataConfig()

const linkRewardsAnalytics = (() => {
    const {
        eventParameters: { step, action },
        event: { centralRegistration },
    } = analyticsAttributes

    /**
     * Prepare registration onload data
     * @param {string} sourceURL - The source URL of the registration
     * @param {string} linkOption - The link option used during registration
     * @param {string} linkStep - The current step of the registration process
     * @param {string} linkAction - The action taken during registration
     * @param {IRegistrationAnalyticsActionResult} actionResult - The result of the registration action
     * @returns {AnalyticsRecordInterface} - The analytics data for registration
     */
    const _linkRewardsAnalyticsData = (
        sourceURL: string,
        linkOption: string,
        linkStep: string,
        linkAction: string,
        actionResult: IRegistrationAnalyticsActionResult,
    ): ILinkRewardsAnalyticsEvent => {
        return {
            event: centralRegistration,
            eventParameters: {
                source: dataConfig?.bannerId,
                sourceURL,
                step: linkStep,
                linkOption,
                action: linkAction,
                ...actionResult,
            },
        }
    }

    /**
     * Prepare success screen analytics data
     * @param {string} sourceURL - The source URL of the success screen
     * @param {string} linkStep - The current step of the success screen process
     * @returns {ILinkRewardsAnalyticsEvent} - The analytics data for the success screen
     */
    const _successLinkCardsScreenAnalyticsData = (sourceURL: string, linkStep: string): ILinkRewardsAnalyticsEvent => {
        return {
            event: centralRegistration,
            eventParameters: {
                sourceURL,
                source: dataConfig?.bannerId,
                step: linkStep,
            },
        }
    }

    return {
        setChooseLinkOptionAnalytics(linkOption: string) {
            provider.push(
                _linkRewardsAnalyticsData(
                    '',
                    linkOption,
                    step.chooseLoyaltyOptionFromOb,
                    action.continueToTriangleRewardsRegistration,
                    {},
                ),
            )
        },
        setLinkRewardsResultAnalytics(
            sourceURL: string,
            linkOption: string,
            loyaltyStep: string,
            actionResult: IRegistrationAnalyticsActionResult,
        ) {
            provider.push(
                _linkRewardsAnalyticsData(
                    sourceURL,
                    linkOption,
                    loyaltyStep,
                    action.submitTriangleRewardsRegistration,
                    actionResult,
                ),
            )
        },
        setSuccessScreenAnalytics(sourceURL: string, linkStep: string) {
            provider.push(_successLinkCardsScreenAnalyticsData(sourceURL, linkStep))
        },
    }
})()

export default linkRewardsAnalytics
