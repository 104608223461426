import React from 'react'

import { PREFIX } from '../config'
import { VehicleAvailabilitySectionProps } from './VehicleAvailabilitySection.type'
import Icon from '../Icon'
import { iconTypes } from '../VehicleProductCard/VehicleProduct.constant'
import { getAccessibilityAvailabilityId } from '../../utils/getAccessibilityId'
import { replaceStrWithDynamicVal } from '../../utils'
import LegacyVehicleAvailabilitySection from './LegacyVehicleAvailabilitySection'
import { choose } from '../../utils/choose'

/**
 * Returns Stock Icon
 * @param {string} iconType
 * @return {JSX.Element}
 */
const returnStockIcon = (iconType?: string): JSX.Element => {
    return (
        <span className={`${PREFIX}-product-card__availability-icon`}>
            <Icon type={`ct-${iconType ? iconType : iconTypes.checkmark}`} size="sm" />
        </span>
    )
}

/**
 * @description Vehicle availability section component
 * @param {VehicleAvailabilitySectionProps} props
 * @return {JSX.Element}
 */
function VehicleAvailabilitySection(props: VehicleAvailabilitySectionProps): JSX.Element {
    const {
        skuData,
        checkOtherStoresLabel,
        inStockAisleLabel,
        inStockOnlineLabel,
        outOfStockLabel,
        isUrgentLowStockLabel,
        isAutoPartPlp,
        accessibilityId,
        isUrgentLowStock,
        inStorePurchaseLabel,
    } = props

    // eslint-disable-next-line sonar/function-return-type
    const getAvailabilityLabel = (quantityFlag: number) => {
        const limitedStockMessage = isUrgentLowStockLabel

        if (isUrgentLowStock) {
            const urgencyMsg = limitedStockMessage
                ? replaceStrWithDynamicVal(limitedStockMessage, quantityFlag?.toString())
                : ''
            return (
                <span>
                    <span className={`${PREFIX}-product-card__urgentLowStock`}>{urgencyMsg}</span>
                </span>
            )
        } else {
            return replaceStrWithDynamicVal(inStockAisleLabel, quantityFlag?.toString() ?? '')
        }
    }

    const { sellable, orderable, fulfillment } = skuData
    const availability = fulfillment?.availability
    const quantity = availability?.quantity
    const Corporate = availability?.Corporate
    const corpQuantity = Corporate?.Quantity
    const wrap = (elem: JSX.Element) => (
        <div
            className={`${PREFIX}-product-card__availability-message`}
            id={isAutoPartPlp ? getAccessibilityAvailabilityId(accessibilityId) : undefined}>
            {elem}
        </div>
    )
    const getMessage = () =>
        choose()
            // Delegation to legacy logic if the toggle on
            .when(() => props.isUseLegacyLogicOfVehicleCheckAvailability)
            .then(() => <LegacyVehicleAvailabilitySection {...props} />)

            // Render skeleton with no information
            .when(() => !skuData?.fulfillment)
            .then(() => <div className={`${PREFIX}-product-card__availability-message`}></div>)

            // In store Only
            .when(() => quantity > 0 && !sellable && !orderable)
            .then(() => (
                <>
                    {wrap(
                        <>
                            {returnStockIcon()}
                            {getAvailabilityLabel(quantity)}
                        </>,
                    )}
                    {wrap(
                        <>
                            {returnStockIcon()}
                            {inStorePurchaseLabel}
                        </>,
                    )}
                </>
            ))

            // 0 in Stock
            .when(() => !quantity && corpQuantity > 0 && !orderable && !sellable)
            .then(() => (
                <>
                    {wrap(
                        <>
                            {returnStockIcon(iconTypes.prohibited)}
                            {getAvailabilityLabel(0)}
                        </>,
                    )}
                    {wrap(
                        <>
                            {returnStockIcon()}
                            {checkOtherStoresLabel}
                        </>,
                    )}
                </>
            ))
            // Out of stock
            .when(() => !quantity && !corpQuantity)
            .then(() => (
                <>
                    {wrap(
                        <>
                            {returnStockIcon(iconTypes.prohibited)}
                            {outOfStockLabel}
                        </>,
                    )}
                </>
            ))

            // In Stock
            .when(() => quantity > 0 && sellable)
            .then(() => (
                <>
                    {wrap(
                        <>
                            {returnStockIcon()}
                            {getAvailabilityLabel(quantity)}
                        </>,
                    )}
                </>
            ))

            // In stock online
            .when(() => !quantity && corpQuantity > 0 && sellable)
            .then(() => (
                <>
                    {wrap(
                        <>
                            {returnStockIcon()}
                            {inStockOnlineLabel}
                        </>,
                    )}
                </>
            ))

            // Check other store
            .when(() => !sellable && !orderable && (quantity > 0 || corpQuantity > 0))
            .then(() => (
                <>
                    {wrap(
                        <>
                            {returnStockIcon()}
                            {checkOtherStoresLabel}
                        </>,
                    )}
                </>
            ))

            // It would log non covered case if it was possible.
            .defaultThen(() => {
                console.error(
                    `Unexpected values: quantity=${quantity} corpQuantity=${corpQuantity}  sellable=${String(
                        sellable,
                    )} orderable=${String(orderable)}`,
                )
                return null
            })
            .get()

    return <>{getMessage()}</>
}

VehicleAvailabilitySection.displayName = 'VehicleAvailabilitySection'

export default VehicleAvailabilitySection
