// Use this file for string keyboard key codes.
/**
 * Key board key codes (string). keycode is deprecated
 */
// eslint-disable-next-line no-warning-comments
// TODO: This should be a Enum
export const stringKeyCodes: Record<string, string> = Object.freeze({
    esc: 'Escape',
    leftArrow: 'ArrowLeft',
    upArrow: 'ArrowUp',
    rightArrow: 'ArrowRight',
    downArrow: 'ArrowDown',
    enter: 'Enter',
    tab: 'Tab',
    space: ' ',
    backspace: 'Backspace',
})
