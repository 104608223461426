import { ToastProps } from '../../components/ToastWrapper/ToastWrapper.type'

export interface ToastMessage {
    [key: string]: string
}

export interface ToastDetails {
    showToast: boolean
    toastProps: ToastProps
    componentName: string
}

export enum ToastComponentNames {
    'BUYBOX_WISHLIST_SUCCESS' = 'BUYBOX_WISHLIST_SUCCESS',
    'BUYBOX_ERROR' = 'BUYBOX_ERROR',
    'BUYBOX_ATC_ERROR' = 'BUYBOX_ATC_ERROR',
    'NONE' = 'NONE',
    'CHECKOUT_MEMOIZED_TOAST' = 'CHECKOUT_MEMOIZED_TOAST',
    'BILLING_AND_SHIPPING_TOAST' = 'BILLING_AND_SHIPPING_TOAST',
    'CART_FLYOUT' = 'CART_FLYOUT',
    'CART_ITEMS_SUCCESS_WISHLIST' = 'CART_ITEMS_SUCCESS_WISHLIST',
    'CART_ITEMS_FAILURE_WISHLIST' = 'CART_ITEMS_FAILURE_WISHLIST',
    'CART_ITEMS_ADD_TO_CART' = 'CART_ITEMS_ADD_TO_CART',
    'CART_ITEMS_ADD_TO_SAVE_FOR_LATER_SUCCESS' = 'CART_ITEMS_ADD_TO_SAVE_FOR_LATER_SUCCESS',
    'CART_ITEMS_ADD_TO_SAVE_FOR_LATER_ERROR' = 'CART_ITEMS_ADD_TO_SAVE_FOR_LATER_ERROR',
    'CART_ITEMS_ADD_TO_CART_LIMIT_FAILURE' = 'CART_ITEMS_ADD_TO_CART_LIMIT_FAILURE',
    'SHOPPINGCART_FAILURE_POSTALCODE_UPDATE' = 'SHOPPINGCART_FAILURE_POSTALCODE_UPDATE',
    'SHOPPINGCART_SUCCESS_MERGECART' = 'SHOPPINGCART_SUCCESS_MERGECART',
    'SHOPPINGCART_SUCCESS_REMOVEITEM' = 'SHOPPINGCART_SUCCESS_REMOVEITEM',
    'SHOPPINGCART_SUCCESS_REMOVE_BALLOON_PACKAGE_ITEM' = 'SHOPPINGCART_SUCCESS_REMOVE_BALLOON_PACKAGE_ITEM',
    'SHOPPINGCART_SUCCESS_POSTALCODE_UPDATE' = 'SHOPPINGCART_SUCCESS_POSTALCODE_UPDATE',
    'SHOPPINGCART_SAVE_FOR_LATER_REMOVEITEM' = 'SHOPPINGCART_SAVE_FOR_LATER_REMOVEITEM',
    'SHOPPINGCART_SAVE_FOR_LATER_MOVE_TO_CART' = 'SHOPPINGCART_SAVE_FOR_LATER_MOVE_TO_CART',
    'SAVE_FOR_LATER_MOVE_TO_CART_FAILURE' = 'SAVE_FOR_LATER_MOVE_TO_CART_FAILURE',
    'GET_SAVE_FOR_LATER_FAILURE' = 'GET_SAVE_FOR_LATER_FAILURE',
    'SHOPPINGCART_SAVE_FOR_LATER_SUCCESS_WISHLIST' = 'SHOPPINGCART_SAVE_FOR_LATER_SUCCESS_WISHLIST',
    'SHOPPINGCART_SAVE_FOR_LATER_FAILURE_WISHLIST' = 'SHOPPINGCART_SAVE_FOR_LATER_FAILURE_WISHLIST',
    'TRIANGLE_MC_BANNER_ERROR' = 'TRIANGLE_MC_BANNER_ERROR',
    'LOGIN_SUCCESS' = 'LOGIN_SUCCESS',
    'SIGN_OUT_WARNING' = 'SIGN_OUT_WARNING',
    'CHECKOUT_UNAVAILABLE_ENTRY' = 'CHECKOUT_UNAVAILABLE_ENTRY',
    'CHECKOUT_CANCELLATION' = 'CHECKOUT_CANCELLATION',
    'SHOPPINGCART_SUCCESS_SHARE' = 'SHOPPINGCART_SUCCESS_SHARE',
    'SHOPPINGCART_WG_SERVICES' = 'SHOPPINGCART_WG_SERVICES',
    'ORDER_HISTORY_FAILURE' = 'ORDER_HISTORY_FAILURE',
    'REMOVE_WISHLIST_ITEM' = 'REMOVE_WISHLIST_ITEM',
    'TAB_CHANGE_STORE_SUCCESS' = 'TAB_CHANGE_STORE_SUCCESS',
    'OTP_DIALOG_FAILURE' = 'OTP_DIALOG_FAILURE',
    'PRODUCT_CARD' = 'PRODUCT_CARD',
    'PHONE_VERIFICATION_SUCCESS' = 'PHONE_VERIFICATION_SUCCESS',
    'BUY_NOW_ERROR' = 'BUY_NOW_ERROR',
    'BUY_NOW_MEMOIZED_TOAST' = 'BUY_NOW_MEMOIZED_TOAST',
}
