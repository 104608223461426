import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SpinnerTypes } from '@nl/lib'

export interface ShowSpinner {
    show: boolean
    type: string
}

export interface SpinnerState {
    showSpinner: ShowSpinner
    spinnerCounter: number
}
const initialState: SpinnerState = {
    showSpinner: { show: false, type: SpinnerTypes.DEFAULT },
    spinnerCounter: 0,
}

const spinnerSlice = createSlice({
    name: 'spinner',
    initialState,
    reducers: {
        startSpinnerWithCounter(state) {
            state.spinnerCounter++
        },
        stopSpinnerWithCounter(state) {
            state.spinnerCounter--
        },
        setShowSpinner(state, action: PayloadAction<Omit<ShowSpinner, 'type'> & Partial<Pick<ShowSpinner, 'type'>>>) {
            state.showSpinner.show = action.payload.show
            state.showSpinner.type = action.payload.type || SpinnerTypes.DEFAULT
        },
    },
})

export const { startSpinnerWithCounter, stopSpinnerWithCounter, setShowSpinner } = spinnerSlice.actions

export default spinnerSlice.reducer
