import { AxiosPromise } from 'axios'
import { EnvironmentConfig, getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { cdsTokenResponse, UserProfileData } from '../../redux/models/user.profile.interface'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import { GigyaAccountResp, GigyaJWTResp, OnGigyaTimeoutReadyError, ResponseStatusType } from '../../utils/gigya.type'
import localStorageService from '../../utils/localStorageService'
import { LiteNotificationPreferenceBody } from '../../redux/models/user.preferences.interface'
import { Mode } from '../../components/Accounts/SecuritySection/SecuritySection.constants'
import { InitRegistrationParams, ShowScreenSetParamsType } from '../../components/GigyaScreen/gigyaScreen.type'
import { gigyaActions, jwtPayloadData, status } from '../../components/GigyaScreen/gigya.constants'
import BaseService from '../base.service'
import { logGigyaReadinessStatus } from '../../components/NewRelic/gigyaNewRelic'
import { OnGigyaReadinessStatus } from '../../externals'
import { gigyaInitStatuses } from '../../components/NewRelic/newRelic.constant'

const httpClient = getHttpClient()
const environment: EnvironmentConfig = getEnvironment()
const { READY, FAILED, TIMEOUT } = gigyaInitStatuses

/**
 * Sso login service
 * TODO: name need to be changed
 */
class GigyaService {
    /**
     * Verify if Gigya is ready
     * @param {string} gigyaAction - The action to be performed with Gigya.
     * @returns {Promise<boolean>} A promise that resolves to true if Gigya is ready, otherwise false.
     */
    verifyGigyaReadiness(gigyaAction: string): Promise<void> {
        return new Promise((resolve, reject) => {
            window.onGigyaReadyPromise
                .then((response: OnGigyaReadinessStatus) => {
                    response.isReady
                        ? logGigyaReadinessStatus(READY, gigyaAction, response.gigyaWaitingTime)
                        : logGigyaReadinessStatus(FAILED, gigyaAction)

                    response.isReady
                        ? resolve()
                        : reject(new Error(`'Gigya WEB SDK was not initialized for action: ${gigyaAction}`))
                })
                .catch((error: OnGigyaTimeoutReadyError) => {
                    logGigyaReadinessStatus(TIMEOUT, gigyaAction, error.gigyaReadyTimewindow)
                    reject(error)
                })
        })
    }

    /**
     * set cookie after successful login
     * @param {string} ssoToken
     * @param {boolean} isSSOEnabled
     * @returns {Promise}
     */
    setCookie(ssoToken: string, isSSOEnabled: boolean): Promise<unknown> {
        const url = this.createSetCookieUrl(isSSOEnabled)
        const headers: HttpReqHeaders = {
            'X-Auth': `Bearer ${ssoToken}`,
        }
        return httpClient.apiGet(url, {}, headers)
    }

    /**
     * Create set cookie URL
     * @param {boolean} isSSOEnabled
     * @returns {string}
     */
    createSetCookieUrl(isSSOEnabled: boolean): string {
        return `${environment.API_BASE_URL}${
            environment.API_ENDPOINTS.setCookie
        }?refClient=odp/ctrweb&serviceClient=odp/ctrweb&refPath=%2F&cookiesEnabled=${isSSOEnabled.toString()}`
    }

    /**
     * Get JWT token with Gigya web sdk
     * @returns {Promise} Encoded JWT token
     */
    jwtToken(): Promise<GigyaJWTResp> {
        return new Promise((resolve, reject) => {
            this.verifyGigyaReadiness(gigyaActions.accounts.getJWT)
                .then(() => {
                    return window?.gigya?.accounts?.getJWT?.({
                        callback: (response: GigyaJWTResp) => {
                            response.id_token ? resolve(response) : reject(response)
                        },
                        apiKey: window.gigya.apiKey,
                        fields: [jwtPayloadData.loyaltyId, jwtPayloadData.epclId].join(','),
                    })
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Get Account/user profile Info from Gigya
     * @param {Record<string, string>} reqParam
     * @return {Promise}
     */
    getAccountInfo(reqParam: Record<string, string>): Promise<GigyaAccountResp> {
        return new Promise((resolve, reject) => {
            this.verifyGigyaReadiness(gigyaActions.accounts.getAccountInfo)
                .then(() => {
                    return window?.gigya?.accounts?.getAccountInfo?.({
                        ...reqParam,
                        callback: (response: GigyaAccountResp) => {
                            resolve(response)
                        },
                    })
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Verify session info from Gigya if user is authenticated or not
     * resolve status of gigya is ready
     * See {@link https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/c4b6178f43934cd88d66cff9a38d38ca.html?q=fullEventName | SAP verify session }
     * @return {Promise}
     */
    verifySession(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.verifyGigyaReadiness(gigyaActions.accounts.session.verifySession)
                .then(() => {
                    return window?.gigya?.accounts?.session?.verify({
                        callback(response: ResponseStatusType) {
                            const { errorCode } = response
                            const isVerified = status.ok === response.status && errorCode === status.authorizedUser
                            if (isVerified) {
                                window.userGigyaData = response
                            }
                            resolve(isVerified)
                        },
                    })
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    logoutUser(params: { callback: () => void; UID: string }): void {
        this.verifyGigyaReadiness(gigyaActions.accounts.logoutUser)
            .then(() => {
                window.gigya.accounts.logout?.({
                    ...params,
                    apiKey: window.gigya.apiKey,
                })
            })
            .catch(error => {
                throw new Error(error)
            })
    }

    showScreenSet(params: ShowScreenSetParamsType): void {
        this.verifyGigyaReadiness(gigyaActions.accounts.showScreenSet)
            .then(() => {
                window.gigya.accounts.showScreenSet(params)
            })
            .catch(error => {
                throw new Error(error)
            })
    }

    initRegistration(params: InitRegistrationParams): void {
        this.verifyGigyaReadiness(gigyaActions.accounts.initRegistration)
            .then(() => {
                window.gigya.accounts.initRegistration?.(params)
            })
            .catch(error => {
                throw new Error(error)
            })
    }

    login(param: Record<string, unknown>): void {
        this.verifyGigyaReadiness(gigyaActions.sso.login)
            .then(() => {
                window.gigya.sso.login(param)
            })
            .catch(error => {
                throw new Error(error)
            })
    }

    /**
     *
     * Get cds access token
     * @param  {string} gigyaJwt
     * @returns {AxiosPromise}
     */
    cdsAccessToken(gigyaJwt: string): AxiosPromise<cdsTokenResponse> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsAccessToken}`
        const payload = !!localStorageService.getItem('remember')
            ? { rememberMe: localStorageService.getItem('remember') === 'true' }
            : {}

        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJwt}`,
        }
        return httpClient.apiPost<cdsTokenResponse>(url, payload, headers, true)
    }
    /**
     * Load user profile from CDS
     * @param  {string|null} gigyaJwt token
     * @param  {boolean} isLiteProfile boolean value
     * @returns {UserProfileData} User Profile Data
     */
    userProfile(gigyaJwt: string | null, isLiteProfile?: boolean): Promise<{ data: UserProfileData }> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsUserProfile}`
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${String(gigyaJwt)}`,
        }

        return httpClient.apiGet(url, { lang: BaseService.language, checkStatus: !isLiteProfile }, headers, true)
    }

    /**
     *
     * Call CDS resend verification API
     * @param {string} UID
     * @returns {AxiosPromise}
     */
    resendVerification(UID: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.resendVerification}/${UID}`
        return httpClient.apiPost(url, undefined, undefined, true)
    }

    /**
     * Function to verify OTP Security Code
     * @param {string} otpSecurityCode - OTP Security Code from cds
     * @param {string} vToken - vToken from cds
     * @returns {Promise}
     */
    verifyOtpCode(otpSecurityCode: string, vToken: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.validateOtpCode}`
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        return httpClient.apiPost(url, { vToken, code: otpSecurityCode }, headers, true)
    }

    updatePassword(password: string, newPassword: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.updatePassword}`
        const body = { password, newPassword }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    registrationTfaPhoneNumber(phoneNumber: string, method: string, mode: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.registrationTfaPhoneNumber}`
        const body = { phone: phoneNumber, method, mode }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    getTFAPhoneNumber(): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.getTFAPhoneNumber}`
        const body = { mode: Mode.ADD }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    verifyPhoneVerificationCode(
        phoneVerificationCode: string,
        phvToken: string,
        gigyaAssertion: string,
        mode: string,
    ): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.verifyPhoneVerificationCode}`
        const body = { gigyaAssertion, phvToken, code: phoneVerificationCode, mode }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    /**
     * Call CDS reset password API
     * @param {string} passwordResetToken
     * @param {string} newPassword
     * @returns {AxiosPromise}
     */
    resetPassword(passwordResetToken: string, newPassword: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.resetPassword}`
        const body = { passwordResetToken, newPassword }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    /**
     * Call CDS reset password API to send email with link to the reset password form
     * @param {string} loginID
     * @returns {AxiosPromise}
     */
    sendEmilToResetPassword(loginID: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.resetPassword}`
        const body = { loginID }
        return httpClient.apiPost(url, JSON.stringify(body), undefined, false)
    }

    /**
     * Call CDS notificationPreferenceLite API to update account information
     * @param {LiteNotificationPreferenceBody} accountInfo
     * @returns {AxiosPromise}
     */
    updateAccountInformation(accountInfo: LiteNotificationPreferenceBody): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.notificationPreferenceLite}`
        return httpClient.apiPost(url, JSON.stringify(accountInfo), undefined, false)
    }
}

export { GigyaService }
export default GigyaService
