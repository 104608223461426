import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'

import { PREFIX } from '../config'
import { ITabs, TabVariants } from './Tabs.type'
import { Role, Events, Direction, Position, Size } from '../../types'
import TabV2 from './TabV2'
import { stringKeyCodes, useOnClickOutside, stringifyElement, checkNotNullAndUndefined } from '../../utils'
import {
    arrowActionIdentifier,
    handleArrowsAccessibility,
    handleClickOrEnterAccessibility,
    outsideActionHandler,
} from '../../helpers/keyboardAccessibility.helper'
import { useOnKeyDownOutside } from '../../utils/useOnKeyDownOutside'

/**
 * TabsV2 component (version 2)
 * @param {ITabs} prop values
 * @returns {JSX.Element} returns TabsV2 component
 */
const TabsV2: React.FC<ITabs> = ({ ...props }): JSX.Element => {
    const {
        selected,
        isPrerenderMode,
        id,
        children,
        tabCallback,
        style = {
            direction: Direction.HORIZONTAL,
            position: Position.TOP,
            variant: TabVariants.DEFAULT,
            tabGap: Size.MINI,
            tabsGap: Size.MEDIUM,
            tabContentGap: Size.XS,
        },
    } = props
    const componentClass = `${PREFIX}-tabs`
    const TabWrapperClass = `${PREFIX}-tab__list`
    const labelList = children.map(child => child?.props?.label)

    const initActiveTab = useMemo(() => selected || stringifyElement(labelList[0]), [labelList, selected])
    const [activeTab, setActiveTab] = useState(initActiveTab)
    const tabsRef = useRef(null)
    const outsideActionCallback = useCallback(() => outsideActionHandler(tabsRef.current), [])

    const tabDirection = style.direction === Direction.VERTICAL ? ` ${PREFIX}-xs-column` : ''
    const tabVariant = `${TabWrapperClass}--${style.variant}`
    const tabGap =
        style.tabGap !== 'none' && !checkNotNullAndUndefined(style.tabGap) ? ` ${PREFIX}-tab--gap-${style.tabGap}` : ''
    const tabsGap =
        style.tabsGap !== 'none' && !checkNotNullAndUndefined(style.tabsGap)
            ? ` ${componentClass}--gap-${style.tabsGap}`
            : ''
    const tabContentGap =
        style.tabContentGap !== 'none' && !checkNotNullAndUndefined(style.tabContentGap)
            ? `${PREFIX}-xs-flex ${PREFIX}-xs-align-items-center ${PREFIX}-tab--gap-${style.tabContentGap}`
            : ''

    /**
     * position of where the tab will be located
     * @returns {string} position of tab
     */
    const tabPosition = (): string => {
        const defaultPosition = `${componentClass}--${style.position as Position} ${PREFIX}-xs-flex`
        switch (style.position) {
            case Position.LEFT:
                return defaultPosition
            case Position.BOTTOM:
                return `${defaultPosition} ${PREFIX}-xs-column-reverse`
            case Position.RIGHT:
                return `${defaultPosition} ${PREFIX}-xs-row-reverse`
            case Position.TOP:
            default:
                return `${defaultPosition} ${PREFIX}-xs-column`
        }
    }

    // useEffect to set initial active tab
    useEffect(() => {
        setActiveTab(initActiveTab)
    }, [initActiveTab])

    // Handling edge cases with respect to Tabs accessibility
    // Already in useEffect state as a hook
    useOnClickOutside(tabsRef, outsideActionCallback)
    useOnKeyDownOutside(tabsRef, outsideActionCallback)

    /**
     * This function handles click or enter event for tab both accessibility and actual event
     * @param {React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>} e mouse event element
     * @param {number} index index of keyboard element triggered
     * @param {string} label label of keyboard element triggered
     * @returns {void}
     */
    const handleSwitchTab = (
        e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        index: number,
        label: string,
    ): void => {
        if (e.type === Events.CLICK) {
            handleClickOrEnterAccessibility(e, index, tabsRef.current)
            handleSwitchEvent(label)
            tabCallback(label)
        } else if (e.type === Events.KEYDOWN) {
            handleKeyDown(e as React.KeyboardEvent<HTMLDivElement>, index, label)
        } else null
    }

    /**
     * This function handles keydown event for tab
     * @param {React.KeyboardEvent<HTMLDivElement>} e keyboard element
     * @param {number} index index of keyboard element triggered
     * @param {string} label label of keyboard element triggered
     * @returns {void}
     */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number, label: string): void => {
        const { key } = e
        const { rightArrow, leftArrow, enter, space } = stringKeyCodes
        const { NEXT, PREVIOUS } = arrowActionIdentifier
        switch (key) {
            case rightArrow:
                handleArrowsAccessibility(e, NEXT, index, tabsRef.current)
                break
            case leftArrow:
                handleArrowsAccessibility(e, PREVIOUS, index, tabsRef.current)
                break
            case enter:
            case space:
                handleSwitchTab(e, index, label)
                break
            default:
                break
        }
    }

    /**
     * Function to call callback function to set active tab
     * @param {string} selectedTab - selected tab label
     * @returns {void}
     */
    const handleSwitchEvent = (selectedTab: string): void => setActiveTab(selectedTab)

    /**
     * Function to return Tab List (aka tab header)
     * @returns {JSX.Element[]} returns Overlay component
     */
    const renderTabList = (): JSX.Element[] =>
        labelList.map((label: React.ReactNode, i: number): JSX.Element => {
            const insertContentGap = typeof label !== 'string' ? { gap: tabContentGap } : {}
            return (
                <TabV2
                    key={id ? `${Role.TAB}-${id}-${i}` : `${Role.TAB}label-${i}`}
                    isActive={Boolean(stringifyElement(label) === activeTab)}
                    onSwitchTab={handleSwitchTab}
                    label={stringifyElement(label)}
                    index={i}
                    {...insertContentGap}>
                    {label}
                </TabV2>
            )
        })

    return (
        <section className={`${componentClass} ${tabPosition()}${tabsGap}`} id={id}>
            <div
                ref={tabsRef}
                className={`${TabWrapperClass} ${PREFIX}-xs-flex${tabDirection}${tabGap} ${tabVariant}`}
                aria-labelledby={`${Role.TABLIST}-${id ? id : 1}`}
                role={Role.TABLIST}>
                {renderTabList()}
            </div>
            {children.map((child, i: number) =>
                !isPrerenderMode && stringifyElement(child.props.label) !== activeTab ? null : (
                    <div
                        className={`${PREFIX}-tab__content`}
                        key={id ? `${Role.TABPANEL}-${id}-${i}` : `${Role.TABPANEL}label-${i}`}
                        id={`${Role.TABPANEL}-${i}`}
                        aria-labelledby={`tab-${i}`}
                        role={Role.TABPANEL}>
                        {child.props.children}
                    </div>
                ),
            )}
        </section>
    )
}

export default TabsV2
