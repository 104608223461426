import { gigyaNewRelicResources, gigyaInitStatuses } from './newRelic.constant'
import { logNewRelicInfo, logNewRelicError } from './newRelic.helper'

const { READY, FAILED, TIMEOUT } = gigyaInitStatuses

/**
 * Processes fetched gigya rediness status and logs the appropriate information to New Relic.
 * @param {string} gigyaStatus - The status of Gigya initialization
 * @param {string} gigyaAction - The action to be performed with Gigya.
 * @param {string | undefined} time - The time taken for Gigya to be ready or time taken for the timeout
 * @returns {void}
 */
export const logGigyaReadinessStatus = (gigyaStatus: string, gigyaAction: string, time?: number): void => {
    switch (gigyaStatus) {
        case READY: {
            time &&
                logNewRelicInfo({
                    logInfo: { resolutionTime: `${time}ms`, gigyaAction },
                    resource: gigyaNewRelicResources.gigyaWebSdkInitiated,
                })
            break
        }
        case FAILED: {
            logNewRelicError({
                error: 'Gigya isReady failed after initialization when try to execute',
                errorInfo: { gigyaAction },
                resource: gigyaNewRelicResources.gigyaIsReadyFailed,
            })
            break
        }
        case TIMEOUT: {
            time &&
                logNewRelicError({
                    error: `not initiated in ${time}ms timewindow`,
                    errorInfo: { gigyaAction },
                    resource: gigyaNewRelicResources.gigyaWebSdkInitializationFailed,
                })
            break
        }
        default:
    }
}
