import { PREFIX } from '../config'

export const minimumVariantsLength = 1
export const staggeredSkuLength = 2
export const setOfFourCount = 4
export const minimumSpecificationCount = 0
export const maximumSpecificationCount = 3
export const productCardVehicleClassPrefix = `${PREFIX}-product-card-vehicle`

export const setOfTwoCount = 2
export const iconTypes = {
    checkmark: 'checkmark',
    prohibited: 'prohibited',
}
