export enum SearchRequestHeaderParams {
    X_RINGFENCE = 'x-ringfence',
    X_BR_UID = 'X-BR-UID',
    X_BR_REF_URL = 'X-BR-REF-URL',
    X_BR_REF = 'X-BR-REF',
    LANG = 'lang',
    COUNT = 'count',
    HIDE_FACETS = 'hideFacets',
    WIDGET_ID = 'widgetId',
    WIDGET_TYPE = 'widgetType',
    EXPERIENCE = 'experience',
    CATEGORY_LEVEL = 'categoryLevel',
    CATEGORY_CODE = 'categoryCode',
    Q = 'q',
    BRAND = 'brand',
    PAGE_TYPE = 'pageType',
    LIGHT = 'light',
}

export enum CriteoHeaderParams {
    SPONSORED_PRODUCTS = 'sponsoredProducts',
    INCLUDE_SPONSORED_PRODUCTS = 'injectsponsoredproducts',
    DEVICE_TYPE = 'deviceType',
    X_CUSTOMER_ID = 'x-customer-id',
    X_RETAILER_VISITOR_ID = 'x-retailer-visitor-id',
    CRITEO_CATEGORY_CODE = 'criteoCategoryCode',
    CRITEO_IS_FITMENT_SELECTED = 'criteoIsFitmentSelected',
}
