import { Category } from './../models/secondaryNavigation.interface'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/secondaryNavigation.actionCreators'
import { setSiteMapLinks } from '../actionCreators/secondaryNavigation.actionCreators'

type Action = ActionType<typeof actions>

export const initialState = []
/**
 * update site map category Values
 * @param { Object } state set to initial state by default
 * @param { Action } action action called
 * @return {Object}
 *
 */
export const siteMapReducer = (state: Category[] = initialState, action: Action): Category[] => {
    if (action.type === getType(setSiteMapLinks)) {
        return [...action.payload]
    }
    return state
}
