import { useCallback } from 'react'
import { LoyaltyBadge } from '../components/Badges/Badges.type'
import { getFormattedPriceWithLocale } from '../utils/formatPrice'
import { ProductQuantity } from '../components/ProductReusableCard/product.types'

/**
 * This function used to calculate loyalty value
 * @param {LoyaltyBadge} loyaltyObj - loyalty object
 * @param {boolean} isStaggered - flag that indicates if product is staggered
 * @param {ProductQuantity} productQuantity - quantity object for product
 * @returns {LoyaltyBadge | undefined} return calculated loyalty value
 */
export const useCalculatedLoyaltyValue = (
    loyaltyObj: LoyaltyBadge,
    isStaggered?: boolean,
    productQuantity?: ProductQuantity,
): LoyaltyBadge | undefined => {
    const {
        frontQuantitySelected = 0,
        rearQuantitySelected = 0,
        quantitySelected = 0,
        qtySelectorInput = 0,
    } = productQuantity
    const { OfferRewardValue = '', RewardPercentage, OfferRule } = loyaltyObj?.Bonus || {}

    /**
     * Calculates the loyalty value based on the provided quantities and loyalty object.
     * @returns {LoyaltyBadge | undefined} The calculated loyalty badge object or undefined.
     */
    const calculatedLoyaltyValue = useCallback((): LoyaltyBadge | undefined => {
        let quantity: number
        if (isStaggered) {
            if (qtySelectorInput === frontQuantitySelected) {
                quantity = frontQuantitySelected
            } else {
                quantity = rearQuantitySelected ?? quantitySelected
            }
        } else {
            quantity = qtySelectorInput
        }

        return {
            ...loyaltyObj,
            Bonus: {
                OfferRewardValue: getFormattedPriceWithLocale(Number(OfferRewardValue) * quantity),
                OfferRule: OfferRule || '',
                RewardPercentage: RewardPercentage || '',
            },
        }
    }, [
        OfferRewardValue,
        OfferRule,
        RewardPercentage,
        frontQuantitySelected,
        isStaggered,
        loyaltyObj,
        qtySelectorInput,
        quantitySelected,
        rearQuantitySelected,
    ])

    return calculatedLoyaltyValue()
}
