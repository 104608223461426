import { getProvider } from '../providers/index'
import { CompleteSearchAnalytics } from '../analytics.type'

/**
 * Complete search analytics GA event
 * @param {string} event
 * @param {CompleteSearchAnalytics} data
 */
export const completeSearchAnalytics = (event: string, data: CompleteSearchAnalytics): void => {
    getProvider().push({
        event,
        search: {
            userInput: data?.userInput || '',
            redirect: data?.redirect ? `${data.redirect}` : null,
            searchType: data?.searchType,
            numberSearchResults: data.numberSearchResults || null,
            suggestionType: data?.suggestionType,
            suggestionValue: data?.suggestionValue,
            suggestionUpdateUserInput: data?.isTypeaheadUsed || null,
            pathwaysWidgetId: data?.pathwaysWidgetId || null,
            ...(data.searchLocation && { searchLocation: data?.searchLocation }),
        },
    })
}
