import { Dispatch } from '@reduxjs/toolkit'

import {
    openMiniPDPFlyoutAction,
    setMiniPDPFlyoutDetailsAction,
    initMiniPDPFlowAction,
    fetchMiniPDPFlyoutDataErrorAction,
    updateScuDataByStoreAction,
} from '../actionCreators'
import { ProductResponseData, ProductSkusData } from '../models/product.interface'
import { productDetailsService } from '../../services/productDetailsService/productDetails.service'
import { SkuListType } from '../../components/FrequentlyBoughtTogether/FrequentlyBoughtTogether.type'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { RootState } from '../reducers'
import { ToastComponentNames } from '../models/toastMessage.interface'
import { dispatchToast } from '../../components/ToastMessage/ToastMessage.helper'
import { statusIcons } from '../../globalConstants'
import { getProductTitle, isHomeServiceItem } from '../../components/BuyBox/BuyBox.helper'
import { SIZECHART_CROSS_BRAND } from '../../components/BuyBox/BuyBox.constant'
import { isAutomotivePDP } from '../../components/Vehicles/Vehicle.helper'
import { isArrayNotEmpty, libUtils } from '@nl/lib'
import { SizeChartDataType } from '../../components/BuyBox/SizeChart/SizeChart.type'
import { AxiosResponse } from 'axios'

/**
 *
 * @param productCode
 * @param storeId
 * @param selectedColourVariant
 */
export const initMiniPDPFlyout =
    (
        productCode: string,
        storeId: string,
        selectedColourVariant?: string,
        isMultiSku?: boolean,
        isQuickLookFromCertona?: boolean,
    ) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const {
            miniPDPFlyoutData: { productDataList },
            toastMessageData: { toastMessageData },
            commonContent: {
                commonContentAvailable: { general, product, featureFlag },
            },
        } = getState()
        const chachedData = productDataList[productCode]
        const { addToCartFailureError } = toastMessageData
        const { closeLabel } = general
        const { homeServicesTitle } = product
        const { enableNewSizeChart } = featureFlag

        try {
            if (chachedData && storeId === chachedData.currentStoreId) {
                dispatch(
                    openMiniPDPFlyoutAction({
                        code: productCode,
                        selectedColourVariant,
                        isMultiSku,
                        isQuickLookFromCertona,
                    }),
                )
            } else {
                dispatch(initMiniPDPFlowAction({ code: productCode, selectedColourVariant, isQuickLookFromCertona }))
                const productDataResponse = await productDetailsService.getProductDetails(productCode, storeId, false)
                replaceEmptyImagesWithDefault([productDataResponse?.data] as Record<string, any>[], 'images')
                const skusForAPI = getSkusDataForPriceAvailabilityRequest(
                    productDataResponse?.data as ProductResponseData,
                )
                const {
                    miniPDPFlyoutData: { currentCode },
                } = getState()
                const [variantsProductSkuDataResponse, sizeChartData] = await Promise.all([
                    productDetailsService.getProductDetailsOfSku(skusForAPI, storeId, undefined, true),
                    getSizeChartData(
                        productDataResponse?.data as ProductResponseData,
                        homeServicesTitle,
                        enableNewSizeChart as boolean,
                    ),
                ])
                dispatch(
                    setMiniPDPFlyoutDetailsAction({
                        productData: productDataResponse?.data as ProductResponseData,
                        variantsProductSkuData: variantsProductSkuDataResponse?.data as ProductSkusData,
                        currentStoreId: storeId,
                        isMultiSku,
                        isFlyoutVisible: currentCode === productCode,
                        sizeChartData: sizeChartData?.data as SizeChartDataType,
                    }),
                )
            }
        } catch (error) {
            dispatch(fetchMiniPDPFlyoutDataErrorAction())
            dispatchToast(
                true,
                {
                    options: {
                        toastCloseLabel: closeLabel,
                        toastErrorMessage: addToCartFailureError,
                        toastErrorIcon: statusIcons.failureIcon,
                    },
                    success: false,
                    failed: true,
                    hideCTA: false,
                },
                ToastComponentNames.PRODUCT_CARD,
                dispatch,
            )
        }
    }

/**
 *
 * @param productData
 * @param storeId
 */
export const updateScuDataByStore =
    (productData: ProductResponseData, storeId: string) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const {
            toastMessageData: { toastMessageData },
            commonContent: {
                commonContentAvailable: { general },
            },
        } = getState()
        const { addToCartFailureError } = toastMessageData
        const { closeLabel } = general

        try {
            const skusForAPI = getSkusDataForPriceAvailabilityRequest(productData)
            const variantsProductSkuDataResponse = await productDetailsService.getProductDetailsOfSku(
                skusForAPI,
                storeId,
                undefined,
                true,
            )

            dispatch(
                updateScuDataByStoreAction({
                    variantsProductSkuData: variantsProductSkuDataResponse?.data as ProductSkusData,
                    currentStoreId: storeId,
                }),
            )
        } catch (error) {
            dispatch(fetchMiniPDPFlyoutDataErrorAction())
            dispatchToast(
                true,
                {
                    options: {
                        toastCloseLabel: closeLabel,
                        toastErrorMessage: addToCartFailureError,
                        toastErrorIcon: statusIcons.failureIcon,
                    },
                    success: false,
                    failed: true,
                    hideCTA: false,
                },
                ToastComponentNames.PRODUCT_CARD,
                dispatch,
            )
        }
    }

/**
 *
 * @param productData
 */
const getSkusDataForPriceAvailabilityRequest = (productData: ProductResponseData): SkuListType[] => {
    const {
        skus,
        lowStockThreshold,
        brand: { label },
    } = productData || {}

    return skus?.map(item => ({
        code: item.code,
        lowStockThreshold,
        brand: label,
    }))
}

const getSizeChartData = (
    productData: ProductResponseData,
    homeServicesTitle: string,
    enableNewSizeChart: boolean,
): Promise<AxiosResponse<SizeChartDataType> | Record<string, unknown>> => {
    const homeService = isHomeServiceItem(productData?.type)
    const brand = getProductTitle(homeService, homeServicesTitle, productData)
    const sizeChartBrand = brand || SIZECHART_CROSS_BRAND
    const isAutomotive = isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType)
    const language = libUtils.getLanguage()
    const breadcrumbList = productData?.breadcrumbList || []
    const needToFetchSizeChartData = enableNewSizeChart ? !isAutomotive : productData?.isSizeChartAvailable
    if (isArrayNotEmpty(breadcrumbList) && needToFetchSizeChartData) {
        return productDetailsService
            .getSizeChartData(breadcrumbList, sizeChartBrand, language, enableNewSizeChart)
            .catch(() => ({} as Record<string, unknown>))
    }

    return Promise.resolve({})
}
