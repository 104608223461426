import { Dispatch } from '@reduxjs/toolkit'
import {
    fetchBalloonAddonsAction,
    balloonAddonsFailureAction,
    setBalloonAddonsPriceAction,
} from '../actionCreators/balloonAddons.actionCreators'

import { SuccessResponse, ErrorResponse, HardwareParams, HardwareSkuData } from '../models/extraHardware.interface'
import { balloonAddonsService } from '../../services/balloonAddonsService'
import { AxiosError } from 'axios'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { getEnvironment } from '../../environments'
export const getBalloonAddons =
    (params: HardwareParams) =>
    (dispatch: Dispatch): void => {
        const config = getEnvironment()
        balloonAddonsService
            .fetchBalloonAddons()
            .then((balloonAddonsData: { data: SuccessResponse }) => {
                replaceEmptyImagesWithDefault(
                    balloonAddonsData?.data?.results?.map(result => result?.field),
                    'thumb-img-url',
                    config.defaultProductImage,
                )
                const response = balloonAddonsService.transformData(balloonAddonsData.data, params.sku)
                dispatch(fetchBalloonAddonsAction(response))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                const errorResponse = (error.response ? error.response.data : error) as ErrorResponse
                dispatch(balloonAddonsFailureAction(errorResponse))
            })
    }

export const setBalloonAddonsPrice =
    (balloonAddonsSkuData: HardwareSkuData) =>
    (dispatch: Dispatch): void => {
        dispatch(setBalloonAddonsPriceAction(balloonAddonsSkuData))
    }
