import { Dispatch } from '@reduxjs/toolkit'

import { fetchEstimateFeesAction, estimateFeesFailureAction, resetEstimateFeesAction } from '../actionCreators'
import { estimateFeesService } from '../../services/estimateFeesService'
import { EstimateFees, EstimateFeesParams } from '../models/estimateFees.interface'
import { setShowSpinner } from '../slices/spinner.slice'

export const getEstimateFees =
    (params: EstimateFeesParams, showSpinner = false) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner({ show: true }))
        estimateFeesService
            .fetchEstimateFees(params)
            .then((estimateFeesData: { data: EstimateFees }) => {
                if (estimateFeesData.data && estimateFeesData.data?.shippingFees) {
                    dispatch(fetchEstimateFeesAction(estimateFeesData.data))
                } else {
                    dispatch(estimateFeesFailureAction())
                }
            })
            .catch(() => {
                dispatch(estimateFeesFailureAction())
            })
            .finally(() => {
                dispatch(setShowSpinner({ show: false }))
            })
    }

/**
 * function to reset Estimate Fees
 * @return {Dispatch}
 */
export const resetEstimateFees =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetEstimateFeesAction())
    }
