import {
    getCountryIsoCode,
    getCountryRegionNameFromCode,
} from '../Checkout/PaymentInformation/PaymentInformation.helper'
import { defaultCountryName } from '../Accounts/Addresses/Addresses.constant'
import { UserProfileData } from '../../redux/models/user.profile.interface'
import { CountriesResponseItem, ProvinceRegions } from '../../redux/models/loyaltyRegisterForm.interface'
import { InitialAddressType } from '../Checkout/ShippingAddress/ShippingAddress.types'
import { PaymentUnitType } from '../../redux/models/profile.payment.interface'
import { BuyNowCTFSErrorType, BuyNowPlaceOrderRequest } from './BuyNowModal.type'
import { IFedErrors } from '../../redux/models/commonContent.interface'
import { paymentInformationConst } from '../Checkout/PaymentInformation/cardTypeConstants'

/** Function to collect billing address object
 * @param {UserProfileData} userProfileData userProfileData
 * @param {CountriesResponseItem} countriesData countriesData
 * @param {ProvinceRegions} regions regions
 * @param {boolean} shippingAddress shippingAddress
 * @returns {object} billing address object
 */
export const prepareBillingAddressObject = (
    userProfileData: UserProfileData,
    countriesData: CountriesResponseItem[],
    regions: ProvinceRegions[],
    shippingAddress: boolean,
) => {
    const { primaryBillingAddress: billingInfo = {} as InitialAddressType, firstName, lastName } = userProfileData
    const countryDataObj = {
        isocode: getCountryIsoCode(billingInfo?.country) as string,
        name:
            getCountryRegionNameFromCode(billingInfo?.country as string | Record<string, string>, countriesData) ||
            defaultCountryName,
    }

    return {
        country: countryDataObj || undefined,
        line1: billingInfo?.addressLineOne,
        line2: billingInfo?.addressLineTwo || undefined,
        postalCode: billingInfo?.postalCode,
        region: {
            countryIso: billingInfo?.country || 'CA',
            isocode: billingInfo?.province || '',
            isocodeShort: '',
            name: getCountryRegionNameFromCode(billingInfo?.province, regions) || '',
        },
        town: billingInfo?.city,
        firstName: (billingInfo?.firstName as string) || (firstName as string),
        lastName: (billingInfo?.lastName as string) || (lastName as string),
        shippingAddress,
    }
}

/** Function to prepare place order object
 * @param {UserProfileData} userProfileData userProfileData
 * @param {CountriesResponseItem} countriesData countriesData
 * @param {ProvinceRegions} regions regions
 * @param {PaymentUnitType} profileCardData profileCardData
 * @returns {BuyNowPlaceOrderRequest} place order object
 */
export const preparePlaceOrderObject = (
    userProfileData: UserProfileData,
    countriesData: CountriesResponseItem[],
    regions: ProvinceRegions[],
    profileCardData: PaymentUnitType,
): BuyNowPlaceOrderRequest => {
    const cardType = profileCardData ? profileCardData.type : ''
    return {
        contactInfo: {
            email: userProfileData?.email,
            phone: userProfileData?.addresses?.phone,
        },
        deliveryAddress: {},
        paymentInfo: {
            accountHolderName: `${userProfileData?.firstName as string} ${userProfileData?.lastName as string}`,
            billingAddress: prepareBillingAddressObject(userProfileData, countriesData, regions, false),
            cardNumber: profileCardData ? profileCardData.maskedCardNumber : '',
            cardType: { code: cardType, name: cardType },
            expiryMonth: profileCardData ? profileCardData.validToMonth : '',
            expiryYear: profileCardData ? profileCardData.validToYear : '',
            loyaltyCardNumber: userProfileData?.loyalty?.cardNumber,
            eligibleForFinancing: false,
            defaultPayment: false,
            newCreditCard: false,
            saveToMyProfile: true,
            saved: false,
        },
    }
}

/**
 * Returns error message based on ctfs error code
 * @param { IFedErrors } fedErrors
 * @param { string } errorCode
 * @return { BuyNowCTFSErrorType } errorMessage
 */
export const getPaymentBuyNowErrorMessage = (fedErrors: IFedErrors, errorCode: string): BuyNowCTFSErrorType => {
    const { error100, error400, error800, errorPopupDefaultSubtitle } = fedErrors || {}
    switch (errorCode) {
        case paymentInformationConst.ctfsError100:
            return { message: error100 }
        case paymentInformationConst.ctfsError400:
            return { message: error400 }
        case paymentInformationConst.ctfsError800:
            return { message: error800 }
        default:
            return { message: errorPopupDefaultSubtitle }
    }
}

export const defaultCard = {
    validToYear: '',
    default: '',
    validToMonth: '',
    type: '',
    maskedCardNumber: '',
    cardLogoType: '',
} as PaymentUnitType
