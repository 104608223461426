import { PREFIX } from '../../config'

/**
 * Get the bottom style for the RewardsHub element.
 * @param {boolean} isRewardsHubEnable - Flag to enable or disable the Rewards Hub.
 * @returns {string} The bottom style in pixels or empty if the element is not found.
 */
export const getRewardsHubBottomStyle = (isRewardsHubEnable: boolean): string => {
    const rewardsHubClass = document.querySelector(`.${PREFIX}-rewards-hub`)
    return rewardsHubClass && isRewardsHubEnable ? `${PREFIX}-rewards-hub--bottom` : ''
}
