import { AxiosPromise } from 'axios'

import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import {
    getParamValue,
    updateParam,
    queryParameters,
    Vehicle,
    isArrayNotEmpty,
    getCookieValue,
    getSAPIRequestQueryParam,
    searchConstants,
    addParam,
    updateUrlHistory,
    excludeUtmParams,
    AutoAttributes,
} from '@nl/lib'
import { MagicNumber } from '../../analytics/analytics.type'
import getCategoriesFromURL from '../../utils/PLP/getCategoriesFromURL'
import getPageType from '../../utils/getPageType'
import { pageTypes, REF_URL_KEY } from '../../config'
import { REQUEST_CASHING_DURATION, searchPageTypes } from '../service.constants'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { categoryLevelPrefix, FacetList } from '../../components/Vehicles/Vehicles.constant'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import sessionStorageService from '../../utils/sessionStorageService'
import { WeatherTechVehicle } from '../../redux/models/weatherTech.interface'
import {
    CompareProductSpecificationResponse,
    GetProductDataOptionalValues,
    ProductDataType,
} from '../../redux/models/productData.interface'
import memoizeAsync from '../../utils/HttpClientUtils/memoizeAsync.utils'
import { getFullTireSize } from '../../components/Vehicles/Vehicle.helper'
import { extractCategoryLevelNumberForCustomPlp } from '../../utils/PLP/extractCategoryLevelNumberForCustomPlp'
import { SearchRequestHeaderParams, CriteoHeaderParams } from './plpService.constants'
import { PreselectedVehicleValues } from '../../redux/models/preselectedVehicle.interface'
import { TireType } from '../../redux/models/tireVehicle.interface'
import { CategoryPagesType } from '../../global.type'

const environment = getEnvironment()
const httpClient = getHttpClient()
const apiGetWithCaching = memoizeAsync(REQUEST_CASHING_DURATION, httpClient.apiGet)
const aemPageType = getPageType()
const searchPageType = searchPageTypes[`${aemPageType}` as keyof typeof searchPageTypes]?.x1
const enableSearchPageParameter = () => {
    return (
        aemPageType === pageTypes.brandCategoryPage ||
        aemPageType === pageTypes.promoListing ||
        aemPageType === pageTypes.eventListing
    )
}

/**
 * Search service
 */
class PlpService extends BaseService {
    /**
     * Function to fetch Search Results.
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @param {string} shopAllLabel
     * @returns {AxiosPromise}
     */
    static getProductData(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        // eslint-disable-next-line default-param-last
        pageNumber: number = MagicNumber.ONE,
        optionalValues: GetProductDataOptionalValues,
        shopAllLabel?: string,
    ): AxiosPromise<ProductDataType> {
        const url = this.getPlpUrl(requestPayload, store, isFitmentRequired, pageNumber, optionalValues, shopAllLabel)
        const headers: HttpReqHeaders = this.getHttpReqHeaders(optionalValues, isFitmentRequired, shopAllLabel)
        const httpGetRequest = optionalValues.withCaching ? apiGetWithCaching : httpClient.apiGet
        return httpGetRequest(url, {}, headers) as AxiosPromise<ProductDataType>
    }

    /**
     * function to getHideFacets
     * @param {boolean} isFitmentRequired
     * @param {GetProductDataOptionalValues} optionalValues
     * @returns {string}
     */
    static getHideFacets = (isFitmentRequired?: boolean, optionalValues?: GetProductDataOptionalValues): string => {
        let hideFacets = ''

        if (optionalValues?.searchSuppressFacets?.length) {
            // eslint-disable-next-line sonar/array-callback-without-return
            optionalValues?.searchSuppressFacets?.map((facet, i) => {
                hideFacets += facet.searchSuppressFacet
                if (i !== (optionalValues?.searchSuppressFacets?.length as number) - MagicNumber.ONE) {
                    hideFacets += '|'
                }
            })
        }

        if (isFitmentRequired) {
            hideFacets +=
                // eslint-disable-next-line sonar/no-nested-conditional
                (hideFacets === '' ? '' : hideFacets.endsWith('|') ? '' : '|') + FacetList.deliveryPickupOptions

            if (optionalValues?.isCompleteVehicleState === false) {
                hideFacets += `|${FacetList.partFacet}|${FacetList.positionFacet}`
            }
        }

        return hideFacets
    }

    /**
     * Function to generate Critio HttpReqHeaders.
     * @param {GetProductDataOptionalValues} optionalValues
     * @param path current page category path
     * @param isFitmentRequired whether fitment is required on the page
     * @returns {HttpReqHeaders}
     */
    static getCriteoHeaders(
        optionalValues: GetProductDataOptionalValues,
        path: string,
        isFitmentRequired?: boolean,
    ): HttpReqHeaders {
        let criteoHeaders = {}

        if (!optionalValues.includeSponsoredProducts) {
            return criteoHeaders
        }

        const critioHeaderParamsMap = {
            [CriteoHeaderParams.SPONSORED_PRODUCTS]: () => {
                criteoHeaders = {
                    ...criteoHeaders,
                    [CriteoHeaderParams.SPONSORED_PRODUCTS]: !!optionalValues.includeSponsoredProducts,
                }
            },
            [CriteoHeaderParams.INCLUDE_SPONSORED_PRODUCTS]: () => {
                criteoHeaders = {
                    ...criteoHeaders,
                    [CriteoHeaderParams.INCLUDE_SPONSORED_PRODUCTS]: !!optionalValues.includeCrealytics,
                }
            },
            [CriteoHeaderParams.X_CUSTOMER_ID]: () => {
                if (optionalValues.customerId) {
                    criteoHeaders = { ...criteoHeaders, [CriteoHeaderParams.X_CUSTOMER_ID]: optionalValues.customerId }
                }
            },
            [CriteoHeaderParams.X_RETAILER_VISITOR_ID]: () => {
                if (optionalValues.retailerVisitorId) {
                    criteoHeaders = {
                        ...criteoHeaders,
                        [CriteoHeaderParams.X_RETAILER_VISITOR_ID]: optionalValues.retailerVisitorId,
                    }
                }
            },
            [CriteoHeaderParams.DEVICE_TYPE]: () => {
                criteoHeaders = {
                    ...criteoHeaders,
                    [CriteoHeaderParams.DEVICE_TYPE]: optionalValues.isMobile ? 'm' : 'd',
                }
            },
            [CriteoHeaderParams.CRITEO_IS_FITMENT_SELECTED]: () => {
                if (isFitmentRequired) {
                    criteoHeaders = {
                        ...criteoHeaders,
                        [CriteoHeaderParams.CRITEO_IS_FITMENT_SELECTED]: !!optionalValues.tireOrDefaultVehicle,
                    }
                }
            },
            [CriteoHeaderParams.CRITEO_CATEGORY_CODE]: () => {
                const categoryId = this.getCategoryId(optionalValues.categoryId, path)
                if (categoryId && aemPageType !== pageTypes.eventListing) {
                    criteoHeaders = { ...criteoHeaders, [CriteoHeaderParams.CRITEO_CATEGORY_CODE]: categoryId }
                }
            },
        } as Record<string, () => void>

        Object.values(CriteoHeaderParams).forEach(paramName => critioHeaderParamsMap[paramName]())
        return criteoHeaders
    }

    /**
     * Function to fetch Weathertech Products.
     * @param {string} requestPayload
     * @param {string} store
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @returns {AxiosPromise}
     */
    static getWeatherTechProductData(
        requestPayload: string,
        store: string,
        // eslint-disable-next-line default-param-last
        pageNumber: number = MagicNumber.ONE,
        optionalValues: GetProductDataOptionalValues,
    ): AxiosPromise<ProductDataType> {
        const brUID = getCookieValue('_br_uid_2')
        const brUIDheader = brUID ? { 'X-BR-UID': brUID } : undefined
        const refURL = sessionStorageService.getItem(REF_URL_KEY)
        const referrer = refURL && { 'X-BR-REF-URL': refURL }
        const xBrRef = { 'X-BR-REF': window.location.href }
        const headers: HttpReqHeaders = {
            'x-ringfence': !!optionalValues.enableXRingFence,
            ...brUIDheader,
            ...referrer,
            ...xBrRef,
        }
        const vendorResults = optionalValues?.weatherTechVehicle?.vendorResults

        return httpClient.apiPost(
            this.getPlpUrl(requestPayload, store, undefined, pageNumber, optionalValues),
            checkDataLength(vendorResults) ? { vendorResults } : {},
            headers,
        )
    }

    /**
     * Function to generate HttpReqHeaders.
     * @param {GetProductDataOptionalValues} optionalValues
     * @param {boolean} isFitmentRequired
     * @param {string} shopAllLabel
     * @returns {HttpReqHeaders}
     */
    static getHttpReqHeaders(
        optionalValues: GetProductDataOptionalValues,
        isFitmentRequired?: boolean,
        shopAllLabel?: string,
    ): HttpReqHeaders {
        const locale = PlpService.getEnvironmentLanguage()
        const path = getCategoriesFromURL([], shopAllLabel).extractCategoryPath()
        let header = {}

        const criteoHeaders = this.getCriteoHeaders(optionalValues, path, isFitmentRequired)
        header = { ...header, ...criteoHeaders }

        const headerParamsMap = {
            [SearchRequestHeaderParams.X_RINGFENCE]: () => {
                header = { ...header, [SearchRequestHeaderParams.X_RINGFENCE]: !!optionalValues.enableXRingFence }
            },
            [SearchRequestHeaderParams.X_BR_UID]: () => {
                const brUID = getCookieValue('_br_uid_2')
                if (brUID) {
                    header = { ...header, [SearchRequestHeaderParams.X_BR_UID]: brUID }
                }
            },
            [SearchRequestHeaderParams.X_BR_REF_URL]: () => {
                const refURL = sessionStorageService.getItem(REF_URL_KEY)
                if (refURL) {
                    header = { ...header, [SearchRequestHeaderParams.X_BR_REF_URL]: refURL }
                }
            },
            [SearchRequestHeaderParams.X_BR_REF]: () => {
                header = { ...header, [SearchRequestHeaderParams.X_BR_REF]: window.location.href }
            },
            [SearchRequestHeaderParams.LANG]: () => {
                header = { ...header, [SearchRequestHeaderParams.LANG]: locale }
            },
            [SearchRequestHeaderParams.COUNT]: () => {
                header = { ...header, [SearchRequestHeaderParams.COUNT]: optionalValues.productToFetch }
            },
            [SearchRequestHeaderParams.HIDE_FACETS]: () => {
                const hideFacets = this.getHideFacets(isFitmentRequired, optionalValues)
                if (hideFacets) {
                    header = { ...header, [SearchRequestHeaderParams.HIDE_FACETS]: hideFacets }
                }
            },
            [SearchRequestHeaderParams.WIDGET_ID]: () => {
                if (!!optionalValues.pathwayId && enableSearchPageParameter()) {
                    header = { ...header, [SearchRequestHeaderParams.WIDGET_ID]: optionalValues.pathwayId }
                }
            },
            [SearchRequestHeaderParams.WIDGET_TYPE]: () => {
                if (optionalValues.searchWidgetType) {
                    header = { ...header, [SearchRequestHeaderParams.WIDGET_TYPE]: optionalValues.searchWidgetType }
                }
            },
            [SearchRequestHeaderParams.EXPERIENCE]: () => {
                if (optionalValues.searchExperience) {
                    header = { ...header, [SearchRequestHeaderParams.EXPERIENCE]: optionalValues.searchExperience }
                }
            },
            [SearchRequestHeaderParams.CATEGORY_LEVEL]: () => {
                const level = this.getCategoryLevel(
                    path,
                    optionalValues.categoryURL,
                    optionalValues.searchCategoryLevel,
                    shopAllLabel,
                )
                if (
                    level &&
                    !PlpService.needToAddAutomotiveCategoriesToUrl(optionalValues.breadcrumbList, isFitmentRequired) &&
                    aemPageType !== pageTypes.eventListing
                ) {
                    header = {
                        ...header,
                        [SearchRequestHeaderParams.CATEGORY_LEVEL]: PlpService.buildAstIdLevel(level),
                    }
                }
            },
            [SearchRequestHeaderParams.CATEGORY_CODE]: () => {
                const id = this.getCategoryId(optionalValues.categoryId, path)
                if (
                    id &&
                    !PlpService.needToAddAutomotiveCategoriesToUrl(optionalValues.breadcrumbList, isFitmentRequired) &&
                    aemPageType !== pageTypes.eventListing
                ) {
                    header = { ...header, [SearchRequestHeaderParams.CATEGORY_CODE]: id }
                }
            },
            [SearchRequestHeaderParams.Q]: () => {
                if (!!optionalValues.pathwayId && !!searchPageType && aemPageType === pageTypes.brandPage) {
                    header = { ...header, [SearchRequestHeaderParams.Q]: optionalValues.pathwayId }
                    // eslint-disable-next-line sonarjs/elseif-without-else
                } else if (
                    optionalValues.searchPassQParameter &&
                    optionalValues.searchQParameter &&
                    this.needToAddQuerySearchParam(!!isFitmentRequired)
                ) {
                    header = { ...header, [SearchRequestHeaderParams.Q]: optionalValues.searchQParameter }
                }
            },
            [SearchRequestHeaderParams.BRAND]: () => {
                if (!!optionalValues.pathwayId && !!searchPageType && aemPageType === pageTypes.brandPage) {
                    header = { ...header, [SearchRequestHeaderParams.BRAND]: optionalValues.pathwayId }
                }
            },
            [SearchRequestHeaderParams.PAGE_TYPE]: () => {
                header = { ...header, [SearchRequestHeaderParams.PAGE_TYPE]: aemPageType }
            },
            [SearchRequestHeaderParams.LIGHT]: () => {
                if (optionalValues.priceAvailabilityLazyLoadNeeded) {
                    header = { ...header, [SearchRequestHeaderParams.LIGHT]: true }
                }
            },
        } as Record<string, () => void>

        Object.values(SearchRequestHeaderParams).forEach(paramName => headerParamsMap[paramName]())

        return header
    }

    /**
     * Function to get environment language.
     * @returns {string}
     */
    static getEnvironmentLanguage = (): string => environment.language

    /**
     * Function to check if the page belongs to cat or not.
     * @returns {boolean}
     */
    static isCategoryPage = (): boolean => pageTypes.categoryPages.includes(aemPageType as CategoryPagesType)

    /**
     * Function to check if the page is packagelanding page.
     * @returns {boolean}
     */
    static isPackageLandingPage = (): boolean => pageTypes.packageLanding === aemPageType

    /**
     * Function to check if the page is promoListing page.
     * @returns {boolean}
     */
    static isPromoListingPage = (): boolean => pageTypes.promoListing === aemPageType

    /**
     * Function to check if the page is brandcategorylisting page.
     * @returns {boolean}
     */
    static isBrandCategoryPage = (): boolean => pageTypes.brandCategoryPage === aemPageType

    /**
     * Function to check that search params exist in url.
     * @param {string} requestPayload
     * @param {boolean} isFitmentRequired
     * @param {boolean} isWeatherTech
     * @returns {boolean}
     */
    static isSearchParamExists = (
        requestPayload: string,
        isFitmentRequired?: boolean,
        isWeatherTech?: boolean,
    ): boolean => {
        return isFitmentRequired || isWeatherTech
            ? requestPayload?.split(queryParameters.divider)?.length > MagicNumber.TWO ||
                  requestPayload?.split(queryParameters.plpCDSDivider)?.length > MagicNumber.TWO
            : Boolean(requestPayload)
    }

    /**
     * Function to get category path
     * @param {string} path
     * @returns {string | undefined}
     */
    static getCategoryPath = (path: string): string | undefined => {
        return path?.slice(0, path?.lastIndexOf('-'))
    }

    /**
     * Function to check that need to add automotive categories to url
     * @param {string} path
     * @returns {string | undefined}
     */
    static needToAddAutomotiveCategoriesToUrl = (breadcrumbList?: string[], isFitmentRequired?: boolean): boolean => {
        return isArrayNotEmpty(breadcrumbList) && !!isFitmentRequired
    }

    /**
     * function to append page number
     * @param {string} url
     * @param {string} pageNumber
     * @returns {string}
     */
    static appendPageNumberParameter = (url: string, pageNumber: number): string => {
        return Number(pageNumber) > 1
            ? updateParam(url, queryParameters.page, queryParameters.plpCDSDivider, pageNumber)
            : PlpService.removeRequestParameter(url, queryParameters.page)
    }

    /**
     * function to add request parameter
     * @param {string} url
     * @param {string} parameterName
     * @param {string} parameterValue
     * @returns {string} updated url
     */
    static appendRequestParameter = (url: string, parameterName: string, parameterValue: string): string => {
        return checkDataLength(getParamValue(url, parameterName, queryParameters.divider)) ||
            checkDataLength(getParamValue(url, parameterName, queryParameters.plpCDSDivider))
            ? PlpService.normalizeAndUpdateRequestParameter(url, parameterName, parameterValue)
            : addParam(url, parameterName, parameterValue)
    }

    /**
     * function to normalize and update request parameter in the url
     * parameter will be removed from url and after that it will be added to the end of the url with '&' divider
     * @param {string} url
     * @param {string} parameterName
     * @param {string} parameterValue
     * @returns {string} updated url
     */
    static normalizeAndUpdateRequestParameter = (
        url: string,
        parameterName: string,
        parameterValue: string,
    ): string => {
        let newUrl = url
        newUrl = PlpService.removeRequestParameter(newUrl, parameterName)
        updateUrlHistory(newUrl)
        newUrl = addParam(newUrl, parameterName, parameterValue)
        return newUrl
    }

    /**
     * function to remove request parameter from url which has mixed dividers ('&' and ';')
     * @param {string} url - page url i.e "https:/abc.com/?a=1;b=2&c=3".
     * @param {string} parameterName - to remove
     * @returns {string} updated url
     */
    static removeRequestParameter = (url: string, parameterName: string): string => {
        const requestParametersAvailable = url && url.indexOf('?') !== -1

        if (requestParametersAvailable) {
            const urlParts = url.split('?')
            const basicUrl = urlParts[0]
            const requestParametersString = urlParts[1]

            const requestParametersList = requestParametersString.match(/[;&]|([^&;])+/g) ?? []

            const parameterIndexToRemove = requestParametersList.findIndex(item => item.split('=')[0] === parameterName)

            if (parameterIndexToRemove !== -1) {
                if (parameterIndexToRemove === 0) {
                    // will remove parameter by index with separator after it
                    // eslint-disable-next-line no-magic-numbers
                    requestParametersList.splice(parameterIndexToRemove, 2)
                } else {
                    // will remove parameter with it is value and separator before it
                    // index is a position of parameter, index-1 is a position of it is separator
                    // eslint-disable-next-line no-magic-numbers
                    requestParametersList.splice(parameterIndexToRemove - 1, 2)
                }
                return isArrayNotEmpty(requestParametersList)
                    ? `${basicUrl}?${requestParametersList.join('')}`
                    : basicUrl
            }
        }
        return url
    }

    static getVehicleQueryStringFromVehicleAttributes = (
        // eslint-disable-next-line default-param-last
        autoAttributes: AutoAttributes | PreselectedVehicleValues | undefined = {},
        vehicleQueryStringIndex: number,
    ): string[] =>
        Object.entries(autoAttributes)
            // filtering out empty attributes
            .filter(entry => {
                return Boolean(entry[1])
            })
            .map(([attributeName, attributeValue], index) => {
                const attributeIndex = vehicleQueryStringIndex + index
                return `x${attributeIndex}=auto.${attributeName};q${attributeIndex}=${encodeURIComponent(
                    attributeValue,
                )}`
            })

    static getVehicleQueryStringFromTireSize = (tireSize: TireType): [string] => [`q=${getFullTireSize(tireSize)}`]

    /**
     * function to get tire/wheel filter option query string
     * @param {string[]} vehicleQueryString
     * @param {number} vehicleQueryStringIndex
     * @param {Vehicle | TireType} tireOrDefaultVehicle
     * @returns {string[]}
     */
    static getAutomotiveSearchQueryString = (
        vehicleQueryString: string[],
        vehicleQueryStringIndex: number,
        tireOrDefaultVehicle: Vehicle | TireType | { autoAttributes: PreselectedVehicleValues },
    ): string[] => {
        let additionalQueryString: string[] = []

        if (checkDataLength(tireOrDefaultVehicle)) {
            const hasVehicleAttributes = checkDataLength((tireOrDefaultVehicle as Vehicle)?.autoAttributes)

            additionalQueryString = hasVehicleAttributes
                ? PlpService.getVehicleQueryStringFromVehicleAttributes(
                      (tireOrDefaultVehicle as Vehicle)?.autoAttributes,
                      vehicleQueryStringIndex,
                  )
                : PlpService.getVehicleQueryStringFromTireSize(tireOrDefaultVehicle as TireType)
        } else additionalQueryString = [`&x${vehicleQueryStringIndex}=auto.vehicleType;q${vehicleQueryStringIndex}=*`]

        return vehicleQueryString.concat(additionalQueryString)
    }

    /**
     * function to build ast-id-level
     * @param {string} categoryLevel
     * @param {string} categoryId
     * @param {number} vehicleQueryStringIndex
     * @returns {string}
     */
    static buildAstIdLevel = (categoryLevel: string): string => {
        const attributeName = categoryLevel?.includes('ast-id-level')
            ? categoryLevel
            : `ast-id-level-${categoryLevel?.split('/')?.length}`
        return `${attributeName}`
    }

    /**
     * function to get vehicle information
     * @param {Vehicle | TireType} tireOrDefaultVehicle
     * @param {number} indexPosition
     * @param {string[]} breadcrumbList
     * @param {string} categoryLevel
     * @param {string} id
     * @param {string} currentPackageId
     * @param {boolean | undefined} isFitmentRequired
     * @returns {string}
     */
    static getVehicleInformation = (
        tireOrDefaultVehicle: Vehicle | TireType | { autoAttributes: PreselectedVehicleValues },
        indexPosition: number,
        categoryLevel: string,
        breadcrumbList?: string[],
        currentPackageId?: string,
        isFitmentRequired?: boolean,
    ): string => {
        let vehicleQueryString: string[] = []
        let vehicleQueryStringIndex = indexPosition

        if (isFitmentRequired) {
            vehicleQueryString = PlpService.getAutomotiveSearchQueryString(
                vehicleQueryString,
                vehicleQueryStringIndex,
                tireOrDefaultVehicle,
            )
            vehicleQueryStringIndex = vehicleQueryString.length + MagicNumber.ONE
            if (
                !currentPackageId &&
                vehicleQueryString.length > 0 &&
                // eslint-disable-next-line no-magic-numbers
                !(PlpService.isPromoListingPage() && extractCategoryLevelNumberForCustomPlp(categoryLevel) < 2)
            ) {
                vehicleQueryString.push(`x${vehicleQueryStringIndex}=auto.application;q${vehicleQueryStringIndex}=both`)
                vehicleQueryStringIndex++
            }
        }

        if (PlpService.needToAddAutomotiveCategoriesToUrl(breadcrumbList, isFitmentRequired)) {
            breadcrumbList?.forEach((category: string, index: number) => {
                vehicleQueryString.push(
                    `x${vehicleQueryStringIndex}=c.${categoryLevelPrefix}-${
                        index + MagicNumber.ONE
                    };q${vehicleQueryStringIndex}=${encodeURIComponent(category)}`,
                )
                vehicleQueryStringIndex++
            })
        }

        return `${vehicleQueryString?.join(';')}`
    }

    static needToAddQuerySearchParam = (isFitmentRequired: boolean) => {
        return enableSearchPageParameter() && !isFitmentRequired
    }

    /**
     * function to get category level
     * @param {string} path
     * @param {string} categoryURL
     * @param {string} shopAllLabel
     * @returns {string}
     */
    static getCategoryLevel = (
        path: string,
        categoryURL?: string,
        searchCategoryLevel?: string,
        shopAllLabel?: string,
    ): string | undefined => {
        if (PlpService.isCategoryPage()) {
            return PlpService.getCategoryPath(path)
        } else if (PlpService.isBrandCategoryPage() || PlpService.isPromoListingPage()) {
            return searchCategoryLevel
        } else {
            return getCategoriesFromURL([], shopAllLabel).extractCategoryPath(categoryURL)
        }
    }

    /**
     * Function to get category Id
     * @param {string | undefined} categoryId
     * @param {string} path
     * @returns {string}
     */
    static getCategoryId = (categoryId: string | undefined, path: string): string => {
        if (PlpService.isCategoryPage()) {
            /**
             * Example: a/b-c/c-id - return value of extractCategoryPath
             * Code below removes a/b-c/c- and returns id
             */
            // eslint-disable-next-line no-unsafe-optional-chaining
            return path?.slice(path?.lastIndexOf('-') + 1)
        } else if (
            (PlpService.isPackageLandingPage() ||
                PlpService.isBrandCategoryPage() ||
                PlpService.isPromoListingPage()) &&
            categoryId
        ) {
            return categoryId
        } else {
            return ''
        }
    }

    /**
     * function that adds param for universal products if AEM toggle enableUniversalProductFilter is true AND vehicle has been defined AND is auto parts PLP
     * @param {string} plpCDSUrl
     * @param {boolean} filterUniversalProducts
     * @returns {string}
     */
    static parameterizeUniversalProducts = (plpCDSUrl: string, filterUniversalProducts?: boolean): string => {
        return filterUniversalProducts
            ? // eslint-disable-next-line sonar/no-nested-assignment, sonar/no-dead-store
              (plpCDSUrl = `${plpCDSUrl}&${searchConstants.AUTO_UNIVERSAL_PRODUCTS_FILTER}=true`)
            : plpCDSUrl
    }

    /**
     * function searchResultsType  if it is present
     * @param {string} plpCDSUrl
     * @param {string} searchResultsType
     * @returns {string}
     */
    static parameterizeSearchResultType = (plpCDSUrl: string, searchResultsType?: string): string => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return searchResultsType ? `${plpCDSUrl}&${queryParameters.searchResultType}=${searchResultsType}` : plpCDSUrl
    }

    /**
     * function used to generate weather tech url
     * @param {string} apimEndPoint
     * @param {WeatherTechVehicle} weatherTechVehicle
     * @returns {string}
     */
    static generateWeatherTechUrl = (apimEndPoint: string, weatherTechVehicle: WeatherTechVehicle): string => {
        return `${apimEndPoint}/${weatherTechVehicle.year}/${weatherTechVehicle.vehicleId}/${weatherTechVehicle.groupId}/${weatherTechVehicle.choiceIds}/`
    }

    /**
     * function to append params to the url
     * @param {string} plpCDSUrl
     * @param {number} productToFetch
     * @param {string | undefined} minPrice
     * @param {string | undefined} maxPrice
     * @param {boolean | undefined} isFitmentRequired
     * @param {boolean} searchParamExists
     * @param {string} requestPayload
     * @param {string} tireOrWheelSize
     * @param {boolean} filterUniversalProducts
     * @param {GetProductDataOptionalValues} optionalValues
     * @returns {string}
     */
    // eslint-disable-next-line max-params
    static createUrlWithParams(
        plpCDSUrl: string,
        requestPayload: string,
        productToFetch?: number,
        minPrice?: string,
        maxPrice?: string,
        isFitmentRequired?: boolean | undefined,
        searchParamExists?: boolean,
        tireOrWheelSize?: string,
        filterUniversalProducts?: boolean,
        optionalValues?: GetProductDataOptionalValues,
    ): string {
        if (optionalValues?.isWeatherTech) {
            plpCDSUrl = `${plpCDSUrl}${queryParameters.plpCDSDivider}${queryParameters.language}=${PlpService.language}`
            const hideFacets = this.getHideFacets(isFitmentRequired, optionalValues)
            if (hideFacets) {
                plpCDSUrl = `${plpCDSUrl}&hideFacets=${hideFacets}`
            }
            if (optionalValues.searchExperience) {
                plpCDSUrl = `${plpCDSUrl}&experience=${optionalValues.searchExperience}`
            }
            // if product fetch count is given, then append count to the url
            if (productToFetch) {
                plpCDSUrl = `${plpCDSUrl};count=${productToFetch}`
            }
        }

        if (minPrice) {
            plpCDSUrl = `${plpCDSUrl}&priceLowerBound=${minPrice}`
        }
        if (maxPrice) {
            plpCDSUrl = `${plpCDSUrl}&priceUpperBound=${maxPrice}`
        }

        if (tireOrWheelSize) {
            plpCDSUrl = `${plpCDSUrl}&q=${tireOrWheelSize}`
        }

        plpCDSUrl = this.parameterizeUniversalProducts(plpCDSUrl, filterUniversalProducts)
        plpCDSUrl = this.parameterizeSearchResultType(plpCDSUrl, optionalValues?.searchResultsType)
        // if automotive , use this flag to update CDS about automotive flag
        if (isFitmentRequired) {
            plpCDSUrl += '&automotive=true'
        }
        // if any one of the search param exists, use the url from api
        if (searchParamExists) {
            plpCDSUrl = requestPayload
        }
        return plpCDSUrl
    }

    /**
     * function to get CDS PLP Url
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @param {string} shopAllLabel
     * @return {string}
     */
    /* eslint-disable complexity */
    // eslint-disable-next-line sonar/cyclomatic-complexity
    static getCDSPlpUrl(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        pageNumber: number,
        optionalValues: GetProductDataOptionalValues,
        shopAllLabel?: string,
    ): string {
        const {
            API_ENDPOINTS: { getSearchResults, apimEndPointWeatherTechProducts },
        } = environment
        const {
            productToFetch,
            categoryId,
            pathwayId,
            categoryURL,
            breadcrumbList,
            tireOrDefaultVehicle,
            currentPackageId,
            searchCategoryLevel,
            tireOrWheelSize,
            isWeatherTech,
            weatherTechVehicle,
            filterUniversalProducts,
        } = optionalValues
        const url = isWeatherTech
            ? this.generateWeatherTechUrl(apimEndPointWeatherTechProducts, weatherTechVehicle as WeatherTechVehicle)
            : getSearchResults
        const productItem = getParamValue(requestPayload, queryParameters.searchQuery, queryParameters.divider)
        const rqParam = getParamValue(requestPayload, queryParameters.searchRequestQuery, queryParameters.divider)
        const minPrice = getParamValue(requestPayload, queryParameters.priceLowerBound, queryParameters.divider)
        const maxPrice = getParamValue(requestPayload, queryParameters.priceUpperBound, queryParameters.divider)
        const storeParam =
            getParamValue(requestPayload, queryParameters.store, queryParameters.divider) ??
            getParamValue(requestPayload, queryParameters.store, queryParameters.plpCDSDivider)
        const searchParamExists = PlpService.isSearchParamExists(requestPayload, isFitmentRequired, isWeatherTech)

        let plpCDSUrl = `?${queryParameters.store}=${store}`

        if (getSAPIRequestQueryParam()) {
            plpCDSUrl = `${plpCDSUrl}${queryParameters.plpCDSDivider}rq=${rqParam as string}`
        }

        const path = getCategoriesFromURL([], shopAllLabel).extractCategoryPath()
        const categoryLevel = this.getCategoryLevel(path, categoryURL, searchCategoryLevel, shopAllLabel) || ''
        const id = this.getCategoryId(categoryId, path)

        const indexPosition = searchParamExists ? MagicNumber.HUNDRED : MagicNumber.ONE
        const vehicleQueryString = PlpService.getVehicleInformation(
            tireOrDefaultVehicle as Vehicle,
            indexPosition,
            categoryLevel,
            breadcrumbList,
            currentPackageId,
            isFitmentRequired,
        )

        // if product item is given in url, then append product to the url
        if (productItem && !id) {
            plpCDSUrl = `${plpCDSUrl}&q=${productItem}`
        } else if (!!pathwayId && !!searchPageType && aemPageType === pageTypes.brandPage && isWeatherTech) {
            plpCDSUrl = `${plpCDSUrl}&x${indexPosition}=${searchPageType}&q${indexPosition}=${pathwayId}&q=${pathwayId}`
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (
            // eslint-disable-next-line sonar/expression-complexity
            (aemPageType !== pageTypes.promoListing || (aemPageType === pageTypes.promoListing && isFitmentRequired)) &&
            aemPageType !== pageTypes.eventListing &&
            vehicleQueryString
        ) {
            plpCDSUrl = `${plpCDSUrl}&${vehicleQueryString}`
        }

        if (searchParamExists && !storeParam) {
            requestPayload = `${requestPayload}&store=${store}`
        }

        plpCDSUrl = `${this.createUrlWithParams(
            plpCDSUrl,
            requestPayload,
            productToFetch,
            minPrice,
            maxPrice,
            isFitmentRequired,
            searchParamExists,
            tireOrWheelSize,
            filterUniversalProducts,
            optionalValues,
        )}`

        plpCDSUrl = this.appendPageNumberParameter(plpCDSUrl, pageNumber)
        plpCDSUrl = isWeatherTech
            ? this.appendRequestParameter(plpCDSUrl, queryParameters.automotive, 'true')
            : plpCDSUrl
        return `${url}${plpCDSUrl}`
    }

    /**
     * Function used to construct the query params for the search url
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @param {string} shopAllLabel
     * @returns {string} plp url
     */
    static getPlpUrl(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        // eslint-disable-next-line default-param-last
        pageNumber: number = MagicNumber.ONE,
        optionalValues: GetProductDataOptionalValues,
        shopAllLabel?: string,
    ): string {
        const { API_BASE_URL } = environment
        const payload = isFitmentRequired ? excludeUtmParams(requestPayload) : requestPayload
        return `${API_BASE_URL}${this.getCDSPlpUrl(
            payload,
            store,
            isFitmentRequired,
            pageNumber,
            optionalValues,
            shopAllLabel,
        )}`
    }

    /**
     * product specification call
     * @param {string} products products
     * @returns {AxiosPromise} AxiosPromise
     */
    static getProductsComparisonData(products: string): AxiosPromise<CompareProductSpecificationResponse> {
        const url = this.createProductsComparisonUrl(products)
        return httpClient.apiGet(url)
    }

    /**
     * Create product specification url based on cds
     * @param {string} products products
     * @returns {string} url
     */
    static createProductsComparisonUrl(products: string): string {
        const { API_BASE_URL, API_ENDPOINTS } = environment

        return `${API_BASE_URL}${API_ENDPOINTS.productsCompare}/${
            PlpService.language.split('_')[0]
        }?q=${products}&compare=true`
    }
}

export { PlpService }
export default PlpService
