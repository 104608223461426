import { Dispatch } from '@reduxjs/toolkit'

import {
    fetchEstimateDeliveryDateAction,
    estimateDeliveryDateFailureAction,
    resetEstimateDeliveryDateAction,
} from '../actionCreators'
import { estimateDeliveryDateService } from '../../services/estimateDeliveryDateService'
import { EstimateDeliveryDate, EstimateDeliveryDateParams } from '../models/estimateDeliveryDate.interface'
import { setShowSpinner } from '../slices/spinner.slice'

export const getEstimateDeliveryDate =
    (params: EstimateDeliveryDateParams, showSpinner = false) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner({ show: true }))
        estimateDeliveryDateService
            .fetchEstimateDeliveryDate(params)
            .then((estimateDeliveryDateData: { data: EstimateDeliveryDate }) => {
                const isEstimateDeliveryDateData =
                    estimateDeliveryDateData.data &&
                    estimateDeliveryDateData.data?.startDate &&
                    estimateDeliveryDateData.data?.endDate
                if (isEstimateDeliveryDateData) {
                    dispatch(fetchEstimateDeliveryDateAction(estimateDeliveryDateData.data))
                } else {
                    dispatch(estimateDeliveryDateFailureAction())
                }
            })
            .catch(() => {
                dispatch(estimateDeliveryDateFailureAction())
            })
            .finally(() => {
                dispatch(setShowSpinner({ show: false }))
            })
    }

/**
 * function to reset Estimate Delivery Date
 * @return {Dispatch}
 */
export const resetEstimateDeliveryDate =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetEstimateDeliveryDateAction())
    }
