import { AnyAction, Dispatch } from '@reduxjs/toolkit'

import { clearBuyNowCartInfo, setBuyNowInitCheckoutInfo, setIsPlaceOrderFlowFailed } from '../actionCreators'
import { RootState } from '../reducers'
import { ToastComponentNames } from '../models/toastMessage.interface'
import { dispatchToast } from '../../components/ToastMessage/ToastMessage.helper'
import { errorCodePrefix, statusIcons } from '../../globalConstants'
import { cartService } from '../../services'
import { CheckoutService } from '../../services/checkoutService'
import { AxiosError, AxiosResponse } from 'axios'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { IFeatureFlag } from '../models/commonContent.interface'
import { CartResponseErrorData, PlaceOrderDTO } from '../models/cart.interface'
import { isFullPageError } from '../../components/Checkout/Checkout.helper'
import { setShowSpinner } from '../slices/spinner.slice'
import appCacheService from '../../utils/appCacheService'
import { setPaymentError } from './checkoutDrawer.action'
import { getPaymentBuyNowErrorMessage } from '../../components/BuyNowModal/BuyNowModal.helper'

/**
 * @param storeId
 */
export const initBuyNowModal =
    (storeId: string) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const {
            buyNowModalData: { cartCode },
            toastMessageData: { toastMessageData },
            commonContent: {
                commonContentAvailable: { general },
            },
        } = getState()
        const { addToCartFailureError } = toastMessageData
        const { closeLabel } = general
        try {
            const initCheckoutResp = await cartService.initCheckout('', storeId, false, cartCode)
            replaceEmptyImagesWithDefault(initCheckoutResp?.data?.orderEntries, 'images')
            const cartFilteredData = getFilteredCartItems(initCheckoutResp.data)
            dispatch(setBuyNowInitCheckoutInfo(cartFilteredData))
        } catch (error) {
            dispatch(clearBuyNowCartInfo())
            dispatchToast(
                true,
                {
                    options: {
                        toastCloseLabel: closeLabel,
                        toastErrorMessage: addToCartFailureError,
                        toastErrorIcon: statusIcons.failureIcon,
                    },
                    success: false,
                    failed: true,
                    hideCTA: false,
                },
                ToastComponentNames.BUY_NOW_ERROR,
                dispatch,
            )
        }
    }

/**
 * action to call the placeorder api
 * @param {string} cartId cart id
 * @param {Partial<IFeatureFlag>} featureFlag feature flags for fraud session
 * @param {object} placeOrderPayload - data of Buy Now flow
 * @returns {void}
 */
export const placeOrderBuyNow =
    (cartId: string, featureFlag: Partial<IFeatureFlag>, placeOrderPayload: Partial<PlaceOrderDTO>) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const fraudSessionID = CheckoutService.getFraudSessionID(featureFlag)
        CheckoutService.placeOrder(cartId, false, fraudSessionID, false, '', placeOrderPayload, true)
            .then((placeOrderData: AxiosResponse<PlaceOrderDTO>) => {
                dispatch(setPaymentError('') as unknown as AnyAction)
                clearDataBuyNowFlow(dispatch)
                const placeOrderUrlParam = placeOrderData.data?.order?.code
                const globalLinks = getState().commonContent?.commonContentAvailable?.globalLinks
                const placeOrderRedirect = `${globalLinks?.orderConfirmationLink}?orderId=${placeOrderUrlParam}`
                window.location.href = encodeURI(placeOrderRedirect)
            })
            .catch((err: AxiosError<CartResponseErrorData>) => {
                const errorCode = err.response?.data?.errCode
                const fedErrors = getState().commonContent?.commonContentAvailable?.fedErrors
                if (errorCode && fedErrors) {
                    if (isFullPageError(errorCode)) {
                        clearDataBuyNowFlow(dispatch)
                        const placeOrderRedirect = fedErrors[`${errorCodePrefix}${errorCode}`]
                        window.location.href = encodeURI(placeOrderRedirect)
                    } else {
                        const errorMessage = getPaymentBuyNowErrorMessage(fedErrors, errorCode)
                        const closeLabel = getState().commonContent?.commonContentAvailable?.general?.closeLabel
                        dispatchToast(
                            true,
                            {
                                options: {
                                    toastErrorMessage: errorMessage.message,
                                    toastErrorIcon: statusIcons.failureIcon,
                                    toastCloseLabel: closeLabel,
                                },
                                success: false,
                                enableTimer: true,
                                /**
                                 *
                                 */
                                toastCloseFunction: () => dispatchToast(false, {}, ToastComponentNames.NONE, dispatch),
                            },
                            ToastComponentNames.BUY_NOW_ERROR,
                            dispatch,
                        )
                    }
                }
                dispatch(setIsPlaceOrderFlowFailed(true))
            })
            .finally(() => {
                dispatch(setShowSpinner({ show: false }))
            })
    }

/**
 * @param {Dispatch} dispatch
 */
const clearDataBuyNowFlow = (dispatch: Dispatch) => {
    dispatch(clearBuyNowCartInfo())
    appCacheService.buyNowCartDataRequest.delete()
    appCacheService.buyNowProductData.delete()
}
