import { Dispatch } from '@reduxjs/toolkit'

import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import {
    MergeData,
    ErrorResponse,
    LoyaltyCardBody,
    LoyaltyCardBodyResponse,
    ExtendedBody,
    ExtendedBodyResponse,
} from '../models/mergeLoyaltyCard.interface'
import {
    creditCardAssociationSuccessAction,
    creditCardAssociationErrorAction,
    validateLoyaltySuccessAction,
    validateLoyaltyErrorAction,
    validateLoyaltyExtendedSuccessAction,
    validateLoyaltyExtendedErrorAction,
    mergeLoyaltySuccessAction,
    mergeLoyaltyErrorAction,
} from '../actionCreators/mergeLoyaltyCard.actionCreators'
import { isOtpErrorCode, OtpErrorType } from '@nl/lib'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import { AxiosError } from 'axios'
import { componentList } from '../../globalConstants/global.constant'
import { initOtpFlow, setOtpErrCode } from '../actionCreators/otp.actionCreators'

const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
const headers: HttpReqHeaders = {
    authorization: `Bearer ${gigyaJWTToken}`,
}

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Check if Credit card is associated with user
 *
 * @return { Promise }
 */
export const checkCreditCardAssociation =
    (component: string) =>
    (dispatch: Dispatch): Promise<void> => {
        // const baseEndPoint = API_ENDPOINTS.creditCardAssociation
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.creditCardAssociation}`

        return httpClient
            .apiPost(url, undefined, headers, true)
            .then(data => {
                dispatch(creditCardAssociationSuccessAction(data.data as MergeData))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                if (isOtpErrorCode(error?.response?.data?.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: error.response?.data.vToken as string,
                            component,
                            userID: error.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(error?.response?.data?.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(error.response?.data.errCode as string))
                } else {
                    const errorResponse = error.response ? error.response.data : error
                    dispatch(creditCardAssociationErrorAction(errorResponse as ErrorResponse))
                }
            })
    }

/**
 * validate Loyalty card number entered
 * @param { LoyaltyCardBody } requestPayload pass newLoyaltycard number and data as part of requestPayload
 * @return { Promise }
 */
export const validateLoyaltyCard =
    (requestPayload: LoyaltyCardBody) =>
    (dispatch: Dispatch): Promise<void> => {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.validateLoyalty}`
        return httpClient
            .apiPost(url, { ...requestPayload }, headers, true)
            .then(data => {
                dispatch(validateLoyaltySuccessAction(data.data as LoyaltyCardBodyResponse))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                if (isOtpErrorCode(error?.response?.data?.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: error.response?.data.vToken as string,
                            component: componentList.accounts.mergeCardForm,
                            userID: error.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(error?.response?.data?.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(error.response?.data.errCode as string))
                } else {
                    const errorResponse = error.response ? error.response.data : error
                    dispatch(validateLoyaltyErrorAction(errorResponse as ErrorResponse))
                }
            })
    }

/**
 * validate Loyalty card extended, performs extra validation on loyalty card
 * @param { ExtendedBody } requestPayload pass postal code, Year of birth and data as part of requestPayload
 * @return { Promise }
 */
export const validateLoyaltyCardExtended =
    (requestPayload: ExtendedBody) =>
    (dispatch: Dispatch): Promise<void> => {
        // const baseEndPoint = API_ENDPOINTS.validateLoyaltyExtended
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.validateLoyaltyExtended}`
        return httpClient
            .apiPost(url, { ...requestPayload }, headers, true)
            .then(data => {
                dispatch(validateLoyaltyExtendedSuccessAction(data.data as ExtendedBodyResponse))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                if (isOtpErrorCode(error?.response?.data.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: error.response?.data.vToken as string,
                            component: componentList.accounts.mergeCardForm,
                            userID: error.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(error?.response?.data?.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(error.response?.data.errCode as string))
                } else {
                    const errorResponse = error.response ? error.response.data : error
                    dispatch(validateLoyaltyExtendedErrorAction(errorResponse as ErrorResponse))
                }
            })
    }

/**
 * Merge Loyalty card
 * @param { MergeData } requestPayload pass data as part of requestPayload
 * @return { Promise }
 */
export const mergeLoyaltyCard =
    (requestPayload: MergeData) =>
    (dispatch: Dispatch): Promise<void> => {
        // const baseEndPoint = API_ENDPOINTS.mergeLoyaltyCard
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.mergeLoyaltyCard}`
        return httpClient
            .apiPost(url, { ...requestPayload }, headers, true)
            .then(data => {
                dispatch(mergeLoyaltySuccessAction(data.status))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                if (isOtpErrorCode(error?.response?.data?.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: error.response?.data.vToken as string,
                            component: componentList.accounts.mergeCardForm,
                            userID: error.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(error?.response?.data?.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(error.response?.data.errCode as string))
                } else {
                    const errorResponse = error.response ? error.response.data : error
                    dispatch(mergeLoyaltyErrorAction(errorResponse as ErrorResponse))
                }
            })
    }
