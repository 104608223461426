import React from 'react'

import { ComparisonTableProps } from './ComparisonTable.type'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import Icon from '../Icon'
import Button from '../Button'
import Table from '../Table'

/**
 * Generate ComparisonTable Component
 * @param {props} props ComparisonTable props
 * @returns {JSX.Element} returns ComparisonTable component
 */
const ComparisonTable: React.FC<ComparisonTableProps> = ({ ...props }): JSX.Element => {
    const {
        rowsOnLoad,
        comparisonTableData,
        title,
        seeMoreLabel,
        seeLessLabel,
        isSticky,
        setIsSticky,
        visibleRows,
        setVisibleRows,
        isTableExpanded,
        setIsTableExpanded,
    } = props
    const compareSimilarProductClass = `${PREFIX}-csp`
    const stickyHeaderClass = isSticky ? `${PREFIX}__sticky-header` : ''
    /**
     * Function on click of show more/ show less button
     * @returns {void}
     */
    const handleShowMore = (): void => {
        const numberOfHiddenRows = comparisonTableData.length - rowsOnLoad
        setVisibleRows(prevVisibleRows => (isTableExpanded ? rowsOnLoad + 1 : prevVisibleRows + numberOfHiddenRows))
        setIsTableExpanded(!isTableExpanded)
        isTableExpanded && setIsSticky?.(false)
    }

    const visibleData = comparisonTableData.slice(0, visibleRows)
    const buttonChevron = isTableExpanded ? 'ct-chevron-up' : 'ct-chevron-down'

    return (
        <div id={compareSimilarProductClass} className={compareSimilarProductClass}>
            {title && (
                <div className={`${compareSimilarProductClass}__header`}>
                    <h2>{title}</h2>
                </div>
            )}
            <div className={`${compareSimilarProductClass}__table ${stickyHeaderClass}`}>
                <Table tableRichText={true} tableType="stripped" headerType="column" tableData={visibleData} />
                <div className={`${compareSimilarProductClass}__show-more-button`}>
                    <Button id="viewProductBtn" type="tertiary" size="medium" onClick={handleShowMore}>
                        {isTableExpanded ? seeLessLabel : seeMoreLabel}
                        <Icon type={buttonChevron} size="sm" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

ComparisonTable.propTypes = {
    rowsOnLoad: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    seeLessLabel: PropTypes.string,
    seeMoreLabel: PropTypes.string,
}

export default ComparisonTable
