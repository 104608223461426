import { Dispatch } from '@reduxjs/toolkit'

import {
    CHANGE_BOPIS_STORE,
    CHECKOUT_BUTTON_CLICKED,
    PAYPAL_BUTTON_CLICKED,
    PAYPAL_BUTTON_CLICKED_VALID,
    SET_PC_MODAL_OPEN,
    SET_TNC_MODAL_OPEN,
    SET_TNC_ACCEPTED,
    CLICK_TO_PAY_BUTTON_CLICKED,
} from '../types/checkout/checkoutButtonClick.actionTypes.constant'
import {
    checkoutButtonClickAction,
    setPostalCodeOpenAction,
    resetCheckOutVariablesAction,
    setTNCOpenAction,
    changeBopisStoreAction,
    setTncAccepted,
    paypalButtonClickAction,
    paypalButtonClickValidationAction,
    clickToPayButtonClickAction,
} from '../actionCreators/checkoutButtonClick.actionCreators'
import { RootState } from '../reducers'
import { cartService } from '../../services'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { HttpRequestState } from '../../httpClient/client.type'
import {
    getCartItemsFailureAction,
    initCheckoutFailure,
    reservationRequestFinished,
    reservationRequestInProgress,
    resetReservationVariables,
    setHppToken,
    setInitPaymentStatusAction,
    updateCartDataSuccessAction,
    updateStoreInitiatedCart,
} from '../actionCreators'
import { AxiosCartResponseErrorDTO } from '../models/cart.interface'
import { isOneTimeCartForAuthUser } from '../../utils/isOneTimeCartForAuthUser.utils'
import { CheckoutService } from '../../services/checkoutService'
import { userType } from '../../globalConstants'
import { AxiosResponse } from 'axios'
import { GetPaypalTokenPayload, InitPaymentResponseDTO } from '../models/checkout.interface'
import { paymentInformationConst } from '../../components/Checkout/PaymentInformation/cardTypeConstants'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { PayPalTokenData } from '../models/commonContent.interface'

export const checkoutButtonClick =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof CHECKOUT_BUTTON_CLICKED; payload: boolean }>): void => {
        dispatch(checkoutButtonClickAction(requestPayload))
    }

export const isShowTNCModal =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_TNC_MODAL_OPEN; payload: boolean }>): void => {
        dispatch(setTNCOpenAction(requestPayload))
    }

export const setPostalCodeOpen =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_PC_MODAL_OPEN; payload: boolean }>): void => {
        dispatch(setPostalCodeOpenAction(requestPayload))
    }

/**
 * Used to dispatch an action to the reducer.
 * @return {Function} return a function which is triggered in shopping cart.
 */
export const resetCheckOutVariables =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCheckOutVariablesAction())
    }

/**
 * Function to trigger store locator modal in shopping cart component when change store from order summary is clicked
 * @param {boolean} requestPayload
 * @return {Function}
 */
export const changeBopisStore =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof CHANGE_BOPIS_STORE; payload: boolean }>): void => {
        dispatch(changeBopisStoreAction(requestPayload))
    }

/**
 * Funtion to set tnc modal accepted for cart when there is atleast one service addon
 * @param {boolean} requestPayload
 * @return {Function}
 */
export const isTncAccepted =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_TNC_ACCEPTED; payload: boolean }>): void => {
        dispatch(setTncAccepted(requestPayload))
    }

export const paypalButtonClick =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof PAYPAL_BUTTON_CLICKED; payload: boolean }>): void => {
        dispatch(paypalButtonClickAction(requestPayload))
    }

export const paypalButtonClickValidation =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof PAYPAL_BUTTON_CLICKED_VALID; payload: boolean }>): void => {
        dispatch(paypalButtonClickValidationAction(requestPayload))
    }

/**
 * Function to perform action event for click to pay button click
 * @param {boolean} requestPayload
 * @return {Function}
 */
export const clickToPayButtonClick =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof CLICK_TO_PAY_BUTTON_CLICKED; payload: boolean }>): void => {
        dispatch(clickToPayButtonClickAction(requestPayload))
    }

/**
 * action to trigger the sequential api calls needed for paypal CTA click
 * @param {string} cartId cartid
 * @param {Function} resolveHandler promise resolve handler callback
 * @param {Function} rejectHandler promise reject handler callback
 * @returns {void} promise resolving with token data or reject with error
 */
export const getExpressPayPalToken =
    (
        cartId: string,
        resolveHandler: (resolveData: PayPalTokenData | boolean) => void,
        rejectHandler: (errorCode: string) => void,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        const { storeInitiatedCart, isStoreSharedCart } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        )
        const storeId = isStoreSharedCart ? storeInitiatedCart?.storeData?.id : selectedPreferredStoreId
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        dispatch(reservationRequestInProgress())
        cartService
            .initCheckout(cartId, storeId?.toString(), isOneTimeCartFlag)
            .then(data => {
                replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                isStoreSharedCart
                    ? dispatch(updateStoreInitiatedCart(cartFilteredData))
                    : dispatch(updateCartDataSuccessAction(cartFilteredData))
            })
            .then(() => {
                dispatch(reservationRequestFinished())
                resolveHandler(true)
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response
                if (errorResponse) {
                    dispatch(getCartItemsFailureAction(errorResponse))
                }
                dispatch(initCheckoutFailure())
                dispatch(resetCheckOutVariablesAction())
                dispatch(resetReservationVariables())
                rejectHandler(paymentInformationConst.payPalCartValidationError)
            })
    }

/**
 * action to trigger the sequential api calls needed for paypal CTA click
 * @param {GetPaypalTokenPayload} getPaypalTokenPayload action payload params
 * @returns {void} promise resolving with token data or reject with error
 */
export const getPayPalToken =
    (getPaypalTokenPayload: GetPaypalTokenPayload) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        const semaFoneFlag = getPaypalTokenPayload.isSemaFoneEnabled ? 'Y' : ''
        CheckoutService.hppToken(
            getPaypalTokenPayload.cartId,
            getPaypalTokenPayload.storeId,
            getPaypalTokenPayload.initPaymentVersion,
            false,
            userType.anonymous,
            semaFoneFlag,
            isOneTimeCartFlag,
            getPaypalTokenPayload.paymentType,
        )
            .then((resp: AxiosResponse<InitPaymentResponseDTO>) => {
                dispatch(
                    setHppToken({
                        hppToken: resp.data?.transactionId,
                        paymentPlanInfo: resp.data?.paymentPlanInfo,
                        iframeUrl: resp.data?.iframeUrl,
                        validUntil: resp.data.validUntil,
                        giftCards: resp.data?.giftCards,
                        ecToken: resp.data?.ecToken,
                    }),
                )
                dispatch(setInitPaymentStatusAction(true))
                if (!!resp.data?.ecToken && !!resp.data?.transactionId) {
                    getPaypalTokenPayload.resolveHandler({
                        paypalToken: resp.data.ecToken,
                        hppToken: resp.data.transactionId,
                    })
                } else {
                    getPaypalTokenPayload.rejectHandler(paymentInformationConst.payPalInitPaymentError)
                }
            })
            .catch((initPaymentErrResp: AxiosCartResponseErrorDTO) => {
                let error = paymentInformationConst.payPalInitPaymentError
                if (initPaymentErrResp?.response?.data.errCode === paymentInformationConst.payPalError365) {
                    error = paymentInformationConst.payPalMerchantDisabledError
                }
                getPaypalTokenPayload.rejectHandler(error)
            })
    }
