import { isOrderBOPIS, libUtils, localization, getETATimeOptions } from '@nl/lib'
import {
    CartOrderEntries,
    DeliveryStatusBopis,
    DeliveryStatusSTH,
    FilteredCartData,
} from '../../../redux/models/cart.interface'
import { MagicNumber } from '../../../analytics/analytics.type'
import { Order } from '../../../redux/models/orderConfirmation.interface'
import { dateOptions } from '../../../globalConstants'
import { CancelOrderReasonsState } from '../../../redux/reducers/cancelOrderReasons.reducer'
import { AnalyticsEvent } from '../../../global.type'
import { isNullOrUndefined } from '../../../utils/isNullOrUndefined'

/**
 * constant created for month/date/year/time format for status ready for pickup and picked up/completed
 */
const etaTimeOptions = {
    ...dateOptions,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC',
}
const dateOptionsMin = {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
}
/**
 * Function to get formatted date time for different statuses with month/date/year and time format
 * @param {CartOrderEntries} orderEntry
 * @param {Record<string, unknown>} formattedDateOptions
 * @return { Record<string, string>}
 */
export const getFormattedDateTime = (
    orderEntry: CartOrderEntries,
    formattedDateOptions?: Record<string, unknown>,
): Record<string, string> => {
    const formattedDateOptionsMin = formattedDateOptions || dateOptionsMin
    const formattedDateOptionsMax = formattedDateOptions || dateOptions
    const minEta = orderEntry?.minETA
        ? localization.formattedDate(
              formattedDateOptionsMin,
              orderEntry?.statusDate || orderEntry?.minETA,
              libUtils.getLanguage(),
          )
        : ''
    const maxEta = orderEntry?.maxETA
        ? localization.formattedDate(formattedDateOptionsMax, orderEntry?.maxETA, libUtils.getLanguage())
        : ''

    return { formattedMinETA: minEta, formattedMaxETA: maxEta }
}

/**
 * Function to check if the order entry is BOPIS and in completed status
 * @param {CartOrderEntries} orderEntry
 * @return {boolean}
 */
const isBopisCompleted = (orderEntry: CartOrderEntries): boolean => {
    return isOrderBOPIS(orderEntry) && orderEntry.status === DeliveryStatusBopis.COMPLETED
}

/**
 * function to get status Label
 * @param {orderEntry} CartOrderEntries
 * @param {string} statusProps
 * @return {string}
 */
export const getDisplayStatus = (orderEntry: CartOrderEntries, statusProps: string, atLabel: string): string => {
    let statusLabel = statusProps
    let timeRange = ''

    if (orderEntry.status === DeliveryStatusBopis.READY_FOR_PICK_UP || isBopisCompleted(orderEntry)) {
        const { formattedMinETA } = getFormattedDateTime(orderEntry, getETATimeOptions(etaTimeOptions)) || {}
        const [date, year, time] = formattedMinETA.split(',')
        // eslint-disable-next-line sonar/no-nested-conditional
        const revisedTime = year && time ? `,${year} ${atLabel} ${time}` : year ? `${atLabel} ${year}` : ''
        timeRange = `${date} ${revisedTime}`
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (orderEntry.status === DeliveryStatusBopis.IN_PROGRESS) {
        const { formattedMinETA, formattedMaxETA } = getFormattedDateTime(orderEntry) || {}
        timeRange = `${formattedMinETA} ${formattedMaxETA ? ' - ' : ''} ${formattedMaxETA}`
    }

    statusLabel = `${statusLabel} ${timeRange}`
    return statusLabel
}

/**
 * function to get status Label Props
 * @param {string} statusProps
 * @param {boolean} isSTH
 * @return {statusLabel} string
 */
export const getStatusLabelProps = (statusProps: string, isSTH: boolean, showAppointmentStatus?: boolean): string => {
    const statusLabelArray = statusProps?.split('/')
    if (isSTH) {
        return statusLabelArray?.[MagicNumber.ONE] || statusProps
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (showAppointmentStatus && !isNullOrUndefined(statusLabelArray?.[MagicNumber.TWO])) {
        return statusLabelArray?.[MagicNumber.TWO]
    }
    return statusLabelArray?.[MagicNumber.ZERO] || statusProps
}

export const isOrderHasAutomotiveLines = (order: Order | FilteredCartData): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        order &&
        Object.keys(order).length > 0 &&
        ((order.bopis?.length > 0 &&
            order.bopis?.some(entry => {
                return entry.isTireOrWheel || entry.repairOrderRequired
            })) ||
            (order.sth?.length > 0 &&
                order.sth?.some(entry => {
                    return entry.isTireOrWheel || entry.repairOrderRequired
                })))
    )
}

export const cancellationAnalytics = (
    event: string,
    cancelType: string,
    cancelLocation: string,
    cancellationReason?: string,
): void => {
    window.analyticsLayer = window.analyticsLayer || []
    !!cancellationReason
        ? window.analyticsLayer.push({
              event: event,
              cancelType: cancelType,
              cancelLocation: cancelLocation,
              cancellationReason: cancellationReason,
          } as unknown as AnalyticsEvent)
        : window.analyticsLayer.push({
              event: event,
              cancelType: cancelType,
              cancelLocation: cancelLocation,
          } as unknown as AnalyticsEvent)
}

/**
 * function to check if common cancel button is needed to show
 * @param {boolean} customerSelfServiceCancelEnabled
 * @param {CancelOrderReasonsState} cancellationReasons
 * @param {boolean} cancellable
 * @param {string} summaryOrderStatusMsg
 * @return {boolean}
 */
export const checkCommonCancelButton = (
    customerSelfServiceCancelEnabled: boolean,
    cancellationReasons: CancelOrderReasonsState,
    cancellable: boolean,
    summaryOrderStatusMsg: string,
): boolean => {
    const cancellationReasonsAndSelfServiceCancelEnabled = Boolean(
        customerSelfServiceCancelEnabled && cancellationReasons?.cancellationReasons?.length,
    )
    return !!summaryOrderStatusMsg
        ? cancellationReasonsAndSelfServiceCancelEnabled && cancellable
        : cancellationReasonsAndSelfServiceCancelEnabled
}

/**
 * function to check if message about impossibility to cancel order is needed to display
 * @param {boolean} areAllProductsIsNotCancellable
 * @param {boolean} cancellable
 * @param {string} status
 * @param {string} summaryOrderStatusMsg
 * @return {boolean}
 */
export const checkNotCancellableMsg = (
    areAllProductsIsNotCancellable: boolean,
    cancellable: boolean,
    status: string,
    summaryOrderStatusMsg: string,
): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        areAllProductsIsNotCancellable &&
        !cancellable &&
        (status === DeliveryStatusSTH.IN_PROGRESS || status === DeliveryStatusSTH.EN_COURS) &&
        !!summaryOrderStatusMsg
    )
}

/**
 * function to check if edit section toast is available
 * @param {boolean} isModifyContactEmailEnabled
 * @param {boolean} isModifyContactPhoneEnabled
 * @param {boolean} isModifyDeliveryAddressEnabled
 * @param {boolean} isModifyPickupContactEnabled
 * @param {boolean} isModifyPickupMethodEnabled
 * @param {boolean} isModifyAppointmentInformationEnabled
 * @return {boolean}
 */
export const getIsShowEditSectionToast = (
    isModifyContactEmailEnabled = false,
    isModifyContactPhoneEnabled = false,
    isModifyDeliveryAddressEnabled = false,
    isModifyPickupContactEnabled = false,
    isModifyPickupMethodEnabled = false,
    isModifyAppointmentInformationEnabled = false,
): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        isModifyContactEmailEnabled ||
        isModifyContactPhoneEnabled ||
        isModifyDeliveryAddressEnabled ||
        isModifyPickupContactEnabled ||
        isModifyPickupMethodEnabled ||
        isModifyAppointmentInformationEnabled
    )
}

/**
 * function to check if return items button is needed to show
 * @param {boolean | null} authenticatedUser
 * @param {boolean | undefined} displayReturnPortalLink
 * @return {boolean}
 */
export const checkReturnItemsButton = (
    authenticatedUser: boolean | null,
    displayReturnPortalLink: boolean | undefined,
): boolean => {
    return Boolean(authenticatedUser && displayReturnPortalLink)
}
