import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import {
    addProductToWishListSuccessAction,
    removeProductFromWishListActionSuccess,
    wishListErrorAction,
    wishlistSuccessAction,
    wishlistProductCodesSuccessAction,
    wishlistDetailsSuccessAction,
    removeWishlistSuccessAction,
    resetSuccessToastAction,
    setMergeWishlistAction,
    updateProductFromCompareTool,
} from '../actionCreators'

import {
    wishlistPayload,
    errorResponseObject,
    WishlistResponse,
    WishlistProduct,
    AddToWishListPayload,
} from '../models/wishlist.interface'
import analyticsLayer from '../../utils/analyticsService'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { wishlistService } from '../../services/wishlistService'
import { HttpRequestState } from '../../httpClient/client.type'
import { RootState } from '../reducers'
import appCacheService from '../../utils/appCacheService'
import { storageData } from '../../globalConstants'
import getPageType from '../../utils/getPageType'
import getEnvironment from '../../utils/getEnvironment'
import { analyticsInteraction } from './pageAnalytics.action'
import { isArrayNotEmpty } from '@nl/lib'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { CertonaInitialization } from '../../certona/certona.service'
import { certonaEventConst } from '../../certona/certona.constant'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { addToWishlistInactiveProductError } from '../../components/BuyBox/BuyBox.constant'
import { MagicNumber } from '../../analytics/analytics.type'
import { UserProfileData } from '../models/user.profile.interface'
import { IFeatureFlag } from '../models/commonContent.interface'
import { setShowSpinner } from '../slices/spinner.slice'
import { ProductDataTypeObj } from '../models/productData.interface'
import { ComparisonWishlistResponse } from '../../analytics/providers/provider.type'
import { updateCompareWishlistValue } from '../../components/ProductGridView/ProductCard.helper'

const { cartWishlist } = analyticsAttributes.eventParameters.labels
const { action, category } = analyticsAttributes.eventParameters
const wishListErrorHandler = (
    err: errorResponseObject,
    dispatch: Dispatch,
    // eslint-disable-next-line default-param-last
    addRemove = false,
    // eslint-disable-next-line default-param-last
    isFromSFL = false,
    definedFromConfigurationErrorMessage?: string,
): void => {
    const errorResponse = err?.response
    const errorObject = err?.response?.data?.errors?.[0] || {}
    const { type, maxWishlistCount, errorCode = '' } = errorObject
    const message = definedFromConfigurationErrorMessage ?? errorObject.message

    addRemove &&
        dispatch(
            analyticsInteraction(
                message,
                '',
                analyticsAttributes.event.error,
                action.viewErrorMessage,
                getPageType(),
            ) as unknown as AnyAction,
        )

    dispatch(
        wishListErrorAction({
            code: errorResponse?.status,
            success: false,
            message,
            type,
            isFromSFL,
            maxWishlistCount: maxWishlistCount,
            errorCode: errorCode,
        }),
    )
}

const calculateExpiryTime = (ttlForCartAndWishlistCache: string): number => {
    const expiryTime = parseInt(ttlForCartAndWishlistCache, 10)
    return expiryTime >= 0 ? expiryTime : MagicNumber.ONE
}

const cacheWishlistToLS = (
    wishlistCacheObj: WishlistResponse,
    wishlistItems: WishlistResponse,
    userProfileData: UserProfileData | null,
    ttlForCartAndWishlistCache: string,
    featureFlag: IFeatureFlag,
): void => {
    const isUserLoggedIn = checkDataLength(userProfileData)
    const expiryTime = calculateExpiryTime(ttlForCartAndWishlistCache)
    if (!isUserLoggedIn) {
        featureFlag &&
            featureFlag.enableCacheWishlistToLocalStorageForAnonymous &&
            appCacheService.wishlistCache.set(JSON.stringify({ ...wishlistCacheObj, ...wishlistItems }))
    } else {
        featureFlag &&
            featureFlag.enableCacheWishlistToLocalStorageForAnonymous &&
            // eslint-disable-next-line no-magic-numbers
            appCacheService.wishlistCache.setWithExpiry({ ...wishlistItems }, expiryTime * 1000)
    }
}

export const cacheResponseToLS = (getState: () => RootState): void => {
    const state = getState()
    const wishlistItems = state.wishlist.wishlistItems
    const userProfileData = state.userProfile.userProfileData
    const {
        featureFlag,
        wishlist: { ttlForCartAndWishlistCache },
    } = state.commonContent.commonContentAvailable
    const wishlistCache = appCacheService.wishlistCache.get()
    const wishlistCacheObj = wishlistCache ? (JSON.parse(wishlistCache) as WishlistResponse) : ({} as WishlistResponse)

    cacheWishlistToLS(wishlistCacheObj, wishlistItems, userProfileData, ttlForCartAndWishlistCache, featureFlag)
}

/**
 * Adds a product to the wishlist.
 * @param {wishlistPayload} req - The payload containing wishlist details.
 * @param {AddToWishListPayload} addToWishlistPayLoad - The payload containing details of the product to be added to the wishlist.
 * @param {boolean} showSpinner - Flag to indicate whether to show a spinner during the operation.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowWishListSpinner - Function to set the state of the wishlist spinner.
 * @param {(productCode: string) => void} changeWishlistAvailabilityStatus - Optional callback to change the availability status of the wishlist.
 * @param {boolean} isStaggeredProduct - Optional flag to indicate if the product is staggered.
 * @param {boolean} isFromSFL  - Flag to indicate if the request is from Save For Later.
 * @param {string} addToWishlistInactiveProductErrorMsg - Optional error message for inactive products.
 * @param {boolean} isLight - Optional flag to indicate if the operation is light.
 * @param {ProductDataTypeObj[]} compareProductsList - Optional list of products for comparison.
 * @param {ComparisonWishlistResponse[]} wishlistItemsSku - Optional list of wishlist items SKU.
 * @param {boolean} isWishlistClickedFromComparisonTool - Optional flag to indicate if the wishlist was clicked from the comparison tool.
 * @returns {Promise<void>} A promise that resolves when the product is added to the wishlist.
 */
export const addProductToWishList =
    (
        req: wishlistPayload,
        addToWishlistPayLoad: AddToWishListPayload,
        // eslint-disable-next-line default-param-last
        showSpinner = false,
        // eslint-disable-next-line default-param-last
        setShowWishListSpinner: React.Dispatch<React.SetStateAction<boolean>> = () => false,
        changeWishlistAvailabilityStatus?: (productCode: string) => void,
        isStaggeredProduct?: boolean,
        // eslint-disable-next-line default-param-last
        isFromSFL = false,
        addToWishlistInactiveProductErrorMsg?: string,
        isLight?: boolean,
        compareProductsList?: ProductDataTypeObj[],
        wishlistItemsSku?: ComparisonWishlistResponse[],
        isWishlistClickedFromComparisonTool?: boolean,
        // eslint-disable-next-line max-params
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId } = getState().storeDetails
        const environment = getEnvironment()
        showSpinner && dispatch(setShowSpinner({ show: true })) // Display spinner if required
        setShowWishListSpinner(true)
        return wishlistService
            .addItemToWishlist(
                req,
                selectedPreferredStoreId || appCacheService.preferredStoreId.get(),
                addToWishlistPayLoad,
                isStaggeredProduct,
                isLight,
            )
            .then((res: AxiosResponse<WishlistResponse>) => {
                req.analyticsParams &&
                    analyticsLayer.pushData({
                        event: cartWishlist,
                        eventParameters: {
                            action: req.analyticsParams.action,
                            category: cartWishlist,
                            label: req.analyticsParams.sku || '',
                            value: req.analyticsParams.value,
                            location: req.analyticsParams.location,
                        },
                        product: {
                            pcode: req.analyticsParams.pcode,
                        },
                    })
                !!res.data.guid && appCacheService.wishlistGuid.set(res.data.guid)
                dispatch(
                    addProductToWishListSuccessAction({ code: res.status, success: true, active: true, isFromSFL }),
                )
                dispatch(wishlistSuccessAction(res.data))
                isWishlistClickedFromComparisonTool &&
                    compareProductsList &&
                    dispatch(
                        updateProductFromCompareTool(
                            updateCompareWishlistValue(compareProductsList, wishlistItemsSku, true),
                        ),
                    )
                CertonaInitialization.triggerCertona({
                    itemid: addToWishlistPayLoad.wishListEntries[0].product.baseProduct,
                    event: certonaEventConst.addToWishlist,
                    environment: environment,
                    recommendations: false,
                })

                changeWishlistAvailabilityStatus &&
                    changeWishlistAvailabilityStatus(addToWishlistPayLoad.wishListEntries[0].product.code)
            })
            .then(() => cacheResponseToLS(getState))
            .catch(err => {
                wishListErrorHandler(
                    err,
                    dispatch,
                    true,
                    isFromSFL,
                    prepareAddToWishlistErrorMessage(err, addToWishlistInactiveProductErrorMsg),
                )
            })
            .finally(() => {
                dispatch(setShowSpinner({ show: false }))
                setShowWishListSpinner(false)
            })
    }

/**
 * function call for getting error message depending on error that appeared after ATW button click
 * @param {string} err - response error object
 * @param {string} addToWishlistInactiveProductErrorMsg - Add To Wishlist Inactive Product Error Message
 * @return {string} - Add To Wishlist Error Message
 */
const prepareAddToWishlistErrorMessage = (
    err: errorResponseObject,
    addToWishlistInactiveProductErrorMsg: string | undefined,
) => {
    return err?.response?.data?.errors?.[0]?.message.includes(addToWishlistInactiveProductError)
        ? addToWishlistInactiveProductErrorMsg
        : undefined
}

/**
 * function call for getting wishlist items
 * @param {string} guid - guid for the user
 * @return {Dispatch}
 */
export const getWishListItems =
    (guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        wishlistService
            .getWishlist(guid, selectedPreferredStoreId)
            .then((wishlistItems: { data: WishlistResponse }) => {
                replaceEmptyImagesWithDefault(wishlistItems?.data?.products, 'images')
                const wishlistData = {
                    ...wishlistItems.data,
                    xhrInfo: {
                        getWishListInfo: HttpRequestState.done,
                    },
                }
                dispatch(wishlistSuccessAction(wishlistData))
            })
            .then(() => cacheResponseToLS(getState))
            .catch(err => {
                wishListErrorHandler(err, dispatch)
            })
    }

/**
 * function call for remove wishlist items
 * @param {wishlistPayload} req - guid for the user
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowWishListSpinner - Set Show WishList Spinner
 * @param {void} changeWishlistAvailabilityStatus
 * @param {boolean} isLight
 * @param {ProductDataTypeObj[]} compareProductsList - Optional list of products for comparison.
 * @param {ComparisonWishlistResponse[]} wishlistItemsSku - Optional list of wishlist items SKU.
 * @param {boolean} isWishlistClickedFromComparisonTool - Optional flag to indicate if the wishlist was clicked from the comparison tool.
 * @returns {void}
 */
export const removeWishListItems =
    (
        req: wishlistPayload,
        // eslint-disable-next-line default-param-last
        setShowWishListSpinner: React.Dispatch<React.SetStateAction<boolean>> = () => false,
        changeWishlistAvailabilityStatus?: (productCode: string) => void,
        isLight?: boolean,
        compareProductsList?: ProductDataTypeObj[],
        wishlistItemsSku?: ComparisonWishlistResponse[],
        isWishlistClickedFromComparisonTool = false,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        const environment = getEnvironment()
        setShowWishListSpinner(true)
        wishlistService
            .removeProductFromWishlist(
                req.guid,
                selectedPreferredStoreId,
                req.wishlistRequestPayload as AddToWishListPayload,
                isLight,
            )
            .then((res: AxiosResponse<WishlistResponse>) => {
                replaceEmptyImagesWithDefault(res?.data?.products, 'images')
                if (req.analyticsParams) {
                    analyticsLayer.pushData({
                        event: cartWishlist,
                        eventParameters: {
                            action: req.analyticsParams.action,
                            category: cartWishlist,
                            label: req.analyticsParams.sku,
                            value: req.analyticsParams.value,
                            location: req.analyticsParams.location,
                        },
                        product: {
                            pcode: req.analyticsParams.pcode,
                        },
                    })
                }
                CertonaInitialization.triggerCertona({
                    itemid: req?.wishlistRequestPayload?.wishListEntries[0].product.code as string,
                    event: certonaEventConst.removeFromWishlist,
                    environment: environment,
                    recommendations: false,
                })
                dispatch(removeProductFromWishListActionSuccess({ code: res.status, success: true, active: false }))
                dispatch(removeWishlistSuccessAction(res.data))
                isWishlistClickedFromComparisonTool &&
                    compareProductsList &&
                    dispatch(
                        updateProductFromCompareTool(
                            updateCompareWishlistValue(compareProductsList, wishlistItemsSku, false),
                        ),
                    )
                changeWishlistAvailabilityStatus &&
                    changeWishlistAvailabilityStatus(
                        req?.wishlistRequestPayload?.wishListEntries[0].product.code as string, // CART1-674 - regression issue fix
                    )
            })
            .then(() => cacheResponseToLS(getState))
            .catch(err => {
                wishListErrorHandler(err, dispatch, true)
            })
            .finally(() => {
                setShowWishListSpinner(false)
            })
    }

/**
 * function to reset toast status
 * @return {Dispatch}
 */
export const resetWishlistSuccessToast =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetSuccessToastAction())
    }

/**
 * This function returns skus joined by hyphen
 * @param {WishlistProduct[]} products
 * @return {string} skuList
 */

export const skus = (products: WishlistProduct[]): string => {
    const skuList = products?.map(item => item.code)
    return skuList?.join(' - ')
}

export const mergeWishListItems =
    (guid: string) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<any> => {
        const { selectedPreferredStoreId } = getState().storeDetails

        const authWishlistData = await wishlistService.getWishlist(guid, selectedPreferredStoreId)
        wishlistService
            .mergeWishlistItems(guid, selectedPreferredStoreId)
            .then((res: AxiosResponse<WishlistResponse>) => {
                replaceEmptyImagesWithDefault(res?.data?.products, 'images')
                isArrayNotEmpty(authWishlistData.data.products) &&
                    dispatch(
                        analyticsInteraction(
                            skus(authWishlistData.data.products),
                            '',
                            analyticsAttributes.event.mergeWishlist,
                            action.mergeWishlist,
                            category.account,
                        ) as unknown as AnyAction,
                    )
                appCacheService.wishlistGuid.delete(storageData.wishlistGuid)

                dispatch(wishlistSuccessAction(res.data))
                dispatch(setMergeWishlistAction(true))
            })
            .then(() => cacheResponseToLS(getState))
            .catch((error: errorResponseObject) => {
                dispatch(setMergeWishlistAction(false))
                wishListErrorHandler(error, dispatch, true)
                const err = error?.data?.response
                // eslint-disable-next-line no-console
                console.group('Reason for merge wishlist failure')
                // eslint-disable-next-line no-console
                console.log(`error Message: ${err?.message}`)
                // eslint-disable-next-line no-console
                console.log(`errorType: ${err?.error}`)
                // eslint-disable-next-line no-console
                console.log(`statusCode: ${err?.statusCode || ''}`)
                // eslint-disable-next-line no-console
                console.groupEnd()
            })
    }

/**
 * function call for getting wishlistProductCodes items
 * @param {string} guid - guid for the user
 * @param {string} storeId - store id
 * @return {Dispatch}
 */
export const getWishListProductCodesItems =
    (guid: string, storeId: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        wishlistService
            .getWishlistProductCodes(guid, storeId)
            .then((wishlistItems: { data: WishlistResponse }) => {
                const wishlistProductCodesData = {
                    ...wishlistItems.data,
                }
                dispatch(wishlistProductCodesSuccessAction(wishlistProductCodesData))
            })
            .then(() => cacheResponseToLS(getState))
            .catch(err => {
                wishListErrorHandler(err, dispatch)
            })
    }

/**
 * function call for getting wishlistDetails items
 * @param {string} guid - guid for the user
 * @return {Dispatch}
 */
export const getWishListDetailsItems =
    (guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        wishlistService
            .getWishlistDetails(guid, selectedPreferredStoreId)
            .then((wishlistItems: { data: WishlistResponse }) => {
                const wishlistProductCodesData = {
                    ...wishlistItems.data,
                }
                dispatch(wishlistDetailsSuccessAction(wishlistProductCodesData))
            })
            .then(() => cacheResponseToLS(getState))
            .catch(err => {
                wishListErrorHandler(err, dispatch)
            })
    }
