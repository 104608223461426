import React, { useState } from 'react'
import {
    areAllParamsValid,
    ButtonColors,
    ButtonV2,
    FlyoutModalComponent,
    getBalance,
    Icon,
    NotificationBadge,
    Size,
    State,
    useIsMobile,
    Variation,
} from '@nl/lib'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { BREAKPOINTS, PREFIX, previousElementName } from '../../config'
import { useAppSelector } from '../../hooks/react-redux.hook'
import { RewardsHubProps } from './RewardsHub.type'
import { isPanelBlockedForCurrentPage } from '../LoyaltyOffers/LoyaltyOffers.helper'
import { useIsLoggedInLoyaltyUser } from '../../hooks/useIsLoggedInLoyaltyUser.hook'
import RewardsHubFlyout from './RewardsHubFlyout'

/**
 * RewardsHub component
 * @param {RewardsHubProps} props
 * @returns {JSX.Element}
 */
const RewardsHub: React.FC<RewardsHubProps> = ({ ...props }: RewardsHubProps): JSX.Element => {
    const { alertLabel, offersLabel, myDealsLabel, alertIcon, offersIcon, myDealsIcon, blockListPagePaths } = props
    const userProfileData = useAppSelector(userProfileDataSelector)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const { featureFlag, accountDashboard } = commonContentAvailable
    const { enableRewardsHub } = featureFlag || ({} as typeof commonContentAvailable.featureFlag)
    const { rewardsBalanceLabel = '', authenticatedUserGreetingText = '' } =
        accountDashboard || ({} as typeof commonContentAvailable.accountDashboard)
    const isBlockListPage = isPanelBlockedForCurrentPage(blockListPagePaths || [])
    const isMobileOrTablet = useIsMobile(BREAKPOINTS.tabletMaxWidth)
    const isLoggedInLoyaltyUser = useIsLoggedInLoyaltyUser()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedTabLabel, setSelectedTabLabel] = useState<string>('')
    const navigationList = [
        { label: alertLabel, iconType: alertIcon },
        { label: offersLabel, iconType: offersIcon },
        { label: myDealsLabel, iconType: myDealsIcon },
    ]
    const previousElement: HTMLElement | null = document.querySelector(`button[${previousElementName}]`)

    /**
     * Handles the click event for a navigation button.
     * @param {React.MouseEvent<Element, MouseEvent>} event - The mouse event triggered by clicking the navigation button.
     * @param {string} label - The label of the selected tab.
     * @returns {void}
     */
    const onClickHandler = (event: React.MouseEvent<Element, MouseEvent>, label: string): void => {
        setIsOpen(true)
        setSelectedTabLabel(label)
        const navigationButton = event.target as HTMLElement
        navigationButton.setAttribute(previousElementName, 'true')
    }

    /**
     * Closes the flyout by setting the `isOpen` state to `false`.
     * @returns {void}
     */
    const closeFlyout = (): void => {
        setIsOpen(false)
    }

    /**
     * Renders a navigation button with a label, icon, and notification badge.
     *
     * @param {string} label - The text label to display on the button.
     * @param {string} iconType - The type of icon to display on the button.
     * @returns {JSX.Element} The rendered navigation button component.
     */
    const navigationButton = (label: string, iconType: string): JSX.Element => {
        return (
            <ButtonV2
                variant={Variation.PRIMARY}
                size={Size.MINI}
                customClass={`${PREFIX}-rewards-hub__navigation-cta`}
                color={ButtonColors.DARK}
                icon={{ type: iconType, size: 'lg' }}
                onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
                    onClickHandler(event, label)
                }}>
                {label}
                <NotificationBadge
                    value={'4'}
                    notificationClass={`${PREFIX}-rewards-hub__navigation-badge`}
                    color={State.ERROR}
                    size={Size.XS}
                />
            </ButtonV2>
        )
    }
    return (
        <>
            {areAllParamsValid(
                Boolean(enableRewardsHub),
                isLoggedInLoyaltyUser,
                !isBlockListPage,
                isMobileOrTablet,
            ) && (
                <>
                    <div className={`${PREFIX}-rewards-hub`}>
                        <div className={`${PREFIX}-rewards-hub__navigation`}>
                            {navigationList.map(item => (
                                <>{navigationButton(item.label ?? '', item.iconType ?? '')}</>
                            ))}
                        </div>

                        <div className={`${PREFIX}-rewards-hub__balance`}>
                            <div className={`${PREFIX}-rewards-hub__balance-label`}>
                                <Icon size="md" type="ct-triangleMoney" />
                                <span dangerouslySetInnerHTML={{ __html: rewardsBalanceLabel }}></span>:
                            </div>
                            <span className={`${PREFIX}-rewards-hub__balance-value`}>
                                {getBalance(userProfileData?.balance || (0).toString())}
                            </span>
                        </div>
                    </div>
                    {isOpen && (
                        <FlyoutModalComponent isOpen={isOpen} closeFlyout={closeFlyout}>
                            <RewardsHubFlyout
                                {...props}
                                setShouldOpen={setIsOpen}
                                authenticatedUserGreetingText={authenticatedUserGreetingText}
                                balance={getBalance(userProfileData?.balance || (0).toString())}
                                userName={`${userProfileData?.firstName ?? ''}`}
                                selectedTabLabel={selectedTabLabel}
                                setSelectedTabLabel={setSelectedTabLabel}
                                previousElement={previousElement}
                                shouldOpen={isOpen}
                            />
                        </FlyoutModalComponent>
                    )}
                </>
            )}
        </>
    )
}

export default RewardsHub
