import rootReducer, { RootState } from '../redux/reducers'

import { configureStore as configureStoreRTK, Middleware } from '@reduxjs/toolkit'
// eslint-disable-next-line sonar/no-wildcard-import
import * as actionCreators from '../redux/actions'
import { prerenderMiddleware } from '../redux/customMiddlewares/prerenderMiddleware'
import { mergeProductsAndSponsoredAdsMiddleware } from '../redux/customMiddlewares/mergeSearchAdResultMiddleware'
import { mergeSponsoredFromSearchMiddleware } from '../redux/customMiddlewares/mergeSponsoredFromSearchMiddleware'
import { setSponsoredProductsMiddleware } from '../redux/customMiddlewares/setSponsoredProductsMiddleware'

export const configureStore = (initialState?: Partial<RootState>) =>
    configureStoreRTK({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat([
                prerenderMiddleware as Middleware<undefined, RootState>,
                mergeProductsAndSponsoredAdsMiddleware as Middleware<undefined, RootState>,
                mergeSponsoredFromSearchMiddleware as Middleware<undefined, RootState>,
                setSponsoredProductsMiddleware as Middleware<undefined, RootState>,
            ]),
        devTools: {
            actionCreators,
            trace: true,
            traceLimit: 25,
        },
    })

export default configureStore()
