import { AnyAction, Dispatch } from '@reduxjs/toolkit'

import {
    ActivateOffersPayload,
    OfferActivation,
    CustomerOffers,
    FetchOffersPayload,
    ActivateTargetedOfferPayload,
    TargetedOfferActivation,
    ActiveOffer,
    Offers,
    CommunityOffersPayload,
    CommunityOffers,
    SwapOfferPayload,
    SwapOffer,
    FilterComboExclusion,
    SwapableList,
    SwapQueryParam,
} from '../models/offers.interface'
import {
    offersSuccessAction,
    offersFailureAction,
    offersActivateFailureAction,
    offersActivateSuccessAction,
    targetedOfferActivateSuccessAction,
    offersFetchInProgressAction,
    updateFilteredOffersSortingAction,
    updateFilteredOffersAction,
    filteredOffersFailureAction,
    filteredOffersSuccessAction,
    setLoyaltyOffersSuccessAction,
    communityOffersSuccessAction,
    communityOffersFailureAction,
    swapCommunityOfferSuccessAction,
    swapCommunityOfferFailureAction,
    communitySwappableOffer,
    setIsActivateOfferApiDoneAction,
    filteredCommunityOffersSuccessAction,
    filteredCommunityOffersFailureAction,
    updateCommunityFilterOffersAction,
    updateCommunitySelectedFacet,
    updateSelectedFacet,
    updateCommunitySelectedFilter,
    updateSelectedFilter,
    updateFilteredCommunitySortingAction,
    updateCommunitySortDataAction,
    updateOffersSortDataAction,
    filteredSwappableOffersSuccessAction,
    filteredSwappableOffersFailureAction,
    updateSwappableFilterOffersAction,
    updateSwappableSelectedFacet,
    updateSwappableSelectedFilter,
    updateFilteredSwappableOfferSortingAction,
    updateSwappableSortDataAction,
    setPtePropSuccessAction,
} from '../actionCreators'
import { offersService } from '../../services/offersService'
import { offerConstants } from '../../components/Accounts/Rewards/Offers/OffersCard.constants'
import { checkDataLength, libUtils, Facet, Status } from '@nl/lib'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../models/mergeLoyaltyCard.interface'
import {
    areOffersCached,
    parseCachedOffersString,
    cacheOffers,
    offerLanguageKey,
    getOffersPayload,
    handleOffersCacheOnSignOut,
} from '../../utils/offersCache.helper'
import sessionStorageService from '../../utils/sessionStorageService'
import { FilteredOffers } from '../reducers/offers.reducer'
import httpClient from '../utils/httpClient'
import { logNewRelicError } from '../../components/NewRelic/newRelic.helper'
import { RootState } from '../reducers'
import { getComboFilterList } from '../../helpers/offers.helper'
import { dropdownList } from '../../components/LinkRewards/LinkRewards.types'
import { OfferCarouselWrapperProps } from '../../components/OfferCarousel/OfferCarousel.type'
import { setShowSpinner } from '../slices/spinner.slice'

/**
 * Fetch customer offers action
 * @param {FetchOffersPayload} requestPayload
 * @param {boolean} forceCall
 * @param {string} lmsProfileId
 * @param {FilterComboExclusion} filteredComboExlusion
 * @param {string} loyaltyCardNumber
 * @returns {Dispatch}
 */
export const fetchCustomerOffers =
    (
        requestPayload: FetchOffersPayload,
        forceCall?: boolean,
        lmsProfileId?: string | null,
        filteredComboExlusion?: FilterComboExclusion,
        loyaltyCardNumber?: string,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> | void => {
        if (getState().offers?.offersFetchStatus === Status.PROGRESS) return
        dispatch(offersFetchInProgressAction(Status.PROGRESS))
        const language = libUtils.getLanguage()
        const languageMatch = Boolean(sessionStorageService.getItem(offerLanguageKey) === language)
        if (areOffersCached() && !forceCall && languageMatch && !loyaltyCardNumber) {
            let cachedOffers = parseCachedOffersString()
            cachedOffers = getFilterComboData(cachedOffers as CustomerOffers, filteredComboExlusion)
            dispatch(offersSuccessAction(cachedOffers))
        } else {
            // eslint-disable-next-line consistent-return
            return offersService
                .getOffers(requestPayload, lmsProfileId, loyaltyCardNumber)
                .then((offersData: { data: CustomerOffers }) => {
                    const unActivatedOffers = offersData.data.offers?.filter(
                        offer => offer.offerStatus === offerConstants.activate,
                    )
                    offersData.data.unActivatedOffersCount = unActivatedOffers?.length
                    cacheOffers(offersData.data, language)
                    offersData.data = getFilterComboData(offersData.data, filteredComboExlusion)
                    dispatch(offersSuccessAction(offersData.data))
                })
                .catch(() => {
                    handleOffersCacheOnSignOut()
                    dispatch(offersFailureAction())
                })
        }
    }

/**
 * Get Filtered combo excluded data
 * @param {CustomerOffers} offersData
 * @param {FilterComboExclusion} filteredComboExlusion
 * @return {CustomerOffers}
 */
const getFilterComboData = (
    offersData: CustomerOffers,
    filteredComboExlusion?: FilterComboExclusion,
): CustomerOffers => {
    if (filteredComboExlusion) {
        offersData.offers = getComboFilterList(
            offersData.offers,
            filteredComboExlusion.enableComboExclusions,
            filteredComboExlusion.comboExclusions,
        )
        const unActivatedOffers = offersData.offers?.filter(offer => offer.offerStatus === offerConstants.activate)
        offersData.unActivatedOffersCount = unActivatedOffers?.length
    }
    return offersData
}

/**
 * Activate customer offers action
 * @param {ActivateOffersPayload} requestPayload
 * @param {boolean} needToFetchOffers
 * @param {string} lmsProfileId
 * @param {string} offerComponent
 * @param {string[]} offersSortBy
 * @param {string} loyaltyCardNumber
 * @returns {Dispatch}
 */
export const activateCustomerOffers =
    (
        requestPayload: ActivateOffersPayload,
        needToFetchOffers: boolean,
        lmsProfileId?: string,
        offerComponent?: string,
        offersSortBy?: string[],
        loyaltyCardNumber?: string,
    ) =>
    (dispatch: Dispatch): void => {
        offersService
            .activateOffer(requestPayload, lmsProfileId, loyaltyCardNumber)
            .then((offerActivation: { data: OfferActivation }) => {
                needToFetchOffers &&
                    dispatch(
                        fetchCustomerOffers(
                            getOffersPayload(offersSortBy),
                            true,
                            lmsProfileId,
                            undefined,
                            loyaltyCardNumber,
                        ) as unknown as AnyAction,
                    )
                offerActivation.data.component = offerComponent
                dispatch(offersActivateSuccessAction(offerActivation.data))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(offersActivateFailureAction(error?.response?.data as ErrorResponse))
            })
    }

/**
 * Action for targeted offer activation
 * @param {ActivateTargetedOfferPayload} requestPayload
 * @param {boolean} multiOffers
 * @return {Dispatch}
 */
export const activateTargetedOffer =
    (requestPayload: ActivateTargetedOfferPayload, multiOffers: boolean) =>
    (dispatch: Dispatch): void => {
        offersService
            .activateTargetedOffer(requestPayload, multiOffers)

            .then((targetedOfferActivation: { data: TargetedOfferActivation[] }) => {
                dispatch(setIsActivateOfferApiDone(true) as unknown as AnyAction)
                dispatch(targetedOfferActivateSuccessAction(targetedOfferActivation.data))
                const offersActivateSuccessData = {} as OfferActivation
                const offerArray = [] as ActiveOffer[]
                targetedOfferActivation.data.forEach(targetedOffer => {
                    if (targetedOffer.activated === offerConstants.yes) {
                        const offerValue = {
                            offerCode: targetedOffer.offerCode,
                            activated: targetedOffer.activated,
                        } as ActiveOffer
                        offerArray.push(offerValue)
                        offersActivateSuccessData.offers = offerArray
                    }

                    return offersActivateSuccessData
                })
                if (checkDataLength(offersActivateSuccessData)) {
                    dispatch(offersActivateSuccessAction(offersActivateSuccessData))
                }
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(setIsActivateOfferApiDone(true) as unknown as AnyAction)
                dispatch(offersActivateFailureAction(error?.response?.data as ErrorResponse))
            })
    }

/**
 * Action for filtered offered data
 * @param {FilteredOffers} filteredOffers
 * @param {boolean} isTargetSwap target swapable flag
 * @returns {Dispatch}
 */
export const fetchFilteredCustomerOffers =
    (filteredOffers: FilteredOffers, isTargetSwap?: boolean) => (dispatch: Dispatch) => {
        // eslint-disable-next-line sonar/different-types-comparison
        if (filteredOffers?.filteredOffers !== null) {
            isTargetSwap
                ? dispatch(filteredSwappableOffersSuccessAction(filteredOffers))
                : dispatch(filteredOffersSuccessAction(filteredOffers))
        } else {
            isTargetSwap ? dispatch(filteredSwappableOffersFailureAction()) : dispatch(filteredOffersFailureAction())
        }
    }

/**
 * Action for filtered community offered data
 * @param {FilteredOffers} filteredOffers
 * @returns {Dispatch}
 */
export const fetchFilteredCommunityOffers = (filteredOffers: FilteredOffers) => (dispatch: Dispatch) => {
    // eslint-disable-next-line sonar/different-types-comparison
    if (filteredOffers?.filteredOffers !== null) {
        dispatch(filteredCommunityOffersSuccessAction(filteredOffers))
    } else {
        dispatch(filteredCommunityOffersFailureAction())
    }
}

/**
 * Action for update sort filter
 * @param {dropdownList} sortingBy sort filter based on brand,type or catergory
 * @param {boolean} isSwapTab is in community offer Tab
 * @param {boolean} isTargetSwap target swapable flag
 * @returns {Dispatch}
 */
export const updateSelectedSortData =
    (sortingBy: dropdownList[], isSwapTab?: boolean, isTargetSwap?: boolean) => (dispatch: Dispatch) => {
        isSwapTab
            ? // eslint-disable-next-line sonar/no-nested-conditional
              isTargetSwap
                ? dispatch(updateSwappableSortDataAction(sortingBy))
                : dispatch(updateCommunitySortDataAction(sortingBy))
            : dispatch(updateOffersSortDataAction(sortingBy))
    }

/**
 * Action for update sortby label
 * @param {string} sortingBy update sort label
 * @param {boolean} isSwapTab is in community offer Tab
 * @param {boolean} isTargetSwap target swapable flag
 * @returns {Dispatch}
 */
export const updateFilteredOfferSorting =
    (sortingBy: string, isSwapTab?: boolean, isTargetSwap?: boolean) => (dispatch: Dispatch) => {
        isSwapTab
            ? // eslint-disable-next-line sonar/no-nested-conditional
              isTargetSwap
                ? dispatch(updateFilteredSwappableOfferSortingAction(sortingBy))
                : dispatch(updateFilteredCommunitySortingAction(sortingBy))
            : dispatch(updateFilteredOffersSortingAction(sortingBy))
    }
/**
 * Action for updating filltered offer data
 * @param {Offers[]} filteredOffers
 * @param {boolean} isSwapTab is in community offer Tab
 * @param {boolean} isTargetSwap target swapable flag
 * @returns {Dispatch}
 */
export const updateFilteredOffers =
    (filteredOffers: Offers[], isSwapTab?: boolean, isTargetSwap?: boolean) => (dispatch: Dispatch) => {
        isSwapTab
            ? // eslint-disable-next-line sonar/no-nested-conditional
              isTargetSwap
                ? dispatch(updateSwappableFilterOffersAction(filteredOffers))
                : dispatch(updateCommunityFilterOffersAction(filteredOffers))
            : dispatch(updateFilteredOffersAction(filteredOffers))
    }

/**
 * Action for updating selected facer List
 * @param {Facet[]} facetval
 * @param {boolean} isSwapTab is in community offer Tab
 * @param {boolean} isTargetSwap target swapable flag
 * @returns {Dispatch}
 */
export const updateSelectedFacetOffers =
    (facetval: Facet[], isSwapTab?: boolean, isTargetSwap?: boolean) => (dispatch: Dispatch) => {
        isSwapTab
            ? // eslint-disable-next-line sonar/no-nested-conditional
              isTargetSwap
                ? dispatch(updateSwappableSelectedFacet(facetval))
                : dispatch(updateCommunitySelectedFacet(facetval))
            : dispatch(updateSelectedFacet(facetval))
    }

/**
 * Action for updating selcted filter facet list
 * @param {Record<string, string[]>} filter
 * @param {boolean} isSwapTab is in community offer Tab
 * @param {boolean} isTargetSwap target swapable flag
 * @returns {Dispatch}
 */
export const updateSelectedFilterFacet =
    (filter: Record<string, string[]>, isSwapTab?: boolean, isTargetSwap?: boolean) => (dispatch: Dispatch) => {
        isSwapTab
            ? // eslint-disable-next-line sonar/no-nested-conditional
              isTargetSwap
                ? dispatch(updateSwappableSelectedFilter(filter))
                : dispatch(updateCommunitySelectedFilter(filter))
            : dispatch(updateSelectedFilter(filter))
    }

/**
 * function call to get loyalty offers data props
 * @param {string} payload AEM link to get loyalty props
 * @param {string} id carousel unique id
 * @returns {Promise} set store with loyalty
 */
export const setLoyaltyOffers =
    (payload: string, id: string) =>
    // eslint-disable-next-line consistent-return
    (dispatch: Dispatch): Promise<void> | void => {
        if (payload) {
            return httpClient
                .apiGet(payload, {}, 'AEM_EXP_FRAG')
                .then(data => {
                    if (data.data) {
                        dispatch(setLoyaltyOffersSuccessAction({ id, data: data.data as OfferCarouselWrapperProps }))
                    }
                })
                .catch(error => {
                    if (error instanceof Error) {
                        logNewRelicError({
                            error,
                            resource: 'setLoyaltyOffers',
                        })
                    }
                })
        }
    }

/**
 * function call to get npp events
 * @param {string} payload AEM link to get npp list
 * @returns {Promise} set store with npp list
 */
export const getPteProps =
    (payload: string) =>
    // eslint-disable-next-line consistent-return
    (dispatch: Dispatch): Promise<void> | void => {
        if (payload) {
            return httpClient.apiGet(payload, {}, 'AEM_EXP_FRAG').then(data => {
                if (data.data) {
                    dispatch(setPtePropSuccessAction(data.data))
                }
            })
        }
    }

/**
 * Fetch customer community offers action
 * @param {CommunityOffersPayload} requestPayload
 * @return {Dispatch}
 */
export const fetchCommunityOffers =
    (requestPayload?: CommunityOffersPayload) =>
    (dispatch: Dispatch): Promise<void> | void => {
        return offersService
            .getCommunityOffers(requestPayload)
            .then((communityoffersData: { data: CommunityOffers }) => {
                dispatch(communityOffersSuccessAction(communityoffersData.data))
            })
            .catch(() => {
                handleOffersCacheOnSignOut()
                dispatch(communityOffersFailureAction())
            })
    }

/**
 * Swap community offer action
 * @param {SwapOfferPayload} requestPayload
 * @param {SwapQueryParam} queryParam
 * @returns {Dispatch}
 */
export const swapCommunityOffer =
    (requestPayload?: SwapOfferPayload, queryParam?: SwapQueryParam) =>
    (dispatch: Dispatch): Promise<void> | void => {
        dispatch(setShowSpinner({ show: true }))
        offersService
            .swapOffer(requestPayload, queryParam)
            .then((offerSwapped: { data: SwapOffer }) => {
                dispatch(setShowSpinner({ show: false }))
                dispatch(swapCommunityOfferSuccessAction(offerSwapped.data))
                dispatch(communitySwappableOffer({} as SwapableList))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(setShowSpinner({ show: false }))
                dispatch(swapCommunityOfferFailureAction(error?.response?.data as ErrorResponse))
            })
    }

/**
 * function to set if activate api is done or not
 * @param {boolean} isApiCalled param to store value boolean value to set if activate offer api is done
 * @returns {Dispatch} set redux based on activate offer api call
 */
export const setIsActivateOfferApiDone =
    (isApiCalled: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsActivateOfferApiDoneAction(isApiCalled))
    }
