import { Dispatch } from '@reduxjs/toolkit'
import { flyerEventsParams } from '../../components/DigitalFlyer/digitalFlyer.type'
import { digitalFlyerService } from '../../services/digitalFlyerService'
import { getProductSearchAction } from '../actionCreators/search.actionCreators'
import { searchService } from '../../services/searchService'
import { ProductDataType } from '../models/productData.interface'

/**
 * get Produc Url With Flyer Params
 * @param {string} productURL
 * @param {flyerEventsParams} flyerParams
 * @return {string}
 */
export const getProducUrlWithflyerParams = (productURL: string, flyerParams: flyerEventsParams): string => {
    const flyerDealIdParam = productURL.indexOf('?')
        ? `?flyerDealId=${flyerParams.flyerDealId}`
        : `&flyerDealId=${flyerParams.flyerDealId}`
    const flyerPublicationIdParam = `&flyerPublicationId=${flyerParams.flyerPublicationId}`
    return `${productURL}${flyerParams.flyerDealId ? flyerDealIdParam : ''}${
        flyerParams.flyerPublicationId ? flyerPublicationIdParam : ''
    }`
}

export const getDigitalFlyerProductAction =
    (sku: number, storeId: string, flyerParams: flyerEventsParams, flyerId: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const data = await digitalFlyerService.getDigitalFlyerProduct(sku, storeId)

            dispatch(getProductSearchAction(''))
            if (data.data?.results?.[0]['prod-id']) {
                const productData = await searchService.getSearchResults(
                    `q=${data.data?.results?.[0]['prod-id']}&loc=df`,
                    storeId,
                    true,
                )

                if (productData) {
                    const { redirectUrl } = productData.data as ProductDataType
                    if (redirectUrl) {
                        if (sku && storeId && flyerId) {
                            const flyerHistoryURL = `${
                                window.location.origin + window.location.pathname
                            }?flyerId=${flyerId}&storeId=${storeId}&sku=${sku}`
                            window.history.pushState(null, 'null', flyerHistoryURL)
                        }

                        dispatch(
                            getProductSearchAction({
                                redirectURL: getProducUrlWithflyerParams(redirectUrl, flyerParams),
                            }),
                        )
                    }
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(error)
        }
    }
