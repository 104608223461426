import { useEffect } from 'react'
import { useAppDispatch } from '../../../hooks/react-redux.hook'
import appCacheService from '../../../utils/appCacheService'
import { addProductToCartGuest, mergeAuthCartWithGuest } from '../../../redux/actions'
import { AddProductToCartGuestRequestDTO, CartItemsData } from '../../../redux/models/cart.interface'
import { ProductDataATCBuyNowCash } from '../PageInit.type'

/**
 * Hook to auto adding buy now item to current cart for auth user.
 * @return {void}.
 */
export const useAutoATCBuyNowItem = (isAuthFlowExecuted: boolean): void => {
    const dispatch = useAppDispatch()
    const buyNowModalDataStored = appCacheService.buyNowCartDataRequest.get()
    const cartGUID = appCacheService.getCartGuid()
    const buyNowModalData = buyNowModalDataStored && (JSON.parse(buyNowModalDataStored) as unknown as CartItemsData)
    const postalCodeVal = appCacheService.postalCode.get()
    const cartId = (buyNowModalData as CartItemsData)?.cartId

    useEffect(() => {
        if (isAuthFlowExecuted && cartId) {
            if (cartGUID) {
                dispatch(mergeAuthCartWithGuest(cartId, cartGUID, undefined, true, false))
            } else {
                const cartData = {
                    guid: cartGUID,
                    entries: { orderEntries: (buyNowModalData as CartItemsData)?.orderEntries },
                } as unknown as AddProductToCartGuestRequestDTO
                const productDataStored = appCacheService.buyNowProductData.get()
                const productData = productDataStored && (JSON.parse(productDataStored) as ProductDataATCBuyNowCash)
                dispatch(
                    addProductToCartGuest(
                        cartData,
                        false,
                        false,
                        true,
                        postalCodeVal,
                        undefined,
                        (productData as ProductDataATCBuyNowCash).isBulk,
                        (productData as ProductDataATCBuyNowCash).isPackageFlow,
                        (productData as ProductDataATCBuyNowCash).autoPackages,
                        (productData as ProductDataATCBuyNowCash).isStaggered,
                        false,
                        (productData as ProductDataATCBuyNowCash).isShippingPostalCodeHide,
                        false,
                        (productData as ProductDataATCBuyNowCash).enableFOMO,
                    ),
                )
            }
            appCacheService.buyNowCartDataRequest.delete()
            appCacheService.buyNowProductData.delete()
        }
    }, [dispatch, isAuthFlowExecuted, addProductToCartGuest, mergeAuthCartWithGuest])
}
