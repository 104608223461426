/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'
import {
    setBuyNowAEMProps,
    setBuyNowCartInfo,
    setBuyNowModalOpened,
    clearBuyNowCartInfo,
    setBuyNowInitCheckoutInfo,
    setBuyNowAPIError,
    updateBuyNowCartCTMoney,
    updateBuyNowCartReusableBag,
    setFetchedBuyNowAccountInfo,
    setFetchingBuyNowAddToCart,
} from '../actionCreators'
import * as actions from '../actionCreators/buyNowModal.actionCreators'
import { AEMPropsAndFlags, BuyNowCartData } from '../models/buyNowModal.interface'
import {
    AddProductToCartGuestResponseDTO,
    CartSummaryDTO,
    FilteredCartData,
    CartOrderEntries,
} from '../models/cart.interface'
import { HybrisMedia } from '../models/product.interface'

type Action = ActionType<typeof actions>
export interface BuyNowModalDataState {
    aemPropsAndFlags: AEMPropsAndFlags
    guid: string
    cartCode?: string
    isBuyNowModalOpened: boolean
    isFetchedBuyNowAccountInfo: boolean
    isFetchingBuyNowAddToCart: boolean
    cart?: BuyNowCartData
    initCheckoutInfo?: FilteredCartData | null
    hasAPIError?: boolean
    bopis?: CartOrderEntries[]
    sth?: CartOrderEntries[]
    orderThreshold?: number
    handlingFee?: number
    isEligibleForReusableBags?: boolean
    reusableBagInfo?: {
        name: string
        price: number
        images: HybrisMedia[]
    }
    reusableBagOptIn?: boolean
    reusableBagQuantity?: number
}

export const initialState: BuyNowModalDataState = {
    aemPropsAndFlags: {} as AEMPropsAndFlags,
    guid: '',
    cartCode: '',
    isBuyNowModalOpened: false,
    isFetchedBuyNowAccountInfo: false,
    isFetchingBuyNowAddToCart: false,
    hasAPIError: false,
}

/**
 * @param state
 * @param action
 */
export const buyNowModalDataReducer = (
    state: BuyNowModalDataState = initialState,
    action: Action,
    // eslint-disable-next-line sonar/cyclomatic-complexity
): BuyNowModalDataState => {
    switch (action.type) {
        case getType(setBuyNowAEMProps): {
            return {
                ...state,
                aemPropsAndFlags: action.payload,
            }
        }
        case getType(setBuyNowCartInfo): {
            return getBuyNowCartInfo(state, action.payload)
        }
        case getType(setBuyNowInitCheckoutInfo): {
            return { ...state, initCheckoutInfo: action.payload }
        }
        case getType(updateBuyNowCartCTMoney): {
            return {
                ...state,
                guid: action.payload.cartId,
                cart: action.payload,
            }
        }
        case getType(clearBuyNowCartInfo): {
            return {
                ...state,
                guid: '',
                cartCode: '',
                initCheckoutInfo: null,
                hasAPIError: false,
                isFetchedBuyNowAccountInfo: false,
                isFetchingBuyNowAddToCart: false,
            }
        }
        case getType(setBuyNowModalOpened): {
            return {
                ...state,
                isBuyNowModalOpened: action.payload,
            }
        }
        case getType(setBuyNowAPIError): {
            return {
                ...state,
                hasAPIError: action.payload,
                isFetchedBuyNowAccountInfo: false,
                isFetchingBuyNowAddToCart: false,
            }
        }
        case getType(setFetchedBuyNowAccountInfo): {
            return {
                ...state,
                isFetchedBuyNowAccountInfo: action.payload,
            }
        }
        case getType(setFetchingBuyNowAddToCart): {
            return {
                ...state,
                isFetchingBuyNowAddToCart: action.payload,
            }
        }
        case getType(updateBuyNowCartReusableBag): {
            return {
                ...state,
                cart: {
                    ...state.cart,
                    cartSummary: action.payload.cartSummary,
                },
                reusableBagOptIn: action.payload.reusableBagOptIn,
                reusableBagQuantity: action.payload.reusableBagQuantity,
            }
        }
        default:
            return state
    }
}

/**
 *
 * @param state
 * @param payload
 */
const getBuyNowCartInfo = (
    state: BuyNowModalDataState,
    payload: AddProductToCartGuestResponseDTO,
): BuyNowModalDataState => {
    const {
        guid,
        cartCode,
        cartSummary,
        paymentInfo,
        orderThreshold,
        handlingFee,
        isEligibleForReusableBags,
        reusableBagInfo,
        reusableBagOptIn,
    } = payload
    const { absoluteTotal, itemsDiscount, itemsTotal, taxLines, totalWithTaxesAmt, subTotalAmt } =
        cartSummary as CartSummaryDTO
    return {
        ...state,
        guid,
        cartCode,
        cart: {
            cartSummary: {
                absoluteTotal,
                itemsDiscount,
                itemsTotal,
                taxLines,
                totalWithTaxesAmt,
                subTotalAmt,
            },
            paymentInfo,
        },
        isBuyNowModalOpened: true,
        orderThreshold,
        handlingFee,
        hasAPIError: false,
        isEligibleForReusableBags,
        reusableBagInfo,
        reusableBagOptIn,
    }
}
