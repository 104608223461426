import { PREFIX } from '../../../../config'

export const offerConstants = {
    leastNumberOfDaysLeft: 1, // constant for least number of days left
    allBanners: 'ALL',
    currentPageInitialValue: 1,
    incrementValue: 1,
    minimumOffersUpdated: 1,
    flatIndex: 1,
    activationNotRequired: 'N',
    activationRequired: 'Y',
    offerCode: 'offerCode',
    view: 'view',
    click: 'click',
    loyaltyOffersAppearedTimes: 'loyaltyOffersAppearedTimes',
    loyaltyOffersWereExpanded: 'loyaltyOffersWereExpanded',
    loyaltyOffersPanelDismiss: 'loyaltyOffersPanelDismiss',
    maxRewardsCount: 1000,
    flatrate: 'FLATRATE',
    yes: 'Y',
    no: 'N',
    zeroMultiplier: '0X',
    percent: '%',
    activated: 'ACTIVATED',
    activate: 'ACTIVATE',
    redeemed: 'REDEEMED',
    swap: 'SWAP',
    offersCardImgComponentName: 'offers-card',
    bannerLogoImgComponentName: 'banner-logo',
    offerPoolType: 'S',
}

// Mock used currently for UI task to complete. will be removed in next PR.
export const facets = [
    {
        type: 'multiselect',
        collapsed: false,
        label: 'filters',
        clearLink: '',
        values: [
            {
                label: 'activated',
                count: 10,
                selected: false,
            },
            {
                label: 'unactivated',
                count: 14,
                selected: false,
            },
        ],
        selected: false,
    },
    {
        type: 'multiselect',
        collapsed: false,
        label: 'categories',
        clearLink: '',
        values: [
            {
                label: 'Automotive',
                count: 10,
                selected: false,
            },
            {
                label: 'Tools & Hardware',
                count: 14,
                selected: false,
            },
            {
                label: 'Home & Pets',
                count: 10,
                selected: false,
            },
            {
                label: 'Sports & Recreation',
                count: 14,
                selected: false,
            },
        ],
        selected: false,
    },
]

export const OFFERS = `${PREFIX}-offers`
export const NO_OFFER = `${PREFIX}__no-offers`
export const condenseClass = `${PREFIX}-offer-carousel__condensed-view`
export const modalClassName = `${PREFIX}-pte-modal-container`
