// / <reference path="../externals.ts" />
import { GigyaJWTResp, GigyaAccountResp } from './gigya.type'
import GigyaService from '../services/gigyaService/gigya.service'
import { cdsTokenResponse, ExpiredVerificationResponse } from '../redux/models/user.profile.interface'
import store from '../store'
import { fetchTokenUpdateAction } from '../redux/actionCreators/tokenUpdate.actionCreators'
import localStorageService from './localStorageService'
import appCacheService from './appCacheService'
import { fetchProfilePayment } from '../redux/actions'
import { getEnableSingleSignOn } from './getEnableSingleSignOn'
import { gigyaUpdateAction } from '../redux/actionCreators'
import { enableSSSO } from '../helpers/ciam.helper'
import { AnyAction } from '@reduxjs/toolkit'
import { setShowSpinner } from '../redux/slices/spinner.slice'

/* function to get the JWT token from Gigya once the user is signed in */
export const getGigyaJWTToken = (
    callBackFunc: (gigyaResponse: GigyaAccountResp) => void,
    userId?: string,
    loginToken?: string,
    showSpinner?: boolean,
): void => {
    const UID = userId && encodeURIComponent(userId)
    const reqParam = userId ? { UID: UID } : { login_token: loginToken }
    let accountResponse: GigyaAccountResp
    const gigyaService = new GigyaService()

    gigyaService
        .jwtToken() // fetch jwt Token
        .then((resp: GigyaJWTResp) => {
            appCacheService.gigyaJWTToken.set(resp.id_token)
            return gigyaService.getAccountInfo(reqParam as { UID: string }) // fetch account information from gigya
        })
        .then((data: GigyaAccountResp) => {
            const ssoToken: string = appCacheService.gigyaJWTToken.get()
            accountResponse = data // data to pass to redirect callback to determine landing page
            if (enableSSSO()) {
                store.dispatch(fetchProfilePayment() as unknown as AnyAction)
                return gigyaService.setCookie(ssoToken, getEnableSingleSignOn())
            } else {
                return gigyaService.cdsAccessToken(ssoToken).then((tokenData: { data: cdsTokenResponse }) => {
                    store.dispatch(fetchTokenUpdateAction('SUCCESS'))
                    localStorageService.setItem('tokenState', 'SUCCESS')
                    store.dispatch(fetchProfilePayment() as unknown as AnyAction)
                    return gigyaService.setCookie(tokenData.data.ssoToken, getEnableSingleSignOn()) // set cookie after login is successful
                })
            }
        })
        .then((data: unknown) => {
            showSpinner && store.dispatch(setShowSpinner({ show: false }))
            callBackFunc && callBackFunc({ ...accountResponse })
            return data
        })
        .catch(() => {
            showSpinner && store.dispatch(setShowSpinner({ show: false }))
            store.dispatch(fetchTokenUpdateAction('FAIL'))
            localStorageService.setItem('tokenState', 'FAIL')
            callBackFunc && callBackFunc({ ...accountResponse })
        })
        .finally(() => {
            store.dispatch(gigyaUpdateAction(true))
        })
}

/**
 * Get user status Info from Gigya
 * @param {string} regToken
 * @param {GigyaAccountResp} callBackFunc
 */
export const getGigyaUserStatus = (regToken: string, callBackFunc: (gigyaResponse: GigyaAccountResp) => void): void => {
    const gigyaService = new GigyaService()
    let userResponse: GigyaAccountResp
    gigyaService
        .getAccountInfo({ regToken: regToken })
        .then((resp: GigyaAccountResp) => {
            callBackFunc && callBackFunc({ ...resp })
            userResponse = resp
            return resp
        })
        .catch(() => {
            callBackFunc && callBackFunc({ ...userResponse })
        })
}

export const sendVerificationEmail = (
    UID: string,
    callBackFunc: (cdsResponse: ExpiredVerificationResponse) => void,
): void => {
    const gigyaService = new GigyaService()
    let cdsResponse: ExpiredVerificationResponse
    gigyaService
        .resendVerification(UID)
        .then((resp: ExpiredVerificationResponse) => {
            callBackFunc && callBackFunc({ ...resp })
            cdsResponse = resp
            return resp
        })
        .catch(() => {
            callBackFunc && callBackFunc({ ...cdsResponse })
        })
}

/**
 * It will immediately refresh Gigya JWT in the app cache.
 * @returns {void}
 */
export const forceTokenRefresh = (): Promise<void> => {
    return new GigyaService()
        .jwtToken()
        .then((response: GigyaJWTResp) => appCacheService.gigyaJWTToken.set(response.id_token))
        .catch(() => {
            console.error('ftr failed')
        })
}
