import React, { KeyboardEventHandler, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Accordion from '../Accordion'
import Button from '../Button'
import Icon from '../Icon'
import Table from '../Table'
import Tooltip from '../Tooltip'
import NewGiftCardForm from './NewGiftCardForm'
import { PREFIX } from '../config'
import { GiftCardProps } from './GiftCard.types'
import {
    TooltipInteractionKeys,
    componentClassName,
    defaultLogo,
    CARD_MASKING,
    CARD_NUMBER_DIGIT_SLICE,
    CARD_NUMBER_MIN_DIGITS,
    CARD_NUMBER_MAX_DIGITS,
    PIN_MIN_DIGITS,
    PIN_MAX_DIGITS,
} from './GiftCard.constants'
import { replaceStrWithDynamicVal } from '../../utils'
import { formatCurrency, removeDividers, validateLength } from './GiftCard.helpers'
import { applyGiftCardErrorCodes } from '../../globalConstants/global.constant'

/**
 * GiftCard component.
 * @param {GiftCardProps} props - Props for the GiftCard component.
 * @returns {JSX.Element} The rendered GiftCard component.
 */
const GiftCard: React.FC<GiftCardProps> = ({ ...props }): JSX.Element => {
    const {
        gcMaxCardsAmount,
        gcTitle,
        gcLogoIcon,
        gcKeepCardsLabel,
        gcMaxCardsLabel,
        gcMaxCardsReachedLabel,
        gcOrderTotalCoveredLabel,
        gcApplyAnotherCardCTALabel,
        gcApplyCTALabel,
        gcCardNumberColHeader,
        gcBalanceColHeader,
        gcAppliedAmtColHeader,
        gcCardNumberInputLabel,
        gcPinInputLabel,
        gcCardNumberErrorMsg,
        gcPinErrorMsg,
        gcTooltipTitle,
        gcTooltipDesc,
        a11yRemoveLabel,
        giftCardData,
        applyGiftCard,
        deleteGiftCard,
        initiatedGiftCards,
        onGiftCardExpandHandler,
        gcInlineErrorCode,
        setGcInlineErrorCode,
        gcErrorToastMsg,
        showToastMessage,
        isGcSectionExpanded,
    } = props

    const tooltipButtonRef = useRef<HTMLButtonElement>(null)
    const [tooltipVisibility, setTooltipVisibility] = useState(false)
    const [gcNumberError, setGcNumberError] = useState('')
    const [gcPinError, setGcPinError] = useState('')
    const [showNewCardForm, setShowNewCardForm] = useState(false)
    const [maskedNumbers, setMaskedNumbers] = useState('')

    const { numberOfGiftCards, giftCardList, remainingAmount } = giftCardData
    const noCardsApplied = !numberOfGiftCards
    const isMaxCardsReached = numberOfGiftCards === gcMaxCardsAmount
    const isOrderTotalCovered = remainingAmount === 0

    /**
     * Generates a masked list of gift card numbers.
     * @returns {void} Does not return anything.
     */
    const generateMaskedCardList = useCallback(() => {
        let maskedGcNumbers = ''

        giftCardList.forEach((card, index) => {
            maskedGcNumbers += `${CARD_MASKING}${card.number?.substr(CARD_NUMBER_DIGIT_SLICE)}`
            if (index < giftCardList.length - 1) {
                maskedGcNumbers += ', '
            }
        })

        setMaskedNumbers(maskedGcNumbers)
    }, [giftCardList])

    /**
     * Sets the state to hide the new card form and generates a masked list of gift card numbers.
     * @returns {void} Does not return anything.
     */
    useEffect(() => {
        setShowNewCardForm(false)
        generateMaskedCardList()
    }, [generateMaskedCardList, giftCardData])

    /**
     * Callback function to construct the error message gc fields invalid scenario
     * Sets the gcNumberError gcPinError state based on the provided errorCode
     * @param {number} errorCode - The error code associated with the invalid gift card error.
     * @returns {void}
     */
    const showGCFieldsErrorMsg = useCallback(
        (errorCode: number): void => {
            if (gcInlineErrorCode) {
                if (errorCode === applyGiftCardErrorCodes.incorrectGiftPin) setGcPinError(props[`gcError${errorCode}`])
                else setGcNumberError(props[`gcError${errorCode}`])
            }
        },
        [gcInlineErrorCode, props],
    )
    /**
     * Callback function to handle empty gift card error.
     * @param {string} number - The gift card number.
     * @param {string} pin - The gift card pin.
     * @returns {void}
     */
    const validateGCFieldsEmpty = useCallback(
        (number: string, pin: string): void => {
            const isNumberValid = validateLength(number, CARD_NUMBER_MIN_DIGITS, CARD_NUMBER_MAX_DIGITS)
            const isPinValid = validateLength(pin, PIN_MIN_DIGITS, PIN_MAX_DIGITS)
            setGcInlineErrorCode(0)
            setGcPinError('')
            setGcNumberError('')
            // Handle valid fields
            if (isNumberValid && isPinValid) {
                applyGiftCard({ giftCardNumber: removeDividers(number), giftCardPin: removeDividers(pin) })
            }
            // Handle invalid fields
            if (!isNumberValid || !isPinValid) {
                // Handle empty number
                !isNumberValid && setGcNumberError(gcCardNumberErrorMsg)
                // Handle empty pin
                !isPinValid && setGcPinError(gcPinErrorMsg)
                // Show error toast message for invalid scenario
                showToastMessage(gcErrorToastMsg)
            }
        },
        [applyGiftCard, gcCardNumberErrorMsg, gcErrorToastMsg, gcPinErrorMsg, setGcInlineErrorCode, showToastMessage],
    )

    /**
     * useEffect hook to update the gift card inline errors state when gcInlineErrorCode changes.
     * Calls showGCFieldsErrorMsg to handle invalid gift card errors.
     */
    useEffect(() => {
        showGCFieldsErrorMsg(gcInlineErrorCode)
    }, [gcInlineErrorCode, showGCFieldsErrorMsg])

    const renderTooltip = () => {
        const tooltipBtnClickHandler: MouseEventHandler<HTMLButtonElement> = event => {
            event.stopPropagation()
            setTooltipVisibility(prev => !prev)
        }

        const tooltipKeyHandler: KeyboardEventHandler<HTMLSpanElement> = event => {
            if (event.key in TooltipInteractionKeys) {
                event.preventDefault()
                setTooltipVisibility(prev => !prev)
            }
        }

        return (
            <>
                <span
                    className={`${componentClassName}__tooltip-btn`}
                    ref={tooltipButtonRef}
                    onClick={tooltipBtnClickHandler}
                    onKeyDown={tooltipKeyHandler}
                    role="button"
                    tabIndex={0}
                    data-testid="tooltip-btn"
                    aria-label={gcTooltipTitle}>
                    <Icon type="ct-information-details" size="lg" />
                </span>
                {!!tooltipVisibility && (
                    <Tooltip
                        visibility={tooltipVisibility}
                        setVisibility={setTooltipVisibility}
                        iconID="ct-close"
                        headerText={gcTooltipTitle}
                        bodyText={gcTooltipDesc}
                        coords={tooltipButtonRef.current}
                    />
                )}
            </>
        )
    }

    const renderTitleSection = () => {
        return (
            <div className={`${componentClassName}__title-section`}>
                <div className={`${componentClassName}__logo`}>
                    <Icon type={gcLogoIcon ? gcLogoIcon : defaultLogo} />
                </div>
                <span className={`${PREFIX}-h4--sm`}>{gcTitle}</span>
                {renderTooltip()}
                {!isGcSectionExpanded ? (
                    <div className={`${componentClassName}__masked-number-list`}>{maskedNumbers}</div>
                ) : null}
            </div>
        )
    }

    const renderRemoveCardButton = (cardId: string): JSX.Element => {
        return (
            <button
                className={`${componentClassName}__remove-card-btn`}
                onClick={() => deleteGiftCard({ id: cardId })}
                aria-label={a11yRemoveLabel}
                data-testid="gift-card-remove">
                <Icon type="ct-close" size="md" />
            </button>
        )
    }

    const renderAppliedCardsTable = () => {
        return giftCardList?.map(card => {
            const { id, number, balance, amount } = card
            const initiatedCard = initiatedGiftCards?.find(initiatedGiftCard => initiatedGiftCard?.id === id)
            const tableData = [
                {
                    [gcCardNumberColHeader]: `${CARD_MASKING} ${number?.substr(CARD_NUMBER_DIGIT_SLICE)}`,
                    [gcBalanceColHeader]: formatCurrency(balance),
                    [gcAppliedAmtColHeader]: formatCurrency(initiatedCard ? Number(initiatedCard?.amount) : amount),
                    '': renderRemoveCardButton(id),
                },
            ]
            return (
                <div key={id}>
                    <Table tableData={tableData} headerType="row" tableType="borderless" />
                </div>
            )
        })
    }

    const renderAddAnotherCardCTA = (): JSX.Element | null => {
        return !showNewCardForm ? (
            <Button type="tertiary" size="medium" onClick={() => setShowNewCardForm(true)} id="gift-card-add">
                <Icon type="ct-add" size="md" />
                <span>{gcApplyAnotherCardCTALabel}</span>
            </Button>
        ) : null
    }

    const renderAppliedCardsSection = () => {
        return (
            <>
                {renderAppliedCardsTable()}
                {isOrderTotalCovered || isMaxCardsReached ? (
                    <p className={`${componentClassName}__label`}>
                        {isOrderTotalCovered ? gcOrderTotalCoveredLabel : gcMaxCardsReachedLabel}
                    </p>
                ) : (
                    renderAddAnotherCardCTA()
                )}
            </>
        )
    }

    const renderNewCardSection = () => {
        const newGiftCardFormProps = {
            gcApplyCTALabel,
            gcCardNumberInputLabel,
            gcPinInputLabel,
            gcCardNumberErrorMsg,
            gcPinErrorMsg,
            applyGiftCard,
            gcNumberError,
            gcPinError,
            validateGCFieldsEmpty,
        }

        return (
            <>
                <NewGiftCardForm {...newGiftCardFormProps} />
                <p className={`${componentClassName}__label`}>
                    {isMaxCardsReached
                        ? gcMaxCardsReachedLabel
                        : replaceStrWithDynamicVal(gcMaxCardsLabel, gcMaxCardsAmount)}
                </p>
            </>
        )
    }

    return (
        <div className={componentClassName}>
            <Accordion
                title={renderTitleSection()}
                isHeaderOpen={isGcSectionExpanded}
                collapseControl={onGiftCardExpandHandler}>
                <>
                    <div className={`${componentClassName}__section`}>
                        <p className={`${componentClassName}__keep-label`}>{gcKeepCardsLabel}</p>
                        {noCardsApplied ? renderNewCardSection() : renderAppliedCardsSection()}
                    </div>
                    {showNewCardForm && (
                        <div className={`${componentClassName}__section`}>
                            <p className={`${componentClassName}__new-card-heading`}>{gcApplyAnotherCardCTALabel}</p>
                            {renderNewCardSection()}
                        </div>
                    )}
                </>
            </Accordion>
        </div>
    )
}
GiftCard.propTypes = {
    gcMaxCardsAmount: PropTypes.number.isRequired,
    gcTitle: PropTypes.string.isRequired,
    gcLogoIcon: PropTypes.string.isRequired,
    gcKeepCardsLabel: PropTypes.string.isRequired,
    gcMaxCardsLabel: PropTypes.string.isRequired,
    gcMaxCardsReachedLabel: PropTypes.string.isRequired,
    gcOrderTotalCoveredLabel: PropTypes.string.isRequired,
    gcApplyAnotherCardCTALabel: PropTypes.string.isRequired,
    gcApplyCTALabel: PropTypes.string.isRequired,
    gcCardNumberColHeader: PropTypes.string.isRequired,
    gcBalanceColHeader: PropTypes.string.isRequired,
    gcAppliedAmtColHeader: PropTypes.string.isRequired,
    gcCardNumberInputLabel: PropTypes.string.isRequired,
    gcPinInputLabel: PropTypes.string.isRequired,
    gcCardNumberErrorMsg: PropTypes.string.isRequired,
    gcPinErrorMsg: PropTypes.string.isRequired,
    gcTooltipTitle: PropTypes.string.isRequired,
    gcTooltipDesc: PropTypes.string.isRequired,
    a11yRemoveLabel: PropTypes.string.isRequired,
    applyGiftCard: PropTypes.func.isRequired,
    deleteGiftCard: PropTypes.func.isRequired,
    isGcSectionExpanded: PropTypes.bool.isRequired,
}

export default GiftCard
