import { libUtils, updateUrlHistory, checkDataLength } from '@nl/lib'
import { commonContentMessages, IGlobalProps } from '../redux/models/commonContent.interface'
import sessionStorageService from '../utils/sessionStorageService'
import { gigyaParams } from '../components/GigyaScreen/gigya.constants'
import { GlobalPropsHelper } from '../analytics/helpers/globalProps'
import localStorageService from '../utils/localStorageService'
import { globalPartialAuthCode } from '../globalConstants/cdsErrorCodes'
import store from '../store'
import { Status, HTTPS_URL_PREFIX } from '../globalConstants'
import { getCommonContent, getCommonContentWithResult } from './getContent.helper'
import { SingleRegistration } from './ciam.type'
import { setAutoRedirectToCLPAnalytics, setManualRedirectToCLPAnalytics } from '../utils/analytics.util'
import { logNewRelicRedirectToCLP } from '../components/NewRelic/newRelic.helper'
import { getUserUID } from './liteProfile.helper'
import GigyaService from '../services/gigyaService/gigya.service'

const language = libUtils.getLanguage()

/**
 * Checks if redirected from CRP.
 * @returns {boolean}
 */
export const fromCRP = (): boolean => Boolean(window?.gigya?.getUrlParam(gigyaParams.flow) === 'registration')

/**
 * Checks if redirected from CLP.
 * @returns {boolean}
 */
export const fromCLP = (): boolean => Boolean(window?.gigya?.getUrlParam(gigyaParams.actions)) && !fromCRP()

/**
 * Get CRP source URL from link.
 * @returns {string}
 */
export const sourceURLFromCRP = (): string => (fromCRP() ? window?.gigya?.getUrlParam(gigyaParams.sourceURL) : '')

/**
 * Determines SSSO condition based on values from enableSSSO
 * @returns {boolean}
 */
export const enableSSSO = (): boolean => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }
    }

    return hasContent?.featureFlag?.enableSSSO as boolean
}

/**
 * Determines SSO condition based on values from enableCLP in global props and clp parameter in page url
 * @returns {boolean}
 */
export const enableSingleSignOn = (): boolean => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }
    }

    return hasContent?.featureFlag?.enableCLP as boolean
}

/**
 * Determines SSO condition based on values from enableCRP and enableLoyaltyFlowCRP in global props
 * @returns {boolean}
 */
export const enableSingleRegistration = (): SingleRegistration => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }
    }

    return {
        enableCRP: hasContent?.featureFlag?.enableCRP,
        enableLoyaltyFlowCRP: hasContent?.featureFlag?.enableLoyaltyFlowCRP,
    } as SingleRegistration
}

/**
 * Async determines SSO condition based on values from enableCLP in global props and clp parameter in page url
 * @returns {Promise}
 */
export const enableSingleSignOnAsync = (): Promise<boolean> => {
    return getValueFromCommonContent((commonContentAvailable: IGlobalProps) => {
        return commonContentAvailable?.featureFlag?.enableCLP
    })
}

export const enableSingleRegistrationAsync = (): Promise<SingleRegistration> => {
    return getValueFromCommonContent((commonContentAvailable: IGlobalProps) => {
        return {
            enableCRP: commonContentAvailable?.featureFlag?.enableCRP,
            enableLoyaltyFlowCRP: commonContentAvailable?.featureFlag?.enableLoyaltyFlowCRP,
        } as SingleRegistration
    }) as unknown as Promise<SingleRegistration>
}

const getValueFromCommonContent = (
    contentFilter: (commonContentAvailable: IGlobalProps) => boolean | undefined | SingleRegistration,
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const { commonContentAvailable: contentAvailable, commonContentLoadStatus: loadStatus } = getCommonContent()
        if (checkDataLength(contentAvailable) || loadStatus !== '') {
            const result = getCommonContentWithResult(contentAvailable, loadStatus, contentFilter)
            result === Status.error ? reject(new Error(commonContentMessages.LoadFailed)) : resolve(result as boolean)
        } else {
            const unsubscribe = store.subscribe(() => {
                const { commonContentAvailable, commonContentLoadStatus } = getCommonContent()
                if (checkDataLength(commonContentAvailable) || commonContentLoadStatus !== '') {
                    unsubscribe()
                    const result = getCommonContentWithResult(
                        commonContentAvailable,
                        commonContentLoadStatus,
                        contentFilter,
                    )
                    result === Status.error
                        ? reject(new Error(commonContentMessages.LoadFailed))
                        : resolve(result as boolean)
                }
            })
        }
    })
}

/**
 * Calls gigya login method and passes context info from originating page to CLP (Central Login Page)
 * @param {string} redirectURL originating page from which sign-in was initiated
 * @param {ContextType} additionalContext carried to the CLP and can be used to customize user experience
 * @returns {void}
 */
const gigyaSSOLoginHandler = (redirectURL: string, additionalContext?: Record<string, unknown>) => {
    const gigyaService = new GigyaService()
    const globalProps = new GlobalPropsHelper()
    const configs = globalProps.init()

    return gigyaService.login({
        authFlow: 'redirect',
        useChildContext: true,
        redirectURL,
        context: {
            refClient: configs['serviceClient'],
            lang: language,
            clp: 'true',
            sourceURL: redirectURL,
            ...additionalContext,
        },
    })
}

/**
 * Calls gigyaSSOLoginHandler and passes context to function
 * @param {string} redirectURL originating page from which sign-in was initiated
 * @param {boolean} isAutoRedirect indicates whether the redirect is automatic or manual
 * @param {ContextType} additionalContext carried to the CLP and can be used to customize user experience
 * @returns {void}
 */
export const ssoLoginHandler = (
    redirectURL: string,
    isAutoRedirect: boolean,
    additionalContext?: Record<string, unknown>,
): void => {
    if (isAutoRedirect) {
        setAutoRedirectToCLPAnalytics()
    } else {
        logNewRelicRedirectToCLP({ resource: 'manualRedirectToCLP', gigyaUID: getUserUID() })
        setManualRedirectToCLPAnalytics()
    }

    gigyaSSOLoginHandler(redirectURL, additionalContext)
}

// Function to check for "gig_" query parameters
export const checkForGigQueryParams = (removeParam = false): void => {
    if (removeParam && !window.gigya.getUrlParam(gigyaParams.sequence)) {
        updateUrlHistory(undefined, true)
    }
}

/**
 * Function to return profile name if remember is true in localstorage
 * @returns {Record<string, unknown>}
 */
export const profileName = (): Record<string, unknown> => {
    return localStorageService.getItem('remember') === 'true'
        ? { status: globalPartialAuthCode, firstName: localStorageService.getItem('firstName') }
        : {}
}

/**
 * Passes a redirect URL combining origin and pathname from the given page URL
 * to ssoLoginHandler function which calls gigya's login method with provided context
 * @param {string} pageUrl
 * @param {boolean} isAutoRedirect
 * @returns {void}
 */
export const baseSSOLoginHandler = (pageUrl: string, isAutoRedirect: boolean): void => {
    const url = new URL(pageUrl)
    const redirectUrl = url.origin + url.pathname
    return ssoLoginHandler(redirectUrl, isAutoRedirect)
}

/**
 * Passes registrationContext to ssoLoginHandler function which calls gigya's registration method with provided context
 * @param {string} pageUrl
 * @returns {void}
 */
export const ssoRegistrationHandler = (pageUrl: string): void => {
    const { enableLoyaltyFlowCRP } = enableSingleRegistration()
    const additionalContext: { flow: string; loyalty?: string } = {
        flow: 'registration',
    }

    if (!enableLoyaltyFlowCRP) {
        additionalContext.loyalty = 'none'
    }
    gigyaSSOLoginHandler(pageUrl, additionalContext)
}

/**
 * Passes specific pathname and context to ssoLoginHandler function which calls gigya's login method with provided context
 * @param pathName - specific pathname to update redirect url
 * @param isAutoRedirect - indicates whether the redirect is automatic or manual
 * @returns {void}
 */
export const ssoHandlerWithPathName = (
    pathName: string,
    isAutoRedirect: boolean,
    additionalContext?: Record<string, unknown>,
): void => {
    const url = new URL(window.location.href)
    const redirectUrl = url.origin + pathName
    return ssoLoginHandler(redirectUrl, isAutoRedirect, additionalContext)
}

export const redirectToUrlOrPath = (url: string): void => {
    url.startsWith(HTTPS_URL_PREFIX)
        ? // eslint-disable-next-line sonar/no-nested-assignment
          (window.location.href = url)
        : // eslint-disable-next-line sonar/no-nested-assignment
          (window.location.pathname = url)
}

export const redirectToRegistrationPageHelper = (oldRegisterPagePath: string, returnPath?: string): void => {
    if (!enableSingleRegistration().enableCRP) {
        redirectToUrlOrPath(oldRegisterPagePath)
    } else {
        ssoRegistrationHandler(!!returnPath ? returnPath : defaultRedirectionUrlCreation())
    }
}

const defaultRedirectionUrlCreation = (): string => {
    const url = new URL(window.location.href)
    return url.origin + url.pathname
}

export const verifyGigyaReadiness = (gigyaAction: string): Promise<void> => {
    const gigyaService = new GigyaService()
    return gigyaService.verifyGigyaReadiness(gigyaAction)
}
