import React from 'react'
import { ContentVariation, SnippetCard } from '@nl/lib'
import { PREFIX } from '../../config'
import { AlertData, PromoSection } from './RewardsHub.type'
import { triangleSelect } from '../../globalConstants/global.constant'

/**
 * RewardsHub Alert component
 * @param {AlertData} props
 * @returns {JSX.Element}
 */
const Alerts: React.FC<AlertData> = (props: AlertData): JSX.Element => {
    const componentClass = `${PREFIX}-rewards-hub-alert`
    const { promoSectionList } = props

    /**
     * Renders alerts promo banner
     * @param {PromoSection} promoItem
     * @param {number} index
     * @returns {JSX.Element}
     */
    const renderPromoBanner = (promoItem: PromoSection, index: number): JSX.Element => {
        const promoBannerClass = `${componentClass}__promo-banners`
        return (
            <div className={`${promoBannerClass}`} key={index}>
                <SnippetCard
                    imageOffSet={false}
                    image={promoItem?.promoBannerImg}
                    imageAlignment={{ alignment: 'left', size: 'sm' }}
                    imageAlt={promoItem?.promoBannerImgAlt}
                    description={promoItem?.promoDescription}
                    customClass={`${promoBannerClass}-content ${
                        promoItem?.promoType === triangleSelect ? `${promoBannerClass}--triangle-select` : ''
                    } ${
                        promoItem?.promoBannerImgAlignment === ContentVariation.Right ? `${PREFIX}-xs-row-reverse` : ''
                    }`}></SnippetCard>
            </div>
        )
    }
    return (
        <div className={componentClass}>
            {promoSectionList && promoSectionList?.length > 0 && (
                <div className={`${componentClass}__promo`}>
                    {promoSectionList?.map((item, index) => renderPromoBanner(item, index))}
                </div>
            )}
        </div>
    )
}

export default Alerts
