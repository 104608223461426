import httpClient from '../utils/httpClient'
import { Dispatch } from '@reduxjs/toolkit'
import {
    setCommonContentFetchSuccess,
    updateBadgePriorities,
    setCommonAPIContentSuccess,
    updateCommonApiContent,
    setCommonContentLoadStatus,
} from '../actionCreators'
import { API_ENDPOINTS } from '../../environments/apiConfig'
import sessionStorageService from '../../utils/sessionStorageService'
import { libUtils } from '@nl/lib'
import { IGlobalProps } from '../models/commonContent.interface'
import { PayloadAction } from 'typesafe-actions'
import { logNewRelicError } from '../../components/NewRelic/newRelic.helper'
import { Status } from '../../globalConstants'

const language = libUtils.getLanguage()

const getCommonEndpoint = (): string =>
    document.getElementById('commonContentEndpoint')?.getAttribute('data-commoncontentendpoint') || ''

export const setCommonContentSuccess =
    (commonData: IGlobalProps) =>
    (dispatch: Dispatch): Promise<void> | void => {
        window.ODP = window.ODP || {}
        window.ODP.globalLinks = commonData.globalLinks
        dispatch(setCommonContentFetchSuccess(commonData))
    }

export const setCommonContent =
    () =>
    // eslint-disable-next-line consistent-return
    (dispatch: Dispatch): Promise<void> | void => {
        dispatch(updateBadgePriorities())
        const payloadEndpoint = getCommonEndpoint()
        if (payloadEndpoint) {
            return httpClient
                .apiGet(payloadEndpoint, {}, 'AEM_EXP_FRAG')
                .then(data => {
                    if (data.data) {
                        sessionStorageService.setItem(`global_props_${language}`, JSON.stringify(data.data))
                        dispatch(setCommonContentSuccess(data.data) as unknown as PayloadAction<any, IGlobalProps>)
                        dispatch(setCommonContentLoadStatus(Status.success))
                    } else {
                        dispatch(setCommonContentLoadStatus(Status.failed))
                    }
                })
                .catch(() => {
                    dispatch(setCommonContentLoadStatus(Status.failed))
                })
        } else {
            dispatch(setCommonContentLoadStatus(Status.error))
        }
    }

/**
 * function call to get the common api props for canada post API
 * @return {Promise}
 */
export const setCommonAPIContent =
    () =>
    (dispatch: Dispatch): Promise<void> | void => {
        dispatch(updateCommonApiContent())
        return httpClient
            .apiGet(API_ENDPOINTS.commonApiContent, {}, 'AEM_COMMON_API')
            .then(data => {
                if (data.data) {
                    sessionStorageService.setItem('common_api_props', JSON.stringify(data.data))
                    dispatch(setCommonAPIContentSuccess(data.data))
                }
            })
            .catch(error => {
                if (error instanceof Error) {
                    logNewRelicError({
                        error,
                        resource: 'setCommonAPIContent',
                    })
                }
            })
    }
