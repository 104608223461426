import React from 'react'
import PropTypes from 'prop-types'
import { ProductCardsSkeletonProps } from '../BoxedRecommendations.type'
import { PREFIX } from '../../../config'
import { SkeletonComponent } from '@nl/lib'

/**
 * ProductCardsSkeleton for BoxedRecommendations component
 * @param props : ProductCardsSkeletonProps
 * @returns : JSX.Element
 */
const ProductCardsSkeleton: React.FC<ProductCardsSkeletonProps> = ({ ...props }): JSX.Element => {
    const { productCardCount, tilesCount } = props

    const productCardsContainerClassName = `${PREFIX}-product-cards__items`

    const renderProductItemSkeleton = () => {
        const skeletonElements = []
        for (let index = 0; index < productCardCount; index++) {
            skeletonElements.push(
                <div className={`${PREFIX}-product-card`} key={index}>
                    <SkeletonComponent
                        className={`${productCardsContainerClassName}__skeleton`}
                        width="100%"
                        height="100%"
                        dataTestId="product-card-skeleton"
                    />
                </div>,
            )
        }
        return skeletonElements
    }

    const renderAllTilesSkeleton = () => {
        return (
            <>
                {tilesCount.map((_, index: number) => (
                    <div key={index} className={`${productCardsContainerClassName}`}>
                        {renderProductItemSkeleton()}
                    </div>
                ))}
            </>
        )
    }

    return renderAllTilesSkeleton()
}

ProductCardsSkeleton.propTypes = {
    productCardCount: PropTypes.number.isRequired,
    tilesCount: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default ProductCardsSkeleton
