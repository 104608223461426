import { pageTypes } from '../../utils/pageTypes'

export const grid = 'grid' // display type
export const minOptionsArrayLength = 0

export const scrollRestorationKey = 'scroll-position-product-id-marker'
export const plpUrl = '#plp'

export const plp = 'plp'
export const loc = 'loc'
export const saleOrClearanceBadge = ['PROMO', 'CLEARANCE']
export const pagesForScrollRestoration = [
    pageTypes.searchPage,
    pageTypes.plp,
    pageTypes.brandPage,
    pageTypes.brandCategoryPage,
    pageTypes.promoListing,
]

export const PRODUCT_CARD_COUNT = 4
