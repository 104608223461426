import { BadgePriority, HybrisMedia, inImageBadgePriority, RoadRating } from '../ProductGridView/ProductGrid.types'
import { FilteredCartData, IHeliumInflationStoreServiceType, ServiceDTO } from '../CartFlyout/CartFlyout.type'
import { iPrice } from '../PriceOld/PriceOld.types'
import { Price, FeeMessage, PriceMessage, SaleCut, SaleMessagesRule } from '../Price/Price.types'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import React from 'react'
import { PromoMessageType, RestrictedPromoMessageType } from '../PromoMessages/PromoMessage.type'
import { ItemAvailability } from '../HeaderStoreLocator/HeaderStoreLocator.type'
import {
    BalloonData,
    BrandDTO,
    CartItemFulFillmentType,
    CartOrderEntries,
    DeliveryDatesDTO,
    PickupDateTimeDTO,
    StockItemAvailability,
} from './ProductCartItem.type'
import { WishlistProduct } from '../../types'
import { LoyaltyBadge } from '../Badges/Badges.type'

export enum eEntryType {
    PRODUCT = 'PRODUCT (PRO)',
    SERVICE_ADDON = 'SERVICE_ADDON (SER_ADD)',
    SERVICE_STANDALONE = 'STANDALONE (SER_STA)',
}

export enum CartOrderDeliveryModes {
    BOPIS = 'BOPIS',
    STH = 'STH',
    CURBSIDE = 'CURBSIDE',
    EXPRESS = 'EXPRESS',
}

export enum ProductSourceTypes {
    INSTORE = 'INSTORE',
    DC = 'DC',
}

/**
 * Interface for ProductItemInfoProps
 * @interface
 */
export interface ProductItemInfoProps {
    changeShipToHomeCTALabel?: string
    betweenLabel?: string
    betweenLabelOrderConf?: string
    betweenLabelSTH?: string
    availableLabel?: string
    pickUpInLabel?: string
    pickAtStoreCTALabel?: string
    isFreePickUpAtStore?: boolean
    isShipToHome?: boolean
    onlineOrdering?: boolean
    isServices?: boolean
    servicesIcon?: string
    servicesDisclaimer?: string
    homePageLink?: string
    productData?: ProductDataDTO
    serviceData?: CartOrderEntries
    a11yRemoveLabel?: string
    onChangeFulfillmentOption?: (option: string, entryNumber: number) => void
    onRemoveCartItem?: (
        isServiceRemoved?: boolean,
        productData?: productItemData,
        serviceList?: CartOrderEntries[],
        qtySelectorInput?: number,
    ) => void
    cartItemAnalytics?: (
        item: productItemData | CartOrderEntries,
        quantityReduced: number | null,
        actionType?: string,
        maxQtyReached?: boolean,
    ) => void
    exceededTotalAvailableMsg?: string
    limitOfLabel?: string
    exceededMsg?: string
    exceededMsgBar?: string
    receiveBetweenLabel?: string
    availableByLabel?: string
    availableBetweenLabel?: string
    isCurbside?: boolean
    availableShipLabel?: () => React.ReactNode
    getChangeFulfillmentOption?: () => string | undefined
    checkoutButtonClick?: boolean
    allyCloseLabel?: string
    warningToastShow?: (show: boolean) => void
    isProductService?: boolean
    productServiceTitle?: string
    itemAvailabilityInfo?: ItemInlineErrorType
    bulkLabel?: string
    wishListData?: WishlistProduct
    isWishList?: boolean
    PDPLink?: string
    cartModal?: boolean
    a11yTooltipIcon?: string
    totalLabel?: string
    mmQtyLabel?: string
    updatedLabel?: string
    isDetailPage?: boolean
    discScheduleDelivery?: string
    discServiceInstallation?: string
    discCannotTrack?: string
    accessibilityGlobalProps?: GlobalAccessibilityProps
    renderRebate?: () => React.ReactNode | null
    productColorLabel?: string
    inStockLabel?: string
    wishlistInStockLabel?: string
    wishlistInStockPickUpLabel?: string
    wishlistInStockPurchaseLabel?: string
    addToCartLabel?: string
    addToCart?: (product: any) => void
    onRemoveWishlistItem?: (productData: ProductDataDTO) => void
    reviewsContainerId?: string
    a11yReviewRating?: string
    hideQuantitySelector?: boolean
    productDisclaimer?: string
    qtyAbbrLabel?: string
    changeBrowserBackUrl?: () => void
    isPackage?: boolean
    outOfStockLabel?: string
    comingSoonStockLabel?: string
    discontinuedLabel?: string
    roadRatedLabel?: string
    productSizeLabel?: string
    isStaggered?: boolean
    isUnfinishedPackage?: boolean
    language?: string
    attributeOffsetLabel?: string
    skus?: ProductSku[]
    vehicleInformationPresent?: boolean
    tireInformationPresent?: boolean
    availabilityBulletSubtype?: () => string
    getStockInfoMessage?: () => React.ReactNode | null
    showOrderDetailDisclaimer?: (isService: boolean) => React.ReactNode | null
    specificationsFromProps?: string[]
    saleEndDaySoonMessage?: string
    urgentLowStock?: boolean
    limitedStockMessage?: string
    a11yStrikeOutPrice?: string
    a11yStrikeOutPriceRange?: string
    isShoppingCartPage?: boolean
    deliveryMode?: string
    storeData?: ProductStoreData
    singleShippingMethodOption?: boolean
    shipsIn?: string
    shipsInForEA?: string
    pickUpIn?: string
    availableForPickUpIn?: string
    unavailableForBOPIS?: string
    unavailableAtStoreForBOPIS?: string
    unavailableForSTH?: string
    a11yCloseIconLabel?: string
    appliedPromoPrefix?: string
    showDiscountedPrice?: boolean
    highlightDiscountedPrice?: boolean
    saveLabel?: string
    isSTS?: boolean
    saleMessagesRules?: SaleMessagesRule[]
    enableShowPromotionsAndRomanceCopy?: boolean
    enableCustomOrder?: boolean
    heliumInflationUnInflationText?: string
    showInflationLabels?: boolean
    isShoppingCart?: boolean
    saleEndDisableShift?: boolean
    hideSaleEndDaySoonMessage?: boolean
    isPriceRangeViewEnabled?: boolean
}

/**
 * Interface for ProductImageComponentProps
 * @interface
 */
export interface ProductImageComponentProps {
    itemAvailabilityInfo?: ItemInlineErrorType
    isService?: boolean
    a11yServiceInstallation?: string
    servicesIcon?: string
    images?: HybrisMedia[] | HybrisMedia
    changeBrowserBackUrl?: () => void
    hideQuantitySelector?: boolean
    url?: string
    isUnfinishedPackage?: boolean
    name?: string
}

export interface ProductOption {
    descriptor: string
    display: string
    value: string
    variantOrder?: number
}

/**
 * Interface for ProductStoreData
 * @interface
 */
export interface ProductStoreData {
    isExpressDeliveryEligible?: boolean
}

export enum DeliveryStatusSTH {
    SHIPPED = 'SHIPPED',
    IN_PROGRESS_STH = 'IN_PROGRESS',
    DELIVERED = 'DELIVERED',
    CANCELLATION_PENDING = 'CANCELLATION_PENDING',
    CANCELLED = 'CANCELLED',
}

export interface iService {
    serviceType?: string
    maxQuantity?: number
    url: string
    name: string
    shortDescription?: string
    sku: string
    price: number
    brand?: BrandDTO
    images?: HybrisMedia[] | HybrisMedia
    isOutStock?: boolean
}

export interface AutomotiveAddonProps extends iService {
    PDPLink: string
    language: string
    removeService?: (selectedSku: string) => void
    selectService?: (selectedSku: string) => void
    hideQuantitySelector?: boolean
    quantity?: number
    qtyAbbrLabel?: string
    serviceAdded?: boolean
    productData?: ProductDataDTO | CartOrderEntries
    availableLabel?: string
    availableByLabel?: string
    addItemToWishlist?: (productData: ProductDataDTO | CartOrderEntries) => void
    a11yWishlistIcon?: string
    updateCartData?: (quantity: number, isAdd: boolean, entryNumber: number, fromInputUpdate?: boolean) => void
    limitOfLabel?: string
    outOfStockMsg?: string
    a11yRemoveLabel?: string
    onRemoveCartItem?: (
        isServiceRemoved?: boolean,
        productData?: ProductDataDTO | CartOrderEntries,
        serviceList?: CartOrderEntries[],
        qtySelectorInput?: number,
    ) => void
    accessibilityGlobalProps?: GlobalAccessibilityProps
    quantitySelectorId?: string
    errorAnalyticsForInlineToast?: (errorMessage: string) => void
    messageObject?: {
        [Key: string]: string
    }
    itemType?: FulfillmentMethods
    isOutStock?: boolean
    dpBuyAgainLabel?: string
    isDetailPage?: boolean
    setErrorInfoHandler?: (
        isHardStopError: boolean,
        isSoftError: boolean,
        isEtaError?: boolean,
        isLimitQty?: boolean,
    ) => void
    checkoutButtonClick?: boolean
    warningToastProps?: WarningToastProps
}

/**
 * Interface for productItemData
 * @interface
 */
export interface ProductDataDTO {
    rating?: number
    ratingsCount?: number
    url?: string
    brand?: BrandDTO
    name?: string
    color?: string
    cancellable?: boolean
    cancellableQuantity?: number
    pickupDateTime?: PickupDateTimeDTO
    deliveryDates?: DeliveryDatesDTO[]
    images?: HybrisMedia[] | HybrisMedia
    quantity?: number
    maxQuantity?: number
    timeTestedPrice?: iPrice
    code?: string
    baseProduct?: string
    price?: iPrice
    discount?: Price
    originalPrice?: Price
    currentPrice?: Price
    displayWasLabel?: boolean
    totalPrice?: iPrice
    availableInDC?: boolean
    availableDCQuantity?: number
    availableQuantity?: number
    badges?: string[]
    servicesList?: ServiceDTO[]
    limitedQuantity?: number
    sku?: string
    entryType?: eEntryType
    cartEntryReference?: string
    fulfillment?: CartItemFulFillmentType
    isBulk?: boolean
    rebate?: RebateDTO
    instantRebateIncluded?: boolean
    variants?: ProductVariant[]
    availableInStock?: boolean
    availability?: ItemAvailability
    selected?: boolean
    options?: ProductOption[]
    feeTitle?: string
    feeDisclaimerMessage?: string
    feeDisclaimerTitle?: string
    isShipHome?: boolean
    maxPurchaseQty?: number
    entryNumber?: number
    status?: string
    vehicleInformation?: VehicleInformation
    specifications?: Specification[]
    trackingID?: string
    feeMessages?: FeeMessage[]
    staggeredGroup?: string
    isFront?: boolean
    isPackagedItem?: boolean
    isPackageItem?: boolean
    productWheelType?: string
    priceMessage?: PriceMessage[]
    roadRating?: RoadRating
    packageId?: string
    itemAvailabilityInfo?: {
        errorMessage: string
        showInlineError: boolean
        grayOutImage: boolean
        optionsToBeDisplayed: string[]
        fulfillmentDeliverError: string
        hardStopError: boolean
        isShowETAToast: boolean
    }
    addOns?: CartOrderEntries[]
    isUrgentLowStock?: boolean | null
    saleEndDate?: string
    salePriceEndDate?: string
    sellable?: boolean
    errorCode?: string
    promoMessages?: PromoMessageType[]
    restrictedPromoMessages?: RestrictedPromoMessageType[]
    appliedPromoMessages?: PromoMessageType[]
    balloonData?: BalloonData
    basePrice?: string
    adjustedPrice?: string
    isSTS?: boolean
    basePriceValue?: number
    adjustedPriceValue?: number
    availableActions?: {
        isAddToCartEnabled?: boolean
    }
    isShowedEstimatedDeliveryDateBetween?: boolean
    estimatedDeliveryDateBetween?: string
    isInWishList?: boolean
    saleCut?: SaleCut
    estimatedDeliveryDate?: {
        startDate: string
        endDate: string
    }
    eddMax?: string
    eddMin?: string
    selectedStoreServices?: IHeliumInflationStoreServiceType[]
    supportedStoreServices?: IHeliumInflationStoreServiceType[]
    balloonEntryType?: string
    isOnSale?: boolean
    isPriceRangeViewEnabled?: boolean
    loyalty?: LoyaltyBadge
}

interface Specification {
    label: string
    value: string
    code: string
}

export interface VehicleInformation {
    baseVehicleId?: string
    engineConfigId?: string
    body?: string
    make: string
    model: string
    options?: string
    type: string
    year: string
    vin?: string
    boltPattern?: string
}

interface productItemData {
    brand?: BrandDTO
    name?: string
    color?: string
    pickupDateTime?: PickupDateTimeDTO
    deliveryDates?: DeliveryDatesDTO[]
    images?: HybrisMedia[] | HybrisMedia
    quantity?: number
    maxQuantity?: number
    timeTestedPrice?: iPrice
    code?: string
    price?: iPrice
    discount?: Price
    originalPrice?: Price
    currentPrice?: Price
    displayWasLabel?: boolean
    totalPrice?: iPrice
    coreCharge?: iPrice
    availableInDC?: boolean
    availableDCQuantity?: number
    availableQuantity?: number
    ecoFee?: iPrice
}

export type SelectedDeliveryMode = {
    isSTH: boolean
    isExpress: boolean
}

/**
 * Interface for RebateDTO
 * @interface
 */
export interface RebateDTO {
    message?: string
    tooltipMessage?: string
    greyCopyMsg?: string
    pdfLink?: string
}

/**
 * Interface for ProductCartItemProps inherited and extended from ProductItemInfoProps
 * @interface
 */
export interface ProductCartItemProps extends ProductItemInfoProps {
    cart?: FilteredCartData
    saleEndDisableShift?: boolean
    inStockOnlineLabel?: string
    isAvailableOnlyInDC?: boolean
    productData?: ProductDataDTO
    addItemToWishlist?: (productData: ProductDataDTO, isAutoHardware?: boolean) => void
    removeitemFromWishlist?: (productData: ProductDataDTO) => void
    a11yWishlistIcon: string
    updateCartData?: (
        quantity: number,
        isAdd: boolean,
        entryNumber: number,
        fromInputUpdate?: boolean,
        inputRef?: React.MutableRefObject<any>,
    ) => void
    eachLabel: string
    saveForLaterTitle?: string
    addItemToSaveForLater?: (
        productData: ProductDataDTO,
        services: CartOrderEntries[] | ProductDataDTO[],
        isSflClick?: boolean,
        isProductService?: boolean,
    ) => void
    saveForLaterFlagEnabled?: boolean
    coreChargesTooltipTitle?: string
    inclCoreChargesLbl?: string
    coreChargesTooltipParagraph?: string
    a11yCloseIconLabel?: string
    plusCoreChargesLbl?: string
    quantityCoreFeeLabel?: string
    a11yStrikeOutPrice: string
    a11yStrikeOutPriceRange: string
    a11yQuantityInputAriaLabel: string
    envFeeTooltipTitle?: string
    inclEnvFeeLbl?: string
    plusEnvFeeLbl?: string
    envFeeTooltipParagraph?: string
    badgePriorities?: BadgePriority
    inImageBadgePriorities?: inImageBadgePriority
    badges?: string[]
    a11yServiceInstallation: string
    addonTitle?: string
    serviceAvailableMsg?: string
    addServicesCTALabel?: string
    servicesIcon?: string
    serviceAddToCartHandler?: (services: ServiceDTO[], productData?: ProductDataDTO) => void
    selectedServiceList?: CartOrderEntries[]
    quantitySelectorId?: string
    itemAvailabilityInfo: ItemInlineErrorType
    nearbyAvailabilityCTALabel: string
    bulkLabel?: string
    checkNearByCTAHandler: (pCode?: string) => void
    errorAnalyticsForInlineToast?: (errorMessage: string) => void
    getSelectedCartItems?: (isChecked: boolean, productData: ProductDataDTO) => void
    index?: number
    rebateIcon?: string
    inStorePurchaseOnlyMsg?: string
    onlineOrdering?: boolean
    language?: string
    saveLabel?: string
    clearancePriceLabel?: string
    unitPriceLabel?: string
    isCheckboxDisabled?: boolean
    thresholdValue?: number
    hideQuantitySelector?: boolean
    productDisclaimer?: string
    qtyAbbrLabel?: string
    dpBuyAgainLabel?: string
    dpLearnMoreLabel?: string
    dpDeliveryInstructions?: string
    dpInstallInstructions?: string
    discScheduleDelivery?: string
    discServiceInstallation?: string
    discCannotTrack?: string
    isDetailPage?: boolean
    isOrderConfirmationPage?: boolean
    filterVal?: string
    trackingID?: string
    status?: string
    changeBrowserBackUrl?: () => void
    scrollToFooter?: () => void
    nowFromLabel?: string
    saveFromLabel?: string
    wasFromLabel?: string
    fromLabel?: string
    feeDisclaimerType?: string
    cancelProductLabel?: string
    getEntriesInfo?: (
        showModal: boolean,
        TireGM: string,
        orderEntryNumber: number,
        orderCancellableQuantity: number,
        entryName: string,
        balloonEntryType?: string,
        isBouquetPackage?: boolean,
    ) => void
    customerSelfServiceCancelEnabled?: boolean
    enablePartialOrderCancellation: boolean
    disableAvailabilityMessages?: boolean
    setOfFourLabel?: string
    isWheelOrTire?: boolean
    isUnfinishedPackage?: boolean
    createPackageTitle?: string
    setErrorInfoHandler?: (
        isHardStopError: boolean,
        isSoftError: boolean,
        isEtaError?: boolean,
        isLimitQty?: boolean,
    ) => void
    serviceAddOnNotAvailableLabel?: string
    isPostPOSOrderDetails?: boolean
    a11yTooltipIcon?: string
    bannerMaxPurchasableQuantity?: number
    appliedPromoPrefix?: string
    enableRecommendedBalloonProducts?: boolean
    enableAddTireWheelsByFourFromWishlist?: boolean
    isShowedEstimatedDeliveryDateBetween?: boolean
    estimatedDeliveryDateBetween?: string
    saleMessagesRules?: SaleMessagesRule[]
    itemsNotEligibleForShippingMethodGeneralMsg?: string
    itemNotEligibleForExpressDeliveryMsg?: string
    itemNotEligibleForStandardDeliveryMsg?: string
    expressDeliveryDoesNotSupportHeavyOversizedMsg?: string
    insufficientQuantityOfProductForExpressDeliveryMsg?: string
    isSelectedDeliveryAvailable?: boolean
    selectedDeliveryMode?: SelectedDeliveryMode
    setIsQtyExceed?: (flag: boolean) => void
    isAllExpressProducts?: boolean
    a11yClickToReadFootnote?: string
    readyToDeliverLabel?: string
    wishlistProducts?: WishlistProduct[]
    etaTimeOfDelivery?: string
    isAuthUser?: string
    loginPageLink?: string
    cartPageLink?: string
    expressDeliveryAvailableNotification?: string
    expressDeliveryUnavailableNotification?: string
    isExpressDeliverySelected?: boolean
    plusMinusSymbol?: string
    displayRomanceCopyWithWasLabel?: boolean
    enableSingleSignOn?: boolean
    ssoHandler?: (isSFL?: boolean) => void
    enableCustomOrder?: boolean
    isHeliumInflatedUnInflatedShown?: boolean
    heliumInflationUnInflationText?: string
    heliumAppointmentInfoMessage?: string
    showInflationLabels?: boolean
    isBouquetPackage?: boolean
    productYouMayNeedBtnClick?: (pcode: string, deliveryMode: string) => void
    enableCartAccessoriesFlyout?: boolean
    prodYouMayAlsoNeedLabel?: string
    addons?: iService[]
    hardwareTitle?: string
    hardwareAddToCartCTALabel?: string
    saleEndDaySoonMessage?: string
    isShoppingCartPage?: boolean
    hideSaleEndDaySoonMessage?: boolean
    cartItemsData?: FilteredCartData
    isPriceRangeViewEnabled?: boolean
}

export interface AutoProductCartItemProps extends ProductCartItemProps {
    sizeLabel?: string
    hardwareAddToCartCTALabel?: string
    hardwareTitle?: string
    sameEtaForSameYMM?: ETAData
    autoNoGuaranteeFitMsg?: string
    setAvailabilityWarning?: (params: { display: boolean; label: string }) => void
    isTire?: boolean
    addons?: iService[]
    attributeFrontLabel?: string
    attributeRearLabel?: string
    packagedItemLabel?: string
    hardwareServices?: Record<string, unknown>
    maxNumberOfExtraHardware?: number
    messageObject?: {
        [Key: string]: string
    }
    itemType?: FulfillmentMethods
    hasWheels?: boolean
    changeShipToHomeCTALabel?: string
    pickAtStoreCTALabel?: string
    exceededMsgBar?: string
    updatedLabel?: string
    hideQuantitySelector?: boolean
    hasSelectedAddOn?: boolean
    getSelectedAddOn?: ProductDataDTO[]
    isShoppingCartPage?: boolean
    saleEndDaySoonMessage?: string
    urgentLowStock?: boolean
    limitedStockMessage?: string
    hideAccordion?: React.Dispatch<React.SetStateAction<boolean>>
    isOrderPage?: boolean
    packageIsNotCompleteMsg?: string
    completePackageMsg?: string
    completePackage?: (productWheelType: string, currentPackageId: string, vehicleInfo: VehicleInformation) => void
    checkErrorMessage?: () => CheckErrorMessageResponse
    warningToastProps?: WarningToastProps
    isCollapsed?: boolean
    setHideAccordionAfterRemove?: React.Dispatch<React.SetStateAction<boolean>>
    isBulk?: boolean
    isShowedEstimatedDeliveryDateBetween?: boolean
    estimatedDeliveryDateBetween?: string
    productWheelType?: string
    createPackageRedirect?: (productWheelType: string) => string
    productsRequirePackageCreationMessage?: boolean
    packagedItem?: boolean
    isCartPackageToggle?: boolean
    packagesWheelsPath?: string
    packagesTiresPath?: string
    createNewPackageLabelTire?: string
    createNewPackageLabelWheel?: string
    createNewPackageCTATire?: string
    createNewPackageCTAWheel?: string
    createPackageAndNavigate?: (
        event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        productData: ProductDataDTO,
    ) => void
    isPriceRangeViewEnabled?: boolean
}

export interface CheckErrorMessageResponse {
    errorMsg: string
    quantityExceeded: boolean
    exceededValue: string
}

export interface WarningToastProps {
    changeShipToHomeCTALabel: string
    pickAtStoreCTALabel: string
    exceededMsgBar: string
    nearbyAvailabilityCTALabel: string
    isSelectedDeliveryAvailable?: boolean
    selectedDeliveryMode?: SelectedDeliveryMode
    itemNotEligibleForExpressDeliveryMsg?: string
    itemNotEligibleForStandardDeliveryMsg?: string
    expressDeliveryDoesNotSupportHeavyOversizedMsg?: string
    insufficientQuantityOfProductForExpressDeliveryMsg?: string
    isQtyExceeded?: boolean
    onChangeFulfillmentOption: (option: string, entryNumber: number) => void
    checkNearByCTAHandler: (pCode?: string, isBalloonPackage?: boolean) => void
    checkErrorMessage: () => CheckErrorMessageResponse
}

export interface addOrRemoveWishlistProps {
    addItemToWishlist?: (productData: ProductDataDTO) => void
    removeitemFromWishlist?: (productData: ProductDataDTO) => void
}
export interface AutoProductCartItemsProps extends AutoProductCartItemProps {
    products: ProductDataDTO[]
}

export interface AutoProductVehicleContextCartItemProps extends AutoProductCartItemProps {
    products: ProductDataDTO[][]
    sameEtaForSameYMM?: ETAData
}

/**
 * Interface for GlobalAccessibilityProps
 * @interface
 */
export interface GlobalAccessibilityProps {
    a11yCloseIconLabel?: string
    a11yExpandText?: string
    a11yCollapseText?: string
    a11yQuantityLabel?: string
    a11yQuantityDecrementLabel?: string
    a11yQuantityIncrementLabel?: string
    a11yQuantityInputAriaLabel?: string
    a11yMinQuantityReached?: string
    a11yCarouselNextLabel?: string
    a11yCarouselPreviousLabel?: string
    a11yCarouselSelectedLabel?: string
    a11yCarouselUnselectedLabel?: string
    a11yHamburgerIcon?: string
    a11yServiceInstallation?: string
    a11yStrikeOutPrice?: string
    a11yStrikeOutPriceRange?: string
    a11yTooltipIcon?: string
    a11yUpdatedQuantityText?: string
}

/**
 * Interface for CartItemComponentPropsType
 * @interface
 */
export interface CartItemComponentPropsType {
    isService?: boolean
    isProductService?: boolean
    productServiceTitle?: string
    selectedServiceData?: CartOrderEntries
    disableQuantitySelector?: boolean
    removeService?: boolean
    showBorder: boolean
}

/**
 * Interface for ItemInlineErrorType
 * @interface
 */
export interface ItemInlineErrorType {
    showInlineError: boolean
    errorMessage: string
    optionsToBeDisplayed: string[]
    grayOutImage: boolean
    fulfillmentDeliverError?: string
    hardStopError?: boolean
    isShowETAToast?: boolean
}

export interface GetErrorFunctionPropType {
    itemData: CartOrderEntries | ProductDataDTO
    messageObject: {
        [Key: string]: string
    }
    itemType: FulfillmentMethods
    itemAvailabilityData: StockItemAvailability
}

export enum OptionsOnCartItem {
    WISHLIST = 'WISHLIST',
    CHECK_NEAR_BY = 'CHECK_NEAR_BY',
    UPDATED_TEXT = 'UPDATED_TEXT',
    PRICE_AND_QUANTITY = 'PRICE_AND_QUANTITY',
    AVAILABILITY_INFO = 'AVAILABILITY_INFO',
    ADD_TO_CART = 'ADD_TO_CART',
    IN_STOCK_TAG = 'IN_STOCK_TAG',
    STOCK_INFO = 'STOCK_INFO',
    OUT_OF_STOCK = 'OUT_OF_STOCK',
    COMING_SOON = 'COMING_SOON',
    IN_STOCK_FOR_PICK_UP = 'IN_STOCK_FOR_PICK_UP',
    IN_STOCK_FOR_PURCHASE = 'IN_STOCK_FOR_PURCHASE',
}

export enum FulfillmentErrorType {
    FULFILLMENT_STH_ERROR = 'FULFILLMENT_STH_ERROR',
    FULFILLMENT_BOPIS_ERROR = 'FULFILLMENT_BOPIS_ERROR',
}

/**
 * Interface for ProductVariant
 * @interface
 */
export interface ProductVariant {
    label: string
    value: string
}

export enum FulfillmentMethods {
    STH = 'STH',
    BOPIS = 'BOPIS',
}

export interface ETAData {
    etaEarliest: string
    etaLatest: string
}
