import { Dispatch } from '@reduxjs/toolkit'

import {
    fetchDeliveryOptionsAction,
    deliveryOptionsFailureAction,
    resetDeliveryOptionsAction,
    setIsDeliveryOptionsApiDone,
} from '../actionCreators/deliveryEligibility.actionCreators'
import { deliveryOptionsService } from '../../services/deliveryOptionsService'
import { SuccessResponse, ErrorResponse } from '../models/deliveryOptions.interface'
import appCacheService from '../../utils/appCacheService'

export const getDeliveryOptions =
    (
        postalCode: string,
        selectedPreferredStore: string | number,
        selectedSku: string | undefined,
        isBulk = false,
        isProductAndStoreAvailableForED = false,
    ) =>
    (dispatch: Dispatch): void => {
        deliveryOptionsService
            .fetchDeliveryOptions(
                postalCode,
                selectedPreferredStore,
                selectedSku,
                isBulk,
                isProductAndStoreAvailableForED,
                true,
            )
            .then((deliveryOptionsData: Record<string, SuccessResponse | ErrorResponse>) => {
                const response = deliveryOptionsData.data
                if ((response as ErrorResponse).statusCode) {
                    dispatch(deliveryOptionsFailureAction(response as unknown as ErrorResponse))
                } else {
                    appCacheService.postalCode.set(postalCode)
                    dispatch(fetchDeliveryOptionsAction({ ...(response as SuccessResponse) }))
                }
            })
            .catch((error: Record<string, ErrorResponse>) => {
                dispatch(deliveryOptionsFailureAction(error.response))
            })
            .finally(() => {
                dispatch(setIsDeliveryOptionsApiDone(false))
            })
    }
/**
 * function to reset deliveryOptions
 * @return {Dispatch}
 */
export const resetDeliveryOptions =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetDeliveryOptionsAction())
    }
